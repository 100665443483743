import React, { Component } from "react";
import css from "./default.module.css";
import External from "../../components/Buttons/External";
import TagManager from "react-gtm-module";
import { connect } from "react-redux";
import logo from "../../assets/img/new_logo.svg";

class Default extends Component {
    componentDidMount() {
        TagManager.initialize({
            gtmId: process.env.REACT_APP_GTM_ID,
            dataLayer: {
                start: new Date().getTime(),
                event: "gtm.js",
                page: "default"
            }
        });
    }

    render() {
        let queryParams = new URLSearchParams(window.location.search);
        return (
            <div className={css.main}>
                <div className={css.bg} />
                <div className={css.text_box}>
                    <img
                        src={logo}
                        alt={"Avon logo"}
                        width={177}
                        height={35}
                    />
                    <h1>Dziękujemy za zainteresowanie AVON!</h1>
                    <h4>
                        Strona której szukasz jest chwilowo niedostępna, ale nic
                        straconego! <br />
                        <br /> Dołącz do AVON w 5 minut, i korzystaj ze zniżek i
                        ofert niedostępnych dla Klientów!
                    </h4>
                    <External
                        name={"Dołącz do klubu!"}
                        src={queryParams.get("src_r") ? queryParams.get("src_r") : this.props.store.page.pageHash}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        store: state
    };
};

export default connect(mapStateToProps)(Default);
