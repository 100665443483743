import React from "react";
import Container from "../../hoc/Container";
import GoBackButton from "../../components/Buttons/GoBack";
import css from "./add.module.css";
import LoaderComponent from "../../components/LoaderComponent/LoaderComponent";
import { connect } from "react-redux";
import axios from "axios";
import TempBanner from "../../components/TempBanner/TempBanner";
import { setActivePage } from "../../redux/template/actions";
import availableImages from "../../utils/availableImages";
import { moveElemToEnd } from "../../utils/utils";

class Add extends React.Component {
    state = {
        loader: true,
        templates: ""
    };

    liiv = {
        id: 7,
        description: "Szablon LIIV"
    };

    componentDidMount() {
        const url = process.env.REACT_APP_API_URL + this.props.store.user.userNumber + process.env.REACT_APP_API_TEMPLATE;
        
        console.log('Add.componentDidMount.url', url);

        axios
            .get(url,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        authorization: `Basic ${this.props.store.user.userToken} ${this.props.store.user.userNumber}`
                    }
                }
            )
            .then(response => {
                let myTemplates = response.data.data.templates;
                moveElemToEnd(myTemplates, 10, 12);
                this.setState({
                    loader: false,
                    templates: myTemplates
                });
            })
            .catch(() => {
                this.props.history.push("/me/error");
            });
    }

    goBackHandler = () => {
        this.props.history.goBack();
    };

    goToHandler = (link, id) => {
        const url =  process.env.REACT_APP_API_URL + this.props.store.user.userNumber + process.env.REACT_APP_API_CREATE + id;
        
        console.log('Add.goToHandler.url', url);
        
        this.setState({ loader: true });
        
        axios
            .get(url,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        authorization: `Basic ${this.props.store.user.userToken} ${this.props.store.user.userNumber}`
                    }
                }
            )
            .then(response => {
                const leader = { ...response.data.leader };
                leader.pageName = "";
                leader.link = process.env.REACT_APP_PAGE_START_AVON;
                this.props.setActivePage({
                    activeTmpId: id,
                    activePageId: null,
                    edit: false,
                    template: response.data.data,
                    metadata: null,
                    leader: leader,
                    images: availableImages,
                    tags: {}
                });

                if (!id) {
                    this.props.history.push("/me/error");
                } else {
                    this.props.history.push(link);
                }
            })
            .catch(() => {
                this.props.history.push("/me/error");
            });
    };

    render() {
        let templates = "";
        if (this.state.templates) {
            templates = this.state.templates
                .filter(template => {
                    // If userNumber is '57010064', do not filter out any templates
                    // if (this.props.store.user.userNumber === '57010064' || this.props.store.user.userNumber === '32613914') {
                    //     return true;
                    // }
                    // For other users, filter out the template with id 11
                    // return template.id !== 11;
                    return true;
                })
                .map(template => {
                    return (
                        <TempBanner
                            key={template.id}
                            id={template.id}
                            description={template.description}
                            clicked={this.goToHandler}
                        />
                    );
                });
        }
        return this.state.loader ? (
            <Container>
                <LoaderComponent />
            </Container>
        ) : (
            <Container clName={"container_left"}>
                <div id={"add"}>
                    <GoBackButton clicked={this.goBackHandler} />
                    <section className={css.top_section}>
                        <h3>Wybierz szablon strony rekrutacyjnej</h3>
                        <div className={css.text}>
                            W tym miejscu zadecyduj, jak ma wyglądać Twoja
                            strona, skorzystaj z jednego z szablonów, które dla
                            Ciebie przygotowaliśmy.
                        </div>
                    </section>
                    <section className={css.pages}>{templates}</section>
                </div>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        store: state
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivePage: data => {
            dispatch(setActivePage(data));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Add);
