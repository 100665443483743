import React from "react";
import css from "./pageSectionBottom.module.css";
import IconSection from "../IconSection/IconSection";
import xmasimage from "../../assets/img/footer.jpg";
import u_image from "../../assets/img/universal/footer.jpg";
import u_image_footer from "../../assets/img/universal/footer_mobile.jpg";
import xmasimagemobile from "../../assets/img/footermobile.jpg";

class PageSectionBottom extends React.Component {
    state = {};
    updateDimensions = () => {
        this.setState({
            mobile: window.innerWidth < 900 ? true : false
        });
    };

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }
    render() {
        let bottom = css.bottom;
        if (this.props.css !== undefined) {
            bottom += " " + css[this.props.css];
        }
        return this.props.css != "xmas" && this.props.css != "universal" ? (
            <div className={bottom}>
                <div className={css.text}>
                    <h2>{this.props.template[7].content.header}</h2>
                </div>
                <IconSection
                    textSize={"big"}
                    icons={this.props.template[7].content.icons}
                    iconsPath={this.props.iconsPath}
                />
            </div>
        ) : (
            <div className={this.props.css === "xmas" ? css.xmasfooter : css.u_footer}>
                {this.state.mobile ? (
                    this.props.css === "xmas" ? (
                        <img src={xmasimagemobile} />
                    ) : (
                        <img src={u_image_footer} />
                    )
                ) : this.props.css === "xmas" ? (
                    <img src={xmasimage} />
                ) : (
                    <img src={u_image} />
                )}

                <a
                    className="play_link"
                    href="https://play.google.com/store/apps/details?id=com.avon.avonon&hl=pl&gl=US"
                ></a>
                <a
                    className="store_link"
                    href="https://apps.apple.com/us/app/avon-on/id1439105374"
                ></a>
            </div>
        );
    }
}

export default PageSectionBottom;
