import React, { Component } from "react";
import css from "./inputBox.module.css";

class InputBox extends Component {
  componentDidMount() {}

  render() {
    return (
      <div className={css.input_container}>
        <div className={css.description}>
          <p>
            {this.props.input.description} <br />
            <a href={this.props.input.href} target="_blank">
              kliknij tutaj
            </a>
            .
          </p>
        </div>
        <div className={css.input_box}>
          <div className={css.name}>
            <p>{this.props.input.name}</p>
          </div>
          <div className={css.input}>
            <input
              type={"text"}
              value={this.props.input.value}
              onChange={(event) =>
                this.props.changed(event, this.props.input.id)
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

export default InputBox;
