import React, { Component } from "react";
import css from "../ThankYou/thankYou.module.scss";
class DuplicityError extends Component {
    componentDidMount() {}

    clickHandler = () => {
        window.open("https://www2.pl.avon.com/pl-home");
    };

    render() {
        return (
            <div className={css.main}>
                <div className={css.container_content}>
                    <div className="text-box">
                        <h2>Twoje konto już istnieje.</h2>
                    </div>
                    <div className={"text-box"}>
                        <h4>
                            Jeśli nie pamiętasz hasła, kliknij "Nie pamiętam
                            hasła" <br />
                            po przejściu na stronę logowania.
                        </h4>
                    </div>
                    <div className="bottom-text-box">
                        <br />
                        <div>
                            <button type="submit" onClick={this.clickHandler}>
                                Przejdź do strony logowania
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DuplicityError;
