export const state = {
    iconsTop: [
        {
            icon: "list-xmas.svg"
        },
        {
            icon: "envelope-xmas.svg"
        },
        {
            icon: "dollar-xmas.svg"
        }
    ],
    iconsBottom: [
        {
            icon: "gift.svg"
        },
        {
            icon: "bag.svg"
        },
        {
            icon: "box.svg"
        },
        {
            icon: "house.svg"
        }
    ],
    css: {
        top: "xmas",
        middle: "xmas",
        bottom: "xmas"
    }
};
