import React from "react";
import "./container.css";
import logo from "../assets/img/logo.svg";
import { connect } from "react-redux";

const Container = props => {
    let classes = props.clName ? props.clName : "containerCustom";
    if (props.store.user.src !== "mab") classes += ` margin`;
    return (
        <div style={{ width: "100%" }}>
            {props.store.user.src === "mab" ? null : (
                <div className={"header"}>
                    <a href={"https://www2.pl.avon.com/pl-home"}>
                        <img src={logo} alt={"avon logo"} />
                    </a>
                    <div style={{height: "3px", width: "100%", background: "linear-gradient(90deg,#7f28c4 0,#e2197c 67.36%,#e5231b 100%)", marginTop: "10px"}}/>
                </div>
            )}
            <div className={classes}>{props.children}</div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        store: state
    };
};

export default connect(mapStateToProps)(Container);
