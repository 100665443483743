import React from "react";
import { loadReCaptcha } from "react-recaptcha-v3";
import { metaData, setImages, setVideo } from "../../services/service";
import Form from "../../components/Form/Form";
import Template5 from "../../components/Templates/Template5/Template5";
import Template6 from "../../components/Templates/Template6/Template6";
import Template7 from "../../components/Templates/Template7/Template7";
import Template8 from "../../components/Templates/Template8/Template8";
import Template9 from "../../components/Templates/Template9/Template9";
import Template10 from "../../components/Templates/Template10/Template10";
import Template11 from "../../components/Templates/Template11/Template11";
import css from "./landing.module.css";
import LoaderComponent from "../../components/LoaderComponent/LoaderComponent";
import axios from "axios";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import ReactPixel from "react-facebook-pixel";
import { Helmet } from "react-helmet";
import availableImages from "../../utils/availableImages";

class Landing extends React.Component {
  state = {
    metaData: {
      title: `Zapraszam na moją stronę ${window.location.pathname.replace(
        "/",
        ""
      )}`,
      description: `Pracuj tak jak lubisz. Dowiedz się więcej na ${window.location.pathname.replace(
        "/",
        ""
      )}`,
    },
    pageId: null,
    tempId: null,
    loader: true,
    tags: {},
    leader: {},
    images: { confirmed: null, ...availableImages },
    video: {
      options: {
        height: "390",
        width: "640",
        playerVars: {
          https: "//youtu.be/",
          autoplay: 0,
        },
      },
      videoId: "",
    },
  };

  componentDidMount() {
    loadReCaptcha(process.env.REACT_APP_API_RE_CAPTCHA_KEY);
    window.scrollTo(0, 0);
    TagManager.initialize({
      gtmId: process.env.REACT_APP_GTM_ID,
      dataLayer: {
        start: new Date().getTime(),
        event: "gtm.js",
      },
    });

    const pathname = window.location.pathname.replace("/", "");
    const search = window.location.search;

    let newState = { ...this.state };

    axios
      .get(`${process.env.REACT_APP_API_LANDING_URL}${pathname}${search}`)
      .then((res) => {
        const landingPage = res.data.data.landingPage;
        const userData = landingPage.userdata;

        newState.tags = {
          ga: landingPage.statGa,
          gtm: landingPage.statGtm,
          gr: landingPage.statRm,
          fp: landingPage.statFb,
        };

        if (landingPage.statGa) {
          ReactGA.initialize(landingPage.statGa);
          ReactGA.pageview(window.location.pathname + window.location.search);
        }

        if (landingPage.statGtm) {
          TagManager.initialize({
            gtmId: landingPage.statGtm,
          });
        }

        if (landingPage.statFb) {
          const advancedMatching = { em: res.data.data.leaderEmail }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
          const options = {
            autoConfig: true, // set pixel's autoConfig
            debug: false, // enable logs
          };
          ReactPixel.init(landingPage.statFb, advancedMatching, options);

          ReactPixel.pageView(); // For tracking page view
        }

        newState.leader = {
          name: userData.name,
          email: userData.email ? userData.email : res.data.data.leaderEmail,
          phone: userData.phone,
          leaderId: landingPage.leaderId,
        };
        newState.template = landingPage.PageElements;
        newState.pageName = landingPage.name;
        newState.metaData = metaData(landingPage);
        newState.images = {
          ...setImages(newState.images, {
            template: landingPage.PageElements,
          }),
        };
        newState.video.videoId = setVideo(this.state.video.videoId, {
          template: landingPage.PageElements,
        });
        newState.tempId = landingPage.templateId;
        newState.pageId = landingPage.id;
        newState.loader = false;

        this.setState({ ...newState });
      })
      .catch((err) => {
        this.defaultPage();
      });
  }

  template = () => {
    switch (this.state.tempId) {
      case 5:
        return (
          <Template5
            view={true}
            temp={this.state.template}
            img={this.state.images.confirmed}
            video={this.state.video}
          />
        );
      case 6:
        return (
          <Template6
            view={true}
            temp={this.state.template}
            img={this.state.images.confirmed}
            video={this.state.video}
          />
        );
      case 7:
        return (
          <Template7
            view={true}
            temp={this.state.template}
            img={this.state.images.confirmed}
            video={this.state.video}
          />
        );

      case 8:
        return (
          <Template8
            view={true}
            temp={this.state.template}
            img={this.state.images.confirmed}
            video={this.state.video}
          />
        );
      case 9:
        return (
          <Template9
            view={true}
            temp={this.state.template}
            img={this.state.images.confirmed}
            video={this.state.video}
          />
        );
      case 10:
        return (
          <Template10
            view={true}
            temp={this.state.template}
            img={this.state.images.confirmed}
            video={this.state.video}
          />
        );
      case 11:
        return (
          <Template11
            view={true}
            temp={this.state.template}
            img={this.state.images.confirmed}
            video={this.state.video}
            landing={true}
            uplnEmail={this.state.leader.email}
            phone={this.state.leader.phone}
            leaderId={this.state.leader.leaderId}
            pageId={this.state.pageId}
            tags={this.state.tags}
            tempId={this.state.tempId}
            name={this.state.leader.name} 
          />
        );
      default:
        return (
          <Template5
            view={true}
            temp={this.state.template}
            img={this.state.images.confirmed}
            video={this.state.video}
          />
        );
    }
  };

  defaultPage = () => {
    this.props.history.push("/default");
  };

  render() {
    const temp = this.template();
    return this.state.loader ? (
      <LoaderComponent />
    ) : (
      <div className={css.landing + " temp11Landing"}>
        <Helmet
          htmlAttributes={{ lang: "pl" }}
          title={this.state.metaData.title}
          meta={[
            {
              name: "description",
              content: `${this.state.metaData.description}`,
            },
            {
              property: "og:url",
              content: `${window.location.href}`,
            },
            {
              property: "og:type",
              content: "website",
            },
            {
              property: "og:title",
              content: `${this.state.metaData.title}`,
            },
            {
              property: "og:description",
              content: "website",
            },
            {
              property: "fb:app_id",
              content: "444908419970087",
            },
          ]}
        />
        {temp}
        {this.state.tempId !== 11 && (
          <Form
            landing={true}
            uplnEmail={this.state.leader.email}
            phone={this.state.leader.phone}
            leaderId={this.state.leader.leaderId}
            pageId={this.state.pageId}
            tags={this.state.tags}
            tempId={this.state.tempId}
          />
        )}
      </div>
    );
  }
}

export default Landing;
