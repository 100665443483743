import React, { Component } from "react";
import axios from "axios";
import { ReCaptcha } from "react-recaptcha-v3";
import Input2 from "./Input/Input2";
import { DatePicker } from 'react-rainbow-components';
import { Calendar } from "lucide-react"; // Dodajemy ikonę kalendarza
import Agreement1 from "./Agreements/Agreement1";
import Agreement2 from "./Agreements/Agreement2";
import ThankYou2 from "../ThankYou/ThankYou2";
import * as validators from "../../utils/formValidators";
import css from "./form2.module.css";
import { formState } from "../../states/form2";
import TagManager from "react-gtm-module";
import DuplicityError2 from "../DuplicityError/DuplicityError2";
import logo from "../../assets/img/avon_white.png";
import arrowDown from "../../assets/img/new_template/arrow-down.svg";
import { pl } from "date-fns/locale";

class Form2 extends Component {
  constructor() {
    super();
    this.scrollToBottom = this.scrollToBottom.bind(this); // Bind the new handler
  }
  state = {
    birthdate: null,
    isDatePickerFocused: false, // New state property to track focus
  };

  componentDidMount() {
    const newState = { ...this.state };
    newState.uplineEmail = this.props.uplnEmail;
    newState.leaderId = this.props.leaderId;
    newState.utm_term = this.props.utm_term ? this.props.utm_term : "";
    newState.toggleClass = css.form_container;
    newState.test = this.props.test ? this.props.test : newState.test;

    if (this.props.landing) newState.landing = this.props.landing;

    this.setState({ ...newState });
  }

  scrollToBottom() {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth', // This makes the scrolling smooth
    });
  }


  handleDatePickerFocus = () => this.setState({ isDatePickerFocused: true });
  handleDatePickerBlur = () => this.setState({ isDatePickerFocused: false });

  formTrackingCode = (data) => {
    // FormTrackingCodes.trackLeadForm(data);
  };

  getUtmTerm = () => {
    return document.getElementById("utm_term").dataset["utm_term"] || "";
  };

  state = formState;

  maxDate = () => {
    let year = new Date();
    year.setFullYear(year.getFullYear() - 16); // min age set to 16
    return year;
  };

  // validators

  validateForm = () => {
    let inputs = [...this.state.inputs];
    let isValid = true;

    // eslint-disable-next-line array-callback-return
    inputs.map((input, index) => {
      const inputId = input.id;
      if (input.required) {
        switch (inputId) {
          case "firstName":
          case "lastName":
            if (!validators.validateNameValue(input.value)) {
              isValid = false;
              inputs[index].error = true;
            } else {
              inputs[index].error = false;
            }
            break;
          case "Email":
            if (!validators.validateEmail(input.value)) {
              isValid = false;
              inputs[index].error = true;
            } else {
              inputs[index].error = false;
            }
            break;
          case "Phone":
            if (!validators.validatePhone(input.value)) {
              isValid = false;
              inputs[index].error = true;
            } else {
              inputs[index].error = false;
            }
            break;
          case "Street":
            if (!validators.validateStreetName(input.value)) {
              isValid = false;
              inputs[index].error = true;
            } else {
              inputs[index].error = false;
            }
            break;
          case "StreetNr":
            if (!validators.validateStreetNo(input.value)) {
              isValid = false;
              inputs[index].error = true;
            } else {
              inputs[index].error = false;
            }
            break;
          case "PostCode":
            if (!validators.validatePostCode(input.value)) {
              isValid = false;
              inputs[index].error = true;
            } else {
              inputs[index].error = false;
            }
            break;
          case "City":
            if (!validators.validateCityName(input.value)) {
              isValid = false;
              inputs[index].error = true;
            } else {
              inputs[index].error = false;
            }
            break;
          default:
            break;
        }
      } else {
        if (input.value && input.id === "BirthDate") {
          if (
            new Date(input.value) > this.maxDate() ||
            input.value === "Invalid date"
          ) {
            isValid = false;
            inputs[index].error = true;
          } else if (input.value != null) {
            inputs[index].error = false;
          }
        } else if (input.value && input.id === "ApartmentNr") {
          if (!validators.validateAptNo(input.value)) {
            isValid = false;
            inputs[index].error = true;
          } else {
            inputs[index].error = false;
          }
        } else inputs[index].error = false;
      }
    });

    this.setState({ inputs: inputs });

    return isValid;
  };

  validateCheckboxes = () => {
    let checkboxes = [...this.state.checkboxes];
    let isValid = true;

    // eslint-disable-next-line array-callback-return
    checkboxes.map((checkbox, index) => {
      if (checkbox.required) {
        checkboxes[index].error = !document.getElementById(checkbox.id).checked;
        isValid = document.getElementById(checkbox.id).checked;
      } else {
        checkboxes[index].error = false;
      }
    });

    this.setState({ checkboxes: checkboxes });
    return isValid;
  };

  dateToSendParser = (date) => {
    if (!date) {
      return "";
    }

    const y = date.getFullYear();
    const m = date.getMonth() + 1;
    const d = date.getDate();
    const mm = m < 10 ? "0" + m : m;
    const dd = d < 10 ? "0" + d : d;

    return `${y}-${mm}-${dd}`;
  };

  prepareDataToSend = () => {
    const inputs = this.state.inputs;
    const checkboxes = this.state.checkboxes;
    let requestJson = {
      recaptchaToken: this.state.recaptchaToken || null,
      frstNm: "frstNm", // First name
      lastNm: "lastNm", // Last name
      emailAddrTxt: "emailAddrTxt", // Email
      mobilePhoneNr: "mobilePhoneNr", // Mobile phone number
      rcrtrId: this.state.leaderId, // leader id this.state.leaderId
      apptSrceCd: this.state.appSrcCode, // Application source code
      utmTerm: this.state.utm_term,
      addresses: [
        {
          addrLocTyp: "HOME",
          postCd: "postCd", // Post Code
          city: "city", // City
          strAddr1Txt: "strAddr1Txt", // Street
          strAddr2Txt: "strAddr2Txt", // Apartment Number
          strAddr3Txt: "", // Apartment Number
        },
      ],
      agrmnts: [
        {
          agrmntTyp: "agrmntInd",
          agrmntVerNr: "1",
          agrmntAcptdInd: false,
        },
        {
          agrmntTyp: "prsnlDataStorgInd",
          agrmntVerNr: "1",
          agrmntAcptdInd: false,
        },
        {
          agrmntTyp: "prvcyAgrmntInd",
          agrmntVerNr: "1",
          agrmntAcptdInd: false,
        },
      ],
      cmnctn: [
        {
          comnctnTyp: "ALWD",
          email: false,
          phone: false,
          sms: false,
        },
      ],
    };

    // eslint-disable-next-line array-callback-return
    inputs.map((input) => {
      if (input.id === "firstName") {
        requestJson.frstNm = input.value;
      }

      if (input.id === "BirthDate") {
        // change dat format to 1989-01-01
        const myDate = this.dateToSendParser(input.value);
        requestJson.birthDate = myDate;
      }

      if (input.id === "lastName") {
        requestJson.lastNm = input.value;
      }

      if (input.id === "Email") {
        requestJson.emailAddrTxt = input.value;
      }

      if (input.id === "Phone") {
        requestJson.mobilePhoneNr = "48" + input.value.replace(/ /g, "");
      }

      if (input.id === "Street") {
        requestJson.addresses.map((address, i) => {
          requestJson.addresses[i].strAddr1Txt = input.value;
        });
      }

      if (input.id === "StreetNr") {
        requestJson.addresses.map((address, i) => {
          requestJson.addresses[i].strAddr2Txt = input.value;
        });
      }

      if (input.id === "ApartmentNr") {
        requestJson.addresses.map((address, i) => {
          requestJson.addresses[i].strAddr3Txt = input.value;
        });
      }

      if (input.id === "PostCode") {
        requestJson.addresses.map((address, i) => {
          requestJson.addresses[i].postCd = input.value;
        });
      }

      if (input.id === "City") {
        requestJson.addresses.map((address, i) => {
          requestJson.addresses[i].city = input.value;
        });
      }
    });

    checkboxes.map((checkbox) => {
      if (checkbox.id === "Agreement1") {
        requestJson.agrmnts.map((agreement, i) => {
          requestJson.agrmnts[i].agrmntAcptdInd = checkbox.isChecked;
        });
      }

      if (checkbox.id === "Agreement2") {
        requestJson.cmnctn.map((agreement, i) => {
          requestJson.cmnctn[i].email = checkbox.isChecked;
          requestJson.cmnctn[i].phone = checkbox.isChecked;
          requestJson.cmnctn[i].sms = checkbox.isChecked;
        });
      }
    });
    return requestJson;
  };

  submitHandler = (event) => {
    event.preventDefault();

    if (this.validateForm() && this.validateCheckboxes()) {
      this.setState({ isFormSent: true });
      const errorFunc = this.prepareErrorResponse;
      const successFunc = this.prepareSuccessResponse;
      if (this.state.test) {
        successFunc({ data: { id: 123, apptId: 123 } });
      } else {
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(process.env.REACT_APP_API_RE_CAPTCHA_KEY, {
              action: "submit",
            })
            .then((token) => {
              this.setState({ recaptchaToken: token });
              axios
                .post(
                  process.env.REACT_APP_FORM_API,
                  this.prepareDataToSend(),
                  {
                    headers: { "Content-Type": "application/json" },
                  }
                )
                .then(function(response) {
                  successFunc(response);
                })
                .catch(function(error) {
                  if (!error.response) {
                    errorFunc("");
                  } else {
                    errorFunc(error.response.data.errCd);
                  }
                });
            });
        });
      }
    }
  };

  prepareErrorResponse = (code) => {
    const inputs = this.state.inputs;

    switch (code) {
      case "DUPLICITY_REP_EMAIL":
        // eslint-disable-next-line array-callback-return
        inputs.map((input, i) => {
          if (input.id === "Email") {
            inputs[i].error = true;
            inputs[i].correct = false;
            inputs[i].errorMessage = "Podany adres jest już wykorzystywany";
          }
        });
        break;
      case "DUPLICITY_REP_MOBILE":
        // eslint-disable-next-line array-callback-return
        inputs.map((input, i) => {
          if (input.id === "Phone") {
            inputs[i].error = true;
            inputs[i].correct = false;
            inputs[i].errorMessage =
              "Podany nr telefonu jest już wykorzystywany";
          }
        });
        break;
      case "MULTIPLE_ACCOUNTS_NOT_REAPPOINTABLE":
      case "DUPLICITY_APPOINTMENT_NOT_POSSIBLE":
      case "DUPLICITY_REINSTATABLE_REPRESENTATIVE":
        this.setState({ errorDuplicity: true, error: true });
        break;
      default:
        this.setState({ error: true });
        break;
    }

    this.setState({ inputs: inputs });
    this.setState({ isFormSent: false });
  };

  prepareSuccessResponse = (response) => {
    this.setState({
      responseSuccess: true,
      duplicityError: false,
      error: false,
    });
    this.sendTempId({
      leader_id: this.props.leaderId,
      pageId: this.props.pageId,
      consultantId: response.data.id,
    });

    if (this.props.tags.gtm !== null) {
      const gtmClientTag = {
        gtmId: this.props.tags.gtm,
        dataLayer: {
          event: "formularz wysłany poprawnie",
        },
      };

      TagManager.initialize(gtmClientTag);
    }

    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GTM_ID,
      dataLayer: {
        event: "typ",
        leadNumber: response.data.id,
        typeForm: " social_prospecting",
        attachedId: this.state.leaderId,
        repId: response.data.apptId,
      },
    };

    TagManager.initialize(tagManagerArgs);
  };

  sendTempId = (data) => {
    axios
      .post(process.env.REACT_APP_FORM_API_TEMP_ID, data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  changeInputHandler = (event, id) => {
    const inputIndex = this.state.inputs.findIndex((i) => {
      return i.id === id;
    });

    const input = {
      ...this.state.inputs[inputIndex],
    };

    switch (input.id) {
      case "firstName":
      case "lastName":
        if (!validators.validateNameValue(event.target.value)) {
          input.error = true;
          input.class = "errorBg";
          input.correct = false;
        } else {
          input.error = false;
          input.class = "correct";
          input.correct = true;
        }
        input.value = event.target.value.substr(
          0,
          event.target.getAttribute("maxChars")
        );
        break;

      case "Email":
        if (!validators.validateEmail(event.target.value)) {
          input.error = true;
          input.class = "errorBg";
          input.correct = false;
        } else {
          input.error = false;
          input.class = "correct";
          input.correct = true;
        }
        input.value = event.target.value.substr(
          0,
          event.target.getAttribute("maxChars")
        );
        break;
      case "Street":
        if (!validators.validateStreetName(event.target.value)) {
          input.error = true;
          input.class = "errorBg";
          input.correct = false;
        } else {
          input.error = false;
          input.class = "correct";
          input.correct = true;
        }
        input.value = event.target.value.substr(
          0,
          event.target.getAttribute("maxChars")
        );
        break;
      case "StreetNr":
        if (!validators.validateStreetNo(event.target.value)) {
          input.error = true;
          input.class = "errorBg";
          input.correct = false;
        } else {
          input.error = false;
          input.class = "correct";
          input.correct = true;
        }
        input.value = event.target.value.substr(
          0,
          event.target.getAttribute("maxChars")
        );
        break;
      case "ApartmentNr":
        input.value = event.target.value.substr(
          0,
          event.target.getAttribute("maxChars")
        );
        if (input.value && !validators.validateAptNo(event.target.value)) {
          input.error = true;
          input.class = "errorBg";
          input.correct = false;
        } else {
          input.error = false;
          input.class = "correct";
          input.correct = true;
        }

        break;
      case "City":
        if (!validators.validateCityName(event.target.value)) {
          input.error = true;
          input.class = "errorBg";
          input.correct = false;
        } else {
          input.error = false;
          input.class = "correct";
          input.correct = true;
        }
        input.value = event.target.value.substr(
          0,
          event.target.getAttribute("maxChars")
        );
        break;

      case "PostCode":
        const notValidPostCode = !validators.validatePostCode(
          event.target.value
        );
        let eValue = event.target.value;
        if (eValue.length > 2 && eValue.indexOf("-") === -1) {
          String.prototype.splice = function(idx, rem, str) {
            return this.slice(0, idx) + str + this.slice(idx + Math.abs(rem));
          };
          input.value = eValue.splice(2, 0, "-");
        } else if (eValue.length <= 6 && notValidPostCode) {
          input.error = true;
          input.class = "errorBg";
          input.correct = false;
          input.value = event.target.value;
        } else if (eValue.length > 6) {
          return;
        } else {
          input.error = false;
          input.class = "correct";
          input.correct = true;
          if (!notValidPostCode) {
            input.value = event.target.value;
          }
        }
        break;

      case "Phone":
        const notValid = !validators.validatePhone(event.target.value);
        if (notValid && event.target.value.length <= 9) {
          input.value = event.target.value;
          input.error = true;
          input.class = "errorBg";
          input.correct = false;
        } else {
          if (!notValid) {
            input.value = event.target.value;
            input.error = false;
            input.class = "correct";
            input.correct = true;
          }
        }
        break;
      case "BirthDate":
        // Obsługa zmiany daty
        input.value = event;
        this.setState({ birthdate: input.value });
        if (
          (input.value && new Date(event) > this.maxDate()) ||
          isNaN(new Date(event).getTime()) // More robust check for an invalid date
        ) {
          input.error = true;
          input.class = "errorBg";
          input.correct = false;
        } else if (input.value != null && input.value !== "") {
          // Ensure input.value is not null or an empty string
          input.error = false;
          input.class = "correct";
          input.correct = true;
        }

        break;
      default:
        input.value = event.target.value;
        break;
    }

    const inputs = [...this.state.inputs];
    inputs[inputIndex] = input;

    this.setState({ inputs: inputs });
  };

  changeCheckboxHandler = (event, id) => {
    const checkboxIndex = this.state.checkboxes.findIndex((i) => {
      return i.id === id;
    });

    const checkbox = {
      ...this.state.checkboxes[checkboxIndex],
    };

    if (checkbox.isChecked) {
      checkbox.isChecked = false;
      if (checkbox.id === "Agreement1") {
        checkbox.error = true;
      }
    } else {
      checkbox.isChecked = true;
      checkbox.error = false;
    }
    const checkboxes = [...this.state.checkboxes];
    checkboxes[checkboxIndex] = checkbox;

    this.setState({ checkboxes: checkboxes });
  };

  mobileFormToggleHandler = () => {
    if (this.state.formOpened) {
      this.setState({
        formOpened: false,
        toggleClass: `${css.form_container}`,
      });
    } else {
      this.setState({
        formOpened: true,
        toggleClass: `${css.form_container} ${css.open}`,
      });
    }
  };

  verifyCallback = (recaptchaToken) => {
    this.setState({ recaptchaToken: recaptchaToken });
  };

  render() {
    // let inputs = this.state.inputs.map((input) => {
    //   if (input.id === "uplnEmial") {
    //     return <Input2 editable={false} input={input} key={input.id} />;
    //   } else {
    //     return (
    //       <Input2
    //         editable={true}
    //         input={input}
    //         key={input.id}
    //         changed={(event) => this.changeInputHandler(event, input.id)}
    //       />
    //     );
    //   }
    // });

    const personalInfoInputs = this.state.inputs.filter((input) =>
      ["firstName", "lastName", "BirthDate", "Phone", "Email"].includes(
        input.id
      )
    );

    const addressInputs = this.state.inputs.filter((input) =>
      ["Street", "StreetNr", "ApartmentNr", "City", "PostCode"].includes(
        input.id
      )
    );

    return (
      <div
        className={
            this.state.toggleClass + " mb-4"
        }
      >
        <ReCaptcha
          sitekey={process.env.REACT_APP_API_RE_CAPTCHA_KEY}
          action="form_action"
          verifyCallback={this.verifyCallback}
        />

        {this.state.responseSuccess ? (
          // this.state.responseSuccess
          <ThankYou2 />
        ) : this.state.errorDuplicity ? (
          <DuplicityError2/>
        ) : (
          <div className={css.form_box + " py-4"}>
            {this.state.error ? (
              <div style={{ color: "red" }}>
                Wystąpił błąd podczas przesyłania danych. Odczekaj chwilę i
                spróbuj ponownie.
              </div>
            ) : (
              <div>
                <div className={[css.logo, css.mobile].join(" ")}>
                  <img src={logo} alt="logo" />
                </div>
                <div>
                  <h5>
                    <span className={css.secondary}>WYPEŁNIJ</span> FORMULARZ
                  </h5>
                  <p className="mb-4">
                    W ciągu 5 minut otrzymasz e-mail i SMS z jednorazowym hasłem
                    do logowania.
                  </p>
                </div>
              </div>
            )}
            <form name="consultantForm" id="consultantForm">
             <div className="mb-3"><b>DANE OSOBOWE</b></div>
              <div className={css.input_grid}>
                {personalInfoInputs.map((input) => {
                  if (input.id === "BirthDate") {
                    return (
                      <div
                        key={input.id}
                        className={
                          css.date_picker_wrapper + " " + css.half_width
                        }
                      >
                        <div
                          className={
                            this.state.isDatePickerFocused
                              ? css.focusedPlaceholder
                              : css.customPlaceholder
                          }
                        >
                          Data urodzenia
                        </div>

                        <DatePicker
                          value={this.state.birthdate}
                          onChange={(date) =>
                            this.changeInputHandler(date, "BirthDate")
                          }
                          dateFormat="dd.MM.yyyy"
                          onFocus={this.handleDatePickerFocus}
                          className={`${css.date_picker_input} ${
                            this.state.isDatePickerFocused ||
                            this.state.birthdate
                              ? css.focused
                              : ""
                          }`}
                          id="BirthDate"
                          locale={pl} // Ustawienie lokalizacji na polską
                        />
                        {input.error ? (
                          <div className={css.errorBox}>
                            <div className={css.errorBoxContent}>
                              <div className={css.error}>
                                {input.value === "Invalid date"
                                  ? "Wybierz poprawną datę"
                                  : input.errorMessage}
                              </div>
                              <img src="/img/icons/exclamation.svg" />
                            </div>
                          </div>
                        ) : null}
                      </div>
                    );
                  }
                  return (
                    <Input2
                      editable={true}
                      input={input}
                      key={input.id}
                      changed={(event) =>
                        this.changeInputHandler(event, input.id)
                      }
                      className={css.form_input}
                    />
                  );
                })}
              </div>

              <div className="mb-3 mt-4"><b>DANE ADRESOWE</b></div>
              <div className={css.input_grid}>
                {addressInputs.map((input) => (
                  <Input2
                    editable={true}
                    input={input}
                    key={input.id}
                    changed={(event) =>
                      this.changeInputHandler(event, input.id)
                    }
                  />
                ))}
              </div>

              <Agreement1
                input={this.state.checkboxes[0]}
                checkmarkClass={css.agreement_checkmark || ''}
                agreementClass={css.agreementClass || ''}
                changed={(event) =>
                  this.changeCheckboxHandler(event, this.state.checkboxes[0].id)
                }
              />
              <Agreement2
                input={this.state.checkboxes[1]}
                checkmarkClass={css.agreement_checkmark || ''}
                agreementClass={css.agreementClass || ''}
                changed={(event) =>
                  this.changeCheckboxHandler(event, this.state.checkboxes[1].id)
                }
              />
              {this.state.isFormSent ? (
                <div>Zaczekaj, przetwarzamy Twoje zgłoszenie</div>
              ) : (
                <div className="d-flex align-items-center">
                  <button
                    data-action="submit"
                    disabled={this.state.test || this.props.test}
                    type="submit"
                    onClick={(e) => this.submitHandler(e)}
                  >
                   DOŁĄCZ DO MOJEGO ZESPOŁU
                  </button>
                  <div className="ms-3">
                    <img className={css.pointer + " d-lg-block d-none"} src={arrowDown
                    } alt="arrow" onClick={this.scrollToBottom} />
                  </div>
                </div>
              )}
            </form>
          </div>
        )}
        {/* <FormFooter
              opened={this.state.formOpened}
              email={this.props.uplnEmail}
              phone={this.props.phone}
            /> */}
      </div>
    );
  }
}

export default Form2;
