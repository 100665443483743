import React from "react";
import DatePickerDropdown from "../DatePickerDropdown/DatePickerDropdown";
import css from "./Input.module.css";

const Input = data => {
    let error = css.error;

    if (["Street", "PostCode"].includes(data.input.id)) {
        error += ` ${css.left}`;
    }

    const minDate = () => {
        let year = new Date();
        year.setFullYear(year.getFullYear() - 120); // max age
        return year;
    };

    const maxDate = () => {
        let year = new Date();
        // year.setFullYear(year.getFullYear() - 16); // min age set to 16
        return year;
    };
    return (
        <div
            className={
                data.input.divClasses ? data.input.divClasses + " flex" : "flex"
            }
        >
            <label className={css.textInput + " text-input"}>
                {data.editable ? (
                    data.input.id === "BirthDate" ? (
                        <div>
                            <div className={css.pickerlabel}>
                                Data urodzenia:
                            </div>
                            <DatePickerDropdown dateChange={data.changed} />
                            <input
                                readOnly
                                type={data.input.type}
                                name={data.input.name}
                                id="BirthDate"
                                className={data.input.class}
                                value={data.input.value}
                            />
                        </div>
                    ) : (
                        <input
                            type={data.input.type}
                            name={data.input.name}
                            id={data.input.id}
                            className={data.input.class}
                            value={data.input.value}
                            onChange={data.changed}
                            placeholder={data.input.placeholder}
                            maxChars={data.input.maxlength}
                        />
                    )
                ) : (
                    <input
                        readOnly
                        type={data.input.type}
                        name={data.input.name}
                        id={data.input.id}
                        className={data.input.class}
                        value={data.input.value}
                    />
                )}
                {data.input.error ? (
                    <div className={css.errorBox}>
                        <div className={css.errorBoxContent}>
                            <div className={error}>
                                {data.input.value === "Invalid date"
                                    ? "Wybierz poprawną datę"
                                    : data.input.errorMessage}
                            </div>
                            <img
                                src="/img/icons/exclamation.svg"
                            />
                        </div>
                    </div>
                ) : null}
                {data.input.correct ? (
                    <span className={css.correct}></span>
                ) : null}
            </label>
        </div>
    );
};

export default Input;
