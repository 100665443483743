export const state = {
    iconsTop: [
        {
            icon: "listliiv.svg"
        },
        {
            icon: "envelopeliiv.svg"
        },
        {
            icon: "dollarliiv.svg"
        }
    ],
    iconsBottom: [
        {
            icon: "giftliiv.svg"
        },
        {
            icon: "bagliiv.svg"
        },
        {
            icon: "boxliiv.svg"
        },
        {
            icon: "houseliiv.svg"
        }
    ],
    css: {
        top: "liiv",
        middle: "liiv",
        bottom: "liiv"
    }
};
