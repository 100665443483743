import * as cons from "../const";

const INITIAL_STATE = {
    activePageId: null,
    activeTmpId: null,
    edit: false,
    template: {},
    leader: {},
    images: {},
    tags: {}
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case cons.SET_ACTIVE_PAGE:
            return {
                ...state,
                ...action.payload
            };
        case cons.SET_ACTIVE_TEMPLATE_CONTENT:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};

export default reducer;
