export const formState = {
    errorDuplicity: false,
    test: false,
    toggleClass: "",
    formOpened: false,
    landing: false,
    error: false,
    responseSuccess: false,
    uplineEmail: "",
    appSrcCode: "52",
    utm_term: "",
    inputs: [
        {
            name: "firstName",
            value: "",
            id: "firstName",
            type: "text",
            error: false,
            errorMessage: "Wpisz poprawnie imię bez znaków specjalnych.",
            correct: null,
            required: true,
            placeholder: "Imię *",
            maxlength: 38
        },
        {
            name: "lastName",
            value: "",
            id: "lastName",
            type: "text",
            error: false,
            errorMessage: "Wpisz poprawnie nazwisko bez znaków specjalnych.",
            correct: null,
            required: true,
            placeholder: "Nazwisko *",
            maxlength: 38
        },
        {
            name: "birthDate",
            value: "",
            id: "BirthDate",
            type: "date",
            error: false,
            errorMessage: "Minimalny wiek to 16 lat",
            correct: null,
            required: false,
            divClasses: "birth-date",
            placeholder: "Data urodzenia",
            maxlength: 10
        },
        {
            name: "phone",
            value: "",
            id: "Phone",
            type: "number",
            error: false,
            errorMessage: "Nieprawidłowy numer telefonu",
            correct: null,
            required: true,
            divClasses: "phone",
            placeholder: "Numer kontaktowy *",
            maxlength: 9
        },
        {
            name: "email",
            value: "",
            id: "Email",
            type: "email",
            error: false,
            errorMessage: "Wpisz poprawny adres e-mail.",
            correct: null,
            required: true,
            placeholder: "Email *",
            maxlength: 38
        },
        {
            name: "street",
            value: "",
            id: "Street",
            type: "text",
            error: false,
            errorMessage: "Wpisz poprawną nazwę ulicy.",
            correct: null,
            required: true,
            placeholder: "Ulica *",
            divClasses: "street",
            maxlength: 20
        },
        {
            name: "streetNr",
            value: "",
            id: "StreetNr",
            type: "text",
            error: false,
            errorMessage: "Wpisz poprawny numer domu.",
            correct: null,
            required: true,
            placeholder: "Nr domu *",
            divClasses: "street-no",
            maxlength: 6
        },
        {
            name: "apartmentNr",
            value: "",
            id: "ApartmentNr",
            type: "text",
            error: false,
            errorMessage: "Wpisz poprawny numer mieszkania.",
            correct: null,
            required: false,
            placeholder: "Nr lokalu",
            divClasses: "apartment-no",
            maxlength: 6
        },
        {
            name: "City",
            value: "",
            id: "City",
            type: "text",
            error: false,
            errorMessage: "Wpisz poprawną nazwę miejscowości.",
            correct: null,
            required: true,
            placeholder: "Miejscowość *",
            divClasses: "city",
            maxlength: 38
        },
        {
            name: "postCode",
            value: "",
            id: "PostCode",
            type: "text",
            error: false,
            errorMessage: "Wpisz poprawny kod.",
            correct: null,
            required: true,
            placeholder: "Kod pocztowy *",
            divClasses: "post-code",
            maxlength: 6
        },
    ],
    checkboxes: [
        {
            name: "Agreement1",
            id: "Agreement1",
            type: "checkbox",
            error: false,
            errorMessage: "Zgoda jest wymagana",
            required: true,
            isChecked: false
        },
        {
            name: "Agreement2",
            id: "Agreement2",
            type: "checkbox",
            error: false,
            errorMessage: "Zgoda jest wymagana",
            required: false,
            isChecked: false
        }
    ],
    isFormSent: false
};
