import React from "react";
import { state } from "../../../states/tem9";
import PageSection from "../PageSection";

class Template9 extends React.Component {
    state = state;
    render() {
        return (
            <PageSection
                view={this.props.view}
                temp={this.props.temp}
                iconsTop={this.state.iconsTop}
                iconsBottom={this.state.iconsBottom}
                margin={this.props.margin}
                img={this.props.img}
                video={this.props.video}
                css={this.state.css}
            />
        );
    }
}

export default Template9;
