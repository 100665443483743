import React from "react";
import css from "./solid.module.css";

class ButtonSolid extends React.Component {
    state = {
        className: css.btn,
        buttonText: this.props.name
    };

    componentDidMount() {
        let localState = this.state.className;

        if (this.props.active === 0) {
            this.setState({ buttonText: "Włącz" });
        }

        if (this.props.inactive === 1 || this.props.active === 0) {
            localState += " " + css.btn_inactive;
        }

        if (this.props.narrow === 1) {
            localState += " " + css.narrow;
        }

        this.setState({ className: localState });
    }

    render() {
        if (this.props.active) {
            return (
                <button className={this.state.className}>
                    {this.state.buttonText}
                </button>
            );
        }

        return this.props.linkTo ? (
            <button
                className={this.state.className}
                onClick={() =>
                    this.props.clicked(this.props.linkTo, this.props.id)
                }
            >
                {this.state.buttonText}
            </button>
        ) : (
            <button className={this.state.className}>
                {this.state.buttonText}
            </button>
        );
    }
}

export default ButtonSolid;
