import React from "react";
import Container from "../../hoc/Container";
import Content from "../../components/Content/Content";
import Table from "../../components/Table/Table";
import LoaderComponent from "../../components/LoaderComponent/LoaderComponent";
import {
    prepareData,
    callApi,
    pageOnOff,
    getTempData,
    shareLinks,
    getStatistics
} from "../../services/service";
import { connect } from "react-redux";
import * as cons from "../../redux/const";
import PopUp from "../../hoc/PopUp/PopUp";
import Share from "../../components/Share/Share";
import {
    setActivePage,
    setActivePageStatistics
} from "../../redux/template/actions";
import { setStatistics } from "../../redux/statistics/actions";
import { ACTIVE_PAGES_LIMIT } from "../../utils/const";

class Homepage extends React.Component {
    state = {
        createPage: true,
        loading: true,
        popup: false,
        shareData: {}
    };
    componentDidMount() {
        if (
            !this.props.store.user.userNumber ||
            !this.props.store.user.userToken
        ) {
            this.errorHandler();
        } else {
            prepareData(this.props.store.user)
                .then(data => {
                    return callApi(data);
                })
                .then(response => {
                    this.props.dispatchData(response.data.data);

                    this.setState({
                        // createPage: response.data.data.count < ACTIVE_PAGES_LIMIT,
                        loading: false,
                        auth: response.data.status,
                        pages: response.data.data.rows
                    });
                })
                .catch(err => {
                    this.errorHandler();
                });
        }
    }

    loaderOn = () => {
        this.setState({ loading: true });
    };

    editHandler = id => {
        this.loaderOn();
        getTempData(this.props.store.user, id)
            .then(pageTemp => {
                this.props.setActivePage(pageTemp);
                this.props.history.push("/me/edit");
            })
            .catch(err => {
                this.errorHandler();
            });
    };

    continueHandler = goTo => {
        this.loaderOn();
        this.props.history.push(goTo);
    };

    statisticsHandler = id => {
        this.loaderOn();
        getStatistics(this.props.store.user, id)
            .then(resp => {
                this.props.setStatistics(resp.data.data);
                this.props.setActivePageStatistics(resp.data.landingPage);
                this.continueHandler("/me/statistics");
            })
            .catch(err => this.errorHandler());
    };

    previewHandler = (id, name) => {
        getTempData(this.props.store.user, id)
            .then(pageTemp => {
                this.props.setActivePage(pageTemp);
                window.open(`${window.location.origin}/${name}`);
            })
            .catch(err => {
                this.errorHandler();
            });
    };

    shareLinkHandler = (id, margin) => {
        shareLinks(this.props.store.user, id)
            .then(response => {
                const newShareData = { ...this.state.shareData };
                newShareData.link = response.data.data.link;
                newShareData.imgPath = response.data.templateImg.path;
                newShareData.share = response.data.share;

                this.setState({ shareData: newShareData });
                this.popUpOnHandler(margin);
            })
            .catch(err => {
                this.errorHandler();
            });
    };

    popUpOnHandler = margin => {
        this.setState({ popup: true, margin: margin });
    };

    popUpOffHandler = () => {
        this.setState({ popup: false });
    };

    errorHandler = () => {
        this.props.history.push("/me/error");
    };

    turnOnOffPageHandler = id => {
        pageOnOff(this.props.store.user, id)
            .then(response => {
                if (response.data.status === "success") {
                    let pages = this.state.pages;
                    let newPages = pages.map(page => {
                        if (page.id === id) {
                            page.active = page.active ? 0 : 1;
                        }
                        return page;
                    });
                    this.setState({ pages: newPages });
                }
            })
            .catch(error => console.log(error));
    };

    render() {
        if (this.state.loading) {
            return (
                <Container>
                    <LoaderComponent />
                </Container>
            );
        }

        return (
            <Container>
                {this.state.popup ? (
                    <PopUp
                        margin={this.state.margin}
                        close={this.popUpOffHandler}
                    >
                        <Share data={this.state.shareData} />
                    </PopUp>
                ) : null}
                <Content
                    createPage={this.state.createPage}
                    clicked={this.continueHandler}
                />
                <Table
                    pages={this.state.pages}
                    clicked={this.continueHandler}
                    preview={this.previewHandler}
                    edit={this.editHandler}
                    margin={this.state.popup.margin}
                    share={this.shareLinkHandler}
                    onOff={this.turnOnOffPageHandler}
                    stats={this.statisticsHandler}
                />
            </Container>
        );
    }
}
const mapStateToProps = state => {
    return {
        store: state
    };
};

const mapDispatchToProps = dispatch => {
    return {
        dispatchData: data => {
            dispatch({
                type: cons.SET_LANDING_PAGES,
                payload: { data: data }
            });
        },
        setActivePage: data => {
            dispatch(setActivePage(data));
        },
        setActivePageStatistics: data => {
            dispatch(setActivePageStatistics(data));
        },
        setShareData: data => {
            dispatch({
                type: cons.SET_SHARE_DATA,
                payload: { data: data }
            });
        },
        setStatistics: data => dispatch(setStatistics(data))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
