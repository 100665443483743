import React from "react";
import css from "./pageSectionMiddle.module.css";
import xmas_home from "../../assets/img/xmashome.svg";
import xmas_gift from "../../assets/img/xmasgift.svg";
import xmas_bag from "../../assets/img/xmasbag.svg";
import xmas_box from "../../assets/img/xmasbox.svg";
// import xmasbaner from "../../assets/img/xmasbaner.jpg";
// import u_baner from "../../assets/img/universal/u_baner.jpg";
// import u_baner_mobile from "../../assets/img/universal/u_baner_mobile.jpg";
// import xmasbaner_mobile from "../../assets/img/xmasbaner_mobile.jpg";
import EditSolid from "../Buttons/EditSolid";
import Dark from "../../hoc/Dark/Dark";
import SectionInput from "./SectionInput/SectionInput";
import SectionTextArea from "./SectionTextArea/SectionTextArea";
import { IMAGE_TEXT, VIDEO_TEXT } from "../../utils/const";
import IconSection from "../IconSection/IconSection";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { CALLBACK_TEXT } from "../../utils/const";
import Video from "../Video/Video";
import slide1 from "../../assets/img/liiv/liiv-slider-kolagen.jpg";
import slide1mobile from "../../assets/img/liiv/kolagenmobile.jpg";
import slide2 from "../../assets/img/liiv/liiv-slider-proszek.jpg";
import slide2mobile from "../../assets/img/liiv/slider-mobile-shake.jpg";
import slide3 from "../../assets/img/liiv/liiv-slider-tabletki.jpg";
import slide3mobile from "../../assets/img/liiv/slider-mobile-liiv.jpg";
import slidearrow from "../../assets/img/liiv/arrow.svg";
import slidearrowxmas_r from "../../assets/img/naviright.svg";
import slidearrowxmas_l from "../../assets/img/navileft.svg";
import nagrodyIcon from "../../assets/img/nagrody.jpg";
import slide1xmas from "../../assets/img/xmas-slide1.jpg";
import slide2xmas from "../../assets/img/xmas-slide2.jpg";
import slide3xmas from "../../assets/img/xmas-slide3.jpg";
import slide1xmasmobile from "../../assets/img/xmas-slide1-mobile.jpg";
import slide2xmasmobile from "../../assets/img/xmas-slide2-mobile.jpg";
import slide3xmasmobile from "../../assets/img/xmas-slide3-mobile.jpg";
import xmashow from "../../assets/img/xmas-how.jpg";
import u_show from "../../assets/img/universal/baner2.jpg";
import u_video_bg from "../../assets/img/universal/u_video_bg.png";
import xmashowmobile from "../../assets/img/xmas-how-mobile.jpg";
import Slider from "react-slick";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div className={className} onClick={onClick}>
            <img src={slidearrow} />
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div className={className} onClick={onClick}>
            <img src={slidearrow} />
        </div>
    );
}

function NextArrowXmas(props) {
    const { className, style, onClick } = props;
    return (
        <div className={className} onClick={onClick}>
            <img src={slidearrowxmas_r} />
        </div>
    );
}

function PrevArrowXmas(props) {
    const { className, style, onClick } = props;
    return (
        <div className={className} onClick={onClick}>
            <img src={slidearrowxmas_l} />
        </div>
    );
}

class PageSectionMiddle extends React.Component {
    state = {
        name: "nagrody",
        img: nagrodyIcon,
        video: {
            options: {
                height: "390",
                width: "640",
                playerVars: {
                    https: "//youtu.be/",
                    autoplay: 0
                }
            },
            videoId: "WlS7PoU1wRg"
        }
    };

    updateDimensions = () => {
        this.setState({
            mobile: window.innerWidth < 900 ? true : false
        });
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (nextProps.video.videoId !== nextState.video.videoId) {
            let newVideo = { ...this.state.video };

            newVideo.videoId = nextProps.video.videoId;
            this.setState({ video: newVideo });
        }
        if (nextProps.img.name !== nextState.name) {
            this.setState({
                name: nextProps.img.name,
                img: `/img/${nextProps.img.name}.jpg`
            });
        }
        return true;
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);
        const newVideo = { ...this.state.video };

        newVideo.videoId = this.props.video;
        this.setState({ video: newVideo });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    render() {
        const slidesettings = {
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />
        };

        const slidesettingsxmas = {
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: <NextArrowXmas />,
            prevArrow: <PrevArrowXmas />
        };

        let middle = css.middle;
        if (this.props.css !== undefined) {
            middle += " " + css[this.props.css];
        }

        if (this.props.view) middle += ` ${css.landing}`;
        return this.props.css != "liiv" &&
            this.props.css != "xmas" &&
            this.props.css != "universal" ? (
            <div className={middle}>
                <Dark>
                    {this.props.view ? (
                        <div className={css.text_box}>
                            <h2>{this.props.template[5].content.header}</h2>
                        </div>
                    ) : (
                        <SectionTextArea
                            size={"big"}
                            temp={this.props.template[5].content}
                            changed={this.props.changed}
                            message={IMAGE_TEXT}
                            id={5}
                            validation={{
                                name: this.props.template[5].content.name
                            }}
                        />
                    )}

                    <div className={css.img_section}>
                        <div className={css.aspect_ratio_box}>
                            <div className={css.aspect_ratio_box_inside}>
                                <LazyLoadImage
                                    src={this.state.img}
                                    alt={"zdjęcie"}
                                />
                                {this.props.view ? null : (
                                    <EditSolid
                                        name={"Wybierz zdjęcie"}
                                        className={"select_picture"}
                                        type={"img"}
                                        clickedShow={this.props.clickedShow}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    {this.props.view ? (
                        <div className={css.text_box}>
                            <h2>{this.props.template[6].content.header}</h2>
                        </div>
                    ) : (
                        <SectionInput
                            temp={this.props.template[6].content}
                            changed={this.props.changed}
                            message={VIDEO_TEXT}
                            id={6}
                            validation={{
                                name: this.props.template[6].content.name
                            }}
                        />
                    )}
                    <div className={css.video_section}>
                        <Video
                            videoId={this.state.video.videoId.toString()}
                            opts={this.state.video.options}
                        />
                        {this.props.view ? null : (
                            <EditSolid
                                name={"Wybierz swój film"}
                                type={"vid"}
                                clickedShow={this.props.clickedShow}
                            />
                        )}
                    </div>
                </Dark>
            </div>
        ) : this.props.css == "liiv" ? (
            <div className={middle}>
                <Dark>
                    <div className={css.section_liiv}>
                        <div className={css.text}>
                            <h4>{this.props.template[1].content.header2}</h4>
                        </div>
                        <IconSection
                            icons={this.props.template[1].content.icons}
                            iconsPath={this.props.iconsPath}
                            custom={"iconsmiddle"}
                        />

                        {this.props.view ? (
                            <div className={css.text}>
                                <h4>{this.props.template[2].content.header}</h4>
                            </div>
                        ) : (
                            <SectionTextArea
                                temp={this.props.template[2].content}
                                changed={this.props.changed}
                                message={CALLBACK_TEXT}
                                id={2}
                                validation={{
                                    name: this.props.template[2].content.name
                                }}
                            />
                        )}
                    </div>

                    <div className={css.sliderwrapper}>
                        <Slider {...slidesettings}>
                            <div>
                                {this.state.mobile ? (
                                    <img src={slide1mobile} />
                                ) : (
                                    <img src={slide1} />
                                )}

                                <div className={"slidetext"}>
                                    <h3>
                                        Suplement diety <br></br>Kolagen dla
                                        urody
                                    </h3>
                                    <p>
                                        Ten suplement diety zwiększa zawartość
                                        kolagenu w organizmie i stymuluje jego
                                        naturalną produkcję. Kolagen, jako ważny
                                        budulec wewnętrznych i zewnętrznych
                                        struktur ciała, ma kluczowe znaczenie
                                        dla młodego wyglądu skóry. Przyczynia
                                        się do zwiększenia jej elastyczności, a
                                        także redukcji linii, bruzd i
                                        zmarszczek. Wzmacnia również paznokcie i
                                        włosy, zapewniając im zdrowy wygląd i
                                        blask.
                                    </p>
                                </div>
                            </div>

                            <div>
                                {this.state.mobile ? (
                                    <img src={slide2mobile} />
                                ) : (
                                    <img src={slide2} />
                                )}
                                <div className={"slidetext"}>
                                    <h3>
                                        Zbilansowany posiłek <br></br> w formie
                                        koktajlu w proszku
                                    </h3>
                                    <p>
                                        Wszystkie niezbędne składniki odżywcze w
                                        jednym, smacznym koktajlu - wysokiej
                                        jakości białko, błonnik oraz aż 25
                                        witamin i składników mineralnych.
                                        Koktajl Liiv stanowi kompletny zamiennik
                                        pożywnego śniadania, obiadu lub kolacji
                                        i został stworzony z myślą o
                                        zaspokojeniu potrzeb żywieniowych
                                        dorosłych kobiet ceniących zdrowy styl
                                        życia.
                                    </p>
                                </div>
                            </div>

                            <div>
                                {this.state.mobile ? (
                                    <img src={slide3mobile} />
                                ) : (
                                    <img src={slide3} />
                                )}
                                <div className={"slidetext"}>
                                    <h3>
                                        Relaksacyjny suplement diety <br></br> w
                                        tabletkach
                                    </h3>
                                    <p>
                                        Suplement diety łączący w sobie moc
                                        naturalnych ziół, roślin, witaminy B6 i
                                        magnezu to sposób na zdrowy relaks i
                                        spokojny sen. Odpręż się dzięki
                                        mieszance starannie dobranych ziół i
                                        roślin wspomagających relaks i zdrowy
                                        sen. Wspomaga odprężenie organizmu i
                                        ukojenie natłoku myśli, znakomicie
                                        sprawdzając się przed snem. Naturalnie,
                                        zdrowo i skutecznie niweluje napięcia
                                        psychiczne, uspokaja i poprawia nastrój.
                                    </p>
                                </div>
                            </div>
                        </Slider>
                    </div>

                    {this.props.view ? (
                        <div className={css.text_box}>
                            <h2>{this.props.template[5].content.header}</h2>
                        </div>
                    ) : (
                        <SectionTextArea
                            size={"big"}
                            temp={this.props.template[5].content}
                            changed={this.props.changed}
                            message={IMAGE_TEXT}
                            id={5}
                            validation={{
                                name: this.props.template[5].content.name
                            }}
                        />
                    )}

                    {this.props.view ? (
                        <div className={css.text_box}>
                            <h2>{this.props.template[6].content.header}</h2>
                        </div>
                    ) : (
                        <SectionInput
                            temp={this.props.template[6].content}
                            changed={this.props.changed}
                            message={VIDEO_TEXT}
                            id={6}
                            validation={{
                                name: this.props.template[6].content.name
                            }}
                        />
                    )}

                    <div className={css.video_section}>
                        <Video
                            videoId={this.state.video.videoId.toString()}
                            opts={this.state.video.options}
                        />
                        {this.props.view ? null : (
                            <EditSolid
                                name={"Wybierz swój film"}
                                type={"vid"}
                                clickedShow={this.props.clickedShow}
                            />
                        )}
                    </div>
                </Dark>
            </div>
        ) : this.props.css == "xmas" ? (
            <div className={middle}>
                <Dark>
                    <div>
                        <div className={css.text}>
                            <h2>Co jeszcze zyskujesz?</h2>
                        </div>
                        <div className={css.xmasicons}>
                            <div>
                                <img src={xmas_home} />
                                <p>Możesz pracować z domu</p>
                            </div>

                            <div>
                                <img src={xmas_gift} />
                                <p>Dodatkowe nagrody o wartości do 800 zł</p>
                            </div>

                            <div>
                                <img src={xmas_bag} />
                                <p>Bezpłatna dostawa InPost</p>
                            </div>

                            <div>
                                <img src={xmas_box} />
                                <p>45 dni na zwrot</p>
                            </div>
                        </div>

                        {/* <div className={css.xmasbaner}>
                            {this.state.mobile ? (
                                <img src="https://pliki.avon.pl/web/uploads/social-prospecting-img1-mobile-2255.jpg" />
                            ) : (
                                <img src="https://pliki.avon.pl/web/uploads/social-prospecting-img1-2254.jpg" />
                            )}
                        </div> */}

                        <div className={css.xmashow}>
                            <div>
                                {this.state.mobile ? (
                                    <img src={xmashowmobile} />
                                ) : (
                                    <img src={xmashow} />
                                )}
                            </div>
                            <div>
                                <h3>
                                    Jak się dostać do świata Avon?<br></br>To
                                    bardzo proste!
                                </h3>
                                <div>
                                    <div>
                                        <div>01</div>
                                        <div>
                                            <div>Zapisz się</div>
                                            <div>
                                                Wypełnij formularz rejestracyjny
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div>02</div>
                                        <div>
                                            <div>Zaloguj się</div>
                                            <div>
                                                W ciagu 5 minut otrzymasz sms z
                                                hasłem, mail z dedykowanym
                                                numerem oraz link do zalogowania
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div>03</div>
                                        <div>
                                            <div>Korzystaj</div>
                                            <div>
                                                Od tej chwili zamawiasz
                                                kosmetyki do 40% taniej!
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={css.goldbar}>
                            {this.props.view ? (
                                <div className={css.text}>
                                    <h4>
                                        {this.props.template[2].content.header}
                                    </h4>
                                </div>
                            ) : (
                                <SectionTextArea
                                    temp={this.props.template[2].content}
                                    changed={this.props.changed}
                                    message={CALLBACK_TEXT}
                                    id={2}
                                    validation={{
                                        name: this.props.template[2].content
                                            .name
                                    }}
                                />
                            )}
                        </div>
                    </div>

                    <h2>Galeria udanych prezentów</h2>

                    <div className={css.sliderwrapper}>
                        <Slider {...slidesettingsxmas}>
                            <div>
                                {this.state.mobile ? (
                                    <img src={slide1xmasmobile} />
                                ) : (
                                    <img src={slide1xmas} />
                                )}
                            </div>

                            <div>
                                {this.state.mobile ? (
                                    <img src={slide2xmasmobile} />
                                ) : (
                                    <img src={slide2xmas} />
                                )}
                            </div>

                            <div>
                                {this.state.mobile ? (
                                    <img src={slide3xmasmobile} />
                                ) : (
                                    <img src={slide3xmas} />
                                )}
                            </div>
                        </Slider>
                    </div>

                    <div className={css.video_section}>
                        <Video
                            videoId={this.state.video.videoId.toString()}
                            opts={this.state.video.options}
                        />
                        {this.props.view ? null : (
                            <EditSolid
                                name={"Wybierz swój film"}
                                type={"vid"}
                                clickedShow={this.props.clickedShow}
                            />
                        )}
                    </div>

                    {this.props.view ? (
                        <div className={css.text_box}>
                            <h2>{this.props.template[5].content.header}</h2>
                        </div>
                    ) : (
                        <SectionTextArea
                            size={"big"}
                            temp={this.props.template[5].content}
                            changed={this.props.changed}
                            message={IMAGE_TEXT}
                            id={5}
                            validation={{
                                name: this.props.template[5].content.name
                            }}
                        />
                    )}
                </Dark>
            </div>
        ) : this.props.css == "universal" ? (
            <div className={middle}>
                <Dark>
                    <div>
                        <div className={css.text}>
                            <h2>Zobacz, co jeszcze zyskujesz!</h2>
                        </div>
                        <IconSection
                            textSize={"big"}
                            icons={this.props.template[7].content.icons}
                            iconsPath={this.props.iconsPath}
                        />

                        {/* <div className={css.u_baner}>
                            {this.state.mobile ? (
                                <img src="https://pliki.avon.pl/web/uploads/social-prospecting-img1-mobile-2255.jpg" />
                            ) : (
                                <img src="https://pliki.avon.pl/web/uploads/social-prospecting-img1-2254.jpg" />
                            )}
                        </div> */}

                        <div className={css.u_show}>
                            <div>
                                <h3>
                                    Jak się dostać do świata Avon?<br></br>To
                                    bardzo proste!
                                </h3>
                                <div>
                                    <div>
                                        <div>1</div>
                                        <div>
                                            Wypełnij formularz rejestracyjny
                                        </div>
                                    </div>
                                    <div>
                                        <div>2</div>
                                        <div>
                                            W ciagu 5 minut otrzymasz sms z
                                            hasłem, mail z dedykowanym numerem
                                            oraz link do zalogowania
                                        </div>
                                    </div>
                                    <div>
                                        <div>3</div>
                                        <div>
                                            Od tej chwili zamawiasz kosmetyki <br></br><b>do
                                            40% taniej!</b>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                               
                              
                             
                                    <img src={u_show} />
                           
                                {this.props.view ? (
                                    <div className={css.text}>
                                        <h4>
                                            {
                                                this.props.template[2].content
                                                    .header
                                            }
                                        </h4>
                                    </div>
                                ) : (
                                    <SectionTextArea
                                        temp={this.props.template[2].content}
                                        changed={this.props.changed}
                                        message={CALLBACK_TEXT}
                                        id={2}
                                        validation={{
                                            name: this.props.template[2].content
                                                .name
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>

                    {this.props.view ? (
                        <div className={css.text_box}>
                            <h2>{this.props.template[5].content.header}</h2>
                        </div>
                    ) : (
                        <SectionTextArea
                            size={"big"}
                            temp={this.props.template[5].content}
                            changed={this.props.changed}
                            message={IMAGE_TEXT}
                            id={5}
                            validation={{
                                name: this.props.template[5].content.name
                            }}
                        />
                    )}

                    <div className={css.img_section}>
                        <div className={css.aspect_ratio_box}>
                            <div className={css.aspect_ratio_box_inside}>
                                <LazyLoadImage
                                    src={this.state.img}
                                    alt={"zdjęcie"}
                                />
                                {this.props.view ? null : (
                                    <EditSolid
                                        name={"Wybierz zdjęcie"}
                                        className={"select_picture"}
                                        type={"img"}
                                        clickedShow={this.props.clickedShow}
                                    />
                                )}
                            </div>
                        </div>
                    </div>

                    <div className={css.u_video_header}>
                        <h2>Zapraszam Cię do świata AVON... Zobacz, jak fajnie w nim być!</h2>
                    </div>

                    <div className={css.video_section}>
                        <Video
                            videoId={this.state.video.videoId.toString()}
                            opts={this.state.video.options}
                        />
                        {this.props.view ? null : (
                            <EditSolid
                                name={"Wybierz swój film"}
                                type={"vid"}
                                clickedShow={this.props.clickedShow}
                            />
                        )}

                      <div>
                          <img src={u_video_bg}/>
                      </div>
                    </div>
                </Dark>
            </div>
        ) : null;
    }
}

export default PageSectionMiddle;
