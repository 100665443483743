import React from "react";
import style from "./error.module.css";
import img from "../../../src/assets/img/sad.svg";
import { deleteCookie } from "../../services/cookies";
import Container from "../../hoc/Container";

class Error extends React.Component {
    state = {
        message: "Wystąpił błąd podczas autoryzacji"
    };

    componentDidMount() {
        deleteCookie("authData");

        if (this.props.message) this.setState({ message: this.props.message });
    }

    render() {
        return (
            <Container>
                <div id="error_page" className={style.error_page}>
                    <div>
                        <img src={img} alt="" />
                        <h1>Coś poszło nie tak</h1>
                        <p>{this.state.message}</p>
                    </div>
                </div>
            </Container>
        );
    }
}

export default Error;
