import * as cons from "../const";

export const setActivePage = data => ({
    type: cons.SET_ACTIVE_PAGE,
    payload: data
});

export const setTemplate = data => ({
    type: cons.SET_ACTIVE_TEMPLATE_CONTENT,
    payload: data
});

const prepareActionTemplateData = data => {
    // const landingPage = response.data.data.landingPage;
    return {
        activePageId: data.id ? data.id : null,
        activeTmpId: data.templateId ? data.templateId : null,
        edit: data.edit ? data.edit : true,
        template: data.PageElements ? data.PageElements : {},
        leader: {
            phone: data.userdata.phone ? data.userdata.phone : "",
            email: data.userdata.email ? data.userdata.email : "",
            pageName: data.name ? data.name : "",
            link: data.link ? data.link : ""
        },
        tags: {
            ga: data.statGa ? data.statGa : "",
            gtm: data.statGtm ? data.statGtm : "",
            gr: data.statRm ? data.statRm : "",
            fp: data.statFb ? data.statFb : ""
        }
    };
};

export const setActivePageStatistics = data => ({
    type: cons.SET_ACTIVE_PAGE,
    payload: prepareActionTemplateData(data)
});
