import { combineReducers } from "redux";
import reducer from "./auth/reducer";
import landingReducer from "./landings/reducer";
import templateReducer from "./template/reducer";
import statistics from "./statistics/reducer";
import page from "./page/reducer";

export default combineReducers({
    user: reducer,
    landing: landingReducer,
    template: templateReducer,
    statistics: statistics,
    page: page
});
