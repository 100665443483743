import React from "react";
import css from "./helpComponent.module.css";

class HelpComponent extends React.Component {
    render() {
        return (
            <div className={css.help}>
                <div className={css.message_box}>
                    {this.props.error ? (
                        <img src="/img/icons/infored.svg" alt={"error icon"} />
                    ) : (
                        <img src="/img/icons/info.svg" alt={"info icon"} />
                    )}
                    <div
                        className={
                            this.props.error
                                ? [css.message, css.block].join(" ")
                                : css.message
                        }
                    >
                        {this.props.message}
                    </div>
                </div>
            </div>
        );
    }
}

export default HelpComponent;
