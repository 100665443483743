import React from "react";
import css from "./outline.module.css";

class ButtonOutline extends React.Component {
    state = {
        id: "",
        className: "",
        active: true,
        linkTo: "",
        name: ""
    };

    handleClick(e) {
        let width = window.innerWidth;
        let top = e.target.getBoundingClientRect().top;
        let margin = top + window.pageYOffset - 200;
        if (this.props.name === "Udostępnij" && width > 768) margin = 0;
        this.props.clicked(this.state.linkTo, margin);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (nextProps.active !== nextState.active) {
            let newClasses = css.btn;
            if (nextProps.active === 0) {
                newClasses = css.btn + " " + css.btn_disabled;
            }
            this.setState({
                active: nextProps.active,
                className: newClasses
            });
            return false;
        }
        return true;
    }

    componentDidMount() {
        let newClasses = css.btn;
        if (this.props.active === 0) {
            newClasses = css.btn + " " + css.btn_disabled;
        }

        this.setState({
            className: newClasses,
            active: this.props.active,
            linkTo: this.props.linkTo,
            name: this.props.name
        });
    }

    render() {
        let styles = this.state.className;

        if (this.props.active === false) {
            styles = css.btn_grey;
        }
        return this.state.linkTo ? (
            <button className={styles} onClick={this.handleClick.bind(this)}>
                {this.state.name}
            </button>
        ) : this.state.active ? (
            <button className={styles} onClick={this.props.clicked}>
                {this.state.name}
            </button>
        ) : (
            <button className={styles}>{this.state.name}</button>
        );
    }
}

export default ButtonOutline;
