import React from "react";
import moment from "moment";
import Select from "react-styled-select";
import css from "./picker.module.css";

export default class DatePickerDropdown extends React.Component {
    constructor(props) {
        super(props);
        const { dayLabel, monthLabel, yearLabel, defaultDate } = props;

        this.state = {
            day: null,
            month: null,
            year: null,
            selectDay: defaultDate ? moment(defaultDate).date() : dayLabel,
            selectMonth: defaultDate
                ? moment(defaultDate).month() + 1
                : monthLabel,
            selectYear: defaultDate ? moment(defaultDate).year() : yearLabel
        };
    }

    shouldComponentUpdate(_nextProps, nextState) {
        return (
            this.state.selectDay !== nextState.selectDay ||
            this.state.selectMonth !== nextState.selectMonth ||
            this.state.selectYear !== nextState.selectYear
        );
    }

    componentWillMount() {
        let day = [],
            month = [],
            year = [];

        const pad = n => {
            return n < 10 ? "0" + n : n;
        };

        for (let i = 1; i <= 31; i++) {
            day.push(this.props.padDay ? pad(i) : i);
        }

        let monthIndex = 1;
        for (const monthName of moment.localeData().months()) {
            month.push({
                text: this.props.useMonthNames
                    ? monthName
                    : this.props.padMonth
                    ? pad(monthIndex)
                    : monthIndex,
                value: monthIndex
            });
            monthIndex++;
        }

        for (let i = this.props.maxYear; i >= this.props.minYear; i--) {
            year.push(i);
        }

        this.setState({
            day: day,
            month: month,
            year: year
        });
    }

    changeDate(type) {
        this.setState({
            [type]: this.state.type
        });
        this.checkDate(this.state.type, type);
    }

    handleChange = type => selectedOption => {
        this.setState({
            [type]: selectedOption
        });
        this.checkDate(selectedOption, type);
    };

    getDate(date) {
        return moment(date).format("YYYY-MM-DD");
    }

    checkDate(value, type) {
        let { selectDay, selectMonth, selectYear } = this.state;

        if (type === "selectDay") {
            selectDay = value;
        } else if (type === "selectMonth") {
            selectMonth = value;
        } else if (type === "selectYear") {
            selectYear = value;
        }

        if (this.isSelectedAllDropdowns(selectDay, selectMonth, selectYear)) {
            const dateObject = {
                year: selectYear,
                month: selectMonth - 1,
                day: selectDay
            };
            this.props.dateChange(this.getDate(dateObject));
        } else {
            this.props.dateChange(undefined);
        }
    }

    isSelectedAllDropdowns(selectDay, selectMonth, selectYear) {
        if (selectDay === "" || selectMonth === "" || selectYear === "") {
            return false;
        }
        return this.props.mode === "TH"
            ? selectDay !== "วันที่" &&
                  selectMonth !== "เดือน" &&
                  selectYear !== "ปี"
            : selectDay !== this.props.dayLabel &&
                  selectMonth !== this.props.monthLabel &&
                  selectYear !== this.props.yearLabel;
    }

    render() {
        const dayElement = this.state.day.map((day, id) => {
            return (
                <option value={day} key={id}>
                    {day}
                </option>
            );
        });
        const monthElement = this.state.month.map((month, id) => {
            return (
                <option value={month.value} key={id}>
                    {month.text}
                </option>
            );
        });
        const yearElement = this.state.year.map((year, id) => {
            return (
                <option value={year} key={id}>
                    {year}
                </option>
            );
        });

        const dayOptions = this.state.day.map(day => {
            return { label: day < 10 ? "0" + day : day, value: day };
        });

        const monthOptions = this.state.month.map(month => {
            return {
                label: month.value < 10 ? "0" + month.value : month.value,
                value: month.value
            };
        });

        const yearOptions = this.state.year.map(year => {
            return { label: year, value: year };
        });

        const { selectDay, selectMonth, selectYear } = this.state;

        return (
            <div className={css.picker}>
                <Select
                    options={dayOptions}
                    onChange={this.handleChange("selectDay")}
                    placeholder="dd"
                    className={css.spTheme}
                    value={selectDay}
                    searchable={false}
                    classes={{
                        selectValue: "my-custom-value",
                        selectArrow: css.myarrow,
                        selectMenu: css.menus,
                        selectControl: css.myControl,
                        selectInput: "my-input",
                        selectInputField: "my-input-field",
                        selectMenuOuter: "my-menu-outer",
                        selectMultiValueWrapper: css.wrapper
                    }}
                />

                <Select
                    options={monthOptions}
                    onChange={this.handleChange("selectMonth")}
                    placeholder="mm"
                    className={css.spTheme}
                    value={selectMonth}
                    searchable={false}
                    classes={{
                        selectValue: "my-custom-value",
                        selectArrow: css.myarrow,
                        selectMenu: css.menus,
                        selectControl: css.myControl,
                        selectInput: "my-input",
                        selectInputField: "my-input-field",
                        selectMenuOuter: "my-menu-outer",
                        selectMultiValueWrapper: css.wrapper
                    }}
                />

                <Select
                    options={yearOptions}
                    onChange={this.handleChange("selectYear")}
                    placeholder="rrrr"
                    className={css.spTheme}
                    value={selectYear}
                    searchable={false}
                    classes={{
                        selectValue: "my-custom-value",
                        selectArrow: css.myarrow,
                        selectMenu: css.menus,
                        selectControl: css.myControl,
                        selectInput: "my-input",
                        selectInputField: "my-input-field",
                        selectMenuOuter: "my-menu-outer",
                        selectMultiValueWrapper: css.wrapper
                    }}
                />
            </div>
        );
    }
}

DatePickerDropdown.defaultProps = {
    dayLabel: "dd",
    minYear: 1916,
    maxYear: 2020,
    monthLabel: "mm",
    padDay: false,
    padMonth: false,
    selectDay: "",
    selectMonth: "",
    selectYear: "",
    yearLabel: "rrrr",
    useMonthNames: false
};
