import React from "react";
import DatePickerDropdown from "../DatePickerDropdown/DatePickerDropdown";
import DatePicker from "react-datepicker"; // Dodaj tę linię
import "react-datepicker/dist/react-datepicker.css"; // Dodaj tę linię
import { Calendar } from 'lucide-react'; // Dodajemy ikonę kalendarza
import css from "./Input2.module.css";

const Input = (data) => {
  let error = css.error;

  if (["Street", "PostCode"].includes(data.input.id)) {
    error += ` ${css.left}`;
  }


  return (
    <div
      className={`${
        data.input.id === 'Street' || data.input.id === 'firstName' || data.input.id === 'lastName' ||  data.input.id === 'Email' || data.input.id === 'City' ? css.full_width :
        (data.input.id === 'StreetNr' || data.input.id === 'ApartmentNr') ? css.half_width :
        (data.input.id === 'PostCode' || data.input.id === 'City' || data.input.id === 'Phone') ? css.half_width : ''
      }`}
    >
      <label className={css.textInput + " text-input"}>
        {data.editable ? (
          data.input.id === "BirthDate" ? (
               <div key={data.input.id} className={css.date_picker_wrapper}>
                        <DatePicker
                          selected={data.input.value}
                          onChange={(date) => this.changeInputHandler(date, 'BirthDate')}
                          dateFormat="dd/MM/yyyy"
                          placeholderText="Data urodzenia *"
                          className={`${css.date_picker_input} ${data.input.class}`}
                        />
                        <Calendar className={css.calendar_icon} size={20} />
                      </div>
          ) : (
            <div className={css.container}>
              <input
                type={data.input.type}
                name={data.input.name}
                id={data.input.id}
                className={css.inputField}
                value={data.input.value}
                onChange={data.changed}
                placeholder={data.input.placeholder}
                maxChars={data.input.maxlength}
              />
              <label for={data.input.id} class={css.label}>
                {data.input.placeholder}
              </label>
            </div>
          )
        ) : (
          <input
            readOnly
            type={data.input.type}
            name={data.input.name}
            id={data.input.id}
            className={data.input.class}
            value={data.input.value}
          />
        )}
        {data.input.error ? (
          <div className={css.errorBox}>
            <div className={css.errorBoxContent}>
              <div className={error}>
                {data.input.value === "Invalid date"
                  ? "Wybierz poprawną datę"
                  : data.input.errorMessage}
              </div>
              <img src="/img/icons/exclamation.svg" />
            </div>
          </div>
        ) : null}
        {data.input.correct ? <span className={css.correct}></span> : null}
      </label>
    </div>
  );
};

export default Input;
