import React from "react";
import css from "./tempBanner.module.css";
import logo from "../../assets/img/avon_white.png";
import logoliiv from "../../assets/img/liiv-logo.png";
import Cleo from "../../assets/img/new_template/Cleo-okladka.png";
import ButtonSolid from "../Buttons/Solid";
import ReactHtmlParser from "react-html-parser";

class TempBanner extends React.Component {
    render() {
        let description = "";
        const tempClassName = "temp_" + this.props.id;
        if (this.props.description) {
            description = ReactHtmlParser(this.props.description);
        } else {
            description = (
                <p>
                    Szablon w nowej wizualnej odsłonie <br /> - z wyeksponowanym
                    formularzem i aktywnymi przyciskami kontaktowymi
                </p>
            );
        }
        return (
            <div className={css.page_box}>
                <div className={css.top}>
                    <div
                        id={"new_page"}
                        className={
                            this.props.id === 8
                                ? css.new + " " + css.liiv
                                : this.props.id === 10
                                ? css.new + " " + css.xmas
                                : this.props.id === 9
                                ? css.new + " " + css.universal
                                : this.props.id === 11
                                ? css.new + " " + css.cleo
                                : `${css.new} ${css[tempClassName]}`
                        }
                    >
                        {this.props.id !== 8 && this.props.id !== 10 && this.props.id ? (
                            <h6>Dołącz do mojego zespołu</h6>
                        ) : this.props.id === 10 ? (
                            <h6>Zapraszam Cię do mojego zespołu</h6>
                        ) : null}
                        <img
                            className={css.logo}
                            src={this.props.id === 8 ? logoliiv : logo}
                            alt={"AVON logo"}
                        />
                    </div>
                    <div className={css.text_bottom}>
                        <p>{this.props.id !== 11 ? description : 'Nowy świetny szablon 2024!'}</p>
                    </div>
                </div>
                <ButtonSolid
                    name={"Wybierz"}
                    clicked={this.props.clicked}
                    linkTo={"/me/edit"}
                    narrow={1}
                    id={this.props.id}
                />
            </div>
        );
    }
}

export default TempBanner;
