import React from "react";
import css from "./temp.module.css";
import PageSectionTop from "../PageSection/PageSectionTop";
import PageSectionMiddle from "../PageSection/PageSectionMiddle";
import PageSectionBottom from "../PageSection/PageSectionBottom";
import { state } from "../../states/tem5";
import ScrollArea from "react-scrollbar";

class PageSection extends React.Component {
    state = state;

    updateDimensions = () => {
        this.setState({
            scrollContainerStyle: {
                right: "15px",
                background: "none",
                boxSizing: "border-box",
                opacity: "1",
                display: window.innerWidth < 900 ? "none" : "block"
            },
            scrolling: window.innerWidth > 899
        });
    };

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    scrollStyle = {
        background: this.props.css.top === "liiv" ? "#d991b7" : this.props.css.top === "xmas" ?  "#821338" : this.props.css.top === "universal" ?  "#6E2381" : "#fff",
        borderRadius: "10px",
        width: "10px",
        opacity: "0.8",
        cursor: "pointer"
    };
    render() {
        const scrolling = this.state.scrolling;

        if (scrolling) {
            return (
                <ScrollArea
                    verticalContainerStyle={this.state.scrollContainerStyle}
                    verticalScrollbarStyle={this.scrollStyle}
                    horizontal={false}
                >
                    <section className={css.page_section}>
                        <PageSectionTop
                            view={this.props.view}
                            template={this.props.temp}
                            css={this.props.css.top}
                            iconsPath={this.props.iconsTop}
                        />
                        <PageSectionMiddle
                            view={this.props.view}
                            template={this.props.temp}
                            margin={this.props.margin}
                            img={this.props.img}
                            video={this.props.video}
                            css={this.props.css.middle}
                            iconsPath={this.props.iconsTop}
                        />
                        <PageSectionBottom
                            view={this.props.view}
                            template={this.props.temp}
                            css={this.props.css.bottom}
                            iconsPath={this.props.iconsBottom}
                        />
                    </section>
                </ScrollArea>
            );
        } else {
            return (
                <section className={css.page_section}>
                    <PageSectionTop
                        view={this.props.view}
                        template={this.props.temp}
                        css={this.props.css.top}
                        iconsPath={this.props.iconsTop}
                    />

                    <PageSectionMiddle
                        view={this.props.view}
                        template={this.props.temp}
                        margin={this.props.margin}
                        img={this.props.img}
                        video={this.props.video}
                        css={this.props.css.middle}
                        iconsPath={this.props.iconsTop}
                    />

                    <PageSectionBottom
                        view={this.props.view}
                        template={this.props.temp}
                        css={this.props.css.bottom}
                        iconsPath={this.props.iconsBottom}
                    />
                </section>
            );
        }
    }
}

export default PageSection;
