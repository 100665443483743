import React from "react";
import style from "./go-back.module.css";

class GoBackButton extends React.Component {
    render() {
        return (
            <button className={style.btn} onClick={this.props.clicked}>
                {this.props.name ? this.props.name : "Powrót do moich stron"}
            </button>
        );
    }
}

export default GoBackButton;
