import React from "react";
import css from "../pageSectionTop.module.css";
import TextareaAutosize from "react-autosize-textarea";
import HelpComponent from "../../HelpElement/HelpComponent";

export default class SectionTextArea extends React.Component {
    render() {
        const setStyles = () => {
            let styles = css.textarea;
            if (this.props.temp.error) styles += ` ${css.error}`;
            if (this.props.hidden) styles += ` ${css.hide}`;
            if (this.props.size && this.props.size === "big")
                styles += ` ${css.textarea_big}`;

            return styles;
        };
        const setMessage = () => {
            let message = this.props.message;

            if (this.props.temp.error) message = this.props.temp.errMessage;

            return message;
        };
        return (
            <div className={setStyles()}>
                <TextareaAutosize
                    type={"text"}
                    value={this.props.temp.header}
                    onChange={event =>
                        this.props.changed(
                            event,
                            this.props.id,
                            this.props.validation.name
                        )
                    }
                />

                {!this.props.hidden ? (
                    <HelpComponent
                        error={this.props.temp.error}
                        message={setMessage()}
                    />
                ) : null}
            </div>
        );
    }
}
