import React from "react";
import css from "./formfooter.module.css";

class FormFooter extends React.Component {
    state = {
        footer: css.footer,
        email: {
            path: "/img/icons/envelope.svg",
            alt: "email icon"
        },
        phone: {
            path: "/img/icons/phone.svg",
            alt: "phone icon"
        }
    };

    footerHandler = () => {
        return this.props.opened ? `${css.footer} ${css.opened}` : css.footer;
    };

    render() {
        const footer = this.footerHandler();
        return (
            <div className={footer}>
                <div className={css.top_box}>
                    <h2>Masz pytania? Skontaktuj się ze mną!</h2>
                </div>
                <div className={css.phone_box}>
                    <img
                        src={this.state.phone.path}
                        alt={this.state.phone.alt}
                    />{" "}
                    <a href={`tel: +48${this.props.phone}`}>
                        {this.props.phone}
                    </a>
                </div>
                <div className={css.email_box}>
                    <img
                        src={this.state.email.path}
                        alt={this.state.email.alt}
                    />{" "}
                    <a href={`mailto:${this.props.email}`}>
                        {this.props.email}
                    </a>
                </div>
            </div>
        );
    }
}

export default FormFooter;
