import React from "react";
import css from "./inputEdit.module.css";

class InputEdit extends React.Component {
    render() {
        let styles = css.input;
        if (this.props.data.error) {
            styles += " " + css.error;
        }
        return (
            <div className={styles}>
                <label>{this.props.data.label}</label>
                <div className={css.input_box}>
                    <input
                        readOnly={
                            this.props.readOnly ? this.props.readOnly : false
                        }
                        type={
                            this.props.data.inputType
                                ? this.props.data.inputType
                                : "text"
                        }
                        name={this.props.data.inputName}
                        id={this.props.data.inputName}
                        value={
                            this.props.data.value ? this.props.data.value : ""
                        }
                        onChange={this.props.changed}
                        onBlur={event =>
                            this.props.blur(event, this.props.data.inputName)
                        }
                    />
                    <p>{this.props.data.description}</p>
                </div>
                {this.props.data.error &&
                this.props.data.inputName !== "pageLink" ? (
                    <div className={css.err_msg}>
                        {this.props.data.errMessage}
                    </div>
                ) : null}
            </div>
        );
    }
}

export default InputEdit;
