import React from "react";
import css from "./iconBox.module.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

class IconBox extends React.Component {
    state = {
        style: css.box,
    };
    componentDidMount() {
        if (this.props.textSize !== undefined) {
            this.setState({ style: css.box + " " + css[this.props.textSize] });
        }
    }

    render() {
        return (
            <div
                className={
                    this.props.customclass === "iconsmiddle"
                        ? `${this.state.style} ${css.iconsmiddle}`
                        : `${this.state.style} ${css.iconsbottom}`
                }
            >
                <div className={css.img}>
                    <LazyLoadImage
                        src={`/img/icons/${this.props.icon}`}
                        alt={"icon"}
                    />
                </div>
                <div className={css.text}>{this.props.text}</div>
            </div>
        );
    }
}

export default IconBox;
