import React from "react";
import css from "./temp.module.css";
import PageSectionTop from "../PageSection/PageSectionTop";
import PageSectionMiddle from "../PageSection/PageSectionMiddle";
import PageSectionBottom from "../PageSection/PageSectionBottom";
import { state } from "../../states/tem5";

class PageSection extends React.Component {
    state = state;
    render() {
        return (
            <section className={css.page_section}>
                <h5>Edytor zawartości:</h5>
                <PageSectionTop
                    template={this.props.temp}
                    changed={this.props.changed}
                    css={this.props.css.top}
                    iconsPath={this.props.iconsTop}
                />
                <PageSectionMiddle
                    template={this.props.temp}
                    changed={this.props.changed}
                    clickedShow={this.props.clickedShow}
                    clickedHide={this.props.clickedHide}
                    margin={this.props.margin}
                    img={this.props.img}
                    video={this.props.video}
                    css={this.props.css.middle}
                    iconsPath={this.props.iconsTop}
                />
                <PageSectionBottom
                    template={this.props.temp}
                    changed={this.props.changed}
                    css={this.props.css.bottom}
                    iconsPath={this.props.iconsBottom}
                />
            </section>
        );
    }
}

export default PageSection;
