import React, { Component } from "react";
import css from "./thankYou.module.scss";

class ThankYou extends Component {
    componentDidMount() {}

    clickHandler = link => {
        window.open("https://www2.pl.avon.com/pl-home");
    };

    render() {
        return (
            <div className={css.main}>
                <div className={css.container_content}>
                    <div className="text-box">
                        <img
                            src="/img/icons/success.png"
                            alt={"success icon"}
                        />
                        <h2>Wszystko gotowe!</h2>
                        <h5>
                        Dziękujemy za rejestrację.
                            <br />
                            Wkrótce skontaktujemy się z Tobą.
                            <br />
                            Sprawdź swoją skrzynkę email oraz SMSa i zaloguj się do strony zamawiania Klubu Avon.
                        </h5>
                    </div>
                    <div className="bottom-text-box">
                        <br />
                        <div>
                            <button type="submit" onClick={this.clickHandler}>
                                Przejdź do strony logowania
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ThankYou;
