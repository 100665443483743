export const state = {
    iconsTop: [
        {
            icon: "list.svg"
        },
        {
            icon: "envelope.svg"
        },
        {
            icon: "dollar.svg"
        }
    ],
    iconsBottom: [
        {
            icon: "gift.svg"
        },
        {
            icon: "bag.svg"
        },
        {
            icon: "box.svg"
        },
        {
            icon: "house.svg"
        }
    ],
    css: {
        top: "purple",
        middle: "middle_purple",
        bottom: "bottom_purple"
    }
};
