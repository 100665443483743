import React, { Component } from "react";
import css from "./dark.module.css";

class Dark extends Component {
    render() {
        return <div className={css.dark}>{this.props.children}</div>;
    }
}

export default Dark;
