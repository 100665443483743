import React from "react";
import css from "./agreement.module.css";

const Agreement2 = data => {
    return (
        <div className={"agreement " + data.agreementClass}>
            <label className={css.container}>
                <input
                    type="checkbox"
                    id={data.input.id}
                    name={data.input.name}
                />
                <span
                    className={css.checkmark + " " + data.checkmarkClass}
                    id={`${data.input.id}checkmark`}
                    name={data.input.name}
                    onClick={data.changed}
                ></span>
                <span className="" onClick={data.changed}>
                Chcę wziąć udział w programie dla nowych Konsultantek oraz otrzymywać informacje o produktach, ofertach, konkursach Avon Cosmetics Polska Sp. z o.o. oraz partnetów Avon.
                    <a
                        href="https://www.avon.pl/polityka-prywatnosci/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {" "}
                        Zobacz tutaj
                    </a>
                </span>
                {data.input.error ? (
                    <div className={css.error}>{data.input.errorMessage}</div>
                ) : null}
            </label>
        </div>
    );
};

export default Agreement2;
