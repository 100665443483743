import React from "react";
import css from "./solid.module.css";

export default class External extends React.Component {
    state = {
        className: `${css.btn} ${css.external}`,
        buttonText: this.props.name,
        link: "https://www.avon.pl/dolacz-do-klubu"
    };

    componentDidMount() {
        let localState = this.state.className;

        this.setState({ className: localState });
    }

    clickHandler = () => {
        const openLink = this.props.link
            ? this.props.link
            : !this.props.src
            ? this.state.link
            : `${this.state.link}?src_r=${this.props.src}`;
        window.open(openLink);
    };

    render() {
        return (
            <button
                className={this.state.className}
                onClick={this.clickHandler}
            >
                {this.state.buttonText}
            </button>
        );
    }
}
