import availableImages from "../utils/availableImages";
export const editInitialState = {
    images: {
        confirmed: { name: "nagrody", tempId: null },
        selected: { name: "nagrody", tempId: null },
        ...availableImages
    },
    video: {
        options: {
            height: "390",
            width: "640",
            playerVars: {
                https: "//youtu.be/",
                autoplay: 0
            }
        },
        videoId: "eIAO9U9Dt2Q"
    },
    popup: { active: false, type: "" },
    loader: false,
    inputs: {
        pageName: {
            inputName: "pageName",
            inputType: "text",
            label: "Nazwa strony:",
            description:
                "Nazwa Twojej strony będzie wyświetlana na zakładce przeglądarki",
            value: "",
            error: false,
            errMessage: "",
            validation: true
        },
        pageLink: {
            inputName: "pageLink",
            inputType: "text",
            label: "Twój link:",
            description:
                "To będzie Twój indywidualny link strony - wybierz krótką nazwę, tak aby łatwo zapadała w pamięć",
            value: process.env.REACT_APP_PAGE_START_AVON,
            defaultValue: process.env.REACT_APP_PAGE_START_AVON,
            error: false
        },
        email: {
            inputName: "email",
            inputType: "text",
            label: "E-mail:",
            description:
                "Adres Email wyświetlany na stronie jako kontakt do Ciebie",
            value: "",
            error: false,
            validation: true,
            errMessage: ""
        },
        phone: {
            inputName: "phone",
            inputType: "number",
            label: "Telefon:",
            description: "Numer telefonu wyświetlany jako numer do Ciebie",
            value: "",
            error: false,
            validation: true,
            errMessage: ""
        },
        metaTitle: {
            inputName: "metaTitle",
            inputType: "text",
            label: "Tytuł:",
            description: "Tytuł strony prezentowany w wynikach wyszukiwania",
            value: "",
            error: false,
            validation: true,
            errMessage: ""
        },
        metaDescription: {
            inputName: "metaDescription",
            inputType: "text",
            label: "Opis:",
            description: "Krótki opis strony prezentowany w wynikach wyszukiwania.",
            value: "",
            error: false,
            validation: true,
            errMessage: ""
        }
    },
    tagInputs: { ga: "", gtm: "", gr: "", fp: "" },
    templateId: null,
    activePageId: null,
    template: [
        {
            id: 0,
            content: {
                header: "Sample text",
                name: "topheading"
            }
        },
        {
            id: 1,
            content: {
                header: "AVON",
                name: "join",
                header2: "",
                icons: {
                    icon1: {
                        text: "",
                        path: ""
                    },
                    icon2: {
                        text: "",
                        path: ""
                    },
                    icon3: {
                        text: "",
                        path: ""
                    }
                }
            }
        },
        {
            id: 2,
            content: {
                header: "Oddzwonię do Ciebie jeszcze tego samego dnia!",
                name: "callback"
            }
        },
        {
            id: 3,
            content: {
                header: "Dlaczego warto?",
                name: "reason"
            }
        },
        {
            id: 4,
            content: {
                header:
                    "Dołączając do AVON niezależność i dostęp do niesamowitych kosmetyków i produktów, możesz rozwijać swój biznes i uczestniczyć w szkoleniach, a także brać udział w programach i korzystać ze specjalnych ofert!",
                name: "business"
            }
        },
        {
            id: 5,
            content: {
                header: "Kupuj kosmetyki z 40% rabatem i odbieraj nagrody!",
                name: "image"
            }
        },
        {
            id: 6,
            content: {
                header: "Zobacz, jak fajnie jest być w AVON!",
                name: "video"
            }
        },
        {
            id: 7,
            content: {
                header: "sample?",
                name: "benefits",
                icons: {
                    icon1: {
                        text: "sample",
                        path: ""
                    },
                    icon2: {
                        text: "sample",
                        path: ""
                    },
                    icon3: {
                        text: "sample",
                        path: ""
                    }
                }
            }
        }
    ],
    leader: {
        id: 56604468,
        phone: "",
        email: "tygrysek8620@o2.pl",
        fname: "",
        lname: "",
        userdata: {
            picture: null,
            header_1: null,
            name: "Ewelina Stryczek",
            title: "kordynator",
            email: "tygrysek8620@o2.pl",
            phone: "508966081",
            header_social: null,
            social_whatsapp: null,
            social_skype: null,
            social_messenger: null,
            social_facebook: null,
            social_gplus: null,
            social_instagram: null,
            disclaimer: null
        },
        numlandings: 3,
        cacheCurlandings: 1
    }
};
