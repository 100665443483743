import React from "react";
import css from "./pastvideo.module.css";
import YouTube from "react-youtube";
import ButtonOutline from "../Buttons/Outline";
import { validateYoutubeLink } from "../../utils/validators";

export default class PasteVideo extends React.Component {
    state = {
        btnActive: true,
        error: false,
        errMessage: "",
        video: {
            options: {
                height: "390",
                width: "640",
                playerVars: {
                    https: "//youtu.be/",
                    autoplay: 0
                }
            },
            videoId: "eIAO9U9Dt2Q"
        }
    };

    componentDidMount() {
        let video = { ...this.state.video };

        video.videoId = this.props.video.videoId;

        this.setState({ video: video });
    }

    pastedLinkHandler = event => {
        const validation = validateYoutubeLink(event.target.value);
        let newState = { ...this.state };

        newState.error = validation.error;
        newState.errMessage = validation.message;
        newState.btnActive = !validation.error;

        if (!validation.error) {
            newState.video.videoId = validation.link[2];
        }

        this.setState(newState);
    };

    confirmHandler = () => {
        this.props.clicked(this.state.video.videoId, "video");
    };

    render() {
        let inputCss = css.input_box;

        if (this.state.error) inputCss += ` ${css.error}`;
        return (
            <div className={css.video}>
                <div className={css.top}>
                    <h2>Wybierz swój film</h2>
                    <p>Tutaj wklej link do swojego filmu z YouTube.</p>
                </div>
                <div className={css.video_box}>
                    <YouTube
                        videoId={this.state.video.videoId}
                        opts={this.state.video.options}
                    />
                </div>
                <div className={inputCss}>
                    <input
                        type="text"
                        placeholder={"Wklej link"}
                        onChange={event => this.pastedLinkHandler(event)}
                    />
                    {this.state.error ? (
                        <div className={css.error_msg}>
                            {this.state.errMessage}
                        </div>
                    ) : null}
                    <ButtonOutline
                        active={this.state.btnActive}
                        name={"Ok"}
                        clicked={this.confirmHandler}
                    />
                </div>
            </div>
        );
    }
}
