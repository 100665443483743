import * as cons from "../const";

const INITIAL_STATE = {
    pageHash: ""
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case cons.SET_PAGE_INFO:
            return {
                ...state,
                pageHash: action.payload
            };
        default:
            return state;
    }
};

export default reducer;
