import React from "react";
import { state } from "../../../states/tem7";
import PageSection from "../PageSection";

class Template7 extends React.Component {
    state = state;
    render() {
        return (
            <PageSection
                temp={this.props.temp}
                changed={this.props.changed}
                iconsTop={this.state.iconsTop}
                clickedShow={this.props.clickedShow}
                clickedHide={this.props.clickedHide}
                margin={this.props.margin}
                img={this.props.img}
                video={this.props.video}
                iconsBottom={this.state.iconsBottom}
                css={this.state.css}
            />
        );
    }
}

export default Template7;
