import React from "react";
import ReactDOM from "react-dom";
import css from "./editbox.module.css";
import HelpComponent from "../../HelpElement/HelpComponent";
export default class EditBox extends React.Component {
    state = { html: this.props.temp.header, active: false };

    setWrapperRef = this.setWrapperRef.bind(this);
    handleClickOutside = this.handleClickOutside.bind(this);

    componentDidMount() {
        document.addEventListener("click", this.handleClickOutside);
    }

    componentDidUpdate(prevProps) {
        if (this.props.temp.header !== prevProps.temp.header) {
            this.setState({ html: this.props.temp.header });
        }
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.handleClickOutside);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({ active: false });
        }
    }

    handleClickInside() {
        this.setState({ active: true });
    }

    toggleClass() {
        const currentState = this.state.active;
        this.setState({ active: !currentState });
    }

    emitChange = () => {
        var html = ReactDOM.findDOMNode(this).childNodes[0].innerHTML;
        this.props.onChange({
            target: {
                value: html
            }
        });
    };

    wrap = tag => {
        document.execCommand(tag, false, null);
    };
    setMessage = () => {
        let message = this.props.message;

        if (this.props.temp.error) message = this.props.temp.errMessage;

        return message;
    };

    setStyles = () => {
        let styles = css.editboxwrap;
        if (this.props.temp.error) styles += ` ${css.error}`;
        if (this.state.active) styles += ` ${css.active}`;
        return styles;
    };

    render() {
        return (
            <div className={this.setStyles()} ref={this.setWrapperRef}>
                <div
                    className={css.editbox}
                    onClick={this.handleClickInside.bind(this)}
                    id={this.props.id}
                    contentEditable="true"
                    onInput={this.emitChange}
                    onBlur={this.emitChange}
                    dangerouslySetInnerHTML={{ __html: this.state.html }}
                ></div>

                <div
                    className={css.switcher}
                    onClick={() => {
                        this.toggleClass();
                    }}
                >
                    {this.state.active ? (
                        <img src="/img/icons/pencilwhite.svg" alt={"error icon"} />
                    ) : (
                        <img src="/img/icons/pencil.svg" alt={"info icon"} />
                    )}
                </div>

                <div
                    className={
                        this.state.active
                            ? css.toolbar + " " + css.active
                            : css.toolbar
                    }
                >
                    <div className={css.buttons}>
                        <button
                            onClick={() => {
                                this.wrap("bold");
                            }}
                        >
                            B
                        </button>
                        <button
                            onClick={() => {
                                this.wrap("italic");
                            }}
                        >
                            <i>I</i>
                        </button>
                        <button
                            onClick={() => {
                                this.wrap("underline");
                            }}
                        >
                            <u>U</u>
                        </button>
                    </div>
                    <div className={css.message}>
                        Zaznacz tekst i kliknij jeden z powyższych przycisków
                        aby edytować jego wygląd
                    </div>
                </div>

                <HelpComponent
                    error={this.props.temp.error}
                    message={this.setMessage()}
                />
            </div>
        );
    }
}
