import * as cons from "../const";

const INITIAL_STATE = {
    landingPages: {},
    shareData: {
        facebook: "",
        linkedin: "",
        twitter: "",
        wizaz: "",
        banner: "",
        email: ""
    }
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case cons.SET_LANDING_PAGES:
            return {
                ...state,
                landingPages: action.payload.data
            };
        case cons.SET_SHARE_DATA:
            return {
                ...state,
                share: action.payload.data
            };
        default:
            return state;
    }
};

export default reducer;
