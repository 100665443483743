import React from "react";
import ButtonOutline from "../Buttons/Outline";
import css from "./sampleCnfirmation.module.css";

class SampleConfirmation extends React.Component {
    render() {
        const {content} = this.props;
        const printText = () => {
            const newProps = [];
            for (const con of content) {
                newProps.push(con);
            }

            return newProps.map(prop => <h2>{prop}</h2>);
        };

        const contentToDisplay = () => {
            return (typeof content === "string") ? <h2>{this.props.content}</h2> : printText();
        };
        return (
            <div className={css.box}>
                <div className={css.content}>
                    {contentToDisplay()}
                </div>
                {this.props.btnName ? (
                    <ButtonOutline
                        name={this.props.btnName}
                        clicked={this.props.clicked}
                        linkTo={this.props.link}
                    />
                ) : null}
            </div>
        );
    }
}

export default SampleConfirmation;
