const availableImages = {
    images: [
        {
            name: "nagrody"
        },
        {
            name: "nagrody1"
        },
        {
            name: "nagrody2"
        },
        {
            name: "nagrody3"
        },
        {
            name: "nagrody4"
        },
        {
            name: "nagrody5"
        },
        {
            name: "nagrody6"
        },
        {
            name: "nagrody7"
        },
        {
            name: "nagrody8"
        },
        {
            name: "nagrody9"
        }
    ]
};

export default availableImages;
