const stationaryData =[
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12(5,7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "121(0,1-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:44"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 1219,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "122(3,4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "1220(5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-22 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12200(0,1,3,6,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 122002,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 122004,
      "Operator (nazwa)": "F.U.H. AG-NET Joanna M�cze�ska",
      "Operator (ID)": 8768,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 122005,
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-12 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 122007,
      "Operator (nazwa)": "ITEL Solutions Sp. z o.o.",
      "Operator (ID)": 11274,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-08-05 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 122009,
      "Operator (nazwa)": "Colt Technology Services GmbH",
      "Operator (ID)": 12380,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-12-02 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 12201,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12202(1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12202(3,4)",
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-20 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12202(5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 122020,
      "Operator (nazwa)": "TOYA Sp. z o.o.",
      "Operator (ID)": 127,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12203(0,3-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-12-06 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12203(2,5)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-12-06 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 122031,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12204(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-22 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 122041,
      "Operator (nazwa)": "MEDIDESK Sp. z o.o.",
      "Operator (ID)": 12669,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-22 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "1221(4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12210(3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-09 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 122100,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 122101,
      "Operator (nazwa)": "Telfon Poland Sp. z o.o.",
      "Operator (ID)": 12517,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-03-07 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 122102,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-29 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 12211,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12212(3-5)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12212(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-04-30 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 122120,
      "Operator (nazwa)": "K2M Sp. z o.o.",
      "Operator (ID)": 13135,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-04-30 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 122121,
      "Operator (nazwa)": "Limtel Sp. z o.o.",
      "Operator (ID)": 9118,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 122122,
      "Operator (nazwa)": "IPTELEKOM Micha� Ludwiczak",
      "Operator (ID)": 10051,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 122126,
      "Operator (nazwa)": "Stimo.Net Sp. z o.o.",
      "Operator (ID)": 11891,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2024-05-15 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12213(0-1,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-10-05 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 122132,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-10-05 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 122133,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "1222(1,3-4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "1222(5-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12220(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 122200,
      "Operator (nazwa)": "Spikon Sp. z o.o.",
      "Operator (ID)": 10784,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 12222,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "1225(2-4,6,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12250(0,1-3,5-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 122504,
      "Operator (nazwa)": "Dialoga Servicios Interactivos S.A.",
      "Operator (ID)": 10930,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 122508,
      "Operator (nazwa)": "FIBERLINK Sp. z o.o.",
      "Operator (ID)": 5543,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2021-09-15 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12251(0,1-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 122518,
      "Operator (nazwa)": "DIDWW Ireland Limited",
      "Operator (ID)": 12132,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-11-24 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12255(0-1,4-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12255(2,3)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12257(0,1,3-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 122572,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-01 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12259(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 122599,
      "Operator (nazwa)": "Telnyx Ireland Limited",
      "Operator (ID)": 13021,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-01 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "1226(0,1,2,4,6,7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12263(0,1,2,4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12263(3,5)",
      "Operator (nazwa)": "ITH Sp. Z o.o.",
      "Operator (ID)": 12635,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-03-14 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12265(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 122659,
      "Operator (nazwa)": "Media-Com Sp. z o.o.",
      "Operator (ID)": 2161,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-03-07 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12268(0,1,2,4-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 122683,
      "Operator (nazwa)": "GT11 Sp. z o.o.",
      "Operator (ID)": 10475,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "1227(0,2-4,6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12271(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 122719,
      "Operator (nazwa)": "K2 MEDIA Janusz Kaczmarczyk",
      "Operator (ID)": 7535,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12275(0,1,3,4,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12275(2,5,6,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12277(0,1-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 122776,
      "Operator (nazwa)": "MYNET Sp. z o.o.",
      "Operator (ID)": 8053,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-07-04 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12278(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12278(1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12279(0,1-5,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12279(6-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "1228(0-1,4-5,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12282(0-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 122827,
      "Operator (nazwa)": "ITH Sp. Z o.o.",
      "Operator (ID)": 12635,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-03-14 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12283(0,1-3,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 122834,
      "Operator (nazwa)": "Platan Sp. z o.o. Sp. k.",
      "Operator (ID)": 12733,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-10-08 12:20"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12286(0,1,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12286(2-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12287(0,1,3,4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12287(2,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12288(0,1-3,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12288(5-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-11-04 12:35"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12288(7,8)",
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-20 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 122884,
      "Operator (nazwa)": "THULIUM Sp. z o.o.",
      "Operator (ID)": 13433,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-16 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "1229(0,2-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12291(0,1-8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 122919,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "1230(2-5,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12300(5-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-05-13 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123000,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123001,
      "Operator (nazwa)": "e-Telko Sp. z o.o.",
      "Operator (ID)": 11564,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-04-19 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123002,
      "Operator (nazwa)": "IP Telecom Bulgaria LTD",
      "Operator (ID)": 11805,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-06-22 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123003,
      "Operator (nazwa)": "NETTEL Sp. z o.o.",
      "Operator (ID)": 9575,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123004,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-12-22 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 12301,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12306(0-4,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-04-10 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12306(5,7)",
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123066,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-04-10 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 12307,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12308(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-07-06 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12308(3-5)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-01-15 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123081,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-04-01 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123082,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "1231(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-15 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12311(1-9)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123110,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12312(1-3)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123120,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123124,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123125,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123126,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-01-02 12:20"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123127,
      "Operator (nazwa)": "INET CENTRUM Sp. z o.o.",
      "Operator (ID)": 5069,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123128,
      "Operator (nazwa)": "NASZASIEC.NET KRAK�W Damian Murzynowski",
      "Operator (ID)": 7643,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123129,
      "Operator (nazwa)": "Nettelekom GK Sp. z o.o.",
      "Operator (ID)": 9896,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12313(0,1-8)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123139,
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12314(0,9)",
      "Operator (nazwa)": "S-NET Sp. z o.o.",
      "Operator (ID)": 8697,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12314(1,2)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12314(3-8)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12315(1-7)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-15 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12315(8,9)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-01-04 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123150,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-24 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "1232(0,2,5-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 12321,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12323(1-6,8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123230,
      "Operator (nazwa)": "Debesis Sp. z o.o.",
      "Operator (ID)": 10138,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-12-29 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123237,
      "Operator (nazwa)": "FREELY Sp. z o.o.",
      "Operator (ID)": 13143,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123239,
      "Operator (nazwa)": "Intelligent Technologies S.A.",
      "Operator (ID)": 748,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-18 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12324(0,2,4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-11-03 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12324(1,5-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-11-03 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123243,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12328(0-1,4,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-09-29 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12328(2,5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-17 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123283,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123286,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-08-05 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "1233(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-04-15 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "1233(1,2)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12333(8,9)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123330,
      "Operator (nazwa)": "HyperNET Sp. z o.o.",
      "Operator (ID)": 8619,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123331,
      "Operator (nazwa)": "THULIUM Sp. z o.o.",
      "Operator (ID)": 13433,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-16 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123332,
      "Operator (nazwa)": "I-TEL Sp. z o.o.",
      "Operator (ID)": 6768,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123333,
      "Operator (nazwa)": "INFANET Sylwester Kus",
      "Operator (ID)": 5441,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123334,
      "Operator (nazwa)": "Connected Sp. z o.o.",
      "Operator (ID)": 8033,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123335,
      "Operator (nazwa)": "CLUDO Sp z o.o.",
      "Operator (ID)": 10056,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-02-01 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123336,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123337,
      "Operator (nazwa)": "ST LINE Sp. z o.o. Sp. k.",
      "Operator (ID)": 12096,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-01 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 12334,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12335(0,2-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-05-20 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123351,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-05-20 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123357,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-05-20 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "1234(0,1,2,7,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "1234(4,8)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12343(2,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2021-08-05 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12343(3,5)",
      "Operator (nazwa)": "Multimedia Polska Biznes S.A.",
      "Operator (ID)": 11221,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-01-16 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123430,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123431,
      "Operator (nazwa)": "BT Poland Sp. z o.o.",
      "Operator (ID)": 109,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2021-08-05 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123434,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12345(1,2)",
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12345(5,7)",
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123450,
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123453,
      "Operator (nazwa)": "Intelligent Technologies S.A.",
      "Operator (ID)": 748,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123454,
      "Operator (nazwa)": "IPTELL Sp. z o.o.",
      "Operator (ID)": 7201,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123456,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-12-16 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123458,
      "Operator (nazwa)": "BT Poland Sp. z o.o.",
      "Operator (ID)": 109,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-03 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123459,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-11-05 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12346(1-6)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12346(7-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-10 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123460,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-10 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "1235(3,4)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "1235(8,9)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12350(1,5)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123500,
      "Operator (nazwa)": "FIBERLINK Sp. z o.o.",
      "Operator (ID)": 5543,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-06-01 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123502,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123503,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-01 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123504,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123506,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123507,
      "Operator (nazwa)": "ENTER T&T Sp. z o.o.",
      "Operator (ID)": 10688,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123508,
      "Operator (nazwa)": "Roman Bidu� TELBIT wsp�lnik sp�ki cywilnej",
      "Operator (ID)": 7904,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123509,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12351(1-6,8)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123510,
      "Operator (nazwa)": "Focus Telecom Polska Sp. z o.o.",
      "Operator (ID)": 8974,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123517,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-04 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123519,
      "Operator (nazwa)": "NETFIBRA Bartosz Krokosz",
      "Operator (ID)": 13428,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-06-15 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12352(1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123520,
      "Operator (nazwa)": "BLAST.PL Sebastian Bagi�ski",
      "Operator (ID)": 7337,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-09-12 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123529,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2021-12-14 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12355(0,1-8)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123559,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-12-30 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12356(1,2)",
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12356(5-8)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123560,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-06 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123563,
      "Operator (nazwa)": "FHU BALMONT Monika Joanna Ba�a",
      "Operator (ID)": 1969,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123564,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123569,
      "Operator (nazwa)": "RADIO-TELECOM Andrzej de Junosza Za�uski",
      "Operator (ID)": 2707,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12357(1-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123570,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-02-16 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "1236(1,3)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "1236(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 12360,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12362(0,8)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-03-26 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12362(1,2)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12362(4-7)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123623,
      "Operator (nazwa)": "ATMAN Sp. z o.o.",
      "Operator (ID)": 944,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-10-21 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123629,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12364(0,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12364(1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 12365,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12370(1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123700,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-08 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123709,
      "Operator (nazwa)": "Pomerania Services Group Sp. z o.o.",
      "Operator (ID)": 11625,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-10-11 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12371(0,1-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12371(5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 12372,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12373(0,1-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123737,
      "Operator (nazwa)": "Krapkowickie Sieci Internetowe Sp. z o.o.",
      "Operator (ID)": 11425,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-12-11 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12374(0,1-8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123749,
      "Operator (nazwa)": "S-NET Sp. z o.o.",
      "Operator (ID)": 8697,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-04-10 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12375(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12375(1-5)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12376(0,1-6,9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12376(7,8)",
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-12-01 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 12377,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12378(0,1,2,4-8)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12378(3,9)",
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-12-01 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 12379,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "1238(6,8,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12380(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123800,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12381(1-4,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-12-02 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12381(5-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123810,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-12-02 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 12382,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12383(0,3)",
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12383(1,2,4-9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12384(0,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12384(1,2)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12384(3-7)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123848,
      "Operator (nazwa)": "VOXNET Sp. z o.o. Sp. k.",
      "Operator (ID)": 728,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12385(0,1-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123858,
      "Operator (nazwa)": "FIBERWAY Sp. z o.o.",
      "Operator (ID)": 9750,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12387(0,1-4,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123875,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-01-02 12:20"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123876,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "1239(7,8)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 12390,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12391(0-4,8)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123915,
      "Operator (nazwa)": "FIBERWAY Sp. z o.o.",
      "Operator (ID)": 9750,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-03 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123916,
      "Operator (nazwa)": "FIBERWAY Sp. z o.o.",
      "Operator (ID)": 9750,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-06 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123917,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123919,
      "Operator (nazwa)": "SMSWIZARD POLSKA Sp. z o.o.",
      "Operator (ID)": 12975,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-01-25 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 12392,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 12393,
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 12394,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "Krak�w",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123950,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "Krak�w",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123951,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-12 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123952,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-06-15 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123953,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123954,
      "Operator (nazwa)": "Televoice Polska Sp. z o.o.",
      "Operator (ID)": 8808,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123955,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-01 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123956,
      "Operator (nazwa)": "4 CARRIERS GLOBAL Sp. z o.o.",
      "Operator (ID)": 12739,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-12-23 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123957,
      "Operator (nazwa)": "S-NET Sp. z o.o.",
      "Operator (ID)": 8697,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123958,
      "Operator (nazwa)": "FIBERWAY Sp. z o.o.",
      "Operator (ID)": 9750,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123959,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "Krak�w",
      "Data modyfikacji": "2023-12-30 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12396(0,8)",
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12396(3,4)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123961,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-01 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123962,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123965,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123966,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-08 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123967,
      "Operator (nazwa)": "e-Telko Sp. z o.o.",
      "Operator (ID)": 11564,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123969,
      "Operator (nazwa)": "Globus Telecom Sp. z o.o.",
      "Operator (ID)": 13120,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-04 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12399(0,1-3,5-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 123994,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "124(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "1240(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12400(1-3)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-31 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12400(5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-31 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 124000,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 124004,
      "Operator (nazwa)": "IP Telecom Bulgaria LTD",
      "Operator (ID)": 11805,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-23 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "1241(0,1-3,5,7,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12414(0,1-5,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12414(6,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12416(0,4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12416(1-3,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12419(0,1-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 124198,
      "Operator (nazwa)": "FreePhone Sp. z o.o.",
      "Operator (ID)": 10788,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-01 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "1242(1-6,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12420(0-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 124207,
      "Operator (nazwa)": "didXL B.V.",
      "Operator (ID)": 13079,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-10-22 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12427(0,1-3,5,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12427(4,6,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12428(0-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 124288,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "1243(0,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "1243(5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12431(0,1,2,4-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 124313,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12433(0-3,5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 124334,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 124336,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-12-31 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12434(0,1-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 124344,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "1244(0,7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 12441,
      "Operator (nazwa)": "TeleGo sp. z o.o.",
      "Operator (ID)": 9324,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 124420,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 124421,
      "Operator (nazwa)": "TOYA Sp. z o.o.",
      "Operator (ID)": 127,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:17"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 124422,
      "Operator (nazwa)": "SFERANET S.A.",
      "Operator (ID)": 4882,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 124423,
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 124424,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-06-10 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 124425,
      "Operator (nazwa)": "Telbeskid Sp. z o.o.",
      "Operator (ID)": 57,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-03-14 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 124426,
      "Operator (nazwa)": "Arkadiusz Ko�ma FIRMA HANDLOWA \"GIGA",
      "Operator (ID)": 5561,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 124427,
      "Operator (nazwa)": "Artur Furtak wsp�lnik sp�ki cywilnej POLMEX-SERWIS",
      "Operator (ID)": 1837,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "Krak�w",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 124428,
      "Operator (nazwa)": "Intelligent Technologies S.A.",
      "Operator (ID)": 748,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-11-03 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 124429,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12443(0-2,6-7)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12443(3,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12443(4,5,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12444(0,8-9)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12444(1,3,4)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 124442,
      "Operator (nazwa)": "Multinet 24 Sp. z o.o.",
      "Operator (ID)": 8597,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 124445,
      "Operator (nazwa)": "FIBERLINK Sp. z o.o.",
      "Operator (ID)": 5543,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 124446,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 124447,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12445(2,8)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12445(3,4,7)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12445(5,6)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "Krak�w",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 124450,
      "Operator (nazwa)": "TIMPLUS B. Dudek, R. Walentowski sp�ka jawna",
      "Operator (ID)": 3110,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 124451,
      "Operator (nazwa)": "PRZEDSI�BIORSTWO AJC Adam Ozga Przedsi�biorstwo AJC2",
      "Operator (ID)": 390,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 124459,
      "Operator (nazwa)": "Arkadiusz Ko�ma FIRMA HANDLOWA \"GIGA",
      "Operator (ID)": 5561,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 124460,
      "Operator (nazwa)": "Blue Prospect Sp. z o.o.",
      "Operator (ID)": 13345,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-08-05 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 124461,
      "Operator (nazwa)": "Intertele S.A.",
      "Operator (ID)": 1487,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 124462,
      "Operator (nazwa)": "ATMAN Sp. z o.o.",
      "Operator (ID)": 944,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 124463,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-06-10 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 124464,
      "Operator (nazwa)": "GEMINI INTERNET Sp. z o.o.",
      "Operator (ID)": 2888,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 124465,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 124466,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 124467,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-15 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 124468,
      "Operator (nazwa)": "World Discount Telecommunication Polska Sp. z o.o.",
      "Operator (ID)": 68,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 124469,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-10-01 12:20"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12448(1,2)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-15 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12448(5-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-08-30 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 124480,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-08-07 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 124483,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-15 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 124484,
      "Operator (nazwa)": "LoVo Sp. z o.o.",
      "Operator (ID)": 7184,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-08-30 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 124488,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "1245(0,3,4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12451(0,5,6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12451(1-4,7,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 12452,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 12455,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "126(7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "1260(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12600(1-5,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2024-05-15 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 126000,
      "Operator (nazwa)": "SUDER Sp. z o.o.",
      "Operator (ID)": 10822,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 126006,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2021-08-20 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 126007,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2024-05-15 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "1261(0,1,3)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "1261(6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-04-01 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12612(0,5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-14 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12612(1,2)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-12-15 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12612(3,4,7-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-09-14 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 126126,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12614(0-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12614(7,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 126149,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 12615,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-04-01 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "1262(2-4,6,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12620(0,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12620(1-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 12621,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12625(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 126259,
      "Operator (nazwa)": "MT Sp. z o.o.",
      "Operator (ID)": 3833,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-10-13 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12627(0,1-3,6,7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12627(4,5,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12629(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 126299,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-11-24 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "1263(1-3,6-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-01-08 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12630(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 126300,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12634(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 126349,
      "Operator (nazwa)": "MIDIKO Sp. z o.o.",
      "Operator (ID)": 4990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-10-01 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12635(0,3,5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12635(1,2,4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12639(0-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-01-08 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 126396,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-10-01 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 1264,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "1265(0,1-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12656(0,1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 126568,
      "Operator (nazwa)": "PPHU AWIST Artur Walig�ra",
      "Operator (ID)": 9395,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-12 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 126569,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-01 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "1266(1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "1266(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12660(0,1,3,7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12660(2,4-5,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-07-01 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 126606,
      "Operator (nazwa)": "Leon Sp. z o.o.",
      "Operator (ID)": 1471,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-07-01 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12663(0,6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12663(1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 126637,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12664(0,1,2,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12664(3-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12665(0,1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12665(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12666(1-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-03 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 126660,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-03 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 126666,
      "Operator (nazwa)": "INFANET Sylwester Kus",
      "Operator (ID)": 5441,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "1268(0,4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12681(0-1,3-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 126812,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12682(0,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12682(1-6,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 126829,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12683(0,1-3,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 126834,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-04-30 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12685(0,1,2,5,6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12685(3,4,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12686(0,1-4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 126865,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-06-04 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12687(0,2,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12687(1,3-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12688(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 126880,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-10 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 12689,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-11-24 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "128(2-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "1280(0,6-9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12801(0,1,4,7,8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12801(2,5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12801(3,6)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-01-06 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 128019,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12802(0,1)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12802(3,5,7-9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12802(4,6)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 128022,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12803(0,1,3,4,5,9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 128032,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 128036,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-01-06 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 128037,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 128038,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12804(0,2,6,8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12804(1,9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-01-06 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12804(3-5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 128047,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12805(0,1,3)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12805(2,7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12805(4,9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12805(5,8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-01-06 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 128056,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "1281(0-1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12812(0-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 128128,
      "Operator (nazwa)": "Telfon Poland Sp. z o.o.",
      "Operator (ID)": 12517,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "1288(2-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-12-11 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12880(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-12-11 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 128805,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-12-11 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 12881,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 12888,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12889(0,7-9)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-03 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "12889(1-4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-11-30 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 128895,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-11-30 12:15"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": 128896,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "129(0,1-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 12,
      "Zakres (number)": "129(8,9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "KRAK�W",
      "Strefa (symbol)": "SN-KR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13(2,5-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "131(0,1-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 1319,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "133(2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "1330(1-5,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13300(1,2,4-6)",
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13300(8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-24 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 133000,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 133003,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 133007,
      "Operator (nazwa)": "MEDIDESK Sp. z o.o.",
      "Operator (ID)": 12669,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-24 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13306(2-4)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13306(5,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 133060,
      "Operator (nazwa)": "AMS SYSTEM Sp. z o.o.",
      "Operator (ID)": 7006,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-11-15 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 133061,
      "Operator (nazwa)": "KCI KROSOFT Dariusz Jastrz�b",
      "Operator (ID)": 338,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 133067,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 133068,
      "Operator (nazwa)": "ON-LINE NET Pawe� �cibor",
      "Operator (ID)": 7864,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 133069,
      "Operator (nazwa)": "e-Telko Sp. z o.o.",
      "Operator (ID)": 11564,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-04-19 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13307(2,3)",
      "Operator (nazwa)": "COMP-SERWIS Sp. z o.o.",
      "Operator (ID)": 445,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 133070,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 133071,
      "Operator (nazwa)": "MT Sp. z o.o.",
      "Operator (ID)": 3833,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 133074,
      "Operator (nazwa)": "KCI KROSOFT Dariusz Jastrz�b",
      "Operator (ID)": 338,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 133075,
      "Operator (nazwa)": "WIDE-NET II Sp. z o.o.",
      "Operator (ID)": 6329,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 133076,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 133077,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 133078,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-01 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 133079,
      "Operator (nazwa)": "NETSYSTEM Tomasz Przybysz",
      "Operator (ID)": 7922,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-11-09 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13308(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 133082,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "1331(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13313(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 133133,
      "Operator (nazwa)": "Telfon Poland Sp. z o.o.",
      "Operator (ID)": 12517,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "1333(0,2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13331(0-1,3-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-30 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 133312,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-30 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 13333,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "134(5,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "1340(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13400(2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-22 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134000,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134001,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-22 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "1341(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13413(0-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134134,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-05 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "1342(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13420(0,1-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13420(5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-06-10 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134206,
      "Operator (nazwa)": "AP-MEDIA Sp. z o.o. Sp. k.",
      "Operator (ID)": 7476,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-06-10 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13421(1-5)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13421(6-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134210,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134219,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13422(0,1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13422(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13423(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134231,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13424(0,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13424(1-4)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13425(0,1)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13425(2-4)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13425(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134255,
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-20 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13426(0,1-5)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13426(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "1343(1,2,4-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13430(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134300,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13433(0,1,3,5,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13433(6,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-07-01 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134332,
      "Operator (nazwa)": "IT SYSTEMATIC GROUP Piotr Bukowczyk",
      "Operator (ID)": 9274,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-29 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134334,
      "Operator (nazwa)": "Stimo.Net Sp. z o.o.",
      "Operator (ID)": 11891,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2024-05-15 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13437(1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134370,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-17 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134379,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-10-01 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13438(0,1-6,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13438(7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13439(1-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134390,
      "Operator (nazwa)": "Voice Net Sp. z o.o.",
      "Operator (ID)": 12749,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2024-02-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134396,
      "Operator (nazwa)": "Dialoga Servicios Interactivos S.A.",
      "Operator (ID)": 10930,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134400,
      "Operator (nazwa)": "FREELY Sp. z o.o.",
      "Operator (ID)": 13143,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134401,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134402,
      "Operator (nazwa)": "PUH DARNET Dariusz Czerkies",
      "Operator (ID)": 5610,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-08-13 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134403,
      "Operator (nazwa)": "Ryszard Ziembicki INTERQ US�UGI INTERNETOWE",
      "Operator (ID)": 3756,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134404,
      "Operator (nazwa)": "IPTELL Sp. z o.o.",
      "Operator (ID)": 7201,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134405,
      "Operator (nazwa)": "AP-MEDIA Sp. z o.o. Sp. k.",
      "Operator (ID)": 7476,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-27 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134406,
      "Operator (nazwa)": "AP-MEDIA Sp. z o.o. Sp. k.",
      "Operator (ID)": 7476,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134407,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134408,
      "Operator (nazwa)": "FORWEB S.C. Monika Bodetko Tomasz Paw�owski",
      "Operator (ID)": 5731,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134409,
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13441(0,3-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13441(1,2,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13442(0,1,3-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134422,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13443(3-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134430,
      "Operator (nazwa)": "Galena Sp. z o.o.",
      "Operator (ID)": 66,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-16 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134431,
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134432,
      "Operator (nazwa)": "FHU PASCAL Krzysztof Musia�",
      "Operator (ID)": 2188,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134439,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13444(0,4-7)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-13 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134441,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-04 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134442,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134443,
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134448,
      "Operator (nazwa)": "Intelligent Technologies S.A.",
      "Operator (ID)": 748,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-05-14 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134449,
      "Operator (nazwa)": "ITH Sp. Z o.o.",
      "Operator (ID)": 12635,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2023-06-10 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13445(0,1-3,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134454,
      "Operator (nazwa)": "DIDWW Ireland Limited",
      "Operator (ID)": 12132,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-19 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13446(0,2-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134461,
      "Operator (nazwa)": "Metrosan Sp. z o.o.",
      "Operator (ID)": 11083,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13447(0,1,2,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13447(3,4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13448(0,1-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134488,
      "Operator (nazwa)": "FHU KOMNET Grzegorz P�torak",
      "Operator (ID)": 6724,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-06-14 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13449(0,6-9)",
      "Operator (nazwa)": "TeleGo sp. z o.o.",
      "Operator (ID)": 9324,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-09-25 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13449(1,3-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134492,
      "Operator (nazwa)": "CLUDO Sp z o.o.",
      "Operator (ID)": 10056,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-02-01 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "1346(2,4,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13460(0,2,4-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13460(1,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134603,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13461(0,1-4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134615,
      "Operator (nazwa)": "Telnyx Ireland Limited",
      "Operator (ID)": 13021,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-01 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13463(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134639,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-23 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13466(0,5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13466(1-4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13467(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134679,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-06-08 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13468(0,1-3,5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13468(4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13469(0,1,2,4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13469(3,5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "1347(2,3,5,7,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13470(0,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13470(2-3)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "Lesko",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134701,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "Lesko",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13471(0,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13471(1,3)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "Ustrzyki Dolne",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134712,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "Ustrzyki Dolne",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13474(0,4-7)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "Sanok",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13474(1,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "Sanok",
      "Data modyfikacji": "2022-07-13 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13474(2,3,8)",
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "Sanok",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13476(1-3,9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "Krosno",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13476(4-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "Krosno",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134760,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "Krosno",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13479(0,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13479(1-3)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "Jas�o",
      "Data modyfikacji": "2018-06-01 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "1349(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13491(0,7,8)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-01 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13491(2,3)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13491(4-6)",
      "Operator (nazwa)": "PRZEDSI�BIORSTWO TELEKOMUNIKACYJNE \"TELGAM\" SPӣKA AKCYJNA",
      "Operator (ID)": 37,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "Biecz, Jas�o",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134911,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134919,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "Krosno",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13492(0-3,8)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134924,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134925,
      "Operator (nazwa)": "VOXNET Sp. z o.o. Sp. k.",
      "Operator (ID)": 728,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134926,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134927,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134929,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-07-22 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13493(0,4,7)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13493(1,3)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13493(5-6)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134932,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134938,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134939,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 13494,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13495(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134951,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13496(0,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13496(1-3)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13497(0,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13497(1,2)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 134973,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "138(2-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "1380(0,6-9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "1380(2-5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13801(1,2)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13801(3,0)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13801(4-6)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13801(7-9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "1381(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13813(0-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 138138,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-10 12:15"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "1388(0,1-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "13888(0,2-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 138881,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": 138888,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "139(0,1-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 13,
      "Zakres (number)": "139(8,9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "KROSNO",
      "Strefa (symbol)": "SN-KS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14(2,4,5,7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "141(0,1-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 1419,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "143(2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "1430(1-5,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14300(2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-11-16 12:50"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 143000,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 143001,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-11-16 12:50"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 143003,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14306(1,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-11-29 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14306(2-4)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14306(5,6)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 143060,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-24 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 143067,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 143069,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-11-29 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14307(5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 143070,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 143071,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-01-02 12:20"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 143072,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 143073,
      "Operator (nazwa)": "AVANET Bart�omiej Czy�",
      "Operator (ID)": 6350,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-11-17 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 143074,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-08-08 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14308(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 143082,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "1431(0-2,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-01-20 12:45"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14313(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-01-20 12:45"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 143131,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-01-20 12:45"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14314(0,2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-09-29 12:45"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 143141,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-17 12:45"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 143143,
      "Operator (nazwa)": "MEDIDESK Sp. z o.o.",
      "Operator (ID)": 12669,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-12-09 12:45"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "1460(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 14600,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "1461(1,3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "1461(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14610(0,1,2,4,5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146103,
      "Operator (nazwa)": "Dialoga Servicios Interactivos S.A.",
      "Operator (ID)": 10930,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146106,
      "Operator (nazwa)": "ITH Sp. Z o.o.",
      "Operator (ID)": 12635,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-03-14 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14612(0,2-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146121,
      "Operator (nazwa)": "ATMAN Sp. z o.o.",
      "Operator (ID)": 944,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-01-13 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14614(0,1,2,4,7,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14614(3,5,6,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14615(1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146150,
      "Operator (nazwa)": "FIBERLINK Sp. z o.o.",
      "Operator (ID)": 5543,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-15 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146159,
      "Operator (nazwa)": "DIDWW Ireland Limited",
      "Operator (ID)": 12132,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-19 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "1462(1,6,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14620(0,1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14620(3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14622(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146229,
      "Operator (nazwa)": "Telnyx Ireland Limited",
      "Operator (ID)": 13021,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-01 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14623(0,1,2,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14623(3,4)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-15 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14623(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-15 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14624(0,1-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14624(5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14625(0,1,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14625(2-4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14627(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146279,
      "Operator (nazwa)": "CHEELOO J. Turczyn i Wsp�lnicy Sp. J.",
      "Operator (ID)": 8632,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14628(1,2)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14628(3-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146280,
      "Operator (nazwa)": "ENTER T&T Sp. z o.o.",
      "Operator (ID)": 10688,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-08-03 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146289,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-01-04 12:45"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14630(0,3-5,7-9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14630(1,2,6)",
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 14631,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14632(0,1-4,6-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14632(5,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14633(0,1-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14633(5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-12-14 12:45"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146336,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-12-14 12:45"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14634(0,1)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14634(2-4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14634(7-8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146345,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146346,
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146349,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14635(0,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14635(1,2)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 14636,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "Tarn�w",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14637(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14637(2-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146371,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-08-08 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 14638,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14639(1-2,4,6-9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14639(3,5)",
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146390,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-01-15 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "1464(1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "1464(7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14640(1-3)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14640(4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-08-30 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146400,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-08-30 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14643(1-2,4-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146430,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146433,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14644(0,1-4,6,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14644(5,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14645(0,1,2,4-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14645(3,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14646(0,6-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14646(1-5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14648(0,1-4)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14648(5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14650(0,1)",
      "Operator (nazwa)": "FAITH-NET Robert Wr�bel, Stanis�aw Sutkowski Sp. J.",
      "Operator (ID)": 6767,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN (w granicach woj. ma�opolskiego)",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14650(2,3,7-9)",
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14650(4,6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146505,
      "Operator (nazwa)": "Zicom Next Sp. z o.o.",
      "Operator (ID)": 7661,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 14651,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14652(0,2-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14652(1,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14653(0,1,2,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14653(3,4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14654(0,1-3,6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14654(4,5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 14655,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "Tarn�w",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14656(0-4,6-9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "Tarn�w",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146565,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "Tarn�w",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14657(0,8-9)",
      "Operator (nazwa)": "INTEGRA Pawe� Mr�z",
      "Operator (ID)": 6636,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14657(1-4)",
      "Operator (nazwa)": "INFO-NET ZESPӣ EFEKTYWNYCH TECHNIK OBLICZENIOWYCH S.A. RYMAR TELECOM Sp. z o.o. Sp. j.",
      "Operator (ID)": 766,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14657(5-7)",
      "Operator (nazwa)": "RWD PROSPECT Sp. z o.o.",
      "Operator (ID)": 1312,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14658(6,7)",
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146580,
      "Operator (nazwa)": "WDM Sp. z o.o.",
      "Operator (ID)": 12229,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-12-31 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146581,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-01 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146582,
      "Operator (nazwa)": "AP-MEDIA Sp. z o.o. Sp. k.",
      "Operator (ID)": 7476,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146583,
      "Operator (nazwa)": "Tellion Sp. z o.o.",
      "Operator (ID)": 9357,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146584,
      "Operator (nazwa)": "Roman Bidu� TELBIT wsp�lnik sp�ki cywilnej",
      "Operator (ID)": 7904,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146585,
      "Operator (nazwa)": "PHU BESTCONNECT Janusz Ciuru�",
      "Operator (ID)": 8460,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-12-21 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146588,
      "Operator (nazwa)": "AMS SYSTEM Sp. z o.o.",
      "Operator (ID)": 7006,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-04-01 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146589,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-10-01 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 14659,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "1466(0,1,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "1466(3,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14662(0,1,5,6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14662(3,4,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146622,
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-20 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14664(0-3,5,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-09-03 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14664(6-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146644,
      "Operator (nazwa)": "Debesis Sp. z o.o.",
      "Operator (ID)": 10138,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-09-03 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14666(1,2,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146660,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146663,
      "Operator (nazwa)": "Multinet 24 Sp. z o.o.",
      "Operator (ID)": 8597,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146664,
      "Operator (nazwa)": "KM-NET Marek Sob�l",
      "Operator (ID)": 8279,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "1467(0,6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14671(0,1,2,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14671(3-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14672(0,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14672(1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14673(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14673(1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14674(1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146740,
      "Operator (nazwa)": "F.U.H. CLIMAX Rafa� Kraj",
      "Operator (ID)": 1027,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146749,
      "Operator (nazwa)": "CLUDO Sp z o.o.",
      "Operator (ID)": 10056,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-02-01 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14675(0,1-4,6,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14675(5,7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14677(0,1-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146777,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "1468(1-3,5)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 14680,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14684(0-7,9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146848,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14686(0-4,6-9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146865,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14687(0,9)",
      "Operator (nazwa)": "Tarnowski O�rodek Informacyjny Aleksander Rajski",
      "Operator (ID)": 1741,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14687(6,7)",
      "Operator (nazwa)": "MT Sp. z o.o.",
      "Operator (ID)": 3833,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146871,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146872,
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146873,
      "Operator (nazwa)": "IPTELL Sp. z o.o.",
      "Operator (ID)": 7201,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146874,
      "Operator (nazwa)": "Nettelekom GK Sp. z o.o.",
      "Operator (ID)": 9896,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146875,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-11-10 12:35"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146878,
      "Operator (nazwa)": "NETFIBER Sp. z o.o. (d. MARKOMP Marcin W�jcik)",
      "Operator (ID)": 6198,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN (w granicach woj. ma�opolskiego)",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 14688,
      "Operator (nazwa)": "Tarnowski O�rodek Informacyjny Aleksander Rajski",
      "Operator (ID)": 1741,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "Tarn�w",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14689(5-9)",
      "Operator (nazwa)": "TeleGo sp. z o.o.",
      "Operator (ID)": 9324,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146890,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146891,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146892,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146893,
      "Operator (nazwa)": "Galena Sp. z o.o.",
      "Operator (ID)": 66,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-16 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146894,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-04 12:45"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14690(0,2,5,6)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-13 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146901,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146903,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-01-15 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146904,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-06-01 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146907,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146908,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146909,
      "Operator (nazwa)": "Voice Net Sp. z o.o.",
      "Operator (ID)": 12749,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2024-02-20 12:45"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14691(0,8-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14691(2,4-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14691(3,7)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146911,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-01 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14692(4,6)",
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14692(5,8,9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146920,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146921,
      "Operator (nazwa)": "VOXNET Sp. z o.o. Sp. k.",
      "Operator (ID)": 728,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146922,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146923,
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146927,
      "Operator (nazwa)": "Telbeskid Sp. z o.o.",
      "Operator (ID)": 57,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-03-14 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14693(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14693(1-5)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 14694,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 14695,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14696(0-3,7)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14696(5,6)",
      "Operator (nazwa)": "Zesp� Efektywnych Technik Obliczeniowych S.A.",
      "Operator (ID)": 1170,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-06-01 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146964,
      "Operator (nazwa)": "FREELY Sp. z o.o.",
      "Operator (ID)": 13143,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146968,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-17 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146969,
      "Operator (nazwa)": "Multinet 24 Sp. z o.o.",
      "Operator (ID)": 8597,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14697(0,2-6,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-03-02 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146971,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 146977,
      "Operator (nazwa)": "Netsystem Sp. z o.o.",
      "Operator (ID)": 11282,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-03-02 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14698(0,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14698(1-4)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14699(0-5,8-9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14699(6,7)",
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "148(2-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "1480(0,6-9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "1480(3-5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14801(0,4,7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14801(1,3,6)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14801(8,9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 148012,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-01-06 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 148015,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14802(0,1-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 148029,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "1481(0-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14814(0-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 148148,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-10 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "1488(0,1-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14888(0,2,7,9)",
      "Operator (nazwa)": "Multinet 24 Sp. z o.o.",
      "Operator (ID)": 8597,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-10 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14888(3-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-10 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 148881,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 148888,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "14889(0,2-4,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-12-06 12:45"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 148891,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-12-06 12:45"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 148895,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-11-30 12:15"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": 148896,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "149(0,1-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 14,
      "Zakres (number)": "149(8,9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "TARN�W",
      "Strefa (symbol)": "SN-TA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15(2,4,5,7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "151(0,1-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 1519,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:46"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "153(2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-01-09 12:45"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "1530(1-2,4-5,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-12-06 12:45"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15300(0,3)",
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15300(2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-25 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 153001,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-08-07 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15303(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-12-06 12:45"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 153033,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-12-06 12:45"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15306(0,8-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15306(1-5)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 153066,
      "Operator (nazwa)": "DEBACOM Sp. z o.o.",
      "Operator (ID)": 3239,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-08-15 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 153067,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15307(1-5)",
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15307(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 153070,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15308(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 153082,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "1531(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15315(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 153153,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-10 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "1533(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-01-09 12:45"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15333(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-01-09 12:45"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 153333,
      "Operator (nazwa)": "SUDER Sp. z o.o.",
      "Operator (ID)": 10822,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-01-09 12:45"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "156(2-3,5-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "1560(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 15600,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "1561(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15615(0-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 156156,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-05 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15640(0,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15640(1-3)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 156404,
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-20 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15641(0,4,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-09-29 12:45"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15641(1,2,5-7)",
      "Operator (nazwa)": "Hyperion S.A.",
      "Operator (ID)": 329,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 156413,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-17 12:45"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15642(0-2,5-7)",
      "Operator (nazwa)": "Hyperion S.A.",
      "Operator (ID)": 329,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-03-01 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15642(3,4,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-03-01 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15643(0,1,2,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 156433,
      "Operator (nazwa)": "Hyperion S.A.",
      "Operator (ID)": 329,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-19 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 156434,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-19 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15644(0-2,4-6)",
      "Operator (nazwa)": "Hyperion S.A.",
      "Operator (ID)": 329,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-19 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 156443,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-03-01 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 156447,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-07-22 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 156448,
      "Operator (nazwa)": "Galena Sp. z o.o.",
      "Operator (ID)": 66,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-16 12:16"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 156449,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-19 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15645(0,2-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-30 12:45"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 156451,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-30 12:45"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15646(0,6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-03-01 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15646(1,5,8)",
      "Operator (nazwa)": "Hyperion S.A.",
      "Operator (ID)": 329,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-02-22 12:45"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15646(2-4,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 156469,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-02-22 12:45"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15647(2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 156470,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-03-01 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 156471,
      "Operator (nazwa)": "Hyperion S.A.",
      "Operator (ID)": 329,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-03-01 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15648(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15648(1-5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15649(2-4)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15649(5,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15649(8,9)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 156490,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 156491,
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 156497,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "1568(0-5,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15686(0-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 156868,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15687(0,1,3,6-9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-14 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 156872,
      "Operator (nazwa)": "Voice Net Sp. z o.o.",
      "Operator (ID)": 12749,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2024-02-20 12:45"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 156874,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 156875,
      "Operator (nazwa)": "VOXNET Sp. z o.o. Sp. k.",
      "Operator (ID)": 728,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15688(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 156882,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-02-01 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "1580(0,6-9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "1580(3-5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15801(0,7-9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15801(1-2,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-03 12:45"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 158013,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-03 12:45"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 158014,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 158016,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15802(0,3-9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15802(1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15810(0,6,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15810(3,4)",
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15810(8,9)",
      "Operator (nazwa)": "STELLA Z.I.T. Sp. z o.o.",
      "Operator (ID)": 2664,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "Stalowa Wola",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 158101,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-01-16 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 158102,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-10-25 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 158105,
      "Operator (nazwa)": "e-Telko Sp. z o.o.",
      "Operator (ID)": 11564,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15811(0,1-3,6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15811(4,5,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15812(1-3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15812(4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 158120,
      "Operator (nazwa)": "Dialoga Servicios Interactivos S.A.",
      "Operator (ID)": 10930,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15813(4-8)",
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 158130,
      "Operator (nazwa)": "F.P.U.H. \"CZAJEN\" K. Czaja",
      "Operator (ID)": 3330,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 158131,
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 158132,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 158133,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 158139,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-07-05 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15814(0,8)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15814(1-7)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 158149,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15815(0-4,8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15815(5-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 158159,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-02-29 10:01"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15816(0,8-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 158161,
      "Operator (nazwa)": "Tarnobrzeska Sp�dzielnia Mieszkaniowa",
      "Operator (ID)": 1419,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 158162,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 158163,
      "Operator (nazwa)": "FREELY Sp. z o.o.",
      "Operator (ID)": 13143,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 158164,
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 158165,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 158166,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 158167,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-04 12:45"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 15817,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15818(0,7-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15818(2-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 158181,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15819(0,6-9)",
      "Operator (nazwa)": "TeleGo sp. z o.o.",
      "Operator (ID)": 9324,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-09-25 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 158191,
      "Operator (nazwa)": "INSTALNET Szabat, Rydzewski Sp. J.",
      "Operator (ID)": 706,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 158192,
      "Operator (nazwa)": "IPTELL Sp. z o.o.",
      "Operator (ID)": 7201,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 158193,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 158194,
      "Operator (nazwa)": "COVERNET Damian Giecko",
      "Operator (ID)": 1053,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 158195,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-17 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "1582(0,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "1582(2,3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15821(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 158210,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15824(0,1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15824(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 158246,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15825(0,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 158251,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 158252,
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 158253,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 158254,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15826(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 158261,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15830(0,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15830(1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15831(0,1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15831(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 15832,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15833(0,1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15833(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15834(0,3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15834(1,2,4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15835(0-2,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-03-15 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15835(4,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 158353,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-03-15 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15836(0,1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15836(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15837(0,1-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 158377,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15838(0,4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15838(1-3,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15839(0,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15839(1-3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "1584(2,4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15840(0,9)",
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15840(1-8)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15841(0,1-6,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15841(7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15843(0,1-4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 158435,
      "Operator (nazwa)": "MTM-INFO M.Pikor, T.Tacza�ski Sp. j.",
      "Operator (ID)": 4692,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-02-03 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15845(0,1-4,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15845(5-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15846(1-5,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15846(6,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 158460,
      "Operator (nazwa)": "HLG Internet Sp. Z o.o.",
      "Operator (ID)": 12837,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-30 12:45"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15847(0,1-4,6-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 158475,
      "Operator (nazwa)": "ENTER T&T Sp. z o.o.",
      "Operator (ID)": 10688,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 158479,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15848(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 158481,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 15849,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "1585(0,2-4,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15851(1-2,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-23 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15851(3,4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 158510,
      "Operator (nazwa)": "HLG Internet Sp. Z o.o.",
      "Operator (ID)": 12837,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-30 12:45"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15855(0,1-3,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15855(4,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15856(0,1-3,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15856(4-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "1586(2,3)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15860(0,2,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15860(1,3,4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 15861,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15864(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 158640,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-03-29 12:46"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15865(0,1,2,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15865(3,4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15866(0,4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15866(1-3,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15867(0,1,2,4,5,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15867(3,6,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15868(0,1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15868(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15869(0,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15869(1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15870(0,4-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15870(1-3,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15871(0,1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15871(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15872(0,1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15872(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15873(0,1-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 158736,
      "Operator (nazwa)": "Telnyx Ireland Limited",
      "Operator (ID)": 13021,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-01 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15874(0,1,2,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15874(3,4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15875(0,1,4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15875(2,3,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15876(0,1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15876(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15877(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15877(2-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 158771,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15878(0,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15878(1,2)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15879(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 158790,
      "Operator (nazwa)": "DIDWW Ireland Limited",
      "Operator (ID)": 12132,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-19 12:15"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "1588(0,1-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15888(0,2-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 158881,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": 158888,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "1589(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15891(0,1-3,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "15891(4,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "159(0,1-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 15,
      "Zakres (number)": "159(8,9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "TARNOBRZEG",
      "Strefa (symbol)": "SN-TG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16(2,4,5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "161(0,1-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 1619,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "163(2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-01-09 12:45"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "1630(1-5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16300(0,3)",
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16300(2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-11-16 12:50"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 163001,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-11-16 12:50"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16306(0,4-6,9)",
      "Operator (nazwa)": "TeleGo sp. z o.o.",
      "Operator (ID)": 9324,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-09-25 12:15"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16306(1-3)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 163067,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 163068,
      "Operator (nazwa)": "e-Telko Sp. z o.o.",
      "Operator (ID)": 11564,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-04-19 12:15"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16307(3,5)",
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 163070,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 163071,
      "Operator (nazwa)": "Voice Net Sp. z o.o.",
      "Operator (ID)": 12749,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-02-20 12:45"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 163072,
      "Operator (nazwa)": "DIDWW Ireland Limited",
      "Operator (ID)": 12132,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-19 12:15"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 163074,
      "Operator (nazwa)": "EZBIT SPӣKA Z OGRANICZON� ODPOWIEDZIALNO�CI�",
      "Operator (ID)": 5926,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 163076,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-01-02 12:20"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 163077,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 163078,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 163079,
      "Operator (nazwa)": "Telnyx Ireland Limited",
      "Operator (ID)": 13021,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-01 12:15"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16308(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 163082,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16309(0,2-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-30 12:45"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 163091,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-30 12:45"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "1631(0-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16316(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 163163,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-10 12:15"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "1633(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-01-09 12:45"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16333(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-01-09 12:45"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 163333,
      "Operator (nazwa)": "SUDER Sp. z o.o.",
      "Operator (ID)": 10822,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-01-09 12:45"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "166(0,6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "1661(0,2-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 16611,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16616(0,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16616(1-5)",
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 166166,
      "Operator (nazwa)": "Telfon Poland Sp. z o.o.",
      "Operator (ID)": 12517,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "1662(0,5,6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "1662(1-4,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16627(1-3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16627(4-8)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 166270,
      "Operator (nazwa)": "FREELY Sp. z o.o.",
      "Operator (ID)": 13143,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 166279,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-17 12:15"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "1663(1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "1663(4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-18 12:15"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16630(3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-22 12:15"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 166300,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 166301,
      "Operator (nazwa)": "Debesis Sp. z o.o.",
      "Operator (ID)": 10138,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-18 12:15"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 166302,
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-22 12:15"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16633(0,1-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16633(5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 166336,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "1664(3-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16640(0,1-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16640(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 166405,
      "Operator (nazwa)": "Dialoga Servicios Interactivos S.A.",
      "Operator (ID)": 10930,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16641(0,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16641(1-3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16642(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 166420,
      "Operator (nazwa)": "ITH Sp. Z o.o.",
      "Operator (ID)": 12635,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-10 12:46"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 16648,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16649(0,1-3,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16649(4-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "1665(3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16650(0,1-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 166505,
      "Operator (nazwa)": "Zicom Next Sp. z o.o.",
      "Operator (ID)": 7661,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16651(0,1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16651(8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-06-29 12:45"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 166517,
      "Operator (nazwa)": "Multinet 24 Sp. z o.o.",
      "Operator (ID)": 8597,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-06-29 12:45"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16652(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16652(1-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 166525,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "1667(0,1,2,5,6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16673(0,1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16673(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16674(0,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-10-31 12:15"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16674(1,2)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 166743,
      "Operator (nazwa)": "Multimedia Polska Biznes S.A.",
      "Operator (ID)": 11221,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-10-31 12:15"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16677(0,3-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-01-08 12:15"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 166771,
      "Operator (nazwa)": "AMS SYSTEM Sp. z o.o.",
      "Operator (ID)": 7006,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-10-26 12:15"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 166772,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-01 12:15"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 166776,
      "Operator (nazwa)": "TOYA Sp. z o.o.",
      "Operator (ID)": 127,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16678(0,2-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 166781,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16679(0,1-4,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16679(5-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "167(0,2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "1671(0-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16716(0-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 167167,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-05 12:15"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "1673(1,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16730(0,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16730(1,2)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16732(0,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16732(1-8)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16733(4,5)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16733(8,9)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 167330,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 167331,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 167332,
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 167333,
      "Operator (nazwa)": "Hyperion S.A.",
      "Operator (ID)": 329,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 167336,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 167337,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16734(0,7-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16734(1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 167346,
      "Operator (nazwa)": "Galena Sp. z o.o.",
      "Operator (ID)": 66,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-16 12:16"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16735(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16735(1-5)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16736(2-4)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 167360,
      "Operator (nazwa)": "TOYA Sp. z o.o.",
      "Operator (ID)": 127,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 167361,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 167365,
      "Operator (nazwa)": "IPTELL Sp. z o.o.",
      "Operator (ID)": 7201,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 167366,
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 167367,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 167368,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 167369,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "168(2-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "1680(0,6-9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "1680(3-5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16801(0,1,5,6,9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16801(2,3)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16801(7-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 168014,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16802(0,1,5,6,9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16802(2,3,7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16802(4,8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "1681(0-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16816(0-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 168168,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-10 12:15"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "1688(0,1-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "16888(4-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-10-04 12:20"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 168880,
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-10-04 12:20"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 168881,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 168882,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-03-29 12:46"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 168883,
      "Operator (nazwa)": "F.U.H. CLIMAX Rafa� Kraj",
      "Operator (ID)": 1027,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-01-14 12:15"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 168888,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": 168889,
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-24 12:15"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "169(0-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-07-08 12:15"
    },
    {
      "Strefa (AB)": 16,
      "Zakres (number)": "169(8,9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "PRZEMY�L",
      "Strefa (symbol)": "SN-PR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17(4,6)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "171(0,1-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 1719,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "1720(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17200(0,2)",
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17200(3-7)",
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17200(8-9)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-15 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 172001,
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-12 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "1721(0-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17217(0-1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 172172,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-10 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "1722(2,4,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 17220,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17221(0,1-4,6-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 172215,
      "Operator (nazwa)": "Dialoga Servicios Interactivos S.A.",
      "Operator (ID)": 10930,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 172219,
      "Operator (nazwa)": "Sp�dzielnia Telekomunikacyjna OST",
      "Operator (ID)": 36,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "Tyczyn",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17223(0,1,3-5,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17223(6-8)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 172232,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-12-14 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17226(0,1-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 172268,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-08-15 12:45"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17227(0,1-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 172276,
      "Operator (nazwa)": "WDM COMPUTERS Maria Jaros�awska",
      "Operator (ID)": 159,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17228(0,1,3,5,6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 172282,
      "Operator (nazwa)": "TOYA Sp. z o.o.",
      "Operator (ID)": 127,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-04-10 10:38"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 172284,
      "Operator (nazwa)": "WDM Sp. z o.o.",
      "Operator (ID)": 12229,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-09-30 12:45"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 172287,
      "Operator (nazwa)": "Orientelco Sp. z o.o.",
      "Operator (ID)": 12993,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-08-05 12:20"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 172288,
      "Operator (nazwa)": "HLG Internet Sp. Z o.o.",
      "Operator (ID)": 12837,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-30 12:45"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 172289,
      "Operator (nazwa)": "Galena Sp. z o.o.",
      "Operator (ID)": 66,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-16 12:16"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 17229,
      "Operator (nazwa)": "Sp�dzielnia Telekomunikacyjna OST",
      "Operator (ID)": 36,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "Tyczyn",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "1723(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17230(0,4-6)",
      "Operator (nazwa)": "Sp�dzielnia Telekomunikacyjna OST",
      "Operator (ID)": 36,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17230(1-3)",
      "Operator (nazwa)": "Sp�dzielnia Telekomunikacyjna OST",
      "Operator (ID)": 36,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "Tyczyn",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17230(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "1724(4-6,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17240(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 172400,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17241(0,1-3,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 172414,
      "Operator (nazwa)": "Telnyx Ireland Limited",
      "Operator (ID)": 13021,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-01 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17242(0,1,4-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17242(2,3)",
      "Operator (nazwa)": "F.P.U.H. \"CZAJEN\" K. Czaja",
      "Operator (ID)": 3330,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-18 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 17243,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17247(0,1-3,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17247(4,6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 172477,
      "Operator (nazwa)": "INTERDAM Damian Zarzycki (od�aczenie na podstawie wniosku w ESOD z 26.08.2022)",
      "Operator (ID)": 8241,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 172478,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-10-30 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17249(0,1)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17249(2-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 172499,
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-20 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "1725(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17250(0,2,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 172501,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-03-20 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 172503,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 172504,
      "Operator (nazwa)": "Hyperion S.A.",
      "Operator (ID)": 329,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 172505,
      "Operator (nazwa)": "4 CARRIERS GLOBAL Sp. z o.o.",
      "Operator (ID)": 12739,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-12-23 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 172506,
      "Operator (nazwa)": "F.P.U.H. \"CZAJEN\" K. Czaja",
      "Operator (ID)": 3330,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 172507,
      "Operator (nazwa)": "F.U.H. CLIMAX Rafa� Kraj",
      "Operator (ID)": 1027,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-22 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 172508,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:47"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "1726(0,1-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17266(0,1-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 172666,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-26 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "1727(0,1-4,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17275(1,2)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17275(3,4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 172750,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 172755,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17276(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 172769,
      "Operator (nazwa)": "CHEELOO J. Turczyn i Wsp�lnicy Sp. J.",
      "Operator (ID)": 8632,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 17277,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "1728(0,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17281(0,1-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 172817,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17282(0,6-9)",
      "Operator (nazwa)": "TeleGo sp. z o.o.",
      "Operator (ID)": 9324,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-09-25 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 172821,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 172822,
      "Operator (nazwa)": "BT Poland Sp. z o.o.",
      "Operator (ID)": 109,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-12-21 12:20"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 172823,
      "Operator (nazwa)": "Nettelekom GK Sp. z o.o.",
      "Operator (ID)": 9896,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 172824,
      "Operator (nazwa)": "Multinet 24 Sp. z o.o.",
      "Operator (ID)": 8597,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 172825,
      "Operator (nazwa)": "ZETO-RZESZ�W Sp. z o.o.",
      "Operator (ID)": 1025,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17283(0,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17283(1,2)",
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17283(5,6)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-01 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17283(8,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 172833,
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 172834,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 1729,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "173(2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "1730(1-6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17300(2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-08-13 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 173000,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 173001,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-08-07 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 173003,
      "Operator (nazwa)": "NETTEL Sp. z o.o.",
      "Operator (ID)": 9575,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17307(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 173070,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17308(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 173082,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "1731(0-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17317(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 173173,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-09 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "1733(0,1,2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17333(0,2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-01-13 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 173331,
      "Operator (nazwa)": "ATMAN Sp. z o.o.",
      "Operator (ID)": 944,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-01-13 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 173333,
      "Operator (nazwa)": "Strzy�owski.Net Micha� Gom�ka Sp.k.",
      "Operator (ID)": 13114,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-29 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "175(2-4,6-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "1750(1-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17500(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 175000,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17506(0,2-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 175061,
      "Operator (nazwa)": "NEXET Sp. z o.o.",
      "Operator (ID)": 286,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-10-14 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 175065,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "1751(0-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17517(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 175175,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-10-19 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "1755(0,1-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17555(0,1-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 175555,
      "Operator (nazwa)": "Enformatel Sp. z o.o.",
      "Operator (ID)": 12946,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-11-29 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "1758(0,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "1758(1,3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17582(0,2,4-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17582(1,3,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17584(0,1-3,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17584(4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17585(0,1,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17585(2-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17586(0,1,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17586(2-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "177(3,5,6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "1770(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17700(1-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-03-15 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 177000,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 177007,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-03-15 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "1771(2-4,6,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 17710,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17711(0,6,9)",
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17711(1-5)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17711(7,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-23 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17715(1,2,4)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17715(5,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-01-08 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 177150,
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 177153,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-04 12:45"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 177157,
      "Operator (nazwa)": "IPTELL Sp. z o.o.",
      "Operator (ID)": 7201,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 177158,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 177159,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17717(0,1,2,4-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 177173,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-12-01 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "1772(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17722(0,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 177221,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 177222,
      "Operator (nazwa)": "TelNet Ropczyce Sp. z o.o. (d. TELNET Krzysztof Drozd)",
      "Operator (ID)": 10454,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17740(0-1,4-6,8)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17740(2-3)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 177407,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 177409,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17741(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17741(1-7)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17742(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17742(1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17743(0-1,4-7)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "Le�ajsk",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17743(2,3)",
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "Le�ajsk",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17743(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "Le�ajsk",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17744(0-6)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "Kolbuszowa",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17744(7,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "Kolbuszowa",
      "Data modyfikacji": "2022-07-13 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 177449,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "Kolbuszowa",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17745(0-8)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "Ropczyce",
      "Data modyfikacji": "2022-07-13 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 177459,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "Ropczyce",
      "Data modyfikacji": "2022-07-13 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17746(0-5)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "Strzy��w",
      "Data modyfikacji": "2022-07-13 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17746(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "Strzy��w",
      "Data modyfikacji": "2022-07-13 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17747(0-1,5-6,8-9)",
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17747(2-4,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-07-13 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17748(0-4)",
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17748(5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-07-13 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17749(0,9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-01-15 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17749(1,4-7)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17749(2,3,8)",
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "1777(1,2)",
      "Operator (nazwa)": "Sp�dzielnia Telekomunikacyjna WIST",
      "Operator (ID)": 61,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "Rzesz�w",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "1777(5,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17770(1-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17770(8,9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 177700,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 177707,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17773(0-4)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "Mielec",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17773(5-9)",
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "Mielec",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17774(0-4,6-9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "Mielec",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 177745,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "Mielec",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17777(0,9)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17777(7,8)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 177771,
      "Operator (nazwa)": "Zak�ad Teleradiotechniki TELEFON SERWIS Antoni Kois",
      "Operator (ID)": 9193,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 177772,
      "Operator (nazwa)": "MTM-INFO M.Pikor, T.Tacza�ski Sp. j.",
      "Operator (ID)": 4692,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-03 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 177773,
      "Operator (nazwa)": "Voice Net Sp. z o.o.",
      "Operator (ID)": 12749,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-02-20 12:45"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 177774,
      "Operator (nazwa)": "CLUDO Sp z o.o.",
      "Operator (ID)": 10056,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-02-01 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 177775,
      "Operator (nazwa)": "Connected Sp. z o.o.",
      "Operator (ID)": 8033,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-04-17 12:45"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 177776,
      "Operator (nazwa)": "Enformatel Sp. z o.o.",
      "Operator (ID)": 12946,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-02-20 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17778(0,1,2)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17778(4-6,8,9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-03-01 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 177783,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 177787,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-03-01 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17779(0,8-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17779(1-4)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17779(5,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 177796,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17780(2,3,8,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 177800,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "Mielec",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 177801,
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 177804,
      "Operator (nazwa)": "ITH Sp. Z o.o.",
      "Operator (ID)": 12635,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-03-14 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 177805,
      "Operator (nazwa)": "WDM Sp. z o.o.",
      "Operator (ID)": 12229,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-12-31 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 177806,
      "Operator (nazwa)": "Blue Prospect Sp. z o.o.",
      "Operator (ID)": 13345,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2023-08-05 12:46"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 177807,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17781(1-3)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17781(4-8)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 177810,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-24 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 177819,
      "Operator (nazwa)": "Voice Net Sp. z o.o.",
      "Operator (ID)": 12749,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2024-02-20 12:45"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17782(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 177821,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17783(0,7,8)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17783(2,3)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "Rzesz�w",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 177831,
      "Operator (nazwa)": "Intelligent Technologies S.A.",
      "Operator (ID)": 748,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 177834,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 177835,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 177836,
      "Operator (nazwa)": "Sp�dzielnia Telekomunikacyjna OST",
      "Operator (ID)": 36,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-02-01 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 177839,
      "Operator (nazwa)": "MT Sp. z o.o.",
      "Operator (ID)": 3833,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17784(1-5)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17784(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 177840,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17785(0,3-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17785(1,2)",
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-12-01 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17786(3,4)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17786(5-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 177860,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 177861,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "Rzesz�w",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 177862,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-10-21 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 177869,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17787(0,8)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17787(1,3-5)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "Radomy�l Wielki i Wadowice G�rne z SN Tarn�w",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 177872,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "Radomy�l Wielki i Wadowice G�rne z SN Tarn�w",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 177876,
      "Operator (nazwa)": "VOXNET Sp. z o.o. Sp. k.",
      "Operator (ID)": 728,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 177877,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 177879,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17788(0,1,8)",
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "Mielec",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17788(2-7,9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "Mielec",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17789(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17789(1,2)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17789(3-5)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 177896,
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-14 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 177897,
      "Operator (nazwa)": "Enformatel Sp. z o.o.",
      "Operator (ID)": 12946,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2021-02-20 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "178(2-4,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "1780(0,6-9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "1780(2-5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17801(0,4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17801(2,6)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17801(3,7-9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 178011,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 178015,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "1781(0-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17817(0-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 178178,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-05 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "1785(0,2,4,6-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17851(0,2-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 178511,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-01 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17853(0,2-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 178531,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-06-08 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17855(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17855(1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17859(0,1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 178598,
      "Operator (nazwa)": "Focus Telecom Polska Sp. z o.o.",
      "Operator (ID)": 8974,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-01 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 178599,
      "Operator (nazwa)": "DIDWW Ireland Limited",
      "Operator (ID)": 12132,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-19 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "1786(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17860(0,1-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 178608,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-13 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17861(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17861(1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 17862,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17863(0,1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17863(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17864(0,1-3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17864(4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17865(1,3-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 178650,
      "Operator (nazwa)": "FREELY Sp. z o.o.",
      "Operator (ID)": 13143,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 178652,
      "Operator (nazwa)": "Voice Net Sp. z o.o.",
      "Operator (ID)": 12749,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-02-20 12:45"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17866(0,1,4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17866(2,3,5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17867(0,1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17867(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "1787(4,6,7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17870(2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 178700,
      "Operator (nazwa)": "Skyware Sp. z o.o.",
      "Operator (ID)": 3530,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 178701,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 17871,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17872(0,1-3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17872(4-6,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 178727,
      "Operator (nazwa)": "MARIAN WITEK Marian Witek",
      "Operator (ID)": 7150,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17873(0,1-3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17873(4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17875(0,1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17875(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17878(0,1-6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17878(7,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "1788(0,2-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "17881(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 178811,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": 17888,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "179(0,1-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 17,
      "Zakres (number)": "179(8,9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "RZESZ�W",
      "Strefa (symbol)": "SN-RZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18(6,7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "181(0,1-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 1819,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "182(3-5,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "1820(3-5,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18200(0,1)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18200(2-4)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18200(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 182005,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18201(1-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 182010,
      "Operator (nazwa)": "ATMAN Sp. z o.o.",
      "Operator (ID)": 944,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-01-13 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 182018,
      "Operator (nazwa)": "Dialoga Servicios Interactivos S.A.",
      "Operator (ID)": 10930,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18202(0,1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18202(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18206(0,5,7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18206(1-4,6,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 18207,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18208(0,1,2,4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18208(3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "1821(0,2-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18211(0,4-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18211(1-3)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 182118,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18218(0-1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 182182,
      "Operator (nazwa)": "MEDIDESK Sp. z o.o.",
      "Operator (ID)": 12669,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-05-06 12:45"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "1822(1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18220(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 182200,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 18222,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "1826(2,4,5,7,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 18260,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18261(0,1-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18261(5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18263(0,1-5,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 182636,
      "Operator (nazwa)": "FHU KOMPEX Gabriel Sulka",
      "Operator (ID)": 4528,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 182637,
      "Operator (nazwa)": "KTM TELEKOM Krzysztof Trzop",
      "Operator (ID)": 8028,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 182638,
      "Operator (nazwa)": "KM-NET Marek Sob�l",
      "Operator (ID)": 8279,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18266(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 182660,
      "Operator (nazwa)": "ENTER T&T Sp. z o.o.",
      "Operator (ID)": 10688,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18269(0,4-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18269(1-3,8,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "1827(0,1-4,6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18275(0,1,2,4-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 182753,
      "Operator (nazwa)": "Intelligent Technologies S.A.",
      "Operator (ID)": 748,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-09-16 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 182759,
      "Operator (nazwa)": "Voice Net Sp. z o.o.",
      "Operator (ID)": 12749,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2024-02-20 12:45"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18277(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18277(1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "1828(0,1-4,6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18285(0,5,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18285(1-4,6-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18287(0,1,2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 182873,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "183(2,4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "1830(1-6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18300(0,3)",
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18300(2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-22 12:45"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 183001,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-22 12:45"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18307(1-5)",
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18307(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 183070,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18308(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 183082,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "1831(0-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18318(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 183183,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-10 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "1833(1-4,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "1833(5,6,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18330(0,1,4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18330(2,3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "1835(2,3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "1835(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18350(3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-10-31 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 183500,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 183501,
      "Operator (nazwa)": "Multimedia Polska Biznes S.A.",
      "Operator (ID)": 11221,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-01-16 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 183502,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2021-03-12 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18351(0,1-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 183517,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18354(0,1,3-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18354(2,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18355(0-3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18355(5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 183554,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "184(3,5-6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-06-10 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "1840(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18400(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 184000,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "1841(0-3,6-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 18414,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18415(0,1,2)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18415(3-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18418(0-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 184184,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-10 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "1842(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-06-10 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18420(0-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-06-10 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 184206,
      "Operator (nazwa)": "AP-MEDIA Sp. z o.o. Sp. k.",
      "Operator (ID)": 7476,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-06-10 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "1844(0,1-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18448(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 184489,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 18470,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 18471,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18472(0,1,2,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18472(3-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18473(0,3-4,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-01-20 12:45"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18473(1,2,6-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 184735,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-01-20 12:45"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18474(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 184741,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18475(0,2-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 184751,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 184755,
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-20 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18476(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 184761,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18477(0,7,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18477(1-6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18478(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 184781,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18479(0,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18479(1,2)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "1848(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18480(0,1-5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-06-10 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18480(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "185(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "1850(0,1-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18506(0,1-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 185065,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "1851(0-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18518(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 185185,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-05 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "1852(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18521(1-2,4)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-05-15 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 185210,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 185213,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 185215,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-05-15 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 185216,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-05-15 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 185217,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 185218,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-17 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 185219,
      "Operator (nazwa)": "P.H.U. ALLKOMP Grzegorz K�ska",
      "Operator (ID)": 1134,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "1853(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18530(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18530(1-5)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18531(0-1,3-4,7-8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18531(2,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-09-29 12:45"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 185315,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-17 12:45"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 185319,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-10-07 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18532(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-24 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18532(1-4)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 185325,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-24 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 18533,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 185340,
      "Operator (nazwa)": "IPTELL Sp. z o.o.",
      "Operator (ID)": 7201,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 185341,
      "Operator (nazwa)": "TOYA Sp. z o.o.",
      "Operator (ID)": 127,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-10-18 12:45"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 185342,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 185343,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-01 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 185344,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 185345,
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 185346,
      "Operator (nazwa)": "AP-MEDIA Sp. z o.o. Sp. k.",
      "Operator (ID)": 7476,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 185347,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-13 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 185348,
      "Operator (nazwa)": "Platan Sp. z o.o. Sp. k.",
      "Operator (ID)": 12733,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-02-14 12:45"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 185349,
      "Operator (nazwa)": "MAXSERWIS Sp. z o.o.",
      "Operator (ID)": 4131,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-05-21 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18535(0,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18535(1-4)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "1854(7,8)",
      "Operator (nazwa)": "Telbeskid Sp. z o.o.",
      "Operator (ID)": 57,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "Nowy S�cz",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18540(0,8-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18540(1,7)",
      "Operator (nazwa)": "Regionalna Telewizja Kablowa Sp. J. L. Iwa�ski i Wsp�lnicy",
      "Operator (ID)": 2129,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "Nowy S�cz",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18540(4,5)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 185402,
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 185403,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-04 12:45"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 185406,
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18541(0-1,3-4)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 185412,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 185415,
      "Operator (nazwa)": "Firma Handlowo-Us�ugowa INTERSIE� Urszula Ko�odziej",
      "Operator (ID)": 2918,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-01-17 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 185416,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 185417,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 185418,
      "Operator (nazwa)": "FREELY Sp. z o.o.",
      "Operator (ID)": 13143,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 185419,
      "Operator (nazwa)": "DIDWW Ireland Limited",
      "Operator (ID)": 12132,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-10-19 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18542(3,5,9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18542(4,6-8)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 185420,
      "Operator (nazwa)": "ITH Sp. Z o.o.",
      "Operator (ID)": 12635,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-10 12:45"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 185421,
      "Operator (nazwa)": "VOXNET Sp. z o.o. Sp. k.",
      "Operator (ID)": 728,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 185422,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18543(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18543(1-5)",
      "Operator (nazwa)": "TeleGo sp. z o.o.",
      "Operator (ID)": 9324,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18544(3-5)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18544(8,9)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 185440,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 185441,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 185442,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-12-01 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 185446,
      "Operator (nazwa)": "Regionalna Telewizja Kablowa Sp. J. L. Iwa�ski i Wsp�lnicy",
      "Operator (ID)": 2129,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "Nowy S�cz",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 185447,
      "Operator (nazwa)": "INET CENTRUM Sp. z o.o.",
      "Operator (ID)": 5069,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18545(0,4-7,9)",
      "Operator (nazwa)": "Telbeskid Sp. z o.o.",
      "Operator (ID)": 57,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "Gorlice",
      "Data modyfikacji": "2020-03-14 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18545(1-2,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "Gorlice",
      "Data modyfikacji": "2021-09-01 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 185453,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "Gorlice",
      "Data modyfikacji": "2021-09-01 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18546(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18546(1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18549(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-12-06 12:45"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18549(1-4)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 185495,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-12-06 12:45"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "1855(1-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18550(0,1-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 185505,
      "Operator (nazwa)": "Zicom Next Sp. z o.o.",
      "Operator (ID)": 7661,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18555(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 185551,
      "Operator (nazwa)": "e-Telko Sp. z o.o.",
      "Operator (ID)": 11564,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-04-19 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 185552,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 185553,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 185554,
      "Operator (nazwa)": "Telnyx Ireland Limited",
      "Operator (ID)": 13021,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-01 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 185555,
      "Operator (nazwa)": "INFANET Sylwester Kus",
      "Operator (ID)": 5441,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 185556,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 185557,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-02-29 12:45"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "188(2-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-23 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "1880(0,6-9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "1880(3-5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18801(1-2)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18801(3-6)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18801(7,9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18801(8,0)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18802(2-9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 188020,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 188021,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-01-06 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "1881(0-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-23 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18818(0,2-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-03-07 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 188181,
      "Operator (nazwa)": "HyperNET Sp. z o.o.",
      "Operator (ID)": 8619,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-03-07 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 188188,
      "Operator (nazwa)": "SUDER Sp. z o.o.",
      "Operator (ID)": 10822,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-23 12:15"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "1888(0,1-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:48"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18888(2-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 188880,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 188881,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 188888,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "18889(0,1-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": 188896,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "189(0,1-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 18,
      "Zakres (number)": "189(8,9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "NOWY S�CZ",
      "Strefa (symbol)": "SN-NS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2210(7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-04-28 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22100(1-6)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22100(8,9)",
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221000,
      "Operator (nazwa)": "PSTD Sp. z o.o.",
      "Operator (ID)": 8828,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221007,
      "Operator (nazwa)": "DAG S.C.",
      "Operator (ID)": 1958,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22101(1-6)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22101(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-09-30 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221010,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221017,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22102(1,3-5)",
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22102(6,7)",
      "Operator (nazwa)": "SUPERMEDIA Sp. z o.o.",
      "Operator (ID)": 1369,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22102(8,9)",
      "Operator (nazwa)": "Televoice Polska Sp. z o.o.",
      "Operator (ID)": 8808,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2024-04-05 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221020,
      "Operator (nazwa)": "INFODATA S.A.",
      "Operator (ID)": 12302,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-11-22 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221022,
      "Operator (nazwa)": "VIRTUAL OPERATOR Sp. z o.o. (d. Virtuaoperator Sp. z o.o.)",
      "Operator (ID)": 9648,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22103(1-9)",
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-07-31 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221030,
      "Operator (nazwa)": "NETTEL Sp. z o.o.",
      "Operator (ID)": 9575,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 22104,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-02-02 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22105(0,1-4,6-9)",
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221055,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 22106,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-04-28 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22108(0,3,4,6,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22108(1,5,9)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22108(2,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2211(0,1)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2211(4,5,9)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22112(0,1)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221122,
      "Operator (nazwa)": "CLUDO Sp z o.o.",
      "Operator (ID)": 10056,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-02-01 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221123,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221124,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-13 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221125,
      "Operator (nazwa)": "E-CALL POLSKA Sp. z o.o.",
      "Operator (ID)": 9331,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221126,
      "Operator (nazwa)": "INTERWAN Sp. z o.o.",
      "Operator (ID)": 5522,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "gminy powiatu mi�skiego przynale�ne do SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221127,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-01-31 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221128,
      "Operator (nazwa)": "S-NET Sp. z o.o.",
      "Operator (ID)": 8697,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221129,
      "Operator (nazwa)": "AMS SYSTEM Sp. z o.o.",
      "Operator (ID)": 7006,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221130,
      "Operator (nazwa)": "Voice Net Sp. z o.o.",
      "Operator (ID)": 12749,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-02-20 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221131,
      "Operator (nazwa)": "AMS SYSTEM Sp. z o.o.",
      "Operator (ID)": 7006,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-06-28 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221132,
      "Operator (nazwa)": "UAB B2B Network (d. UAB NordConnect)",
      "Operator (ID)": 11976,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-03-20 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221133,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221134,
      "Operator (nazwa)": "Focus Telecom Polska Sp. z o.o.",
      "Operator (ID)": 8974,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-01 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221135,
      "Operator (nazwa)": "Krapkowickie Sieci Internetowe Sp. z o.o.",
      "Operator (ID)": 11425,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-12-11 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221136,
      "Operator (nazwa)": "CLUDO Sp z o.o.",
      "Operator (ID)": 10056,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-02-01 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221137,
      "Operator (nazwa)": "Media Telekom Sp. z o.o.",
      "Operator (ID)": 10860,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221138,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-08-13 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221139,
      "Operator (nazwa)": "Focus Telecom Polska Sp. z o.o.",
      "Operator (ID)": 8974,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22116(0,2,3,5-9)",
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221161,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-19 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221164,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-07-22 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 22117,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22118(1-5)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221180,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-08-13 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221186,
      "Operator (nazwa)": "Omico Active Sp. z o.o.",
      "Operator (ID)": 11697,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-12-01 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221187,
      "Operator (nazwa)": "CLUDO Sp z o.o.",
      "Operator (ID)": 10056,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-02-01 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221188,
      "Operator (nazwa)": "CLUDO Sp z o.o.",
      "Operator (ID)": 10056,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-02-01 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221189,
      "Operator (nazwa)": "ST LINE Sp. z o.o. Sp. k.",
      "Operator (ID)": 12096,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-01 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2212(1,6,7)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22120(3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-16 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221200,
      "Operator (nazwa)": "SUDER Sp. z o.o.",
      "Operator (ID)": 10822,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221201,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221202,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22122(2,4)",
      "Operator (nazwa)": "Omnitec Sp. z o.o.",
      "Operator (ID)": 7139,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221220,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221221,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221223,
      "Operator (nazwa)": "CLUDO Sp z o.o.",
      "Operator (ID)": 10056,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-02-01 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221225,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221226,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-01 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221227,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-03 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221228,
      "Operator (nazwa)": "ST LINE Sp. z o.o. Sp. k.",
      "Operator (ID)": 12096,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-01 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221229,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-04-25 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22123(0,7-9)",
      "Operator (nazwa)": "Focus Telecom Polska Sp. z o.o.",
      "Operator (ID)": 8974,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22123(5,6)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221231,
      "Operator (nazwa)": "Twilio Ireland Limited",
      "Operator (ID)": 12633,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-07-03 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221232,
      "Operator (nazwa)": "Galena Sp. z o.o.",
      "Operator (ID)": 66,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-16 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221233,
      "Operator (nazwa)": "ENTER T&T Sp. z o.o.",
      "Operator (ID)": 10688,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221234,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22124(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-02-13 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22124(2,3)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221241,
      "Operator (nazwa)": "Galena Sp. z o.o.",
      "Operator (ID)": 66,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-16 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221244,
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-12 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221245,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-09-24 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22125(0,8-9)",
      "Operator (nazwa)": "Smart Idea International Sp. z o.o.",
      "Operator (ID)": 10832,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-08-08 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22125(2-4)",
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221251,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221255,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221256,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221257,
      "Operator (nazwa)": "M3.NET Sp. z o.o.",
      "Operator (ID)": 10099,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-28 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 22128,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 22129,
      "Operator (nazwa)": "Focus Telecom Polska Sp. z o.o.",
      "Operator (ID)": 8974,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-12-08 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 2213,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2214(0,6-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2214(1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2215(4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-11-24 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22150(4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-22 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221500,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221501,
      "Operator (nazwa)": "MEDIDESK Sp. z o.o.",
      "Operator (ID)": 12669,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-09-05 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221502,
      "Operator (nazwa)": "MEDIDESK Sp. z o.o.",
      "Operator (ID)": 12669,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-08-31 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221503,
      "Operator (nazwa)": "MEDIDESK Sp. z o.o.",
      "Operator (ID)": 12669,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-22 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22151(0,6-9)",
      "Operator (nazwa)": "FREELY Sp. z o.o.",
      "Operator (ID)": 13143,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22151(1-4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221515,
      "Operator (nazwa)": "INFODATA S.A.",
      "Operator (ID)": 12302,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-11-22 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 22152,
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-04-07 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 22153,
      "Operator (nazwa)": "DIDWW Ireland Limited",
      "Operator (ID)": 12132,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-11-24 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22155(0,2-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-14 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221551,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-14 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221555,
      "Operator (nazwa)": "SUDER Sp. z o.o.",
      "Operator (ID)": 10822,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 2216,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2217(0,1,4-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22172(0,1,2)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-07 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22172(3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22173(0,6-9)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22173(1-2)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-08-05 12:20"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221733,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-31 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221734,
      "Operator (nazwa)": "Orientelco Sp. z o.o.",
      "Operator (ID)": 12993,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-08-05 12:20"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221735,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-18 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22177(0,8,9)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-03 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22177(2-4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2023-08-02 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22177(5,6)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-10-30 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221771,
      "Operator (nazwa)": "PIKA POLSKA Sp. z o.o.",
      "Operator (ID)": 11851,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2023-08-02 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221777,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22180(3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221800,
      "Operator (nazwa)": "24IT Media Sp. z o.o.",
      "Operator (ID)": 13184,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-08-04 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221801,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-05-31 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221802,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22181(0,3-6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-26 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22181(2,7)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221811,
      "Operator (nazwa)": "Telnyx Ireland Limited",
      "Operator (ID)": 13021,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-01 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221818,
      "Operator (nazwa)": "Galena Sp. z o.o.",
      "Operator (ID)": 66,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-02-13 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 22182,
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22183(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221832,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22184(0,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22184(1,2)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22185(0,1,2,4,6,8-9)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221853,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-09-03 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221855,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221857,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-26 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22186(0,2-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22186(1,8)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22187(0,2-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22187(1,7)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22188(6,7)",
      "Operator (nazwa)": "SMSWIZARD POLSKA Sp. z o.o.",
      "Operator (ID)": 12975,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-01-25 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221880,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-02-01 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221881,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221882,
      "Operator (nazwa)": "FreePhone Sp. z o.o.",
      "Operator (ID)": 10788,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-10-04 12:20"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221883,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221884,
      "Operator (nazwa)": "Nettelekom GK Sp. z o.o.",
      "Operator (ID)": 9896,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221885,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221888,
      "Operator (nazwa)": "PSTD Sp. z o.o.",
      "Operator (ID)": 8828,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 221889,
      "Operator (nazwa)": "SIA NetBalt",
      "Operator (ID)": 11234,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 22189,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 2219,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2220(0,2,4,6,8,9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22201(0,1,3-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222012,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-24 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22203(0,1-3,6-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22203(4,5)",
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-24 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22205(1-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222050,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-24 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22207(0,1,3-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222072,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-24 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2221(0,7-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 22211,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22212(0,1-7,9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222128,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-24 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22213(0,1-7,9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222138,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-24 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22214(0-1,4-5)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22214(2,3,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-10-29 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222146,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 22215,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22216(1-4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-12-20 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22216(5-9)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222160,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-12-20 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 22220,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22221(0-2,7)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22221(3-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22221(8,9)",
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22222(0,1-4)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22222(5-9)",
      "Operator (nazwa)": "PSTD Sp. z o.o.",
      "Operator (ID)": 8828,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 22223,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22224(0-7)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22224(8,9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-08-07 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22225(0,2-3,5,9)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22225(1,4,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-09-05 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222256,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222258,
      "Operator (nazwa)": "Spikon Sp. z o.o.",
      "Operator (ID)": 10784,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-09-05 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 22226,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22227(0,2-5,7)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22227(1,6,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22228(0,3-5)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-03-31 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22228(1,2)",
      "Operator (nazwa)": "VOXNET Sp. z o.o. Sp. k.",
      "Operator (ID)": 728,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-21 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22228(6-9)",
      "Operator (nazwa)": "Focus Telecom Polska Sp. z o.o.",
      "Operator (ID)": 8974,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-03-31 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22229(1,4-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22229(2-3,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-03-31 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222290,
      "Operator (nazwa)": "Focus Telecom Polska Sp. z o.o.",
      "Operator (ID)": 8974,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-03-31 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2223(1-9)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22230(1,5-8)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222300,
      "Operator (nazwa)": "IPTELEKOM Micha� Ludwiczak",
      "Operator (ID)": 10051,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222302,
      "Operator (nazwa)": "THULIUM Sp. z o.o.",
      "Operator (ID)": 13433,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-16 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222303,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222304,
      "Operator (nazwa)": "IP Telecom Bulgaria LTD",
      "Operator (ID)": 11805,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-06-22 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222309,
      "Operator (nazwa)": "Focus Telecom Polska Sp. z o.o.",
      "Operator (ID)": 8974,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2224(3,5)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 22240,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22241(1-8)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222410,
      "Operator (nazwa)": "Multinet 24 Sp. z o.o.",
      "Operator (ID)": 8597,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-08-08 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222419,
      "Operator (nazwa)": "Marat Sp. z o.o.",
      "Operator (ID)": 8620,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-08-29 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 22242,
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22244(0,1,3-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222442,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-24 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22246(0-4,6,8-9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22246(5,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22247(3-6,9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222470,
      "Operator (nazwa)": "World Discount Telecommunication Polska Sp. z o.o.",
      "Operator (ID)": 68,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222471,
      "Operator (nazwa)": "Telekomunikacja 7Line Sp. z o.o.",
      "Operator (ID)": 8533,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222472,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222477,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-08-07 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222478,
      "Operator (nazwa)": "NETCENTRICA Sp. z o.o.",
      "Operator (ID)": 7087,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 22248,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22249(0-3,5-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-10-29 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222494,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222498,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-03-02 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222499,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2225(1-4,8)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22250(1,2)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22250(3-7)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22250(8,9)",
      "Operator (nazwa)": "Focus Telecom Polska Sp. z o.o.",
      "Operator (ID)": 8974,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222500,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22255(0,2,5,6,8)",
      "Operator (nazwa)": "Intelligent Technologies S.A.",
      "Operator (ID)": 748,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22255(7,9)",
      "Operator (nazwa)": "SUPERMEDIA Sp. z o.o.",
      "Operator (ID)": 1369,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222551,
      "Operator (nazwa)": "VIRTUAL TELECOM Sp. z o.o.",
      "Operator (ID)": 8894,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-01-23 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222553,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222554,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-13 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22256(0,1,3,4,7)",
      "Operator (nazwa)": "Intelligent Technologies S.A.",
      "Operator (ID)": 748,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22256(6,8,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222562,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222565,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22257(0,7-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22257(1,2,4)",
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22257(3,6)",
      "Operator (nazwa)": "Intelligent Technologies S.A.",
      "Operator (ID)": 748,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222575,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "m. st. Warszawa",
      "Data modyfikacji": "2021-01-21 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22259(0-8)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222599,
      "Operator (nazwa)": "PaduchNET Micha� Paduch",
      "Operator (ID)": 12689,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-02 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 22260,
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22261(1-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222610,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22262(0,8-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22262(1-5)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22262(6,7)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22263(0,9)",
      "Operator (nazwa)": "didXL B.V.",
      "Operator (ID)": 13079,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2021-10-22 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22263(1-5)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22263(6,7)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-24 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222638,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 22264,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22265(1-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222650,
      "Operator (nazwa)": "Hexa Telecom Sp. z o.o.",
      "Operator (ID)": 8945,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22266(0,3-5,7,9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222661,
      "Operator (nazwa)": "Telfon Poland Sp. z o.o.",
      "Operator (ID)": 12517,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-03-07 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222662,
      "Operator (nazwa)": "IP Telecom Bulgaria LTD",
      "Operator (ID)": 11805,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-12-07 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222666,
      "Operator (nazwa)": "didXL B.V.",
      "Operator (ID)": 13079,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-10-22 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222668,
      "Operator (nazwa)": "VOXNET Sp. z o.o. Sp. k.",
      "Operator (ID)": 728,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22267(1-3,7-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22267(4-6)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222670,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-03-26 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22268(0-2)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-05-13 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22268(3-7)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-05-13 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22268(8,9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22269(0,9)",
      "Operator (nazwa)": "didXL B.V.",
      "Operator (ID)": 13079,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-10-22 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22269(3,5-8)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222691,
      "Operator (nazwa)": "Z4O  Sp. z o.o.",
      "Operator (ID)": 13000,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-03-31 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222692,
      "Operator (nazwa)": "Progress Plus Sp. z o.o.",
      "Operator (ID)": 10755,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222694,
      "Operator (nazwa)": "Blue Prospect Sp. z o.o.",
      "Operator (ID)": 13345,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-08-05 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2227(0,2-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22271(0-3,7-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22271(4-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 2228,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 22290,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22291(0-1,3-8)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222912,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222919,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22292(0,8-9)",
      "Operator (nazwa)": "AlterTEL Sp. z o.o.",
      "Operator (ID)": 8614,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22292(4,5,7)",
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222921,
      "Operator (nazwa)": "LoVo Sp. z o.o.",
      "Operator (ID)": 7184,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222922,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-08 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222923,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222926,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 22293,
      "Operator (nazwa)": "TeleGo sp. z o.o.",
      "Operator (ID)": 9324,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22294(0,6-9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-12-15 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22294(1-5)",
      "Operator (nazwa)": "LoVo Sp. z o.o.",
      "Operator (ID)": 7184,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22295(0,6-9)",
      "Operator (nazwa)": "ATMAN Sp. z o.o.",
      "Operator (ID)": 944,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22295(1,2)",
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22295(3,4)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222955,
      "Operator (nazwa)": "Televoice Polska Sp. z o.o.",
      "Operator (ID)": 8808,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-17 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22296(0-3,5-9)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222964,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22297(0-7)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22297(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-10-29 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22298(0-2,5-9)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22298(3,4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-10-29 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22299(0,1-7)",
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222998,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 222999,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2230(1-5)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22300(0,5-9)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22300(2,4)",
      "Operator (nazwa)": "JMDI J. Maleszko",
      "Operator (ID)": 3487,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "m. st. Warszawa",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223001,
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223003,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 22306,
      "Operator (nazwa)": "BT Poland Sp. z o.o.",
      "Operator (ID)": 109,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 22307,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22308(0,1,5-9)",
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22308(2-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 22309,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2231(0,3-5,8-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22311(0-3,5-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223114,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22312(0-7,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223128,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22316(1-3,5-6,8-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22316(4,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-25 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223160,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-25 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22317(0,1,6-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22317(2-5,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2232(1-3,5,7-8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22320(0-5,8-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22320(6,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22324(0-6,8-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223247,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22326(0-7,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223268,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22329(1-4,6-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223290,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-26 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223295,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-26 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2233(0-2,5-7)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-11 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22333(0-4,6-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223335,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22334(0,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22334(1-6,8-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22338(1-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-11 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223380,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-11 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22339(0-1,3-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-11 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223392,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-11 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2234(0-1)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22342(0,3-4,7,9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22342(1,2,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22342(5,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22343(0-4,6,8-9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22343(5,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22344(0,4-9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22344(1-3)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22345(0-7,9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223458,
      "Operator (nazwa)": "INTEGRAL Katarzyna Fitas-Antczak",
      "Operator (ID)": 10429,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-05-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22346(0,3-9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-13 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22346(1,2)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-04-24 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22347(0,2)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-13 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22347(1,3-7,9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223478,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22348(0,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-13 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22348(1-6,8)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223489,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22349(0,1,4-7)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-13 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223492,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223493,
      "Operator (nazwa)": "ELART Sp. z o.o.",
      "Operator (ID)": 905,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223498,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223499,
      "Operator (nazwa)": "VOXNET Sp. z o.o. Sp. k.",
      "Operator (ID)": 728,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2235(1,2,5,6,8,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22350(0,6,7)",
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22350(2,3)",
      "Operator (nazwa)": "ELART Sp. z o.o.",
      "Operator (ID)": 905,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "m. st. Warszawa",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223501,
      "Operator (nazwa)": "Telnap Telecom Sp. z o.o.",
      "Operator (ID)": 11900,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-03-07 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223504,
      "Operator (nazwa)": "FORWEB S.C. Monika Bodetko Tomasz Paw�owski",
      "Operator (ID)": 5731,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223505,
      "Operator (nazwa)": "Metro Internet Sp. z o.o.",
      "Operator (ID)": 7972,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223508,
      "Operator (nazwa)": "ENTER T&T Sp. z o.o.",
      "Operator (ID)": 10688,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223509,
      "Operator (nazwa)": "Televoice Polska Sp. z o.o.",
      "Operator (ID)": 8808,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 22353,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22354(0,1-5,8-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223546,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223547,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22357(0-3,5-9)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223574,
      "Operator (nazwa)": "POXBOX.PL Sp. z o.o.",
      "Operator (ID)": 12813,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-01-31 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2236(1-4,6-9)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 22360,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22365(1-9)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223650,
      "Operator (nazwa)": "SZCZECINSKI.COM Pawe� Szczeci�ski",
      "Operator (ID)": 2274,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2237(1,4)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22370(0,1,3-9)",
      "Operator (nazwa)": "Intelligent Technologies S.A.",
      "Operator (ID)": 748,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223702,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "Nowy Dw�r Maz.",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22372(0,2,5-9)",
      "Operator (nazwa)": "Intelligent Technologies S.A.",
      "Operator (ID)": 748,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22372(1,3,4)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22373(0,1,2,4,5,9)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22373(3,6-8)",
      "Operator (nazwa)": "Intelligent Technologies S.A.",
      "Operator (ID)": 748,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 22375,
      "Operator (nazwa)": "Intelligent Technologies S.A.",
      "Operator (ID)": 748,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22376(0,1,3,5-9)",
      "Operator (nazwa)": "Intelligent Technologies S.A.",
      "Operator (ID)": 748,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223762,
      "Operator (nazwa)": "IPTELL Sp. z o.o.",
      "Operator (ID)": 7201,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223764,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-03-20 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22377(0,4,5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-10-21 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22377(1-3,6-9)",
      "Operator (nazwa)": "Intelligent Technologies S.A.",
      "Operator (ID)": 748,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22378(1,2)",
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22378(3,4)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22378(6-9)",
      "Operator (nazwa)": "Intelligent Technologies S.A.",
      "Operator (ID)": 748,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223780,
      "Operator (nazwa)": "PLJ TELECOM Sp. z o.o.",
      "Operator (ID)": 4529,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223785,
      "Operator (nazwa)": "TOYA Sp. z o.o.",
      "Operator (ID)": 127,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-02-13 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22379(0,1-4,6)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22379(5,8,9)",
      "Operator (nazwa)": "Intelligent Technologies S.A.",
      "Operator (ID)": 748,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223797,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2238(3-7)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 22380,
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22381(0-1,3-4,6-9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223812,
      "Operator (nazwa)": "JMDI J. Maleszko",
      "Operator (ID)": 3487,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "m. st. Warszawa",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223815,
      "Operator (nazwa)": "PIKA POLSKA Sp. z o.o.",
      "Operator (ID)": 11851,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-09-24 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22382(1,5,7)",
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22382(2-3,6,8)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223820,
      "Operator (nazwa)": "JNS Sp. z o.o.",
      "Operator (ID)": 7410,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-03-02 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223824,
      "Operator (nazwa)": "Colt Technology Services GmbH",
      "Operator (ID)": 12380,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-12-02 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223829,
      "Operator (nazwa)": "Telkonekt Sp. z o.o.",
      "Operator (ID)": 9086,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-03-08 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22388(1-5)",
      "Operator (nazwa)": "Intelligent Technologies S.A.",
      "Operator (ID)": 748,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "m. st. Warszawa",
      "Data modyfikacji": "2020-02-29 10:01"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223880,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "m. st. Warszawa",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223886,
      "Operator (nazwa)": "GEO-COM Andrzej Kossakowski",
      "Operator (ID)": 10504,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223887,
      "Operator (nazwa)": "I-TEL Sp. z o.o.",
      "Operator (ID)": 6768,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223888,
      "Operator (nazwa)": "Intelligent Technologies S.A.",
      "Operator (ID)": 748,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223889,
      "Operator (nazwa)": "DIGITAL UTILITIES Sp. z o.o.",
      "Operator (ID)": 9717,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-07-23 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 22389,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "m. st. Warszawa",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22390(0,1,7-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223902,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "Nowy Dw�r Maz.",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223903,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223904,
      "Operator (nazwa)": "ATMAN Sp. z o.o.",
      "Operator (ID)": 944,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-10-21 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223905,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223906,
      "Operator (nazwa)": "4 CARRIERS GLOBAL Sp. z o.o.",
      "Operator (ID)": 12739,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-12-23 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22391(0,1-4,6-9)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223915,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22392(0,6-9)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22392(1-5)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22393(0,2,4,5,7-9)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22393(3,6)",
      "Operator (nazwa)": "Intelligent Technologies S.A.",
      "Operator (ID)": 748,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223931,
      "Operator (nazwa)": "PLJ TELECOM Sp. z o.o.",
      "Operator (ID)": 4529,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 22394,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22395(0,1,2,9)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22395(3-8)",
      "Operator (nazwa)": "Intelligent Technologies S.A.",
      "Operator (ID)": 748,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22396(0-1,5-8)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22396(2,3,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-10-29 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223964,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22397(1-9)",
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223970,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-08 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22398(1-4,7,8)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223980,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "m. st. Warszawa",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223985,
      "Operator (nazwa)": "Elpol Telekom Sp. z o.o.",
      "Operator (ID)": 3478,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "m. st. Warszawa",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223986,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223989,
      "Operator (nazwa)": "ATMAN Sp. z o.o.",
      "Operator (ID)": 944,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-10-21 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22399(1-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-06 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223990,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 223999,
      "Operator (nazwa)": "SUDER Sp. z o.o.",
      "Operator (ID)": 10822,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-01 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 2240,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2241(2,4,6,8,9)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2241(3,7)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22410(0,2)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22410(1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22411(0-1,4-5,8-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22411(2,3,6,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22415(0,1)",
      "Operator (nazwa)": "TEL-KAB Sp. z o.o. Sp. k.",
      "Operator (ID)": 9581,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22415(2-9)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2242(0,9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "m. st. Warszawa",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2242(1,2,6)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2242(3-5)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22427(3,4,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22427(5-8)",
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224270,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224271,
      "Operator (nazwa)": "Corenick Sp. Z o.o.",
      "Operator (ID)": 10757,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-07-23 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224272,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "Warszawa",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22428(0,8-9)",
      "Operator (nazwa)": "Teleinfo Sp. z o.o.",
      "Operator (ID)": 8750,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22428(1-5)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224286,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-03-01 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224287,
      "Operator (nazwa)": "TELEKOM SYSTEM Sp. z o.o.",
      "Operator (ID)": 2793,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2243(0,2,6,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22431(0,2-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224311,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22433(1-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224330,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22434(0-8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224349,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 22435,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22437(1-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224370,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22438(0-5,7-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-02-01 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224386,
      "Operator (nazwa)": "Lukman Multimedia Sp. z o.o.",
      "Operator (ID)": 9030,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-02-01 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2244(0,2-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22441(0-6,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22441(7,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2245(0-2,5,7,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22453(0-8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224539,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22454(0-3,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22454(4-7)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22456(0-4,6-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224565,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22458(0-2,4-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224583,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2246(0-4,6)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22465(0,1-8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224659,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22467(0-4)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22467(5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22468(0-4,7-8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22468(5,6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22469(0,2-4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22469(1,5-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2247(3,4)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22470(5,6,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224700,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-08 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224701,
      "Operator (nazwa)": "Suntar Professional Services Sp. z o.o.",
      "Operator (ID)": 12858,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-03-03 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224702,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-03-03 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224703,
      "Operator (nazwa)": "VIRTUAL TELECOM Sp. z o.o.",
      "Operator (ID)": 8894,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224704,
      "Operator (nazwa)": "Metro Internet Sp. z o.o.",
      "Operator (ID)": 7972,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224707,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-11-29 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22471(1-5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-06 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22471(6-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-28 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22471(8,9)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-28 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224710,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-07-12 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22472(0-2)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-06 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22472(5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224723,
      "Operator (nazwa)": "Messageroute LTD",
      "Operator (ID)": 12026,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-13 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224724,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 22475,
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-10-28 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22476(0,1,4,6-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22476(2,3,5,9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22477(0-1,3-4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-06 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22477(6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-08-30 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224772,
      "Operator (nazwa)": "TBM Telekom Sp. z o.o. Sp.K.",
      "Operator (ID)": 11804,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-02-01 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224775,
      "Operator (nazwa)": "LoVo Sp. z o.o.",
      "Operator (ID)": 7184,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-08-30 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224777,
      "Operator (nazwa)": "ITALNET Sebastian Handzlik",
      "Operator (ID)": 2827,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22478(0-1,4,6-9)",
      "Operator (nazwa)": "Hyperion S.A.",
      "Operator (ID)": 329,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-07-12 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22478(2,3,5)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-07-12 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22479(0,1-3,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-10-21 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22479(4,5)",
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22479(6,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2248(1,2,8,9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22480(1,2)",
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224800,
      "Operator (nazwa)": "EPOKA Sp. z o.o.",
      "Operator (ID)": 5875,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224803,
      "Operator (nazwa)": "e-Telko Sp. z o.o.",
      "Operator (ID)": 11564,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224804,
      "Operator (nazwa)": "AlterTEL Sp. z o.o.",
      "Operator (ID)": 8614,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-08-09 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224805,
      "Operator (nazwa)": "Binotel Polska Sp. z o.o.",
      "Operator (ID)": 13305,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-07-15 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224806,
      "Operator (nazwa)": "PRZEDSI�BIORSTWO AJC Adam Ozga Przedsi�biorstwo AJC2",
      "Operator (ID)": 390,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224807,
      "Operator (nazwa)": "BT Poland Sp. z o.o.",
      "Operator (ID)": 109,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-10-14 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224808,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224809,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-17 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22483(0,1-5,9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22483(6-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22484(0,5-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-28 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224841,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-05-15 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224842,
      "Operator (nazwa)": "Mazowieckie Sieci �wiat�owodowe Sp. z o.o.",
      "Operator (ID)": 9150,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224843,
      "Operator (nazwa)": "Mazowieckie Sieci �wiat�owodowe Sp. z o.o.",
      "Operator (ID)": 9150,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-28 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224844,
      "Operator (nazwa)": "TB Telecom Sp. z o.o.",
      "Operator (ID)": 8831,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-01-21 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224848,
      "Operator (nazwa)": "CLUDO Sp z o.o.",
      "Operator (ID)": 10056,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-02-01 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22485(0,3-9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224851,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224852,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22486(0,1,4,5,7-9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-13 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224862,
      "Operator (nazwa)": "Motomat Group Sp. z o.o.",
      "Operator (ID)": 12553,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-03-07 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224863,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224866,
      "Operator (nazwa)": "Teleinfo Sp. z o.o.",
      "Operator (ID)": 8750,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-06-02 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22487(2-5,8,9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224870,
      "Operator (nazwa)": "SKYNET Sp. z o.o.",
      "Operator (ID)": 3212,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-07-03 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224871,
      "Operator (nazwa)": "SKYNET Sp. z o.o.",
      "Operator (ID)": 3212,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224876,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-04-30 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224877,
      "Operator (nazwa)": "Televoice Polska Sp. z o.o.",
      "Operator (ID)": 8808,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2249(8-9)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22490(2-4)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22490(5-8)",
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224900,
      "Operator (nazwa)": "AMS SYSTEM Sp. z o.o.",
      "Operator (ID)": 7006,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-02 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224901,
      "Operator (nazwa)": "BT Poland Sp. z o.o.",
      "Operator (ID)": 109,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-02-03 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224909,
      "Operator (nazwa)": "Televoice Polska Sp. z o.o.",
      "Operator (ID)": 8808,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 22491,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "Nowy Dw�r Maz.",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 22492,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "Otwock",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22493(0-1,3-4,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "Otwock",
      "Data modyfikacji": "2023-03-31 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22493(5,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "Otwock",
      "Data modyfikacji": "2023-04-05 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22493(6,7)",
      "Operator (nazwa)": "Focus Telecom Polska Sp. z o.o.",
      "Operator (ID)": 8974,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "Otwock",
      "Data modyfikacji": "2023-03-31 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224932,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "Otwock",
      "Data modyfikacji": "2023-04-05 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22494(0,6-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "Piaseczno",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22494(2-5,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "Piaseczno",
      "Data modyfikacji": "2023-03-31 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 224941,
      "Operator (nazwa)": "Focus Telecom Polska Sp. z o.o.",
      "Operator (ID)": 8974,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "Piaseczno",
      "Data modyfikacji": "2023-03-31 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 22495,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "Piaseczno",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22496(0,3,5-6,8-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "Piaseczno",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22496(1,2,4,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "Piaseczno",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22497(0-2,5-9)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22497(3,4)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2250(0-2,4-5,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22503(0-1,4-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-12-24 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22503(2,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 225033,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-12-31 12:20"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22506(0,1-3,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22506(5,6)",
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 225064,
      "Operator (nazwa)": "IBS KAMPINOS TELCO Sp. z o.o.",
      "Operator (ID)": 10534,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-06-17 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22507(0-3,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 225074,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2251(0,4-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22511(0-4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 225115,
      "Operator (nazwa)": "THULIUM Sp. z o.o.",
      "Operator (ID)": 13433,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-16 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22512(0-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-07 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 225129,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-07 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22513(0,1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22513(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22516(0,2-4,6,7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22516(1,5,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2252(0,1-3,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22524(0,1-3,5-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 225244,
      "Operator (nazwa)": "Dialoga Servicios Interactivos S.A.",
      "Operator (ID)": 10930,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 225249,
      "Operator (nazwa)": "Pomerania Services Group Sp. z o.o.",
      "Operator (ID)": 11625,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-03-03 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22525(0,1,2,4-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 225253,
      "Operator (nazwa)": "Lukman Multimedia Sp. z o.o.",
      "Operator (ID)": 9030,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22526(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 225260,
      "Operator (nazwa)": "TELEKOM SYSTEM Sp. z o.o.",
      "Operator (ID)": 2793,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22527(0,1-4,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22527(5,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2253(0,1-4,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22535(0,1,2,4-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 225353,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22536(0,1-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 225368,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22538(0,1-3,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 225384,
      "Operator (nazwa)": "Actus-Info Sp. z o.o.",
      "Operator (ID)": 9186,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22539(1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22539(8,9)",
      "Operator (nazwa)": "ITH Sp. Z o.o.",
      "Operator (ID)": 12635,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-05-24 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 225390,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2254(0,1,3-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22542(0-5,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22542(6,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 225429,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2255(0-1,3-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-07 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22552(0,1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22552(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22558(0-1,4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 225582,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 225583,
      "Operator (nazwa)": "Messagebird B.V.",
      "Operator (ID)": 11757,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-31 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 225585,
      "Operator (nazwa)": "PI TELECOM Wojciech Pipa�a",
      "Operator (ID)": 12740,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-06-13 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22559(0,1-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 225598,
      "Operator (nazwa)": "Inwep Sp. z o.o.",
      "Operator (ID)": 10108,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2256(4,6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22560(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 225609,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22561(0,1-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 225616,
      "Operator (nazwa)": "Liquid Systems Sp. z o.o.",
      "Operator (ID)": 3365,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22562(1-3,6,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22562(4,5,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 225620,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-07 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 225629,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22563(1-4,7-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22563(6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-07 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 225630,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-12-08 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 225635,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22565(0-2,4,6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22565(3,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 225655,
      "Operator (nazwa)": "Helpful Sp. z o.o.",
      "Operator (ID)": 11977,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-05 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22567(0-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 225677,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2257(0,2,4-5,7-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22571(0,1,2,4-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 225713,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-01 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22573(0-2,4-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 225733,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 225736,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22576(0-3,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 225764,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22579(0-1,3-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 225792,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2258(1-3,5-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22580(1-3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22580(4,6,7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 225800,
      "Operator (nazwa)": "AlterTEL Sp. z o.o.",
      "Operator (ID)": 8614,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-12-21 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 225805,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 225808,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-05-26 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22584(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 225840,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-08-08 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22589(0,1-5,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22589(6,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2259(1-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22590(0-3,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 225904,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22595(0,1-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 225957,
      "Operator (nazwa)": "OneFone S.A.",
      "Operator (ID)": 11295,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22596(0-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 225968,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22597(0,1-4,6,7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22597(5,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22598(0,1-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 225987,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22599(0-3,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 225994,
      "Operator (nazwa)": "THULIUM Sp. z o.o.",
      "Operator (ID)": 13433,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-16 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 225995,
      "Operator (nazwa)": "Connected Sp. z o.o.",
      "Operator (ID)": 8033,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-03-31 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 225996,
      "Operator (nazwa)": "TONETIC GROUP Sp. z o.o.",
      "Operator (ID)": 11793,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-03-07 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2260(1,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-04-01 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22600(0,1)",
      "Operator (nazwa)": "SUDER Sp. z o.o.",
      "Operator (ID)": 10822,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-09-24 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22600(3-5,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-05-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 226002,
      "Operator (nazwa)": "ITEL Solutions Sp. z o.o.",
      "Operator (ID)": 11274,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-05-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 226006,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-04-02 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 226007,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-05-11 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22602(1,3-4,6-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-07 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22602(5,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 226020,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-11-29 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 226022,
      "Operator (nazwa)": "IP Telecom Bulgaria LTD",
      "Operator (ID)": 11805,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-23 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22603(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-04-01 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 226030,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-04-01 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 22604,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-04-01 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22606(0,1-3,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 226064,
      "Operator (nazwa)": "Televoice Polska Sp. z o.o.",
      "Operator (ID)": 8808,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-01-21 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22607(0,2-4,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22607(1,5,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 226076,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 226077,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22608(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 226089,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-11-24 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22609(0,1-3,5-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22609(4,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2261(0,2-4,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22611(0,1,3-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 226112,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-12-31 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22615(0,1)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22615(2-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22616(0,1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22616(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2262(0,1,2,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22623(0,1-4,6,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22623(5,7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22624(0,1-3,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 226244,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22625(0,1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22625(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22626(0,1,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22626(2-5)",
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-20 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 226266,
      "Operator (nazwa)": "SUDER Sp. z o.o.",
      "Operator (ID)": 10822,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-07-01 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 226267,
      "Operator (nazwa)": "HCLA Group Poland Sp. z o.o.",
      "Operator (ID)": 9112,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-23 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2263(0-3,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22634(0-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 226347,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2264(1-4,6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-07 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22640(0-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-07 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 226409,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-07 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22645(0,4,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22645(1-3,5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22647(0,1-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22647(5-9)",
      "Operator (nazwa)": "ATMAN Sp. z o.o.",
      "Operator (ID)": 944,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2265(0,3-5,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22651(0,2,3,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22651(1,4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22652(0-3,5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 226524,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-08-15 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 226526,
      "Operator (nazwa)": "FreePhone Sp. z o.o.",
      "Operator (ID)": 10788,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22656(0-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 226568,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 226569,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-02 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22657(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 226579,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-01-02 12:20"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22658(0-6,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 226587,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 226589,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2266(3-5,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22660(2,3)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22660(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-03-15 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 226600,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 226601,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-05-31 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 226604,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-03-15 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 226605,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 226606,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-01-02 12:20"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22661(0,1-3,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 226614,
      "Operator (nazwa)": "TEL-KAB Sp. z o.o. Sp. k.",
      "Operator (ID)": 9581,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22662(0,5,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22662(1-4,6,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22666(0,1-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 226667,
      "Operator (nazwa)": "ProIP Sp. z o.o.",
      "Operator (ID)": 11189,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-28 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22667(0,1-4,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22667(5,6)",
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-20 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22668(0,1,2,4-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 226683,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-08-30 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2267(1-5,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22670(0,1-4,6-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 226705,
      "Operator (nazwa)": "Metro Internet Sp. z o.o.",
      "Operator (ID)": 7972,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 226709,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-09-14 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22676(0,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22676(1-4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22677(0,1-3,5,7,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22677(4,6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2268(1-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 22680,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22688(1-5)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2023-12-15 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22688(6,7)",
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-10-20 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 226880,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-11-21 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 226888,
      "Operator (nazwa)": "Next Mobile Prosta S.A. (d. Globitell Wholesale Sp. z o.o. Sp. k.)",
      "Operator (ID)": 12195,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-02-28 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 226889,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-10-04 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2269(5-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-07 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22690(1-6,8,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 226900,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-05-14 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 226907,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-29 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22691(0,1,3-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 226912,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22692(0,2,4-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22692(3,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 226921,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22693(0-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 226938,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-07 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 226939,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22694(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 226940,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22699(0-2,4-5,7-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 226993,
      "Operator (nazwa)": "ETOP Sp. z o.o.",
      "Operator (ID)": 984,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-25 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 226996,
      "Operator (nazwa)": "Televoice Polska Sp. z o.o.",
      "Operator (ID)": 8808,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-10-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 226999,
      "Operator (nazwa)": "Debesis Sp. z o.o.",
      "Operator (ID)": 10138,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2270(1-3)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2270(4-7,9)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22700(1-9)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 227000,
      "Operator (nazwa)": "PSTD Sp. z o.o.",
      "Operator (ID)": 8828,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22708(0,1-6)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22708(7-9)",
      "Operator (nazwa)": "PSTD Sp. z o.o.",
      "Operator (ID)": 8828,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2271(1,3,6-7,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22710(0-4,7-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22710(5,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 22712,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22714(0-8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 227149,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22715(0,2-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 227151,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22718(0-5)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22718(6-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 227189,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2272(0-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-07 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22726(0-3,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-07 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 227264,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-07 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2273(0,1,4-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22732(0,1,2,4-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 227323,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22733(0,1,2,4-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 227333,
      "Operator (nazwa)": "K2M Sp. z o.o.",
      "Operator (ID)": 13135,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-30 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22737(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 227379,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2274(3,4,6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22740(0,1-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 227408,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22741(0,1-3,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 227414,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22742(0,1,2,4-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22742(3,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22745(0-5,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22745(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 227456,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22747(0-1,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22747(2-5,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22747(6,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 22748,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22749(0,6-9)",
      "Operator (nazwa)": "ATMAN Sp. z o.o.",
      "Operator (ID)": 944,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22749(1-5)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2275(0,1,3-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22752(0,2,3,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22752(1,4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22756(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 227560,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2276(0,1-3,6,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22764(0,3,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-07 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22764(1,4,5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-07 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22764(2,7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 227646,
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22765(0,2-4,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 227651,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 227655,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-10 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 227656,
      "Operator (nazwa)": "Limtel Sp. z o.o.",
      "Operator (ID)": 9118,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22768(0-3,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 227684,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 227685,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22769(0,1-4,6,7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22769(5,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2277(1-5,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22770(0-2,5-6,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 227703,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-13 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 227704,
      "Operator (nazwa)": "ALLWARE RX Sp. z o.o.",
      "Operator (ID)": 11597,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-23 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 227707,
      "Operator (nazwa)": "POLTEL Sp. z o.o.",
      "Operator (ID)": 13246,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2023-02-02 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 227708,
      "Operator (nazwa)": "EKO-LINE Sp. z o.o.",
      "Operator (ID)": 11181,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-28 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22776(0-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 227769,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22777(1-3,5-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 227770,
      "Operator (nazwa)": "Motomat Group Sp. z o.o.",
      "Operator (ID)": 12553,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-04 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 227774,
      "Operator (nazwa)": "FreePhone Sp. z o.o.",
      "Operator (ID)": 10788,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-01 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 227778,
      "Operator (nazwa)": "PPUH Krawarkon Sp. z o.o.",
      "Operator (ID)": 451,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "gminy Warszawa i Nowy Dw�r Maz.",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2278(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22780(0,1-4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 227805,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 2279,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-07 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2280(0,6-9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22801(1,5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22801(3,4,8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22801(7,0)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228012,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-01-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228016,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228019,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-03 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22802(2,3)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22802(4,6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-04-01 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22802(5,7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-01-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22802(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-04-01 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228020,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228021,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22803(0,7-9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-01-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22803(1,2,4,6)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22803(3,5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22804(2,8,9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22804(4,6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-03 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22804(5,0)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228041,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-01-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228043,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-03 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228047,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22805(0,3)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22805(6-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228051,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228052,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228054,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228055,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-01-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228059,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2281(0,1,3,4,6-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22812(0,1-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228128,
      "Operator (nazwa)": "P.K. TELE Sp.J. Jacek Paprota, Dariusz Kulikowski",
      "Operator (ID)": 1602,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22815(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228150,
      "Operator (nazwa)": "G-NET Tomasz Serwatka, Wojciech Rakoniewski Sp. J.",
      "Operator (ID)": 5635,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22819(0-1,4-5,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-07 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22819(3,6-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228192,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-07 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2282(2-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22820(0,1-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228208,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22821(0,1,3-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228212,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-12-30 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2283(1,3-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22830(0,1-3,5-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22830(4,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22832(0-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228329,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 2284,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2285(0-1,3,6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22852(0-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228529,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22854(0,1-4,6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22854(5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22855(0-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228558,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228559,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22857(0,1-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228576,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22858(0-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228586,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22859(0-3,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22859(4,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228595,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2286(1-5,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22860(0,2,4-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22860(3,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228601,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22866(0-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228669,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22867(0,1-3,5,6,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22867(4,7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2287(0,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22871(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228719,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22872(0,1-6,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22872(7,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22873(0,1-6,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228737,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228738,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22874(0-1,3-5,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-07 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22874(2,6,8)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-11-26 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228747,
      "Operator (nazwa)": "Lancelot B.V.",
      "Operator (ID)": 12994,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-04-23 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22875(0,1-4,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22875(5,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22876(0-1,3-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22876(2,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228766,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "2288(2,5-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-07 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22880(0-1,6,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22880(4-5,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-08-01 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228802,
      "Operator (nazwa)": "NETCENTRICA Sp. z o.o.",
      "Operator (ID)": 7087,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-08-01 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228803,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228808,
      "Operator (nazwa)": "PSTD Sp. z o.o.",
      "Operator (ID)": 8828,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22881(0-1,3,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-07 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22881(2,4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-07 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22883(0,1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22883(7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228838,
      "Operator (nazwa)": "CLUDO Sp z o.o.",
      "Operator (ID)": 10056,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-02-01 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22884(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228849,
      "Operator (nazwa)": "Leon Sp. z o.o.",
      "Operator (ID)": 1471,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22888(0-2,6-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228883,
      "Operator (nazwa)": "ProIP Sp. z o.o.",
      "Operator (ID)": 11189,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-28 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228884,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228885,
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228888,
      "Operator (nazwa)": "ATMEL Sp. z o.o.",
      "Operator (ID)": 9777,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-02-01 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228889,
      "Operator (nazwa)": "ProIP Sp. z o.o.",
      "Operator (ID)": 11189,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-08-31 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22890(0-2,4,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22890(3,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228905,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22891(0,1-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22891(5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22892(0,3-4,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22892(5-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-07-12 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228921,
      "Operator (nazwa)": "PIKA POLSKA Sp. z o.o.",
      "Operator (ID)": 11851,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-07-12 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228922,
      "Operator (nazwa)": "Stimo.Net Sp. z o.o.",
      "Operator (ID)": 11891,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-15 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22893(0,1-5,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22893(6,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 22894,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22895(0-2,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22895(3,5-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228954,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22896(0-1,3-4,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228962,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228965,
      "Operator (nazwa)": "Platan Sp. z o.o. Sp. k.",
      "Operator (ID)": 12733,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-10-08 12:20"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228966,
      "Operator (nazwa)": "Oskar Rybczy�ski",
      "Operator (ID)": 13148,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-02 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22897(0,1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22897(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22898(0,1-4,6-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228985,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-12-14 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228989,
      "Operator (nazwa)": "CLUDO Sp z o.o.",
      "Operator (ID)": 10056,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-02-01 12:15"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22899(0,1-3,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "22899(4-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": 228998,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "229(0,1-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 22,
      "Zakres (number)": "229(8,9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "WARSZAWA",
      "Strefa (symbol)": "SN-WA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23(2,4,5,7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "231(0,1-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 2319,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:49"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "233(1,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-03 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "2330(1-5,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 233000,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 233001,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-11-16 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 233003,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-22 12:45"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23306(0,4,5,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23306(1-3)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 233066,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 233067,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23307(1-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-30 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 233070,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 233073,
      "Operator (nazwa)": "MEDIDESK Sp. z o.o.",
      "Operator (ID)": 12669,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-30 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23308(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 233082,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "2332(0-1,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23322(0-1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 233222,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23323(0-1,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-11-11 12:35"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 233232,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-11-11 12:35"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 233233,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-03 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 233234,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-05-17 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "2333(0,1,2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 23333,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "236(0,3-4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "2361(0,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "2361(1-2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23613(0-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236138,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "2362(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23623(0,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23623(1-5)",
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236236,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-05 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "2365(0,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "2365(4,5,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236510,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236511,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "Ciechan�w",
      "Data modyfikacji": "2016-04-01 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236512,
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236513,
      "Operator (nazwa)": "IPTELL Sp. z o.o.",
      "Operator (ID)": 7201,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236514,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236515,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236516,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236517,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-01 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236518,
      "Operator (nazwa)": "DIDWW Ireland Limited",
      "Operator (ID)": 12132,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-19 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236519,
      "Operator (nazwa)": "FREELY Sp. z o.o.",
      "Operator (ID)": 13143,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23652(0,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-08 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23652(2-6)",
      "Operator (nazwa)": "TeleGo sp. z o.o.",
      "Operator (ID)": 9324,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-09-25 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236521,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-03-29 12:45"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236528,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236529,
      "Operator (nazwa)": "Telnyx Ireland Limited",
      "Operator (ID)": 13021,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-01 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23653(1-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23653(5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236530,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23658(0,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23658(1-2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236583,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236584,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-01-08 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23659(0,1-3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23659(4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "2366(0,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23661(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236610,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-09-06 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 23662,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23663(0,1-3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23663(4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23664(0,1-3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23664(4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "2367(1-3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 23670,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23674(0,1-4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236745,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23675(0-2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23675(3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236754,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23676(0,1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23676(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23677(0,1-4)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23677(5,6,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236777,
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-20 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23678(0,1)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23678(2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23679(0,1-4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236795,
      "Operator (nazwa)": "e-Telko Sp. z o.o.",
      "Operator (ID)": 11564,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-04-19 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23680(7,8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236800,
      "Operator (nazwa)": "Galena Sp. z o.o.",
      "Operator (ID)": 66,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-16 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236801,
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236802,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236803,
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236804,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236805,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236806,
      "Operator (nazwa)": "EASYNET.PL Sp. z o.o.",
      "Operator (ID)": 5515,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-05-07 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236809,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23681(0-3)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "Ciechan�w",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23681(4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "Ciechan�w",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23682(0,2-3,7,9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236821,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236824,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236825,
      "Operator (nazwa)": "VOXNET Sp. z o.o. Sp. k.",
      "Operator (ID)": 728,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236826,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236828,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23683(0,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "M�awa",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23683(1,2)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23683(5-8)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "M�awa",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236833,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-04 12:46"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236834,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23684(0,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23684(1-6)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23685(0,6,9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "�uromin",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23685(1-4)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236855,
      "Operator (nazwa)": "AMS SYSTEM Sp. z o.o.",
      "Operator (ID)": 7006,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-04-07 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236857,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "�uromin",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236858,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "�uromin",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23686(1-2)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23686(5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236860,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236863,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236864,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-13 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23687(0,1-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23687(7-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23688(0,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-07-15 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23688(1-4)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236885,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-24 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236886,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-07-15 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23689(1-3,8,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23689(4-7)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236890,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 23690,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23691(0-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-08 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236917,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-08 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 23692,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-08 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23693(0,1-3,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23693(4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23694(0,1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23694(3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23695(0,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23695(1,2)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23696(0,1-3,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236964,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23697(0,2-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236971,
      "Operator (nazwa)": "Dialoga Servicios Interactivos S.A.",
      "Operator (ID)": 10930,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23698(0,1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23698(7,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236989,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23699(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 236991,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "238(1,4-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "2380(0,6-9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "2380(2-5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23801(0-2)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23801(4,7,9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23801(5,6)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 238013,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 238018,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "2382(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23823(0-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 238238,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-10 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "2383(0,1-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23835(0,1,2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 238353,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-01 12:15"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "2388(0,1-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "23888(0,2-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 238881,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": 238888,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "239(0,1-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 23,
      "Zakres (number)": "239(8,9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "CIECHAN�W",
      "Strefa (symbol)": "SN-CI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "241(0,1-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 2419,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "2420(3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-22 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24200(3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-13 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 242000,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 242001,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 242002,
      "Operator (nazwa)": "MEDIDESK Sp. z o.o.",
      "Operator (ID)": 12669,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-13 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24201(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 242010,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24202(0,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-22 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24202(1-3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-22 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "2421(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24211(1-3)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24211(4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 242110,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "2422(0-1,3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 24222,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24224(0-1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 242242,
      "Operator (nazwa)": "Telfon Poland Sp. z o.o.",
      "Operator (ID)": 12517,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "2423(0,2-4,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24231(1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 242310,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-07-22 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 242319,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-10-30 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 24235,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24236(0,1,2,4,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24236(3,6-9)",
      "Operator (nazwa)": "TeleGo sp. z o.o.",
      "Operator (ID)": 9324,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-09-25 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "2424(1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-10-03 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24240(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-10-03 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 242400,
      "Operator (nazwa)": "POLTEL Sp. z o.o.",
      "Operator (ID)": 13246,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-02-02 12:45"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24242(0,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-12 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24242(1,2)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-31 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 242423,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-12 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 242424,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "2425(0,5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "2425(3,4,6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24251(0,1)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24251(3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 242512,
      "Operator (nazwa)": "Dialoga Servicios Interactivos S.A.",
      "Operator (ID)": 10930,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24252(0,1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24252(7,8)",
      "Operator (nazwa)": "TOYA Sp. z o.o.",
      "Operator (ID)": 127,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 242529,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "2426(0,2-6,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24261(0-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 242616,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24267(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 242670,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24269(0,1,2,4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24269(3,5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "2427(1-3,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "2427(5-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 24270,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24274(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 242740,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24278(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 242780,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "2428(0,1,3,4,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24282(0,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24282(1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24285(0,1-5,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 242856,
      "Operator (nazwa)": "Telnyx Ireland Limited",
      "Operator (ID)": 13021,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-01 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 242857,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 24286,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24287(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 242870,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24288(1-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 242880,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 242888,
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-20 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 2429,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "243(1,4,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "2430(1-6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24300(2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-08-13 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243000,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243001,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-08-07 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24307(1-5)",
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24307(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243070,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24308(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243082,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "2432(0-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24324(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243243,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-05 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "2433(0-2,4-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24333(0-2)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-07-08 01:22"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24333(4-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-07-08 01:23"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243333,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24337(0,9)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24337(2-5,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-24 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243371,
      "Operator (nazwa)": "MPCNET Sp. z o.o.",
      "Operator (ID)": 11243,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-09-06 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243376,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243378,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-24 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "2435(0,3,4,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24351(1,4)",
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24351(5-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243510,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243512,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243513,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243519,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-10-21 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24352(0,7-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24352(1-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24355(0-8)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243559,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 24356,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24357(0-3,6-8)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24357(4,5,9)",
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "2436(4,5,6)",
      "Operator (nazwa)": "Petrotel Sp. z o.o.",
      "Operator (ID)": 45,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "P�ock",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24360(0,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243601,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243602,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243603,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243604,
      "Operator (nazwa)": "NEW TELEKOM Sp. z o.o.",
      "Operator (ID)": 10247,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-07-30 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24361(0-2,7-8)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24361(3-5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243616,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243619,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24362(1,6)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24362(7-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243620,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243622,
      "Operator (nazwa)": "Voice Net Sp. z o.o.",
      "Operator (ID)": 12749,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2024-02-20 12:45"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243623,
      "Operator (nazwa)": "VOXNET Sp. z o.o. Sp. k.",
      "Operator (ID)": 728,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243624,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243625,
      "Operator (nazwa)": "DIDWW Ireland Limited",
      "Operator (ID)": 12132,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-10-19 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24363(0-1,3,9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24363(4,5,7)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24363(6,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243632,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-13 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24367(0,6-9)",
      "Operator (nazwa)": "Petrotel Sp. z o.o.",
      "Operator (ID)": 45,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24367(1-5)",
      "Operator (nazwa)": "Petrotel Sp. z o.o.",
      "Operator (ID)": 45,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "P�ock",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24368(0,8-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24368(3,4)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243681,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-04 12:46"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243682,
      "Operator (nazwa)": "Galena Sp. z o.o.",
      "Operator (ID)": 66,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-16 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243685,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "Gostynin",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243686,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "��czyca",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243687,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "Sierpc",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243690,
      "Operator (nazwa)": "TOYA Sp. z o.o.",
      "Operator (ID)": 127,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243691,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243692,
      "Operator (nazwa)": "FREELY Sp. z o.o.",
      "Operator (ID)": 13143,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243693,
      "Operator (nazwa)": "Colt Technology Services GmbH",
      "Operator (ID)": 12380,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-12-02 12:45"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243694,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243695,
      "Operator (nazwa)": "ATMAN Sp. z o.o.",
      "Operator (ID)": 944,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-01-13 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243696,
      "Operator (nazwa)": "FORWEB S.C. Monika Bodetko Tomasz Paw�owski",
      "Operator (ID)": 5731,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243697,
      "Operator (nazwa)": "IPTELL Sp. z o.o.",
      "Operator (ID)": 7201,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243698,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243699,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "2437(0,1-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24377(0,1-4)",
      "Operator (nazwa)": "Petrotel Sp. z o.o.",
      "Operator (ID)": 45,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24377(5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24380(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243801,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24381(0,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24381(1-4)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24382(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24382(1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24383(0,1-3,9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24383(4-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 24384,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24385(1-3)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24385(4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243850,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-04 12:46"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24386(3-4,7)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-07-13 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24386(5,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-07-13 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243860,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243861,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243862,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243868,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243869,
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24387(1-3)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24387(5-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-07-13 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243870,
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243874,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-07-13 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243879,
      "Operator (nazwa)": "Petrotel Sp. z o.o.",
      "Operator (ID)": 45,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-08-03 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24388(1-9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243880,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24389(0-6,9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243897,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-07-13 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 243898,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "244(0,1,3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "2442(0,1-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24424(0-1,3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-09 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 244242,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-09 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 244244,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "2444(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "2444(1-7)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "245(1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "2450(0,1-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24506(0,1-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 245065,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "2452(0,1-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24524(0,1-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 245245,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 246,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "247(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "2472(0,3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 24721,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24722(0-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24722(5-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 247229,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24724(0-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 247247,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-10 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "248(1,3,5-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "2480(0,6-9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "2480(3-5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24801(2,3,6)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-01-06 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24801(4,7,8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24801(5,9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 248010,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 248011,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24802(0,2-5,7,9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24802(1,6)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 248028,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "2482(0-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24824(0-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 248248,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-05 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24840(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 248402,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24841(0,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 248411,
      "Operator (nazwa)": "Telkonekt Sp. z o.o.",
      "Operator (ID)": 9086,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 248412,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24842(0,1,3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 248422,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 248424,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24843(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 248432,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24844(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 248442,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24845(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 248452,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24846(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 248462,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24847(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 248472,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24848(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 248482,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24849(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 248492,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "2488(0,1-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24888(0,3-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-28 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 248881,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 248882,
      "Operator (nazwa)": "AMS SYSTEM Sp. z o.o.",
      "Operator (ID)": 7006,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-28 12:15"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 248888,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "249(0,1,3-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "249(8,9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "2492(0,1-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": "24924(0,1-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 24,
      "Zakres (number)": 249249,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "P�OCK",
      "Strefa (symbol)": "SN-P�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "251(0,1-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 2519,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 252,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "253(1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-03 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "2530(1-6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 25300,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25307(1-3)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25307(5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-12-29 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 253070,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 253074,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-02-29 10:01"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25308(0,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 253081,
      "Operator (nazwa)": "FMEDIA Jakub Frankowski",
      "Operator (ID)": 8798,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 253082,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:50"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "2532(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-03 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25325(0-1,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-10-12 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 253252,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-10-12 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 253253,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-03 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "254(1,3-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "2540(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25400(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 254000,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "2542(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25425(0-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 254254,
      "Operator (nazwa)": "MEDIDESK Sp. z o.o.",
      "Operator (ID)": 12669,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-29 12:45"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "2546(0,1-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 25467,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "255(1,3,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "2550(1-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25500(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 255000,
      "Operator (nazwa)": "Media Telekom Sp. z o.o.",
      "Operator (ID)": 10860,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-10 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25506(0-4,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-04-10 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 255065,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 255066,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-04-10 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "2552(0,1-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25525(0,6-9)",
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25525(1,3-4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-10 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 255252,
      "Operator (nazwa)": "E-CALL POLSKA Sp. z o.o.",
      "Operator (ID)": 9331,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-10 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 255255,
      "Operator (nazwa)": "Televoice Polska Sp. z o.o.",
      "Operator (ID)": 8808,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "2554(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25542(0,1-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 255426,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "2555(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25555(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 255555,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "256(0,1,5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "2562(0,2,6-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 25621,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25623(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 256239,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25624(0,1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25624(3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25625(0,1-3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25625(4-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 256256,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-05 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25629(0,1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 256297,
      "Operator (nazwa)": "Intelligent Technologies S.A.",
      "Operator (ID)": 748,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-09-16 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 256298,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 256299,
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-20 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "2563(0,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "2563(1-3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "2564(0,2,4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "2564(5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25641(0-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 256418,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25643(0-2,4-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 256433,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 256438,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "2566(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25661(0,1,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25661(2-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "2567(0,1-4,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 25675,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25676(0,1)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25676(2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "2568(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "2568(2,3,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25681(0,1,3)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25681(2,4-6,8,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 256817,
      "Operator (nazwa)": "Dialoga Servicios Interactivos S.A.",
      "Operator (ID)": 10930,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25684(1-6,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 256840,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 256847,
      "Operator (nazwa)": "NET-KOMP Krystian Kania",
      "Operator (ID)": 7032,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 256849,
      "Operator (nazwa)": "ATMAN Sp. z o.o.",
      "Operator (ID)": 944,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-01-13 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "2569(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25691(0,7-9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25691(1-5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 256916,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "257(0-1,3,6-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "2572(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25725(0-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 257257,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-10 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "2574(4,5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25740(2,3)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25740(7,8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 257400,
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 257401,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 257404,
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 257405,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 257406,
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 257409,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 25741,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25742(0,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25742(1,3)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 257422,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25743(0,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-15 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25743(1-3)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 257434,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-15 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25746(0,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25746(1-4)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25747(0-3,6-7,9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 257474,
      "Operator (nazwa)": "Voice Net Sp. z o.o.",
      "Operator (ID)": 12749,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-02-20 12:45"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 257475,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 257478,
      "Operator (nazwa)": "VOXNET Sp. z o.o. Sp. k.",
      "Operator (ID)": 728,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25748(1,5,7,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 257480,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 257482,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-10-21 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 257483,
      "Operator (nazwa)": "FREELY Sp. z o.o.",
      "Operator (ID)": 13143,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 257484,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 257486,
      "Operator (nazwa)": "TPNETS.COM Sp. z o.o.",
      "Operator (ID)": 3012,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 257489,
      "Operator (nazwa)": "Telnyx Ireland Limited",
      "Operator (ID)": 13021,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-01 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25749(1-3)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25749(4,9)",
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 257490,
      "Operator (nazwa)": "Metro Internet Sp. z o.o.",
      "Operator (ID)": 7972,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 257495,
      "Operator (nazwa)": "DIDWW Ireland Limited",
      "Operator (ID)": 12132,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-19 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 257496,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-11-24 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 257497,
      "Operator (nazwa)": "INTERWAN Sp. z o.o.",
      "Operator (ID)": 5522,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "gminy powiatu mi�skiego przynale�ne do SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 257498,
      "Operator (nazwa)": "IPTELL Sp. z o.o.",
      "Operator (ID)": 7201,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "2575(2,5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25750(0,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25750(1,2)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25750(3-4,6-7)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 257505,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 257508,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-12-01 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25751(0,1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25751(3,6)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "Siedlce",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 257514,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 257515,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 257517,
      "Operator (nazwa)": "FORWEB S.C. Monika Bodetko Tomasz Paw�owski",
      "Operator (ID)": 5731,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 257518,
      "Operator (nazwa)": "PLATKOM TELE-INFORMATYKA Piotr P�atek",
      "Operator (ID)": 5162,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 257519,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25753(0,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25753(1-6)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25754(0,6-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25754(1-4,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-01-08 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 257545,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-01-08 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25756(0-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 257567,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 257568,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 257569,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "2578(0,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-01-28 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "2578(1,7)",
      "Operator (nazwa)": "Telefony Podlaskie S.A.",
      "Operator (ID)": 77,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "Soko��w Podlaski",
      "Data modyfikacji": "2017-12-08 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "2578(2,3)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "Soko��w Podlaski",
      "Data modyfikacji": "2017-12-08 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25784(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 257840,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25785(0-4,6-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-02-01 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 257855,
      "Operator (nazwa)": "Lukman Multimedia Sp. z o.o.",
      "Operator (ID)": 9030,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-02-01 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 25786,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-02-01 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25788(0-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-01-28 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 257888,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "2579(6,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 25790,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25791(0,2,6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25791(1,3-5,7)",
      "Operator (nazwa)": "TeleGo sp. z o.o.",
      "Operator (ID)": 9324,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-09-25 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25792(0,2-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 257921,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 257929,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25793(0,1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 257938,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 257939,
      "Operator (nazwa)": "Lukman Multimedia Sp. z o.o.",
      "Operator (ID)": 9030,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25794(0,6)",
      "Operator (nazwa)": "TELEKOM SYSTEM Sp. z o.o.",
      "Operator (ID)": 2793,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 257941,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 257942,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-01 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 257943,
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 257944,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 257945,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-08-07 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 257947,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 257948,
      "Operator (nazwa)": "Media Telekom Sp. z o.o.",
      "Operator (ID)": 10860,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 257949,
      "Operator (nazwa)": "NEA NET �ukasz �ugowski",
      "Operator (ID)": 8086,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 25795,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25797(0,1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25797(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25799(0-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 257996,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "258(1,3-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "2580(0,6-9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "2580(3-5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25801(1,4)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25801(2,3,5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25801(7-9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 258010,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 258016,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25802(1,0)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25802(2,4)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25802(3,5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25802(6-9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "2582(0-4,6-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25825(0-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 258258,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-10 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25828(0,1-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 258284,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "2588(0,1-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "25888(2-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-08-29 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 258880,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-08-29 12:15"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 258881,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": 258888,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "259(0,1-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 25,
      "Zakres (number)": "259(8,9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "SIEDLCE",
      "Strefa (symbol)": "SN-SE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "291(0,1-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 2919,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "292(0,1,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-03 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "2922(1-8,0)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-03 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29229(0,3-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-07-29 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 292291,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-05-15 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 292292,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-03 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 292299,
      "Operator (nazwa)": "TOYA Sp. z o.o.",
      "Operator (ID)": 127,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-07-29 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "2923(0,1-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29234(0,5,6,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-12-29 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29234(1-3)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 292344,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-02-29 10:01"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 292347,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-01 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "293(1,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "2930(1-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29300(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 293000,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29307(1-5)",
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29307(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-03-31 12:45"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 293070,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 293076,
      "Operator (nazwa)": "ITEL Solutions Sp. z o.o.",
      "Operator (ID)": 11274,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-03-31 12:45"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29308(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 293082,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29309(0-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 293099,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "2932(0,1-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29329(0,1,2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 293293,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-01 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "2933(2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 29330,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-04-21 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 29331,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 29333,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 294,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "295(1,3-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "2950(0,1-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29506(0,1-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 295065,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "2952(0-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29529(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 295295,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-10 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "2959(0,5,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29591(0,1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29591(3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29592(0,1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29592(3-6,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 295927,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 295929,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29593(0,1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29593(3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29594(0,1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29594(3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29596(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 295969,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29597(0,1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29597(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "296(0-1,3,5-6,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "2962(0-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29629(0-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 296296,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-10 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 296400,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 296401,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 296402,
      "Operator (nazwa)": "IPTELL Sp. z o.o.",
      "Operator (ID)": 7201,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 296403,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 296404,
      "Operator (nazwa)": "TIMPLUS B. Dudek, R. Walentowski sp�ka jawna",
      "Operator (ID)": 3110,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 296405,
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 296406,
      "Operator (nazwa)": "AMS SYSTEM Sp. z o.o.",
      "Operator (ID)": 7006,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 296407,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 296408,
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 296409,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29641(0-3)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-24 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29641(4-6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-10-29 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29641(7,8)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-06 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29642(0,1-3)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29642(8,9)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 296424,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 296425,
      "Operator (nazwa)": "VOXNET Sp. z o.o. Sp. k.",
      "Operator (ID)": 728,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 296426,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-08-07 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 296427,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-04 12:46"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29643(3,7,9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-13 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29643(4-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-13 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 296430,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 296431,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 296432,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 296438,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29644(0-1,3-9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 296442,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29645(0-1,3-6,8-9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29645(2,7)",
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29646(2,3)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29646(4-5,8)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 296460,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2023-03-29 12:46"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 296461,
      "Operator (nazwa)": "YNET MANAGEMENT Pawe� Skrodzki",
      "Operator (ID)": 4551,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 296466,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 296467,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 296469,
      "Operator (nazwa)": "Intelligent Technologies S.A.",
      "Operator (ID)": 748,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-08-29 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29647(0,1-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29647(7-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29648(0,8-9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29648(2-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 296481,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29649(0,5-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29649(1-3)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 296494,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 296499,
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-20 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "2967(1-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29670(0,7,9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29670(1-6,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29679(0-8)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 296799,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "2969(0,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29691(1,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29691(2-6)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29691(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 296910,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29692(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29692(1-5)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29693(0,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29693(1,2)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29694(1,5)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29694(6-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 296940,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-09-06 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 296942,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-07-01 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 296943,
      "Operator (nazwa)": "Nettelekom GK Sp. z o.o.",
      "Operator (ID)": 9896,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 296944,
      "Operator (nazwa)": "YNET MANAGEMENT Pawe� Skrodzki",
      "Operator (ID)": 4551,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 296949,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "297(0,3,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "2971(0,1-3,5,6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29714(0,1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29714(3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 29717,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "2972(0-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29729(0-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 297297,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-10 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "2974(1-2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "2974(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29740(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 297400,
      "Operator (nazwa)": "FUZ Adam Rojek",
      "Operator (ID)": 272,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29743(0-3,5-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29743(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 297434,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29744(0,1,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29744(2,4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 297443,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29745(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 297450,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29746(0-4,6,8,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-01-08 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 297465,
      "Operator (nazwa)": "Galena Sp. z o.o.",
      "Operator (ID)": 66,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-04-16 12:16"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 297467,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "2975(0,4,5,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29751(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 297519,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29752(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29752(1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29753(1,2,7-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29753(3-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 297530,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-01 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29756(0,1,5,6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29756(3-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 297562,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 297567,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29757(0,4-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29757(1-3,8,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29758(0,1-3,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29758(4-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "2976(0,1,4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29762(0,2)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29762(3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 297621,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-01-08 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29763(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29763(1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29765(0,1-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29765(5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29766(2-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 297660,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 297661,
      "Operator (nazwa)": "DIDWW Ireland Limited",
      "Operator (ID)": 12132,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-19 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29767(0,1,3,4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 297672,
      "Operator (nazwa)": "Telnyx Ireland Limited",
      "Operator (ID)": 13021,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-01 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 297675,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29768(0,1-3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29768(4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29769(0,1,3,4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29769(5-9)",
      "Operator (nazwa)": "TeleGo sp. z o.o.",
      "Operator (ID)": 9324,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 297692,
      "Operator (nazwa)": "FREELY Sp. z o.o.",
      "Operator (ID)": 13143,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "2977(0,3-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29771(0,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29771(1-4)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29772(0,3,4,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29772(1,2,5-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29777(0-1,4-5,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29777(6,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 297772,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-12-01 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 297773,
      "Operator (nazwa)": "Dialoga Servicios Interactivos S.A.",
      "Operator (ID)": 10930,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "298(1,3-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "2980(0,6-9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "2980(3-5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29801(0,2,3,6)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29801(1,7,9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 298014,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 298015,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 298018,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29802(0,2,6-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29802(3,9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 298021,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-01-06 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 298024,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 298025,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "2982(0-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29829(0-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 298298,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-05 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "2988(0,1-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29888(0,2-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 298881,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 298888,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "299(0,1,3-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "299(8,9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "2992(0-4,6-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 29925,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-07-13 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": "29929(0-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 29,
      "Zakres (number)": 299299,
      "Operator (nazwa)": "Telfon Poland Sp. z o.o.",
      "Operator (ID)": 12517,
      "Strefa (nazwa)": "OSTRO��KA",
      "Strefa (symbol)": "SN-OS",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "321(2,4,6-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-04-04 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3210(3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-04-04 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32100(5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-02-13 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 321000,
      "Operator (nazwa)": "E-PO�UDNIE Stowarzyszenie Na Rzecz Rozwoju Spo�ecze�stwa Informacyjnego",
      "Operator (ID)": 8720,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 321001,
      "Operator (nazwa)": "SOFT PRO 2 Sp. z o.o.",
      "Operator (ID)": 9375,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:51"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 321002,
      "Operator (nazwa)": "SPI-NET Nobert Nowicki",
      "Operator (ID)": 3026,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-10-18 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 321003,
      "Operator (nazwa)": "E-CALL POLSKA Sp. z o.o.",
      "Operator (ID)": 9331,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-18 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 321004,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-09-24 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32101(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-04-04 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 321010,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-08-17 12:45"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32102(1-3)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-08-19 12:45"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32102(4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-08-19 12:45"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 321020,
      "Operator (nazwa)": "Next Mobile Prosta S.A. (d. Globitell Wholesale Sp. z o.o. Sp. k.)",
      "Operator (ID)": 12195,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-02-28 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3211(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32111(2-4)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32111(5-7)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-10 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 321110,
      "Operator (nazwa)": "ITEL Solutions Sp. z o.o.",
      "Operator (ID)": 11274,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-11-27 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 321111,
      "Operator (nazwa)": "Motomat Group Sp. z o.o.",
      "Operator (ID)": 12553,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-04 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 321118,
      "Operator (nazwa)": "Connected Sp. z o.o.",
      "Operator (ID)": 8033,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-08-06 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 321119,
      "Operator (nazwa)": "MEDIDESK Sp. z o.o.",
      "Operator (ID)": 12669,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-22 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3213(0,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-12 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3213(3-7)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 32131,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-08-29 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32132(0,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-24 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32132(3-6)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-24 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 321321,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 321322,
      "Operator (nazwa)": "Limtel Sp. z o.o.",
      "Operator (ID)": 9118,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32138(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-12 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32138(1-7)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-12 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3215(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-04-04 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32155(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-04-04 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 321555,
      "Operator (nazwa)": "SUDER Sp. z o.o.",
      "Operator (ID)": 10822,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-04-04 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 3219,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "322(6,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3220(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32209(0,1-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 322096,
      "Operator (nazwa)": "Dialoga Servicios Interactivos S.A.",
      "Operator (ID)": 10930,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3221(0,1,2,4-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32213(0-3,5-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-30 12:45"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 322134,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-30 12:45"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3222(0,2,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32221(0,1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32221(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32223(0-2,4-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 322233,
      "Operator (nazwa)": "E-CALL POLSKA Sp. z o.o.",
      "Operator (ID)": 9331,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-18 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32224(1-4,6,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 322240,
      "Operator (nazwa)": "Colt Technology Services GmbH",
      "Operator (ID)": 12380,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-12-02 12:45"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 322245,
      "Operator (nazwa)": "E-CALL POLSKA Sp. z o.o.",
      "Operator (ID)": 9331,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-18 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 322247,
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-12 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 322249,
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-15 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3223(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32239(0-2,4-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 322393,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-11-18 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 322396,
      "Operator (nazwa)": "XBEST.NET.PL Sp. z o.o.",
      "Operator (ID)": 5309,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3224(0-1,3,5-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32242(0-4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 322425,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32244(0,1-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 322446,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32248(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 322489,
      "Operator (nazwa)": "didXL B.V.",
      "Operator (ID)": 13079,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-10-22 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3225(0,1,2,4,6,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32253(0,1-3,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 322534,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-02-16 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32255(1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 322550,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-04-18 12:45"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 322559,
      "Operator (nazwa)": "Blue Prospect Sp. z o.o.",
      "Operator (ID)": 13345,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-08-05 12:45"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32257(0,1-3,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 322574,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-08-08 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32259(0,1-3,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 322594,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3227(0-1,3-4,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32272(0-4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 322725,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32275(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 322759,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32276(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 322769,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3228(0,1,2,4-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32283(0-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32283(7,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 322839,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-06-08 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3230(1,3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3230(4,6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32300(0-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 323007,
      "Operator (nazwa)": "Cyfrowe Systemy Telekomunikacyjne Sp. z o.o.",
      "Operator (ID)": 1058,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-02-01 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32302(0,1,2,4,7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32302(5-6,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-18 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 323023,
      "Operator (nazwa)": "Debesis Sp. z o.o.",
      "Operator (ID)": 10138,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-18 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32305(0,1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32305(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 32307,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32308(0,6-9)",
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32308(1,5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32308(2-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3231(0,5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3231(1,2)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 32313,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32314(0,1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32314(3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32316(0,1-6,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32316(7,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32317(0,1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32317(7-9)",
      "Operator (nazwa)": "TOYA Sp. z o.o.",
      "Operator (ID)": 127,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32318(0-3,6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32318(5,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 323184,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 323187,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32319(0,1,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-17 12:45"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32319(2,4-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-09-29 12:45"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 323193,
      "Operator (nazwa)": "NETTEL Sp. z o.o.",
      "Operator (ID)": 9575,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3232(0,1)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3232(2,4,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32323(0,2-4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 323231,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 323235,
      "Operator (nazwa)": "DIDWW Ireland Limited",
      "Operator (ID)": 12132,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-19 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32325(0-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 323259,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32326(0,1-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 323268,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32328(0,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32328(1-6,8,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32329(0,5,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-03-31 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32329(1,4,6,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 323292,
      "Operator (nazwa)": "PPCom Sp. z o.o.",
      "Operator (ID)": 10876,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 323293,
      "Operator (nazwa)": "Telfon Poland Sp. z o.o.",
      "Operator (ID)": 12517,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-03-07 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 323299,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-03-31 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3233(1,3,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32330(0-6,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 323307,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-28 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 323309,
      "Operator (nazwa)": "DG-NET S.A.",
      "Operator (ID)": 11947,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-04-03 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32332(0-1,4-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 323322,
      "Operator (nazwa)": "AlterTEL Sp. z o.o.",
      "Operator (ID)": 8614,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 323323,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-04-25 12:45"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32334(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 323349,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-13 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32336(0,3-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32336(1,2,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32337(0,1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32337(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32338(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 323389,
      "Operator (nazwa)": "MULTIPLAY Sp. z o.o. Sp. k.",
      "Operator (ID)": 10068,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32339(0,1-4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 323395,
      "Operator (nazwa)": "ORION B�a�ej Hess",
      "Operator (ID)": 1339,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3234(0,5,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 32341,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-07 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32342(0,1-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 323428,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-01-03 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32343(0,1-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 323434,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32344(0,1,7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32344(2-6,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32346(0,1-3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32346(4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32347(0,2,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-09-29 12:45"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32347(3-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-17 12:45"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 323471,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3235(0,1-3,5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32354(0,1-4,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32354(5-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32356(0-3,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32356(5,6)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-12-16 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 323564,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-12-16 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3236(0,3,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3236(1,2,5-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32364(0,1-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32364(5-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32364(8,9)",
      "Operator (nazwa)": "PROTONET Adrian Ludyga",
      "Operator (ID)": 1173,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3237(1,2,4,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32370(0,1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32370(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32373(0,1)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32373(2-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32375(1-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-08-30 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 323750,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 323753,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-08-30 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 32376,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3238(0,1,4,7,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32382(0,1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32382(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32383(0,1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32383(3-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-10-05 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 323838,
      "Operator (nazwa)": "POLTEL Sp. z o.o.",
      "Operator (ID)": 13246,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-02-02 12:45"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 32385,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32386(0,1-3,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32386(4-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32389(0,1-4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 323895,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-12-14 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32390(0,1-5,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32390(6,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32391(0,1-4,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32391(5-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32392(0,1,2,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32392(4-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-04-01 12:45"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 323923,
      "Operator (nazwa)": "LoVo Sp. z o.o.",
      "Operator (ID)": 7184,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-04-01 12:45"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32393(0,1,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32393(2-4,6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 323935,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 32394,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 32395,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32396(0,1)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32396(2-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32397(0,2,6-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32397(3-5,8,9)",
      "Operator (nazwa)": "TeleGo sp. z o.o.",
      "Operator (ID)": 9324,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-09-25 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 323971,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-13 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32398(0,4,5,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32398(1-3,6-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32399(0,2-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 323991,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 323999,
      "Operator (nazwa)": "MEDIANET24.PL Sp. z o.o.",
      "Operator (ID)": 12672,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-05-30 12:45"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3240(2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32400(0,3-4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324001,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324002,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-02-13 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324005,
      "Operator (nazwa)": "FIONE Sp. z o.o. Sp. k.",
      "Operator (ID)": 11156,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-02-13 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32401(0,1,2,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32401(3-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3241(0,5,7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 32411,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32412(0,2-3,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32412(4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324121,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32413(2-4)",
      "Operator (nazwa)": "Proxnet Sp. J.",
      "Operator (ID)": 889,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32413(5-7)",
      "Operator (nazwa)": "SAT-MONT-SERVICE Jacek Mruk, Krzysztof Mruk Sp. z o.o.",
      "Operator (ID)": 315,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "B�dzin, D�browa G�rnicza, Sosnowiec",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32413(8,9)",
      "Operator (nazwa)": "ART-COM Sp. z o.o.",
      "Operator (ID)": 7817,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324130,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324131,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32414(0,4,5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32414(1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32414(3,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 32416,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32418(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324180,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3242(1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 32420,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32427(0-4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324275,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32428(1-5)",
      "Operator (nazwa)": "E-CALL POLSKA Sp. z o.o.",
      "Operator (ID)": 9331,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32428(7,8)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324280,
      "Operator (nazwa)": "Polineo Sp. z o.o.",
      "Operator (ID)": 11499,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-08-03 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324286,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-06-10 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324289,
      "Operator (nazwa)": "Telbeskid Sp. z o.o.",
      "Operator (ID)": 57,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-03-14 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32429(0,7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32429(1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324298,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3243(0,4,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32431(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324310,
      "Operator (nazwa)": "ST LINE Sp. z o.o. Sp. k.",
      "Operator (ID)": 12096,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-01 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32432(0,1-5)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-06-03 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32432(6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32433(0,1,2,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32433(3,4)",
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-20 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32433(5-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32436(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32436(1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 32437,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 32438,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32439(0,1-3,5,6,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32439(4,7,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3244(7,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32440(2,7-9)",
      "Operator (nazwa)": "Leon Sp. z o.o.",
      "Operator (ID)": 1471,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32440(4,5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-06-15 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324400,
      "Operator (nazwa)": "G-NET Tomasz Serwatka, Wojciech Rakoniewski Sp. J.",
      "Operator (ID)": 5635,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324401,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324403,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324406,
      "Operator (nazwa)": "Virtual Operator Sp. z o.o.",
      "Operator (ID)": 13394,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-06-30 12:45"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32441(2,8)",
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324410,
      "Operator (nazwa)": "MIRASOFT Sp. z o.o.",
      "Operator (ID)": 9298,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-03-01 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324411,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-01 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324413,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-03-01 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324414,
      "Operator (nazwa)": "Rybnet Sp. z o.o. Sp. K.",
      "Operator (ID)": 8598,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324415,
      "Operator (nazwa)": "Televoice Polska Sp. z o.o.",
      "Operator (ID)": 8808,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324416,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324417,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324419,
      "Operator (nazwa)": "E-CALL POLSKA Sp. z o.o.",
      "Operator (ID)": 9331,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32442(1,7)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32442(2-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324420,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324428,
      "Operator (nazwa)": "G-NET Tomasz Serwatka, Wojciech Rakoniewski Sp. J.",
      "Operator (ID)": 5635,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324429,
      "Operator (nazwa)": "ARPNET Wojciech Chodacki",
      "Operator (ID)": 7128,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 32443,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32444(0,1,3,4)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-13 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324442,
      "Operator (nazwa)": "�l�ska Sie� Metropolitalna Sp. z o.o.",
      "Operator (ID)": 8784,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-08-26 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324445,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2024-01-15 12:45"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324446,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324447,
      "Operator (nazwa)": "Galena Sp. z o.o.",
      "Operator (ID)": 66,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-16 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324448,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324449,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32445(0,2-4,8)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32445(1,7)",
      "Operator (nazwa)": "VOXNET Sp. z o.o. Sp. k.",
      "Operator (ID)": 728,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324455,
      "Operator (nazwa)": "K2M Sp. z o.o.",
      "Operator (ID)": 13135,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-30 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324456,
      "Operator (nazwa)": "Z4O  Sp. z o.o.",
      "Operator (ID)": 13000,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-03-31 12:45"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324459,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-02-01 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32446(0,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32446(5,7)",
      "Operator (nazwa)": "e-Telko Sp. z o.o.",
      "Operator (ID)": 11564,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324461,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324462,
      "Operator (nazwa)": "ITEL Solutions Sp. z o.o.",
      "Operator (ID)": 11274,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-01-01 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324463,
      "Operator (nazwa)": "BT Poland Sp. z o.o.",
      "Operator (ID)": 109,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-03 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324464,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324466,
      "Operator (nazwa)": "Leon Sp. z o.o.",
      "Operator (ID)": 1471,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324468,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32449(0,1-4,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32449(5-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3245(1,5-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32450(2,3)",
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324500,
      "Operator (nazwa)": "STRONG-PC Tomasz Piekarski",
      "Operator (ID)": 1924,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324501,
      "Operator (nazwa)": "ITH Sp. Z o.o.",
      "Operator (ID)": 12635,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-03-14 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324504,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-06-10 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324505,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324506,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "Racib�rz",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324507,
      "Operator (nazwa)": "FORWEB S.C. Monika Bodetko Tomasz Paw�owski",
      "Operator (ID)": 5731,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324508,
      "Operator (nazwa)": "MIDIKO Sp. z o.o.",
      "Operator (ID)": 4990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324509,
      "Operator (nazwa)": "Hyperion S.A.",
      "Operator (ID)": 329,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 32452,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32453(0,1,2,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32453(3-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32454(0,1-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324548,
      "Operator (nazwa)": "STRONG-PC Tomasz Piekarski",
      "Operator (ID)": 1924,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32458(0,9)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324581,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "Bytom",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324582,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "Czelad�",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324583,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "Ruda �l�ska",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324584,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "Rybnik",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324585,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "�wi�toch�owice",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324586,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "Tarnowskie G�ry",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324587,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "Zabrze",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324588,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "�ory",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32459(0,1-5,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32459(6,8,9)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-11-24 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3246(0,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3246(3,4)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3246(5-7)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32461(1-8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324610,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324619,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32462(0,1-3)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32462(4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32469(0,1-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324697,
      "Operator (nazwa)": "Telnyx Ireland Limited",
      "Operator (ID)": 13021,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-01 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3247(0,1-3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32474(0,1,3-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32474(2,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32475(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324750,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-03-10 12:45"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32476(0,1-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324768,
      "Operator (nazwa)": "MIDIKO Sp. z o.o.",
      "Operator (ID)": 4990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32477(0,2-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32477(1,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32478(0,1-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324786,
      "Operator (nazwa)": "Arkadiusz Ko�ma FIRMA HANDLOWA \"GIGA",
      "Operator (ID)": 5561,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 32479,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 3248,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3249(0-2,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-15 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32493(1-3)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324930,
      "Operator (nazwa)": "NDI Us�ugi komputerowe Tomasz Krzysztofik",
      "Operator (ID)": 3331,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-10-01 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324934,
      "Operator (nazwa)": "SMSWIZARD POLSKA Sp. z o.o.",
      "Operator (ID)": 12975,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-06 12:45"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324935,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-01-02 12:20"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324936,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-12 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324937,
      "Operator (nazwa)": "M3.NET Sp. z o.o.",
      "Operator (ID)": 10099,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324938,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324939,
      "Operator (nazwa)": "Marat Sp. z o.o.",
      "Operator (ID)": 8620,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32494(0,3,8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32494(1,2)",
      "Operator (nazwa)": "ITCOMP Sp. z o.o.",
      "Operator (ID)": 9722,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324944,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324945,
      "Operator (nazwa)": "BETASOFT Sp. z o.o.",
      "Operator (ID)": 5310,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324946,
      "Operator (nazwa)": "PRZEDSI�BIORSTWO AJC Adam Ozga Przedsi�biorstwo AJC2",
      "Operator (ID)": 390,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324947,
      "Operator (nazwa)": "Nettelekom GK Sp. z o.o.",
      "Operator (ID)": 9896,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 324949,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 32495,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32496(0,8-9)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-13 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32496(1-7)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-15 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "325(1,2,4,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3250(1-5,7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32500(2-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-01-12 12:45"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 325000,
      "Operator (nazwa)": "Spikon Sp. z o.o.",
      "Operator (ID)": 10784,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-09-17 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 325001,
      "Operator (nazwa)": "BIM-RS Sp. z o.o.",
      "Operator (ID)": 13392,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-01-12 12:45"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 325005,
      "Operator (nazwa)": "Leon Sp. z o.o.",
      "Operator (ID)": 1471,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32506(0,1-4,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32506(5,6)",
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 32508,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3253(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32532(1-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-03-19 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 325320,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 325325,
      "Operator (nazwa)": "MICROCHIP S.C.",
      "Operator (ID)": 5367,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-03-19 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3255(0,1-4,6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32555(1-4,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-07-03 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 325550,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 325555,
      "Operator (nazwa)": "Centrum Us�ug Komputerowych GROT Sp. z o.o.",
      "Operator (ID)": 1427,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-12-29 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 325556,
      "Operator (nazwa)": "ATMEL Sp. z o.o.",
      "Operator (ID)": 9777,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-07-03 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32557(1-6)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-04 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32557(8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-01-04 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 325570,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-01-04 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 325577,
      "Operator (nazwa)": "OBS�UGA PC S.C.",
      "Operator (ID)": 10402,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3256(0,1-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32569(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 325692,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "326(5,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3260(3-4,6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32600(1,2)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32600(3-5,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-11-09 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 326000,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 326006,
      "Operator (nazwa)": "Leon Sp. z o.o.",
      "Operator (ID)": 1471,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 326007,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-10-29 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 326008,
      "Operator (nazwa)": "LoVo Sp. z o.o.",
      "Operator (ID)": 7184,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-11-09 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32601(0-2,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 326013,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-10-01 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 326014,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-07-31 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32602(0-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 326026,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32605(0-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 326058,
      "Operator (nazwa)": "ENTER T&T Sp. z o.o.",
      "Operator (ID)": 10688,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-12-16 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 326059,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32607(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 326079,
      "Operator (nazwa)": "ENTER T&T Sp. z o.o.",
      "Operator (ID)": 10688,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-12-16 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32610(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 326100,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32611(0,1,5,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32611(2-4,6-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32612(0,1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32612(3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32613(0,2,3,6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32613(1,4,5,7,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32614(0,1-3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32614(4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32615(0,1,2,5-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32615(3,4,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32616(0,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32616(1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32617(0,1-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 326177,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32618(0,1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32618(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 32619,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3262(3,7,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32620(0,1)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32620(2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 32621,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32622(0,1-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32622(7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32624(0-2,5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32624(3,4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 326246,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32625(0,1,7,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32625(2-6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32626(0,1,3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32626(2,4-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-04-04 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 326266,
      "Operator (nazwa)": "SUDER Sp. z o.o.",
      "Operator (ID)": 10822,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-04-04 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 32629,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3263(1,2)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3263(3,5-6,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-12-06 12:45"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32630(1,3)",
      "Operator (nazwa)": "DEBACOM Sp. z o.o.",
      "Operator (ID)": 3239,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 326300,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-01 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 326302,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 326304,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 326305,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-03-10 12:45"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 326306,
      "Operator (nazwa)": "Focus Telecom Polska Sp. z o.o.",
      "Operator (ID)": 8974,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 326307,
      "Operator (nazwa)": "PASJO.NET HAIDER CZEMPIK SP.J.",
      "Operator (ID)": 3192,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 326308,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-12-15 12:45"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 326309,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-01 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32634(0,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32634(1-2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 326343,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32637(0,1,3-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-12-06 12:45"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 326372,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-12-06 12:45"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32639(0,4-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32639(2,3)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 326391,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3264(0,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3264(5,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32641(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32641(1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32642(0,1-4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 326425,
      "Operator (nazwa)": "Focus Telecom Polska Sp. z o.o.",
      "Operator (ID)": 8974,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-01 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32643(0,1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32643(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32644(0,1,2,7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32644(3-6,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32646(0,1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32646(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32649(0,1-4,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32649(5,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3266(1-3)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3266(7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32660(0,1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32660(3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32664(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32664(1-5)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32665(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 326651,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32666(7,8)",
      "Operator (nazwa)": "LoVo Sp. z o.o.",
      "Operator (ID)": 7184,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 326660,
      "Operator (nazwa)": "FIONE Sp. z o.o. Sp. k.",
      "Operator (ID)": 11156,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-05-01 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 326661,
      "Operator (nazwa)": "K3 Telecom Sp. z o.o.",
      "Operator (ID)": 1639,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 326662,
      "Operator (nazwa)": "Club Net - Fuja, Gincelis i P�o�ski Sp. j.",
      "Operator (ID)": 4698,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 326663,
      "Operator (nazwa)": "NETICO Micha� Pielorz",
      "Operator (ID)": 5621,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 326664,
      "Operator (nazwa)": "Biuro Us�ug Komputerowo-Internetowych MANIERA SERVICE Alicja Maniera",
      "Operator (ID)": 705,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 326665,
      "Operator (nazwa)": "ETTH Bartosz Bachowski",
      "Operator (ID)": 10249,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 326666,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-09-01 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 326669,
      "Operator (nazwa)": "TELESIM Sp. z o.o.",
      "Operator (ID)": 11426,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-12-10 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32668(0,1-5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32668(6-9)",
      "Operator (nazwa)": "PASJO.NET HAIDER CZEMPIK SP.J.",
      "Operator (ID)": 3192,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3267(6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32670(0,1-3,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 326704,
      "Operator (nazwa)": "ATMAN Sp. z o.o.",
      "Operator (ID)": 944,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 32671,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32672(0,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32672(1-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32673(0-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 326739,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32674(0,1-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32674(5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32675(0,1,2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 326753,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32677(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32677(1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32678(0,1-3,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32678(4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3268(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32680(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 326800,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3270(2,4)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32700(0,8-9)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32700(4,5)",
      "Operator (nazwa)": "TOYA Sp. z o.o.",
      "Operator (ID)": 127,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327001,
      "Operator (nazwa)": "TELPOL PPMUE Jerzy Krempa",
      "Operator (ID)": 108,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327002,
      "Operator (nazwa)": "ATMAN Sp. z o.o.",
      "Operator (ID)": 944,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327003,
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327006,
      "Operator (nazwa)": "TIMPLUS B. Dudek, R. Walentowski sp�ka jawna",
      "Operator (ID)": 3110,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327007,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32701(0,9)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32701(1-8)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32703(0,9)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32703(1-8)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 32705,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327060,
      "Operator (nazwa)": "GBN.PL Sp. z o.o.",
      "Operator (ID)": 11606,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-06-23 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327061,
      "Operator (nazwa)": "THULIUM Sp. z o.o.",
      "Operator (ID)": 13433,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-16 12:45"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327062,
      "Operator (nazwa)": "PPHU LUKSUS Grzegorz W�och",
      "Operator (ID)": 466,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327063,
      "Operator (nazwa)": "PL Telekom Sp. z o.o.",
      "Operator (ID)": 10217,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327064,
      "Operator (nazwa)": "S-NET Sp. z o.o.",
      "Operator (ID)": 8697,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327065,
      "Operator (nazwa)": "INTERKONEKT Pawe� Barczyk Tomasz Furman Sp�ka Jawna",
      "Operator (ID)": 7541,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327066,
      "Operator (nazwa)": "GRAKOM Andrzej Liszka",
      "Operator (ID)": 1003,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327067,
      "Operator (nazwa)": "Syrion Sp. z o.o.",
      "Operator (ID)": 7883,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-12 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327068,
      "Operator (nazwa)": "TELPOL PPMUE Jerzy Krempa",
      "Operator (ID)": 108,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327069,
      "Operator (nazwa)": "Sileman Sp. z o.o.",
      "Operator (ID)": 326,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32707(2,3)",
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327070,
      "Operator (nazwa)": "Media-Com Sp. z o.o.",
      "Operator (ID)": 2161,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327071,
      "Operator (nazwa)": "NETCENTRICA Sp. z o.o.",
      "Operator (ID)": 7087,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327074,
      "Operator (nazwa)": "Sileman Sp. z o.o.",
      "Operator (ID)": 326,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327075,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327076,
      "Operator (nazwa)": "Sileman Sp. z o.o.",
      "Operator (ID)": 326,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-23 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327077,
      "Operator (nazwa)": "ENTER T&T Sp. z o.o.",
      "Operator (ID)": 10688,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-08-03 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327078,
      "Operator (nazwa)": "ENTER T&T Sp. z o.o.",
      "Operator (ID)": 10688,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-11-01 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327079,
      "Operator (nazwa)": "Orientelco Sp. z o.o.",
      "Operator (ID)": 12993,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-08-05 12:46"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32708(1-6)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327080,
      "Operator (nazwa)": "Beskid Media Sp. z o.o.",
      "Operator (ID)": 9501,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-02-08 12:45"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327087,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327088,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-08-07 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327089,
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32709(0,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32709(1-2)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3271(0,9)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32711(0,2-6,8-9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32711(1,7)",
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32712(0,2-3,7-9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32712(4-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-07-13 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327121,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32713(0,5-9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-07-13 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32713(1-4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-07-13 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32714(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32714(1-3,5)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327144,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-04 12:46"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32715(0,4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32715(1-3,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32716(0-4,6-9)",
      "Operator (nazwa)": "Polska Grupa G�rnicza S.A.",
      "Operator (ID)": 11375,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-12-31 12:20"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327165,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-12-31 12:20"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32717(0,2-5,7-8)",
      "Operator (nazwa)": "Polska Grupa G�rnicza S.A.",
      "Operator (ID)": 11375,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-09-20 12:20"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32717(1,9)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-09-08 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327176,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-09-20 12:20"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32718(1-4)",
      "Operator (nazwa)": "Polska Grupa G�rnicza S.A.",
      "Operator (ID)": 11375,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-09-20 12:20"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327180,
      "Operator (nazwa)": "Webteam Telekomunikacja Sp. z o.o. Sp.k.",
      "Operator (ID)": 760,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327185,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-09-20 12:20"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327186,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327187,
      "Operator (nazwa)": "ENTER T&T Sp. z o.o.",
      "Operator (ID)": 10688,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327188,
      "Operator (nazwa)": "Telkonekt Sp. z o.o.",
      "Operator (ID)": 9086,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-06-14 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327189,
      "Operator (nazwa)": "CLUDO Sp z o.o.",
      "Operator (ID)": 10056,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-02-01 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3272(4,5)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32720(0,1,3-5,7-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32720(2,6)",
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-24 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32721(0,1-7,9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327218,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-24 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32722(1-3)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327220,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327224,
      "Operator (nazwa)": "I-TEL Sp. z o.o.",
      "Operator (ID)": 6768,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327225,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-10-30 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327226,
      "Operator (nazwa)": "SFERANET S.A.",
      "Operator (ID)": 4882,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327227,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327228,
      "Operator (nazwa)": "Optic Telecom Sp. z o.o.",
      "Operator (ID)": 11419,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-12-01 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327229,
      "Operator (nazwa)": "Syrion Sp. z o.o.",
      "Operator (ID)": 7883,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32723(0,4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32723(1,3)",
      "Operator (nazwa)": "Syrion Sp. z o.o.",
      "Operator (ID)": 7883,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327232,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327235,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32726(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32726(1-7)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32727(1,2,5)",
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32727(6,7)",
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327270,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327273,
      "Operator (nazwa)": "FREELY Sp. z o.o.",
      "Operator (ID)": 13143,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327274,
      "Operator (nazwa)": "e-Telko Sp. z o.o.",
      "Operator (ID)": 11564,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327278,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327279,
      "Operator (nazwa)": "F-NET Sp. z o.o.",
      "Operator (ID)": 11353,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-06 12:45"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 32728,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "Katowice",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32729(0,7)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32729(1-4)",
      "Operator (nazwa)": "Polska Grupa G�rnicza S.A.",
      "Operator (ID)": 11375,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-09-01 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327295,
      "Operator (nazwa)": "ELCUK Sp. z o.o.",
      "Operator (ID)": 13049,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-05-29 12:45"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327296,
      "Operator (nazwa)": "F.H.U. MARTCOM Mirela Bugdol",
      "Operator (ID)": 11912,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-09-10 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327298,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327299,
      "Operator (nazwa)": "VOXNET Sp. z o.o. Sp. k.",
      "Operator (ID)": 728,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3273(0,2-3,5-8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32731(0-7,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327318,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32734(0,5,6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32734(1-4,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32739(1,6)",
      "Operator (nazwa)": "Leon Sp. z o.o.",
      "Operator (ID)": 1471,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "Rybnik",
      "Data modyfikacji": "2018-03-26 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32739(2-5,7,8)",
      "Operator (nazwa)": "Polska Grupa G�rnicza S.A.",
      "Operator (ID)": 11375,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-09-01 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327390,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327399,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "Katowice",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3274(7-9)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32740(1-9)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327400,
      "Operator (nazwa)": "Nettelekom GK Sp. z o.o.",
      "Operator (ID)": 9896,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 32741,
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32742(0,1-5,8-9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32742(6,7)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32743(0,3,4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32743(1-2,9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32743(5-8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32744(1-3,5-8)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327440,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-09-03 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327444,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327449,
      "Operator (nazwa)": "ENTER T&T Sp. z o.o.",
      "Operator (ID)": 10688,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-12-16 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32745(1-4)",
      "Operator (nazwa)": "ART-COM Sp. z o.o.",
      "Operator (ID)": 7817,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32745(7-9)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327450,
      "Operator (nazwa)": "Systel Systemy Teleinformatyczne Sp. z o. o.",
      "Operator (ID)": 1894,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327455,
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327456,
      "Operator (nazwa)": "NFON GmbH",
      "Operator (ID)": 11163,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 32746,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3275(1-6,8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32750(0,5,8)",
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-24 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32750(1-4,6,7,9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32757(3-5)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32757(8,9)",
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327570,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327571,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327572,
      "Operator (nazwa)": "Polska Grupa G�rnicza S.A.",
      "Operator (ID)": 11375,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-09-01 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327576,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327577,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "Racib�rz",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32759(0,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32759(1,3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327592,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3276(1-2,4-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32760(0-2,4-5,7-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327603,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327606,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32763(1-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327630,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3277(0-3,5,7-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32774(0-7)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32774(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32776(0-4,6-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327765,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3278(0-2,4-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32783(0,3)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32783(1-2,4-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3279(6,9)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32790(0,6-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32790(2,4,5)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327901,
      "Operator (nazwa)": "AMS SYSTEM Sp. z o.o.",
      "Operator (ID)": 7006,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "Katowice",
      "Data modyfikacji": "2016-03-08 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327903,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "Katowice",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32791(0,1)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "Katowice",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32791(2-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327919,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-10-21 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32792(0,1,2,4-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32792(3,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 32793,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32794(0,1-4,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32794(5,6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32795(0,1-4,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327955,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327956,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32797(0,9)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32797(3-5)",
      "Operator (nazwa)": "Sileman Sp. z o.o.",
      "Operator (ID)": 326,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327971,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327972,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327976,
      "Operator (nazwa)": "IPTELL Sp. z o.o.",
      "Operator (ID)": 7201,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327977,
      "Operator (nazwa)": "Miconet Sp. z o.o.",
      "Operator (ID)": 8065,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 327978,
      "Operator (nazwa)": "Intelligent Technologies S.A.",
      "Operator (ID)": 748,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32798(0,8-9)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-03-26 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32798(1-7)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-02-22 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "328(2,4-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3280(0,6-9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32801(0,8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32801(1,6,7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-01-06 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32801(2,3)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32801(4,5,9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32802(0-1,3,8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32802(4,6,7,9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 328022,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-01-06 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 328025,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32803(0,4)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32803(3,6)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32803(5,8,9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 328031,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-01-06 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 328032,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 328037,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32804(0,3)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32804(1,7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32804(2,4)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32804(5,6)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32804(8,9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-01-06 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32805(1,6,8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32805(5,7,9,0)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 328052,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 328053,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-03 12:45"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 328054,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3281(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32810(3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-11-10 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 328100,
      "Operator (nazwa)": "MULTIPLAY Sp. z o.o. Sp. k.",
      "Operator (ID)": 10068,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-10-07 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 328101,
      "Operator (nazwa)": "MICROCHIP S.C.",
      "Operator (ID)": 5367,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 328102,
      "Operator (nazwa)": "MICROCHIP S.C.",
      "Operator (ID)": 5367,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-11-10 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3283(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 32832,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "3288(1-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32880(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 328800,
      "Operator (nazwa)": "SUDER Sp. z o.o.",
      "Operator (ID)": 10822,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 32888,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "32889(1-4,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-11-30 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 328890,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-10-29 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 328895,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-11-30 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 328896,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 328898,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": 328899,
      "Operator (nazwa)": "SUDER Sp. z o.o.",
      "Operator (ID)": 10822,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-08 12:15"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "329(0,1-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 32,
      "Zakres (number)": "329(8,9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "KATOWICE",
      "Strefa (symbol)": "SN-KA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33(2,6,7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "331(0,1-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 3319,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "3330(1-2,4-6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-22 12:20"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33300(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-22 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 333000,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 333001,
      "Operator (nazwa)": "ATMAN Sp. z o.o.",
      "Operator (ID)": 944,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 333002,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-08-07 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 333003,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 333004,
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-12 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 333005,
      "Operator (nazwa)": "CLUDO Sp z o.o.",
      "Operator (ID)": 10056,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-02-01 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 33303,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-22 12:20"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33307(1-5)",
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33307(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 333070,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33308(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 333082,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "3331(3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-01-22 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33310(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-01-22 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 333101,
      "Operator (nazwa)": "MEDIDESK Sp. z o.o.",
      "Operator (ID)": 12669,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-01-22 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33311(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-08-04 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33311(3-5)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 333111,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 333112,
      "Operator (nazwa)": "K3 Telecom Sp. z o.o.",
      "Operator (ID)": 1639,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 333116,
      "Operator (nazwa)": "Media-Com Sp. z o.o.",
      "Operator (ID)": 2161,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-08-08 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 333117,
      "Operator (nazwa)": "PHU SKRANETCAN Macura Zbigniew",
      "Operator (ID)": 8156,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-08-04 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33312(0,2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-12 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 333121,
      "Operator (nazwa)": "TOYA Sp. z o.o.",
      "Operator (ID)": 127,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-12 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 333123,
      "Operator (nazwa)": "INFODATA S.A.",
      "Operator (ID)": 12302,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-20 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "3332(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33322(0,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-03-16 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 333221,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 333222,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 333223,
      "Operator (nazwa)": "Telfon Poland Sp. z o.o.",
      "Operator (ID)": 12517,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-03-07 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 333224,
      "Operator (nazwa)": "AIR-NET Mariusz Kajdas, Tomasz Pyrek Sp�ka Jawna",
      "Operator (ID)": 9666,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-03-16 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "3333(1-2,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-11-21 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 33330,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 333330,
      "Operator (nazwa)": "HyperNET Sp. z o.o.",
      "Operator (ID)": 8619,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 333331,
      "Operator (nazwa)": "I-TEL Sp. z o.o.",
      "Operator (ID)": 6768,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 333332,
      "Operator (nazwa)": "IPTELL Sp. z o.o.",
      "Operator (ID)": 7201,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 333333,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 333334,
      "Operator (nazwa)": "Polineo Sp. z o.o.",
      "Operator (ID)": 11499,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-08-03 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 333335,
      "Operator (nazwa)": "HyperNET Sp. z o.o.",
      "Operator (ID)": 8619,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-12-30 12:20"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 333336,
      "Operator (nazwa)": "INVICOM Sp. z o.o.",
      "Operator (ID)": 8868,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 333337,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 333338,
      "Operator (nazwa)": "Beskid Media Sp. z o.o.",
      "Operator (ID)": 9501,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-08 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 333339,
      "Operator (nazwa)": "M3.NET Sp. z o.o.",
      "Operator (ID)": 10099,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33334(0-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-11-21 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 333344,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-11-21 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "3334(0,1-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33344(0,1-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 333444,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "3335(0,1-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33355(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-19 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33355(1,2)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-01-02 12:20"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 333553,
      "Operator (nazwa)": "NETUS Renata Gieruszczak-Fikus",
      "Operator (ID)": 9984,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-08 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 333554,
      "Operator (nazwa)": "DIDWW Ireland Limited",
      "Operator (ID)": 12132,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-19 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 333555,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "3336(0,1-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33366(0-5,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-11-19 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 333666,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 333667,
      "Operator (nazwa)": "SFERANET S.A.",
      "Operator (ID)": 4882,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-11-19 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "3337(0,1-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33377(0,4-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-05-14 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33377(1,2)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2023-12-15 12:45"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 333773,
      "Operator (nazwa)": "Debesis Sp. z o.o.",
      "Operator (ID)": 10138,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-12-29 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 333777,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "3338(0,1-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33388(0,1-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 333888,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "3339(0,1-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33399(1-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-09-29 12:45"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33399(7,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-17 12:46"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 333990,
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-20 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 333999,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "334(1,2,5,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "3340(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33400(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334000,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "3343(0-1,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33432(0,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334321,
      "Operator (nazwa)": "LUPRO Sp. z o.o.",
      "Operator (ID)": 5300,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334322,
      "Operator (nazwa)": "Beskid Media Sp. z o.o.",
      "Operator (ID)": 9501,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33433(0-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334334,
      "Operator (nazwa)": "Telfon Poland Sp. z o.o.",
      "Operator (ID)": 12517,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "3344(1,2,6,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33440(0,3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334401,
      "Operator (nazwa)": "ARKOM Adam Mucharski",
      "Operator (ID)": 5882,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-06-20 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334402,
      "Operator (nazwa)": "Telnyx Ireland Limited",
      "Operator (ID)": 13021,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-01 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334404,
      "Operator (nazwa)": "Leon Sp. z o.o.",
      "Operator (ID)": 1471,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33443(1,5)",
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33443(3,6)",
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334430,
      "Operator (nazwa)": "didXL B.V.",
      "Operator (ID)": 13079,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2021-10-22 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334432,
      "Operator (nazwa)": "SFERANET S.A.",
      "Operator (ID)": 4882,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334434,
      "Operator (nazwa)": "SFERANET S.A.",
      "Operator (ID)": 4882,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334437,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334438,
      "Operator (nazwa)": "FREELY Sp. z o.o.",
      "Operator (ID)": 13143,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334439,
      "Operator (nazwa)": "FIBRE TECH S.A.",
      "Operator (ID)": 11853,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-12-13 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33444(0,8-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33444(3,4)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334441,
      "Operator (nazwa)": "Intelligent Technologies S.A.",
      "Operator (ID)": 748,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334442,
      "Operator (nazwa)": "Galena Sp. z o.o.",
      "Operator (ID)": 66,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-16 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334445,
      "Operator (nazwa)": "TIMPLUS B. Dudek, R. Walentowski sp�ka jawna",
      "Operator (ID)": 3110,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334446,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334447,
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334450,
      "Operator (nazwa)": "ENTER T&T Sp. z o.o.",
      "Operator (ID)": 10688,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-08-03 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334451,
      "Operator (nazwa)": "PRZEDSI�BIORSTWO AJC Adam Ozga Przedsi�biorstwo AJC2",
      "Operator (ID)": 390,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334452,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334453,
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334454,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334455,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-07-13 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334456,
      "Operator (nazwa)": "Globus Telecom Sp. z o.o.",
      "Operator (ID)": 13120,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-12-01 12:45"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334457,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334458,
      "Operator (nazwa)": "E-CALL POLSKA Sp. z o.o.",
      "Operator (ID)": 9331,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334459,
      "Operator (nazwa)": "SFERANET S.A.",
      "Operator (ID)": 4882,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-11-17 12:45"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33447(0-1,3,5-6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-12-06 12:45"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33447(2,7,8)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-12-06 12:45"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334474,
      "Operator (nazwa)": "Promarket Computers Sp. z o.o.",
      "Operator (ID)": 3866,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "3347(3,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 33470,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "Wadowice",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33471(0,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33471(1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33472(0,1-8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334729,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-11-30 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 33474,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:52"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33475(0,1,3-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "�ywiec",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334752,
      "Operator (nazwa)": "SPEED-NET Arkadiusz Broniecki",
      "Operator (ID)": 7040,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33476(2,3)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33476(5,7-9)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334760,
      "Operator (nazwa)": "NETICO Micha� Pielorz",
      "Operator (ID)": 5621,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334761,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334764,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334766,
      "Operator (nazwa)": "WadowiceNET Sp. Z o.o.",
      "Operator (ID)": 10234,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-06-30 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33477(0,1,7-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "Cieszyn",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33477(3-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-09-29 12:45"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334772,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-17 12:45"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 33479,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "Cieszyn",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 33480,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33481(0,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33481(1,6)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33481(2-5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33482(1,5)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "Bielsko-Bia�a",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33482(2,6)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "O�wi�cim",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334820,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334823,
      "Operator (nazwa)": "Platan Sp. z o.o. Sp. k.",
      "Operator (ID)": 12733,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-02-14 12:45"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334824,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334827,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-04 12:45"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334828,
      "Operator (nazwa)": "Hyperion S.A.",
      "Operator (ID)": 329,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334829,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33483(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33483(1-5)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33484(0-7,9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-30 12:45"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334848,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-30 12:45"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33485(1,2,4,5)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33485(3,6,8-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334850,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334857,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33486(0,8)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33486(1-3)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33486(5,6)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334864,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-01 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334867,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334869,
      "Operator (nazwa)": "VOXNET Sp. z o.o. Sp. k.",
      "Operator (ID)": 728,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33487(1-2,4-5,7,9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334870,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334873,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334876,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334878,
      "Operator (nazwa)": "CYBERIO Sp. z o.o.",
      "Operator (ID)": 12750,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-02-07 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33488(0,2,5,8-9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33488(1,3,6,7)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334884,
      "Operator (nazwa)": "ITH Sp. Z o.o.",
      "Operator (ID)": 12635,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-10 12:45"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33489(0,6-9)",
      "Operator (nazwa)": "TeleGo sp. z o.o.",
      "Operator (ID)": 9324,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-09-25 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33489(1-3,5)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 334894,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-04 12:46"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "3349(0,1-5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "3349(6-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "Bielsko-Bia�a",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "335(1-2,4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-02-16 12:45"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "3350(1-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33500(1-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 335000,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 335005,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33506(0-3,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-10-02 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33506(5,6)",
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 335064,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-10-02 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "3353(0,1,2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33533(0,1-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 335335,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:46"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "3355(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-02-16 12:45"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33555(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-02-16 12:45"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 335555,
      "Operator (nazwa)": "SUDER Sp. z o.o.",
      "Operator (ID)": 10822,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-02-16 12:45"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "3380(0,6-9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "3380(4,5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33801(0,6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-03 12:45"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33801(2,5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33801(3,7-9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 338011,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-03 12:45"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 338014,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33802(0,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33802(1-4,6-9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33803(1-9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 338030,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 3381,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "3382(0,4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "3382(1,2,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33823(0,1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33823(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33825(0-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-08 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33825(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 338255,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-08 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33826(0,1-3,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33826(4-6,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "3383(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-03 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33833(1-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-10-05 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33833(8,9)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-03 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 338330,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-10-05 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 338337,
      "Operator (nazwa)": "Polineo Sp. z o.o.",
      "Operator (ID)": 11499,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-08-11 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "3384(0,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "3384(2-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33841(0,1-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33841(5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33846(1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33846(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 338460,
      "Operator (nazwa)": "Dialoga Servicios Interactivos S.A.",
      "Operator (ID)": 10930,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33847(0,1,6-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33847(2-5,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33848(0,1-4,7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33848(5,6,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "3385(0,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "3385(1,2,4-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33853(0,1-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 338537,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-01-20 12:45"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "3386(1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33860(0,1-6,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 338607,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-24 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 338608,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-10-01 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33863(0,1-4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 338635,
      "Operator (nazwa)": "EURO.NET.PL Sp. z o.o.",
      "Operator (ID)": 11142,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-11-29 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33864(0,1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33864(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33865(1-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33865(5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 338650,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33866(0,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33866(1-2,4-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-08 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 338663,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-08 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33867(0-2,4-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-08 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33867(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 338673,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-08 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33868(0,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33868(2,3)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 338681,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 33869,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "3387(1,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "3387(2-5,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33870(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 338700,
      "Operator (nazwa)": "THULIUM Sp. z o.o.",
      "Operator (ID)": 13433,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-16 12:45"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33876(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 338769,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33877(0,1-6,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 338777,
      "Operator (nazwa)": "INTER O�WI�CIM Daniel Ku�as",
      "Operator (ID)": 9140,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 338778,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "3388(0,1-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33888(0,3-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33888(1,2)",
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 338888,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "33889(0-4,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-11-30 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 338895,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-11-30 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 338896,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": 3389,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-03 12:15"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "339(0,1-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 33,
      "Zakres (number)": "339(8,9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "BIELSKO-BIA�A",
      "Strefa (symbol)": "SN-BB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34(2,4,6,7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-08-31 12:45"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "341(0,1-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 3419,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "3430(1-6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34300(0,3)",
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34300(5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-12-29 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343001,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-08-07 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343002,
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-22 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343004,
      "Operator (nazwa)": "MEDIDESK Sp. z o.o.",
      "Operator (ID)": 12669,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-12-29 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34307(4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343070,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343071,
      "Operator (nazwa)": "ITCOMP Sp. z o.o.",
      "Operator (ID)": 9722,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343072,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343073,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-09-06 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34308(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34308(3-7)",
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343081,
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-12 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343082,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "3431(3-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34310(0,1-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343108,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34311(0,1-4,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343115,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-11-15 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343116,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343117,
      "Operator (nazwa)": "ENTER T&T Sp. z o.o.",
      "Operator (ID)": 10688,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 34312,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34316(0,1,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34316(2-4)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34316(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "3432(1,3-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34320(0,1-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343207,
      "Operator (nazwa)": "SYSTEMY TELE-INFORMATYCZNE \"INGRAM\" Tomasz Koperski",
      "Operator (ID)": 1438,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-10-01 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34322(0,1-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343228,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "3433(0-2,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-03 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34333(0,1,4,8)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34333(2,3)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343335,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343336,
      "Operator (nazwa)": "@ALFANET M. Ma�olepszy",
      "Operator (ID)": 163,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343337,
      "Operator (nazwa)": "Nettelekom GK Sp. z o.o.",
      "Operator (ID)": 9896,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343339,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34334(0-1,5-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2023-09-29 12:45"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34334(3,4)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-03 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343342,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-10-05 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343348,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2023-11-17 12:46"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34340(4-6)",
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343400,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-01 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343401,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343402,
      "Operator (nazwa)": "Syrion Sp. z o.o.",
      "Operator (ID)": 7883,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-12 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343403,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-07-01 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343407,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-12-18 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343408,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343409,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34341(1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343410,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343418,
      "Operator (nazwa)": "Cyfrowe Systemy Telekomunikacyjne Sp. z o.o.",
      "Operator (ID)": 1058,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-31 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343419,
      "Operator (nazwa)": "Galena Sp. z o.o.",
      "Operator (ID)": 66,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-16 12:16"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34342(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34342(1-5)",
      "Operator (nazwa)": "TeleGo sp. z o.o.",
      "Operator (ID)": 9324,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34343(0-2,4,7)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343433,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-11-11 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343435,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343436,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343438,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343439,
      "Operator (nazwa)": "FREELY Sp. z o.o.",
      "Operator (ID)": 13143,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34344(0,6-8)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34344(2-5,9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343441,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34345(0,1-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34345(8,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34346(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343469,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34347(0,2-8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343471,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343479,
      "Operator (nazwa)": "e-Telko Sp. z o.o.",
      "Operator (ID)": 11564,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-04-19 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 34348,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 34349,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "3435(3-5,7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34350(0,1-6,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343507,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-01-02 12:20"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343508,
      "Operator (nazwa)": "DIDWW Ireland Limited",
      "Operator (ID)": 12132,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-19 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34351(0,1-6,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34351(7,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34352(0-1,4-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-08 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343522,
      "Operator (nazwa)": "ITH Sp. Z o.o.",
      "Operator (ID)": 12635,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-10 12:45"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343523,
      "Operator (nazwa)": "Dialoga Servicios Interactivos S.A.",
      "Operator (ID)": 10930,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343526,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-24 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34356(0,1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34356(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34358(0,5-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34358(2-4,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343581,
      "Operator (nazwa)": "Telnyx Ireland Limited",
      "Operator (ID)": 13021,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-01 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "3436(0-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34367(0,1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34367(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34368(0-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343687,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34369(1-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343690,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343696,
      "Operator (nazwa)": "CLUDO Sp z o.o.",
      "Operator (ID)": 10056,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-02-01 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "3437(0,1,2,7,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34373(7-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343730,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343731,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-12-01 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343732,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343733,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-03-29 12:45"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343734,
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343735,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343736,
      "Operator (nazwa)": "MIDIKO Sp. z o.o.",
      "Operator (ID)": 4990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34374(0,3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-08 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34374(1,2)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-08 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34374(4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-04-21 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34375(1-4)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343750,
      "Operator (nazwa)": "INTERKA Gawin Respondek Sp.J.",
      "Operator (ID)": 3581,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343755,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343756,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-08-15 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343757,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-15 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343758,
      "Operator (nazwa)": "E-CALL POLSKA Sp. z o.o.",
      "Operator (ID)": 9331,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343759,
      "Operator (nazwa)": "TELSAT.TV Sp. z o.o.",
      "Operator (ID)": 199,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-04-30 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34376(0,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34376(1-6)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34379(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343791,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "3438(1,2,4-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-03-08 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 34380,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34383(0-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2023-12-06 12:45"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343838,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2023-12-06 12:45"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343839,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-03-08 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34387(1,2)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34387(3,6)",
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34387(4,5)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-01 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343870,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-12-01 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343877,
      "Operator (nazwa)": "TELSAT.TV Sp. z o.o.",
      "Operator (ID)": 199,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-04-30 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343878,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343879,
      "Operator (nazwa)": "Intelligent Technologies S.A.",
      "Operator (ID)": 748,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34388(0,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343881,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343882,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34389(0,1-3)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34389(5,8)",
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34389(6-7)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343894,
      "Operator (nazwa)": "VOXNET Sp. z o.o. Sp. k.",
      "Operator (ID)": 728,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343899,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "3439(4-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34390(0,2)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34390(5-7)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343901,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343903,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343904,
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343908,
      "Operator (nazwa)": "I-TEL Sp. z o.o.",
      "Operator (ID)": 6768,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343909,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 34391,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-04-21 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34392(0,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343921,
      "Operator (nazwa)": "IPTELL Sp. z o.o.",
      "Operator (ID)": 7201,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343922,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-04-21 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343923,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 34393,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34399(0,6-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34399(1-3,5)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-30 12:45"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343994,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-30 12:45"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 343999,
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-20 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "345(1-2,4,6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-04-04 12:45"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "3450(1-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34500(1-2,4,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-02-08 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 345000,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 345003,
      "Operator (nazwa)": "GT11 Sp. z o.o.",
      "Operator (ID)": 10475,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-02 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 345005,
      "Operator (nazwa)": "Leon Sp. z o.o.",
      "Operator (ID)": 1471,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-02-08 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 345006,
      "Operator (nazwa)": "PRONETEUS Sp. z o.o.",
      "Operator (ID)": 11252,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-11-24 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34506(0,1-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 345065,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "3453(0,1-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34534(0-4,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-10-31 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 345345,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:46"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 345346,
      "Operator (nazwa)": "Multimedia Polska Biznes S.A.",
      "Operator (ID)": 11221,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-10-31 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "3455(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-04-04 12:45"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34555(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-04-04 12:45"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 345555,
      "Operator (nazwa)": "SUDER Sp. z o.o.",
      "Operator (ID)": 10822,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-04-04 12:45"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "3457(0,1-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34576(0,1-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 345765,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:46"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "348(1-2,4-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "3480(0,6-9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "3480(3-5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34801(1,3-5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34801(2,6)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34801(8,9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 348010,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-01-06 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 348017,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34802(0,1-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 348029,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "3483(0-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34834(0-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 348348,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-05 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "3488(0,1-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34888(2-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-08-03 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 348880,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-08-03 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 348881,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 348888,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "34889(0-4,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-11-30 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 348895,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-11-30 12:15"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": 348896,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "349(0,1-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 34,
      "Zakres (number)": "349(8,9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "CZ�STOCHOWA",
      "Strefa (symbol)": "SN-CZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41(4-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "411(0,1-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 4119,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "412(1,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "4120(4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-26 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41200(0,1,2,4-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 412003,
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-12 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41201(1-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 412010,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-12-01 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41202(2-6)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-08-19 12:45"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41202(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-08-19 12:45"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 412020,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 412021,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-06-11 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41203(0,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-12-11 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41203(1,2)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-26 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 412033,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-12-11 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 41205,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-04-01 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "4122(1,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-10-30 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41220(2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-12 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 412200,
      "Operator (nazwa)": "Motomat Group Sp. z o.o.",
      "Operator (ID)": 12553,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-25 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 412201,
      "Operator (nazwa)": "TOYA Sp. z o.o.",
      "Operator (ID)": 127,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-12 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 41222,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41223(0-1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-10-30 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 412232,
      "Operator (nazwa)": "LoVo Sp. z o.o.",
      "Operator (ID)": 7184,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-10-30 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "4123(1,3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41230(5,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 412300,
      "Operator (nazwa)": "PRONETEUS Sp. z o.o.",
      "Operator (ID)": 11252,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-03-09 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 412301,
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-07-12 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 412302,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 412303,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 412304,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2024-05-01 12:45"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 412306,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 412307,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 412308,
      "Operator (nazwa)": "NET-BIS Sp. z o.o. (d. NET-BIS S.C.)",
      "Operator (ID)": 3769,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 41232,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-04-01 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41234(0,6-9)",
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41234(2-4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 412341,
      "Operator (nazwa)": "INTEGRAL Katarzyna Fitas-Antczak",
      "Operator (ID)": 10429,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-13 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 412345,
      "Operator (nazwa)": "INTEGRAL Katarzyna Fitas-Antczak",
      "Operator (ID)": 10429,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "4124(7-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41240(0-6,8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41240(7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41241(1,8,9)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41241(2,4)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-13 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41241(5-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-13 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 412410,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 412413,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41242(0,5,7)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41242(2,3)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41242(4,8,9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 412421,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 412426,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41243(0,2,7-9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41243(4,6)",
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 412431,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-15 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 412433,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 412435,
      "Operator (nazwa)": "VOXNET Sp. z o.o. Sp. k.",
      "Operator (ID)": 728,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41244(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-01-22 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 412441,
      "Operator (nazwa)": "MEDIDESK Sp. z o.o.",
      "Operator (ID)": 12669,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-01-22 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 41245,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41246(0,1-4)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41246(5-7)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41246(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "4125(0,6)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "4125(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41251(0,1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41251(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 41252,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41253(0,1-4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 412535,
      "Operator (nazwa)": "CLUDO Sp z o.o.",
      "Operator (ID)": 10056,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-02-01 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41254(0,1,3-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 412542,
      "Operator (nazwa)": "Dialoga Servicios Interactivos S.A.",
      "Operator (ID)": 10930,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 412547,
      "Operator (nazwa)": "SCARNET Sp. z o.o.",
      "Operator (ID)": 8922,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-12-18 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41255(0,2-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-06-21 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 412551,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 412555,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-30 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "4126(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41260(0,1,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41260(4,5)",
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 412602,
      "Operator (nazwa)": "Galena Sp. z o.o.",
      "Operator (ID)": 66,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-16 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 412603,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-04 12:46"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 412606,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 412607,
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 412608,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41261(0,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41261(1-3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41262(0,1,4-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41262(2,3)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41263(0,1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41263(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41264(0,2,5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41264(1,3,6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 412644,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41265(1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41265(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 412650,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41266(0,1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41266(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41267(0,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41267(1-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "4127(0,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "4127(3-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41271(0,1-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 412717,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41272(0,3,4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41272(1,2,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41276(0,1-3,6,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41276(4,5,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41277(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 412770,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41278(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41278(1-7)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "4130(5,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-02-02 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41300(0,1-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413005,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413006,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-08-07 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413007,
      "Operator (nazwa)": "Virtual Future Sp. z o.o.",
      "Operator (ID)": 11927,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-12-08 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413008,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-03-27 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413009,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41301(0,1,4,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41301(3,5-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-01-03 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413012,
      "Operator (nazwa)": "I-TEL Pawe� B�k",
      "Operator (ID)": 8615,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-01-03 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41302(0,1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413028,
      "Operator (nazwa)": "INTB Sp. z o.o.",
      "Operator (ID)": 11799,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-26 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413029,
      "Operator (nazwa)": "Netcity Sp. z o.o.",
      "Operator (ID)": 4041,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41303(0,1-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413036,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41304(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-02-02 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413040,
      "Operator (nazwa)": "Przedsi�biorstwo Informatyczne FORTES J. W�sik, K. Nizio�ek Sp. J.",
      "Operator (ID)": 8647,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-02-02 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41306(0,1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41306(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413067,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41307(1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41307(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413070,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41308(1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413080,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413082,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "4131(1,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "4131(6,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41310(3-5)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41310(7,8)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413100,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "Kielce",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413101,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413102,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413106,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413109,
      "Operator (nazwa)": "INTEGRAL Katarzyna Fitas-Antczak",
      "Operator (ID)": 10429,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-22 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41312(0,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41312(1-5)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41313(0,8)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "Skar�ysko-Kamienna",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41313(1-5)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413136,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413137,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "Starachowice",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413139,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41314(0,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41314(1-8)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41317(0,1,2,4,5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41317(3,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "4132(0,1,3,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "4132(4-5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-11-01 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41322(1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-10-07 12:45"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41322(4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-10-07 12:45"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413220,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-11-01 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413223,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-09 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "4133(1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "4133(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41330(0,9)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "Kielce",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41330(1-3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41330(5-8)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413304,
      "Operator (nazwa)": "Sieci Blokowe S.C. (Agencja Promocyjna FEMUR Pawe� Wojtal)",
      "Operator (ID)": 3859,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-10-06 12:20"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41333(0,1-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41333(5,6)",
      "Operator (nazwa)": "Nettelekom GK Sp. z o.o.",
      "Operator (ID)": 9896,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41333(7,8)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413339,
      "Operator (nazwa)": "4 Global Networks Service LLC",
      "Operator (ID)": 11261,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-10-13 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41334(0,1-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413348,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-17 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41335(0,2,3,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41335(1,4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41336(3-5)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41336(8,9)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-07-15 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413360,
      "Operator (nazwa)": "ITH Sp. Z o.o.",
      "Operator (ID)": 12635,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2023-06-10 12:45"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413361,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413362,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413366,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413367,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-24 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "4134(3-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41340(0,1-3,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413404,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41341(1,2,5-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41341(8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-08-05 12:20"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413410,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-22 12:20"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413413,
      "Operator (nazwa)": "Telfon Poland Sp. z o.o.",
      "Operator (ID)": 12517,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413414,
      "Operator (nazwa)": "Blue Prospect Sp. z o.o.",
      "Operator (ID)": 13345,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-08-05 12:20"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41342(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413429,
      "Operator (nazwa)": "Intelligent Technologies S.A.",
      "Operator (ID)": 748,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41348(0,1-3,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41348(4-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41349(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413499,
      "Operator (nazwa)": "Telnyx Ireland Limited",
      "Operator (ID)": 13021,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-01 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "4135(2,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41350(0,1-3,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41350(4,6-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413505,
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-20 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41351(0,1-5,7,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41351(6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41353(0,6,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41353(1-5,8,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41354(0,1-6,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41354(7,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41355(0,1-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413555,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41356(0,3)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41356(1,2,4-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41358(0,3-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41358(1,2,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 41359,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "4136(1-2,5,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41360(0-2,6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41360(3-5,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413607,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41363(0-1,3-4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413632,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413635,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41364(0-2,4-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413643,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-08-27 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41366(0,1-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413666,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-12-21 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41367(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413679,
      "Operator (nazwa)": "GT11 Sp. z o.o.",
      "Operator (ID)": 10475,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41369(0,1-3,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413694,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "4137(2,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41370(1-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413700,
      "Operator (nazwa)": "FIBERWAY Sp. z o.o.",
      "Operator (ID)": 9750,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413706,
      "Operator (nazwa)": "SYMETRA Wojciech Wielog�rski",
      "Operator (ID)": 2470,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41371(0,1,2,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41371(3-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41373(0,1,3,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413732,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413734,
      "Operator (nazwa)": "FreePhone Sp. z o.o.",
      "Operator (ID)": 10788,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-02-28 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41374(0,1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41374(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41375(0,1-6,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413757,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413758,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41376(0,3,4,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41376(1,2,5,6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41377(0,3,5,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41377(1,2,4,6,7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41379(0,3-6)",
      "Operator (nazwa)": "TeleGo sp. z o.o.",
      "Operator (ID)": 9324,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-09-25 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41379(1,2,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "4138(3,6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41380(0,3,4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41380(1,2,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41381(0,1-4,6-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41381(5,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41382(0-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413828,
      "Operator (nazwa)": "DIDWW Ireland Limited",
      "Operator (ID)": 12132,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-19 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413829,
      "Operator (nazwa)": "Voice Net Sp. z o.o.",
      "Operator (ID)": 12749,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-02-20 12:45"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41384(0,2,3,5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41384(1,4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41385(0,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41385(1-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41387(0,1,3-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41387(2,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41388(0,1-3,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413884,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-03-12 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413885,
      "Operator (nazwa)": "FREELY Sp. z o.o.",
      "Operator (ID)": 13143,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41389(0,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413891,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413892,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413893,
      "Operator (nazwa)": "e-Telko Sp. z o.o.",
      "Operator (ID)": 11564,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413894,
      "Operator (nazwa)": "IPTELL Sp. z o.o.",
      "Operator (ID)": 7201,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413895,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413896,
      "Operator (nazwa)": "UNINET Sp. z o.o.",
      "Operator (ID)": 8224,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413897,
      "Operator (nazwa)": "KLIKOM.NET Sp. z o.o.",
      "Operator (ID)": 11953,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-08 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413898,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "4139(2,3,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41390(0,1-3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41390(4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41391(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 413910,
      "Operator (nazwa)": "ITEL Solutions Sp. z o.o.",
      "Operator (ID)": 11274,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-10-06 12:45"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41394(0,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41394(1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:53"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41395(0,2,4,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41395(1,3,5,6,8,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "418(1-3,5-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "4180(0,6-9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "4180(3-5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41801(1,3,7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41801(4-6,8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41801(9,0)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 418012,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41802(0,3-9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 418021,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 418022,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "4184(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "41841(0-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 418418,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-10 12:15"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "4188(0,1-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": 41888,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "419(0,1-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 41,
      "Zakres (number)": "419(8,9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "KIELCE",
      "Strefa (symbol)": "SN-KI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42(4,5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "421(0,1-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 4219,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "4220(0,4)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "4220(7,8)",
      "Operator (nazwa)": "TOYA Sp. z o.o.",
      "Operator (ID)": 127,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42201(0,6-9)",
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42201(4,5)",
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422011,
      "Operator (nazwa)": "TPNETS.COM Sp. z o.o.",
      "Operator (ID)": 3012,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422012,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422013,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42202(1-5)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422020,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422026,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422027,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-24 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422028,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2023-08-15 12:45"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422029,
      "Operator (nazwa)": "e-Telko Sp. z o.o.",
      "Operator (ID)": 11564,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-04-19 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42203(7,9)",
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422030,
      "Operator (nazwa)": "Galena Sp. z o.o.",
      "Operator (ID)": 66,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-16 12:16"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422031,
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422032,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422033,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422034,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-19 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422035,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-08 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422036,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-08 12:45"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422038,
      "Operator (nazwa)": "e-Telko Sp. z o.o.",
      "Operator (ID)": 11564,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42205(0,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42205(1,2,4-8)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422053,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42206(1-5)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42206(7,8)",
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422060,
      "Operator (nazwa)": "TOYA Sp. z o.o.",
      "Operator (ID)": 127,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-12-28 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422066,
      "Operator (nazwa)": "TPNETS.COM Sp. z o.o.",
      "Operator (ID)": 3012,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422069,
      "Operator (nazwa)": "DIDWW Ireland Limited",
      "Operator (ID)": 12132,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-19 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42209(1,2,4)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42209(5-7,9)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422090,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422093,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-12-01 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422098,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-04 12:46"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "4221(0,8)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "4221(3,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "4221(6-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-02-06 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42211(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422119,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42212(0,1-3,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42212(4,5)",
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-20 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422126,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422127,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422129,
      "Operator (nazwa)": "UAB B2B Network (d. UAB NordConnect)",
      "Operator (ID)": 11976,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-03-20 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42214(0,1,2,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422143,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422144,
      "Operator (nazwa)": "Focus Telecom Polska Sp. z o.o.",
      "Operator (ID)": 8974,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42219(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-02-06 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422190,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-02-06 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "4222(1,3-4,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-15 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42220(0,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-22 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422201,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-15 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422202,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-11-11 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422203,
      "Operator (nazwa)": "MEDIDESK Sp. z o.o.",
      "Operator (ID)": 12669,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-22 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42222(0,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42222(3-5)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422221,
      "Operator (nazwa)": "Debesis Sp. z o.o.",
      "Operator (ID)": 10138,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-05-19 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422222,
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42225(0,1-5,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42225(6-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 42226,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42227(0,1,3-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422272,
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-12 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422276,
      "Operator (nazwa)": "Focus Telecom Polska Sp. z o.o.",
      "Operator (ID)": 8974,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-12-08 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42230(6-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422300,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422301,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-08 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422302,
      "Operator (nazwa)": "Dariusz Zwoli�ski MOVICOM S.C.",
      "Operator (ID)": 8781,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-09 12:35"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422303,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-08 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422304,
      "Operator (nazwa)": "Actus-Info Sp. z o.o.",
      "Operator (ID)": 9186,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422305,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42231(1,2)",
      "Operator (nazwa)": "Multimedia Polska Biznes S.A.",
      "Operator (ID)": 11221,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-08-13 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42231(3-5)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422310,
      "Operator (nazwa)": "Connected Sp. z o.o.",
      "Operator (ID)": 8033,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-04-17 12:45"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422316,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422317,
      "Operator (nazwa)": "TOYA Sp. z o.o.",
      "Operator (ID)": 127,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-17 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422318,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-10-30 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422319,
      "Operator (nazwa)": "CLUDO Sp z o.o.",
      "Operator (ID)": 10056,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-02-01 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42232(0,6-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42232(1,2)",
      "Operator (nazwa)": "Hyperion S.A.",
      "Operator (ID)": 329,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422323,
      "Operator (nazwa)": "FORWEB S.C. Monika Bodetko Tomasz Paw�owski",
      "Operator (ID)": 5731,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422324,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2021-12-14 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422325,
      "Operator (nazwa)": "AMS SYSTEM Sp. z o.o.",
      "Operator (ID)": 7006,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-04-01 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42233(1,2)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422330,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-08 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422333,
      "Operator (nazwa)": "Stowarzyszenie Telewizji Kablowej TV SAT 364",
      "Operator (ID)": 1807,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422334,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422335,
      "Operator (nazwa)": "Zinet Sp. z o.o. Sp. k.",
      "Operator (ID)": 11939,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-11-30 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422336,
      "Operator (nazwa)": "TOYA Sp. z o.o.",
      "Operator (ID)": 127,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422337,
      "Operator (nazwa)": "Telkonekt Sp. z o.o.",
      "Operator (ID)": 9086,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422338,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422339,
      "Operator (nazwa)": "Hyperion S.A.",
      "Operator (ID)": 329,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-08 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42234(0-4,7-9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422345,
      "Operator (nazwa)": "K2M Sp. z o.o.",
      "Operator (ID)": 13135,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-30 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422346,
      "Operator (nazwa)": "BT Poland Sp. z o.o.",
      "Operator (ID)": 109,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-12-21 12:20"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42235(1,2,7,8)",
      "Operator (nazwa)": "FORWEB S.C. Monika Bodetko Tomasz Paw�owski",
      "Operator (ID)": 5731,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422350,
      "Operator (nazwa)": "Telekomunikacja 7Line Sp. z o.o.",
      "Operator (ID)": 8533,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422353,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422354,
      "Operator (nazwa)": "TBM Telekom Sp. z o.o. Sp.K.",
      "Operator (ID)": 11804,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-05 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422355,
      "Operator (nazwa)": "Intelligent Technologies S.A.",
      "Operator (ID)": 748,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422356,
      "Operator (nazwa)": "IPTELL Sp. z o.o.",
      "Operator (ID)": 7201,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422359,
      "Operator (nazwa)": "Alfaline Sp. z o.o.",
      "Operator (ID)": 9938,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-04-30 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42236(1-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422360,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-01-14 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 42237,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 42238,
      "Operator (nazwa)": "TeleGo sp. z o.o.",
      "Operator (ID)": 9324,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42239(1-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422390,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "4224(5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 42240,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42241(1-3)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-10 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42241(6-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422410,
      "Operator (nazwa)": "ITEL Solutions Sp. z o.o.",
      "Operator (ID)": 11274,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-22 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422414,
      "Operator (nazwa)": "Metro Internet Sp. z o.o.",
      "Operator (ID)": 7972,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-09-06 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422415,
      "Operator (nazwa)": "FREELY Sp. z o.o.",
      "Operator (ID)": 13143,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42242(3,5-6,9)",
      "Operator (nazwa)": "FORWEB S.C. Monika Bodetko Tomasz Paw�owski",
      "Operator (ID)": 5731,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-02-01 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422420,
      "Operator (nazwa)": "Platan Sp. z o.o. Sp. k.",
      "Operator (ID)": 12733,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2023-11-01 12:45"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422421,
      "Operator (nazwa)": "FORWEB S.C. Monika Bodetko Tomasz Paw�owski",
      "Operator (ID)": 5731,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-05-12 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422422,
      "Operator (nazwa)": "WorldON Sp. z o.o.",
      "Operator (ID)": 11461,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-01 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422424,
      "Operator (nazwa)": "POLTEL Sp. z o.o.",
      "Operator (ID)": 13246,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-02-02 12:45"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422427,
      "Operator (nazwa)": "Orientelco Sp. z o.o.",
      "Operator (ID)": 12993,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2023-08-05 12:20"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422428,
      "Operator (nazwa)": "ATMAN Sp. z o.o.",
      "Operator (ID)": 944,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-01-13 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42243(0-5,7-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-30 12:45"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422436,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-30 12:45"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 42244,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "4225(0-3,5-6,8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42254(0,2-3,5-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42254(1,4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42257(0,1-6,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42257(7,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42259(0,1,2,4)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42259(3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "4226(1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-09-14 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42260(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422600,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-07-01 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42262(0,1,2,5,6)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-09-14 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42262(3-4,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-09-14 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "4227(1,6)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42270(0-1,4-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42270(2,3)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42272(1-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422720,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 42273,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42274(0,4)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42274(1-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42275(0,1-5,8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42275(6,7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42277(0,1-6)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422777,
      "Operator (nazwa)": "FORWEB S.C. Monika Bodetko Tomasz Paw�owski",
      "Operator (ID)": 5731,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422778,
      "Operator (nazwa)": "didXL B.V.",
      "Operator (ID)": 13079,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-10-22 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422779,
      "Operator (nazwa)": "S-NET Sp. z o.o.",
      "Operator (ID)": 8697,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-02-13 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42278(0-1,3,5,8)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422782,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422784,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422786,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422787,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422789,
      "Operator (nazwa)": "ITH Sp. Z o.o.",
      "Operator (ID)": 12635,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-03-14 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42279(0,1)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42279(2,3,5,7-9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422794,
      "Operator (nazwa)": "VOXNET Sp. z o.o. Sp. k.",
      "Operator (ID)": 728,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422796,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "4228(3-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42280(3,4)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422800,
      "Operator (nazwa)": "e-Telko Sp. z o.o.",
      "Operator (ID)": 11564,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-04-19 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422801,
      "Operator (nazwa)": "ATMAN Sp. z o.o.",
      "Operator (ID)": 944,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422802,
      "Operator (nazwa)": "Hyperion S.A.",
      "Operator (ID)": 329,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422805,
      "Operator (nazwa)": "Colt Technology Services GmbH",
      "Operator (ID)": 12380,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-12-02 12:45"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422806,
      "Operator (nazwa)": "Nettelekom GK Sp. z o.o.",
      "Operator (ID)": 9896,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422807,
      "Operator (nazwa)": "Telkonekt Sp. z o.o.",
      "Operator (ID)": 9086,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422808,
      "Operator (nazwa)": "Stowarzyszenie D�BROWA SKY",
      "Operator (ID)": 1331,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422809,
      "Operator (nazwa)": "Dariusz Zwoli�ski MOVICOM S.C.",
      "Operator (ID)": 8781,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 42281,
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42282(0,1,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42282(3,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-13 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42282(4-7)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-13 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422822,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42288(0,1-3,5-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422884,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-12-01 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42289(0,9)",
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-20 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42289(2,5-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42289(3,4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-13 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422891,
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422898,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "4229(4,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42290(0,6)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42290(1-5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42290(7-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42291(1,8-9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42291(2,4,5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42291(3,6,7)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422910,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "��d�",
      "Data modyfikacji": "2018-06-08 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42292(1,8)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42292(2-3)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "��d�",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42292(4-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "��d�",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422920,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422929,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 42293,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 42295,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42296(1-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422960,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-12-01 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42298(0,8-9)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42298(1,2)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "��d�",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42298(5,7)",
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422983,
      "Operator (nazwa)": "SMSWIZARD POLSKA Sp. z o.o.",
      "Operator (ID)": 12975,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-01-25 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422984,
      "Operator (nazwa)": "M3.NET Sp. z o.o.",
      "Operator (ID)": 10099,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422986,
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42299(0,1-5,7-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 422996,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-12-01 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "423(1-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "4230(1-3,5,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-11-30 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42300(4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-08-13 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 423000,
      "Operator (nazwa)": "IPTELEKOM Micha� Ludwiczak",
      "Operator (ID)": 10051,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 423001,
      "Operator (nazwa)": "ST LINE Sp. z o.o. Sp. k.",
      "Operator (ID)": 12096,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-01 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 423002,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-08-07 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 423003,
      "Operator (nazwa)": "NETTEL Sp. z o.o.",
      "Operator (ID)": 9575,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42304(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-11-30 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 423040,
      "Operator (nazwa)": "Next Mobile Prosta S.A. (d. Globitell Wholesale Sp. z o.o. Sp. k.)",
      "Operator (ID)": 12195,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-02-28 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42306(0,1-4,6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42306(5,7)",
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 42307,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42308(0,1,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42308(2,3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "4234(0-1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42342(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 423423,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-05 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "4260(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42600(1-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-10-04 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 426000,
      "Operator (nazwa)": "IPTELEKOM Micha� Ludwiczak",
      "Operator (ID)": 10051,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 426006,
      "Operator (nazwa)": "INTEGRAL Katarzyna Fitas-Antczak",
      "Operator (ID)": 10429,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-10-04 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "4261(0,8-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42611(0,1,2,5,6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 426113,
      "Operator (nazwa)": "Blue Prospect Sp. z o.o.",
      "Operator (ID)": 13345,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2023-08-05 12:45"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 426114,
      "Operator (nazwa)": "SUPERMEDIA Sp. z o.o.",
      "Operator (ID)": 1369,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2023-02-08 12:45"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 426117,
      "Operator (nazwa)": "Dialoga Servicios Interactivos S.A.",
      "Operator (ID)": 10930,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42612(0,7-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42612(1-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42612(5,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 42613,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42614(0,8)",
      "Operator (nazwa)": "Televoice Polska Sp. z o.o.",
      "Operator (ID)": 8808,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-06-14 12:45"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42614(1-4,6,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 426145,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-13 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 426149,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-10-31 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 42615,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42616(0,1-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 426166,
      "Operator (nazwa)": "STORM MEDIA Sp. z o.o.",
      "Operator (ID)": 7424,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42617(0,1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42617(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "4262(1-3,5-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-15 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42620(1-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-08-30 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 426200,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 426206,
      "Operator (nazwa)": "LoVo Sp. z o.o.",
      "Operator (ID)": 7184,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-08-30 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42624(0-1,3-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-05-15 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 426242,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-07-01 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 426246,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-07-01 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42628(0-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-15 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 426287,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-15 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "4263(0-6,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42637(0,1-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 426378,
      "Operator (nazwa)": "Telnyx Ireland Limited",
      "Operator (ID)": 13021,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-01 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42638(0,2-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 426381,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "4264(0,2-3,6,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42641(0-2,4-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 426413,
      "Operator (nazwa)": "Telkonekt Sp. z o.o.",
      "Operator (ID)": 9086,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-26 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42644(0,1-3)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42644(4-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42644(7,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 426449,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-01 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42645(0,2-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 426451,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-08-08 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 426456,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42647(0,1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42647(3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42649(0,1-4,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42649(5,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 4265,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "4266(0,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 42661,
      "Operator (nazwa)": "TOYA Sp. z o.o.",
      "Operator (ID)": 127,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42662(1-3,5-6,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 426620,
      "Operator (nazwa)": "TOYA Sp. z o.o.",
      "Operator (ID)": 127,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 426624,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-04-30 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 426627,
      "Operator (nazwa)": "Citynet Sp. z o.o.",
      "Operator (ID)": 5308,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-03-06 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 426629,
      "Operator (nazwa)": "Netpartner Polska Sp. z o.o.",
      "Operator (ID)": 7242,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42663(0,1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42663(6-9)",
      "Operator (nazwa)": "TOYA Sp. z o.o.",
      "Operator (ID)": 127,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 42664,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42665(0-2,4-5,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42665(6,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 426653,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42666(1-3,5,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-04 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42666(4,6,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 426660,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-05-01 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 426669,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-04 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "4267(2-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42670(0-1,3-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 426702,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-12 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42671(0-1,3-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 426712,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 426716,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-10-30 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "4268(1-4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42680(0-4,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 426805,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 426806,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-10-13 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42685(0,4-5,7-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42685(1-3)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42685(6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "4269(0-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-11-25 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42696(0-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-11-25 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 426969,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-10 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "427(3,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "4270(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42700(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 427000,
      "Operator (nazwa)": "SUDER Sp. z o.o.",
      "Operator (ID)": 10822,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "4271(0,2,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 42711,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42713(0,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42713(2-5,7,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 427131,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-07 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 427139,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42714(0,1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42714(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 427146,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-02-07 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42715(0,1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42715(8,9)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "4272(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "4272(1-5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "4274(0-1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42742(0-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 427427,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-10 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "4275(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42750(0,1,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42750(2-4)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "428(1-3,5-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "4280(0,6-9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "4280(4,5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42801(0,4,5,8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42801(1-3)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42801(6,7,9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42802(0,4,5,9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42802(1,2)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42802(3,6,8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 428027,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42803(0,4,5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42803(1,8,9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42803(2,7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 428033,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-01-06 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 428036,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-04-01 12:45"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "4284(0-1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42842(0-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 428428,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-10 12:15"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "4288(0,2-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 42881,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 42888,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "429(0,1-3,5-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "429(8,9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "4294(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42942(0,2)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": "42942(1,3-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 42,
      "Zakres (number)": 429429,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "��D�",
      "Strefa (symbol)": "SN-�D",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43(2,4,5,7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "431(0,1-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:54"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 4319,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "433(1,2,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "4330(1-5,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43300(2,4-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-02-01 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 433000,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-07-01 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 433001,
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-22 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 433003,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 433009,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-02-01 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43306(0,1-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 433067,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43307(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 433070,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 433071,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-01-02 12:20"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 433072,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 433073,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 433074,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-01 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 433075,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-09-06 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 433076,
      "Operator (nazwa)": "PROMEDIA Nowicki Weso�owski Sp.J.",
      "Operator (ID)": 7534,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-06-02 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43308(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 433082,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "4333(0,1,2,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 43333,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43334(0,2,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-01-13 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43334(4-6)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 433341,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-03-29 12:46"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 433343,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-07-01 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "4334(0,1,2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43343(0-1,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-12 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 433432,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-12 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 433433,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "436(1-3,6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "4360(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43600(1-5)",
      "Operator (nazwa)": "TeleGo sp. z o.o.",
      "Operator (ID)": 9324,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-09-25 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43600(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-09-25 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 436000,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 436006,
      "Operator (nazwa)": "FORWEB S.C. Monika Bodetko Tomasz Paw�owski",
      "Operator (ID)": 5731,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "4364(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43643(0-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 436436,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-05 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43650(2,3)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43650(8,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 436500,
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 436501,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 436504,
      "Operator (nazwa)": "FREELY Sp. z o.o.",
      "Operator (ID)": 13143,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 436505,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 436506,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "Sieradz",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 436507,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-04 12:46"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43651(0,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43651(1,2)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-01 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43652(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43652(1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43653(0,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43653(1,2)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43654(0,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43654(1,2)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-01 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43655(5,7)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 436550,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 436551,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-01-15 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 436552,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-06-01 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 436553,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 436554,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 436556,
      "Operator (nazwa)": "DIDWW Ireland Limited",
      "Operator (ID)": 12132,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-19 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 436558,
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 436559,
      "Operator (nazwa)": "AMS SYSTEM Sp. z o.o.",
      "Operator (ID)": 7006,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-10-06 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43656(2-5,7)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43656(8,9)",
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 436560,
      "Operator (nazwa)": "Galena Sp. z o.o.",
      "Operator (ID)": 66,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-16 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 436561,
      "Operator (nazwa)": "VOXNET Sp. z o.o. Sp. k.",
      "Operator (ID)": 728,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 436566,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43657(0,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43657(1-3)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43658(0,1-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43658(7-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43659(0,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43659(1-6)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "4367(3,4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "4367(5,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43670(2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 436700,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 436701,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43671(0,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43671(1-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43672(1-3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43672(4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 436720,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43676(1-4,6,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 436760,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 436765,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-07-22 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 436767,
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-20 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 436769,
      "Operator (nazwa)": "e-Telko Sp. z o.o.",
      "Operator (ID)": 11564,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43677(0,1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43677(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 436777,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43679(0,1)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43679(2-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-12-21 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 436799,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-12-21 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "438(1,3,5,6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "4380(0,6-9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "4380(2-5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43801(0,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43801(2-4,7,9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43801(6,8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 438011,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "4382(2-4,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43820(0,1-4,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43820(5-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 438209,
      "Operator (nazwa)": "Dialoga Servicios Interactivos S.A.",
      "Operator (ID)": 10930,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43821(0,1-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 438218,
      "Operator (nazwa)": "@ALFANET M. Ma�olepszy",
      "Operator (ID)": 163,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-06 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43825(0-5,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43825(6,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 438258,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43826(0,3-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43826(1,2)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 438268,
      "Operator (nazwa)": "MPCNET Sp. z o.o.",
      "Operator (ID)": 11243,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-10-18 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 438269,
      "Operator (nazwa)": "TOYA Sp. z o.o.",
      "Operator (ID)": 127,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43827(0-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 438277,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43828(0,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43828(1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "4384(1,3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "4384(4,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43840(1-2,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-28 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43840(3-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 438400,
      "Operator (nazwa)": "POLTEL Sp. z o.o.",
      "Operator (ID)": 13246,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-02-02 12:45"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43842(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 438429,
      "Operator (nazwa)": "Telnyx Ireland Limited",
      "Operator (ID)": 13021,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-01 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43845(0,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43845(1,2)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43845(3-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43846(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 438462,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "4387(0,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43871(1-2)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43871(3,4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43871(5-9)",
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 438710,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43872(0,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 438721,
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 438722,
      "Operator (nazwa)": "IPTELL Sp. z o.o.",
      "Operator (ID)": 7201,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 438723,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-16 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 438724,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-10-25 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "4388(0,2-5,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43881(0,1-4,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43881(5-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 43886,
      "Operator (nazwa)": "Telekomunikacja Zwi�zku Gmin Ziemi Wielu�skiej S.A.",
      "Operator (ID)": 30,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-08-28 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "43888(0,3-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-08-11 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 438881,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 438882,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-12-01 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 438888,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 438889,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-08-11 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": 43889,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "439(0,1-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 43,
      "Zakres (number)": "439(8,9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "SIERADZ",
      "Strefa (symbol)": "SN-SI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44(2,5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-12-07 12:45"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "441(0,1-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 4419,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "443(1-2,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "4430(1-5,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44300(2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-08-13 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 443000,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-07-01 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 443001,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-08-07 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 443003,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44306(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-14 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44306(2-4)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44306(5,6)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-12-15 12:45"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 443061,
      "Operator (nazwa)": "Marat Sp. z o.o.",
      "Operator (ID)": 8620,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 443067,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44307(5-9)",
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 443070,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 443071,
      "Operator (nazwa)": "DIDWW Ireland Limited",
      "Operator (ID)": 12132,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-19 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 443072,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 443073,
      "Operator (nazwa)": "FREELY Sp. z o.o.",
      "Operator (ID)": 13143,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 443074,
      "Operator (nazwa)": "FORWEB S.C. Monika Bodetko Tomasz Paw�owski",
      "Operator (ID)": 5731,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44308(1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 443080,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 443082,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "4433(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-02-14 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44333(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-02-14 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 443333,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-02-14 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "4434(0-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44344(0-2,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-05-20 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44344(4,5)",
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-05-20 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 443443,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-05 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 444,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "446(2,5,7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "4460(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44600(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 446000,
      "Operator (nazwa)": "Ryszard Wyka Zak�ad Us�ugowo-Handlowy ELEKTRON s.c.",
      "Operator (ID)": 3042,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "4461(1-4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44610(0,1,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44610(2,3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44615(0,3-5,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44615(1,2,6,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 44616,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44617(0,1-3,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 446174,
      "Operator (nazwa)": "Telnyx Ireland Limited",
      "Operator (ID)": 13021,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-01 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44618(0,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44618(1,2)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44619(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 446192,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "4463(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "4463(3,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44631(0,2)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44631(1,3-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44632(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 446320,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-02-29 10:01"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44634(0,1-4,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44634(5,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "4464(0-3)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "4464(6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44644(0-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 446446,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-05 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44645(0-4,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 446455,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-03-29 12:46"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 446456,
      "Operator (nazwa)": "SOFT PARTNER Szczypiorski Sp. J.",
      "Operator (ID)": 3491,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-01-03 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 446457,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 446459,
      "Operator (nazwa)": "TOYA Sp. z o.o.",
      "Operator (ID)": 127,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "4466(0,1-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44667(0,1-5,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 446676,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 446677,
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-20 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "4468(2,3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "4468(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44680(0,2-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44680(1,8,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44681(0,1,4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44681(2,3,5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44684(0,1-3,6,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44684(4,5,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44685(0-4,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44685(5,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 446857,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "447(0,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-10-01 12:20"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44710(0,1-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447107,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44711(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44711(1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44712(0,9)",
      "Operator (nazwa)": "DOLSAT Sp. z o.o.",
      "Operator (ID)": 5483,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44712(2,3,7,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44712(4,5)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447121,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447126,
      "Operator (nazwa)": "Telkonekt Sp. z o.o.",
      "Operator (ID)": 9086,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44713(0,1,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44713(2-4)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 44714,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44715(0-5,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44715(6-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44716(1-5)",
      "Operator (nazwa)": "TeleGo sp. z o.o.",
      "Operator (ID)": 9324,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44716(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447160,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447166,
      "Operator (nazwa)": "Galena Sp. z o.o.",
      "Operator (ID)": 66,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-16 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447167,
      "Operator (nazwa)": "Intelligent Technologies S.A.",
      "Operator (ID)": 748,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-10-12 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 44717,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44718(0,1-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44718(8,9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44719(0,1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447198,
      "Operator (nazwa)": "AMS SYSTEM Sp. z o.o.",
      "Operator (ID)": 7006,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-08-30 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447199,
      "Operator (nazwa)": "Citynet Sp. z o.o.",
      "Operator (ID)": 5308,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-03-06 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "4472(0,1,2,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "4472(3,4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44725(0,1-3,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447254,
      "Operator (nazwa)": "e-Telko Sp. z o.o.",
      "Operator (ID)": 11564,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-04-19 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447255,
      "Operator (nazwa)": "MIDIKO Sp. z o.o.",
      "Operator (ID)": 4990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-09-16 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44726(0-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44726(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447265,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44730(1-4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "Tomasz�w Mazowiecki",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44730(5,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44730(7-9)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447300,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44731(0,9)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44731(3-7)",
      "Operator (nazwa)": "Agencja Rozwoju Regionalnego ARREKS S.A.",
      "Operator (ID)": 21,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "Be�chat�w",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447311,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-12 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447312,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-04 12:46"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447318,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44732(0,1,3-7)",
      "Operator (nazwa)": "Actus-Info Sp. z o.o.",
      "Operator (ID)": 9186,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-28 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44732(2,8-9)",
      "Operator (nazwa)": "Hyperion S.A.",
      "Operator (ID)": 329,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-28 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44733(1-4)",
      "Operator (nazwa)": "Hyperion S.A.",
      "Operator (ID)": 329,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-11-29 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44733(6,9)",
      "Operator (nazwa)": "Actus-Info Sp. z o.o.",
      "Operator (ID)": 9186,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-28 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447330,
      "Operator (nazwa)": "Messageroute LTD",
      "Operator (ID)": 12026,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-08-14 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447335,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-11-29 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447337,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-16 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447338,
      "Operator (nazwa)": "Messageroute LTD",
      "Operator (ID)": 12026,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-13 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44734(0-7)",
      "Operator (nazwa)": "Hyperion S.A.",
      "Operator (ID)": 329,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-03-01 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44734(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-03-01 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44735(0,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44735(1-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44736(0,5-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44736(1,3)",
      "Operator (nazwa)": "Hyperion S.A.",
      "Operator (ID)": 329,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-08 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447362,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-03-01 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447364,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-08 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447368,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-03-01 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44737(0,8-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44737(1-7)",
      "Operator (nazwa)": "PGE G�rnictwo i Energetyka Konwencjonalna S.A.",
      "Operator (ID)": 307,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "Be�chat�w",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44738(0,9)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44738(1-2,5)",
      "Operator (nazwa)": "Hyperion S.A.",
      "Operator (ID)": 329,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-19 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447383,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-27 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447384,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-19 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447386,
      "Operator (nazwa)": "FORWEB S.C. Monika Bodetko Tomasz Paw�owski",
      "Operator (ID)": 5731,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447387,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "Radomsko",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447388,
      "Operator (nazwa)": "Actus-Info Sp. z o.o.",
      "Operator (ID)": 9186,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44739(0,1,5-9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447392,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447393,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447394,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "4474(0,3,6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44741(0,9)",
      "Operator (nazwa)": "TOYA Sp. z o.o.",
      "Operator (ID)": 127,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447411,
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447412,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447413,
      "Operator (nazwa)": "@ALFANET M. Ma�olepszy",
      "Operator (ID)": 163,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447414,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447415,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447416,
      "Operator (nazwa)": "GIGA-LAN Dariusz Gwadera",
      "Operator (ID)": 11117,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-10-12 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447417,
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447418,
      "Operator (nazwa)": "IPTELL Sp. z o.o.",
      "Operator (ID)": 7201,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44742(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447421,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44744(0,5-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-03-07 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44744(1-3)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447444,
      "Operator (nazwa)": "Debesis Sp. z o.o.",
      "Operator (ID)": 10138,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-24 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447447,
      "Operator (nazwa)": "Telfon Poland Sp. z o.o.",
      "Operator (ID)": 12517,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-03-07 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44745(0,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44745(1-4)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 44747,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "4475(0,1-3,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44754(1-4,6-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447540,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447545,
      "Operator (nazwa)": "Dialoga Servicios Interactivos S.A.",
      "Operator (ID)": 10930,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447549,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 44755,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44756(0,1,2,4,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44756(3,5-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44757(0,2,4-6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44757(1,3,7,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44758(0,3,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44758(1,2,4,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "4477(0,1-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 44777,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "4478(0,4,5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44781(0,1,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44781(2-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44782(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-11-29 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44782(1,2)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447823,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-24 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447824,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-07-15 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447825,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-11-29 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44783(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447831,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44786(0,8)",
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44786(1-7,9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44787(0,2,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44787(1,3-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44788(0,1)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-13 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44788(2-6)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44788(8,9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-13 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447887,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44789(1-4,6)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44789(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447890,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:55"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447895,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447897,
      "Operator (nazwa)": "VOXNET Sp. z o.o. Sp. k.",
      "Operator (ID)": 728,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "4479(0-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-10-01 12:20"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44799(0-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-10-01 12:20"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 447999,
      "Operator (nazwa)": "MEDIDESK Sp. z o.o.",
      "Operator (ID)": 12669,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-10-01 12:20"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "448(1-3,5-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "4480(0,6-9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "4480(3-5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44801(0-2)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44801(3,4,6-9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 448015,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44802(0,1-5,7-9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 448026,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "4484(0-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44844(0-3,5-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-09-05 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 448444,
      "Operator (nazwa)": "POLTEL Sp. z o.o.",
      "Operator (ID)": 13246,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-02-02 12:45"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 448448,
      "Operator (nazwa)": "Telfon Poland Sp. z o.o.",
      "Operator (ID)": 12517,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "4488(0,1-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "44888(0,3-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-04-27 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 448881,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 448882,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-04-27 12:15"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": 448888,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "449(0,1-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 44,
      "Zakres (number)": "449(8,9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "PIOTRK�W TRYBUNALSKI",
      "Strefa (symbol)": "SN-PT",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46(2,5-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "461(0,1-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 4619,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "463(2,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "4630(2,4-5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-22 12:45"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 46300,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46301(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-11-16 12:50"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 463010,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-11-16 12:50"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 46303,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-22 12:45"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46306(1-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-22 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 463060,
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-22 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 463067,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46307(1-5)",
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46307(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 463070,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46308(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 463082,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 46309,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "4631(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46310(1-3)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46310(4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 463100,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-07-01 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "4633(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-06-01 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46333(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-06-01 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 463333,
      "Operator (nazwa)": "POLTEL Sp. z o.o.",
      "Operator (ID)": 13246,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-02-02 12:45"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "4634(0-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46346(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 463463,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-10 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "464(1-3,5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "4640(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46400(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 464000,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "4644(0-3,5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-03-24 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46444(0-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-03-24 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 464444,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-03-24 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46446(0,1-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 464464,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-10-06 12:20"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "4646(0,1-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46464(0-3,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-10-12 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 464644,
      "Operator (nazwa)": "Debesis Sp. z o.o.",
      "Operator (ID)": 10138,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-24 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 464645,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-10-12 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 464646,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "4680(0,6-9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "4680(2-5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46801(0-1,4,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46801(2,5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46801(3,7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46801(6,9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "4681(0,2)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "4681(4,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46811(2,3)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 468110,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 468111,
      "Operator (nazwa)": "AlterTEL Sp. z o.o.",
      "Operator (ID)": 8614,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 468114,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 468115,
      "Operator (nazwa)": "ITV MEDIA Sp. z o.o.",
      "Operator (ID)": 5752,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 468116,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 468117,
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 468118,
      "Operator (nazwa)": "M3.NET Sp. z o.o.",
      "Operator (ID)": 10099,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-08-26 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 468119,
      "Operator (nazwa)": "IPTELL Sp. z o.o.",
      "Operator (ID)": 7201,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46813(0,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46813(1-3,7,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 468134,
      "Operator (nazwa)": "Telnyx Ireland Limited",
      "Operator (ID)": 13021,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-01 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 468135,
      "Operator (nazwa)": "e-Telko Sp. z o.o.",
      "Operator (ID)": 11564,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-04-19 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 468139,
      "Operator (nazwa)": "TOYA Sp. z o.o.",
      "Operator (ID)": 127,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46816(0,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46816(2,8)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 468161,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 468163,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 468164,
      "Operator (nazwa)": "AMS SYSTEM Sp. z o.o.",
      "Operator (ID)": 7006,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-12-02 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 468165,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-16 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 468166,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 468167,
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46817(0,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46817(1,2)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46818(0,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46818(1-4)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46819(0,1-8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 468199,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-02-29 10:01"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 4682,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "4683(1-3,7-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46830(0,1-4,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46830(5-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46834(0-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 468347,
      "Operator (nazwa)": "Telkonekt Sp. z o.o.",
      "Operator (ID)": 9086,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-12-21 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46835(0-3,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46835(5-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-31 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 468354,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 468358,
      "Operator (nazwa)": "Dialoga Servicios Interactivos S.A.",
      "Operator (ID)": 10930,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 46836,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46839(0,3,5,7,9)",
      "Operator (nazwa)": "TeleGo sp. z o.o.",
      "Operator (ID)": 9324,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-09-25 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46839(1,2,4,6,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "4684(0,1-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46846(0,1-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 468468,
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "4685(0,1-3,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "4685(5-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46854(0-5,7-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 468546,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-01 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 468549,
      "Operator (nazwa)": "ATMAN Sp. z o.o.",
      "Operator (ID)": 944,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-01-13 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46858(0,1-3,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 468584,
      "Operator (nazwa)": "DIDWW Ireland Limited",
      "Operator (ID)": 12132,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-10-19 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 468585,
      "Operator (nazwa)": "FREELY Sp. z o.o.",
      "Operator (ID)": 13143,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 468586,
      "Operator (nazwa)": "Galena Sp. z o.o.",
      "Operator (ID)": 66,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-04-16 12:16"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 468587,
      "Operator (nazwa)": "ELART Sp. z o.o.",
      "Operator (ID)": 905,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 468588,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "4686(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "4686(1-2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46863(0-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 468638,
      "Operator (nazwa)": "Citynet Sp. z o.o.",
      "Operator (ID)": 5308,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-03-06 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46864(0-2,4,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46864(5-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 468643,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46865(1-5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 468650,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 468656,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "�owicz",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 468657,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "Skierniewice",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 468658,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "Sochaczew",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 468659,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-12-01 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "4687(0,1-3,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 46874,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46875(0,3,4,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46875(1,2,5,6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46876(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 468762,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "4688(2-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46880(1-3)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46880(5,6)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 468800,
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 468804,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 468807,
      "Operator (nazwa)": "INTERKAM Sp. z o.o.",
      "Operator (ID)": 1012,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "SN (w granicach woj. mazowieckiego)",
      "Data modyfikacji": "2020-02-01 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 468808,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 468809,
      "Operator (nazwa)": "TIMPLUS B. Dudek, R. Walentowski sp�ka jawna",
      "Operator (ID)": 3110,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46881(1-5)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46881(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 468810,
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46888(0,3,5-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-24 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46888(4,8)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 468881,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 468882,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-09-06 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 468889,
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-24 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "4689(0,1,3,6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46892(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46892(1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46894(0,1-5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46894(6-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46895(0,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46895(1-6)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46897(0-6)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "46897(7,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": 468979,
      "Operator (nazwa)": "Multimedia �owicz Sp. z o.o.",
      "Operator (ID)": 13178,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-06-16 12:15"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "469(0,1-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 46,
      "Zakres (number)": "469(8,9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "SKIERNIEWICE",
      "Strefa (symbol)": "SN-SK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48(2,4,5,7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "481(0,1-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 4819,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:56"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "483(5,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "4830(2,4-5,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-04-16 12:45"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 48300,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48301(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-06-15 12:45"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483011,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-06-15 12:45"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48303(4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-03 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483030,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483031,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483032,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-03-18 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483033,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-02-29 10:01"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48306(1-3)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48306(4-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-22 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483060,
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-22 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483067,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48307(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483070,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48308(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483082,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "4831(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48312(2,3)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48312(4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-11-16 12:50"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483120,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-11-16 12:50"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483121,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "4832(3,4,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-23 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48320(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483202,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48321(0,1,2,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48321(3-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48322(0,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-01-09 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48322(1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483223,
      "Operator (nazwa)": "Auranet Sp. z o.o.",
      "Operator (ID)": 12393,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-01-09 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48325(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-23 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483253,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-23 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48326(0,1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48326(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "4833(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-23 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48330(1-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-09-20 12:45"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48330(8,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483300,
      "Operator (nazwa)": "MEDIDESK Sp. z o.o.",
      "Operator (ID)": 12669,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-09-20 12:45"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 48331,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48332(0,1-5,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48332(6-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48333(0,1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48333(5-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-24 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483333,
      "Operator (nazwa)": "ENTER T&T Sp. z o.o.",
      "Operator (ID)": 10688,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483334,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483339,
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-24 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48334(0,1-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48334(5-9)",
      "Operator (nazwa)": "TeleGo sp. z o.o.",
      "Operator (ID)": 9324,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-09-25 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48335(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-23 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483353,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-23 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "4834(2,6-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48340(0,1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48340(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48341(0,1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48341(6,7)",
      "Operator (nazwa)": "Multimedia Polska Biznes S.A.",
      "Operator (ID)": 11221,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-10-31 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483418,
      "Operator (nazwa)": "ITH Sp. Z o.o.",
      "Operator (ID)": 12635,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-10 12:46"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483419,
      "Operator (nazwa)": "FASTAR Sp. z o.o.",
      "Operator (ID)": 12967,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-03-17 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 48343,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48344(0,1-3,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483444,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483445,
      "Operator (nazwa)": "Telnyx Ireland Limited",
      "Operator (ID)": 13021,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-01 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48345(0-1,6,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48345(2,3)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483454,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-03-29 12:46"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483455,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-07-15 12:45"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483457,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-12 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483458,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48348(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483483,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-10 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "4836(0,1-3,6,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48364(0,1-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483648,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48365(0,1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483658,
      "Operator (nazwa)": "INET CENTRUM Sp. z o.o.",
      "Operator (ID)": 5069,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483659,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48367(0,2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48367(1,3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48369(0,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48369(1-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "4837(1-4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 48370,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48375(0,1,5-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48375(2-4,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48376(0,2-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48376(1,6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48377(4,7)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483770,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483771,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483772,
      "Operator (nazwa)": "IPTELL Sp. z o.o.",
      "Operator (ID)": 7201,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483773,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483775,
      "Operator (nazwa)": "FREELY Sp. z o.o.",
      "Operator (ID)": 13143,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483776,
      "Operator (nazwa)": "Nettelekom GK Sp. z o.o.",
      "Operator (ID)": 9896,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483778,
      "Operator (nazwa)": "KORBANK S.A.",
      "Operator (ID)": 9294,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483779,
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 48378,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48379(1-5)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48379(6-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483790,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483799,
      "Operator (nazwa)": "Janicki Przemys�aw \"NET-KONT@KT",
      "Operator (ID)": 5009,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48380(0,1-3,5)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483804,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483806,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483807,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483808,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483809,
      "Operator (nazwa)": "VOXNET Sp. z o.o. Sp. k.",
      "Operator (ID)": 728,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48381(0-7,9)",
      "Operator (nazwa)": "Hyperion S.A.",
      "Operator (ID)": 329,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-27 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483818,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-06-30 12:45"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48382(3-7)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-13 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483820,
      "Operator (nazwa)": "ENTER T&T Sp. z o.o.",
      "Operator (ID)": 10688,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-08-03 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483821,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-19 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483822,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-03-01 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483828,
      "Operator (nazwa)": "Hyperion S.A.",
      "Operator (ID)": 329,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-08-03 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483829,
      "Operator (nazwa)": "DIDWW Ireland Limited",
      "Operator (ID)": 12132,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-19 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48383(2,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48383(3-5)",
      "Operator (nazwa)": "Hyperion S.A.",
      "Operator (ID)": 329,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48383(7,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-03-01 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483830,
      "Operator (nazwa)": "Hyperion S.A.",
      "Operator (ID)": 329,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483831,
      "Operator (nazwa)": "FIRMA HANDLOWO-US�UGOWA \"MULTIFOX\" Rojek Arkadiusz",
      "Operator (ID)": 339,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-08 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483836,
      "Operator (nazwa)": "Hyperion S.A.",
      "Operator (ID)": 329,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-03-01 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48384(0,2-9)",
      "Operator (nazwa)": "Hyperion S.A.",
      "Operator (ID)": 329,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-03-01 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483841,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-01-06 12:45"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48385(0-1,3,5,7-8)",
      "Operator (nazwa)": "Hyperion S.A.",
      "Operator (ID)": 329,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-26 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483852,
      "Operator (nazwa)": "MESKO S.A. (d. Przedsi�biorstwo Teleinformatyczne MESKO-NET Sp. z o.o.)",
      "Operator (ID)": 2237,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-26 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483854,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-19 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483856,
      "Operator (nazwa)": "SQS POLSKA S. Wysoki�ski",
      "Operator (ID)": 8836,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-07-04 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483859,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-15 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48386(1,2,4,7)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-14 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48386(3,5,6)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-10-28 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483860,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483868,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483869,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48387(0,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48387(1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 48388,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48389(0,3,5,7)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48389(1,2,4,8,9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 483896,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "486(3,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "4860(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48600(1-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-22 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 486000,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 486006,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-22 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48610(0,1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 486108,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 486109,
      "Operator (nazwa)": "TOYA Sp. z o.o.",
      "Operator (ID)": 127,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-21 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48611(0,2,3,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48611(1,4,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 486115,
      "Operator (nazwa)": "ENEA Wytwarzanie S.A.",
      "Operator (ID)": 26,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "Kozienice",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 48612,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48613(0,1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48613(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48614(0,2-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 486141,
      "Operator (nazwa)": "ENEA Wytwarzanie S.A.",
      "Operator (ID)": 26,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "Kozienice",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48615(0,1-3,6,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48615(4,5,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48616(0,1-5,7,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 486166,
      "Operator (nazwa)": "Dialoga Servicios Interactivos S.A.",
      "Operator (ID)": 10930,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 486169,
      "Operator (nazwa)": "Voice Net Sp. z o.o.",
      "Operator (ID)": 12749,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2024-02-20 12:45"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48617(0,1,4-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48617(2,3,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48618(0,1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48618(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48619(0,1,2,5-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48619(3,4,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "4862(0,5,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48621(0,1,4-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48621(2,3)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48622(0,1)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48622(2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48623(0,1,3,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48623(2,4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48624(0,1,2,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48624(4-6,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 486243,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48627(1,2)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48627(4,5)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 486270,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "Radom",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 486273,
      "Operator (nazwa)": "KORBANK-MEDIA CYFROWE Sp. z o.o.",
      "Operator (ID)": 6729,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-22 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 486276,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-04 12:45"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 486277,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-04 12:45"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 486278,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "Gr�jec",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 486279,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "Warka",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48628(0,6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48628(1,2)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48628(3-4,7-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 486285,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48629(0,1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48629(3-6)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "Radom",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48629(7-9)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "4864(0-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48648(0,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48648(1-5)",
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 486486,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-10 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "4865(0,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48651(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 486511,
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 48652,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48660(0,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48660(1-4)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48661(0,1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48661(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48662(0,1,3-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48662(2,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48663(0,1,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48663(2,4-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 486633,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 48664,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48665(0,1)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48665(2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48666(0,1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48666(3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48667(0-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 486679,
      "Operator (nazwa)": "AMS SYSTEM Sp. z o.o.",
      "Operator (ID)": 7006,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-05-31 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48668(0-3,6-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48668(4,5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-24 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 486688,
      "Operator (nazwa)": "Debesis Sp. z o.o.",
      "Operator (ID)": 10138,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-24 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 486689,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48669(0,1)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48669(2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 48670,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48671(0,3-4,6-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48671(2,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-10-03 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 486711,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 486715,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-10-03 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48672(0-1,4-5,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48672(2,3,6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 486728,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48673(0,1,2,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48673(3-6,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48674(0,1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48674(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48675(0-3,5,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48675(6,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 486754,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 48676,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48677(0,2-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 486771,
      "Operator (nazwa)": "CLUDO Sp z o.o.",
      "Operator (ID)": 10056,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-02-01 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 486777,
      "Operator (nazwa)": "Galena Sp. z o.o.",
      "Operator (ID)": 66,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-16 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 486778,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 486779,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-06-30 12:45"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48678(0,1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48678(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48679(0-7)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48679(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "4868(0,3,4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48681(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 486811,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48682(0-1)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48682(2-4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48682(5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48685(5,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48685(6-8)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 486850,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 486851,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 486852,
      "Operator (nazwa)": "Intelligent Technologies S.A.",
      "Operator (ID)": 748,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 486853,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-08-07 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 486854,
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "488(1-3,5-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-03-15 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "4880(0,6-9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "4880(3-5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48801(1-9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 488010,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48802(0,3)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48802(2,4-6)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48802(8,9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 488021,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 488027,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "4884(0-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-03-15 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48848(0-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-03-15 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 488488,
      "Operator (nazwa)": "SUDER Sp. z o.o.",
      "Operator (ID)": 10822,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-03-15 12:15"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "4888(0,1-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "48888(2-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 488880,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 488881,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": 488888,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "489(0,1-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 48,
      "Zakres (number)": "489(8,9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "RADOM",
      "Strefa (symbol)": "SN-RA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52(2,4,6,7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "521(0,1-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 5219,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "5230(1,5,6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "5230(2,3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52300(4-8)",
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 523000,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-08-07 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 523001,
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-12 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 523002,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-03-08 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 523003,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 523009,
      "Operator (nazwa)": "FREELY Sp. z o.o.",
      "Operator (ID)": 13143,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52304(0,8-9)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52304(1,3-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 523042,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 523046,
      "Operator (nazwa)": "BTK GAWEX Sp. J.",
      "Operator (ID)": 1794,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "Bydgoszcz",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 523047,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52307(0,6-9)",
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52307(1-5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52308(0,8-9)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52308(1,3-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 523082,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "5231(0,2,4,7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-13 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52311(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-07-16 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52311(2-4)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 523111,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 523115,
      "Operator (nazwa)": "e-Telko Sp. z o.o.",
      "Operator (ID)": 11564,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-04-19 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 523116,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-05-13 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 523117,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-07-16 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52313(0,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2021-01-12 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52313(1-3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-13 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 523134,
      "Operator (nazwa)": "TOYA Sp. z o.o.",
      "Operator (ID)": 127,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2021-01-12 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52315(0,1-3,5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52315(4,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52316(0,1)",
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-11-16 12:50"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52316(4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-11-16 12:50"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 523162,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 523163,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 52318,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "5232(0-6,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52327(0-2,4-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 523273,
      "Operator (nazwa)": "didXL B.V.",
      "Operator (ID)": 13079,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-10-22 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52329(0,1,2,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52329(3-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "5233(0,1-4,6,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 523350,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 523351,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-12-14 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 523352,
      "Operator (nazwa)": "e-Telko Sp. z o.o.",
      "Operator (ID)": 11564,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-04-19 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 523353,
      "Operator (nazwa)": "4 CARRIERS GLOBAL Sp. z o.o.",
      "Operator (ID)": 12739,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-12-23 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 523354,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 523355,
      "Operator (nazwa)": "CHOJNET Maciej Szypryt",
      "Operator (ID)": 10602,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 523356,
      "Operator (nazwa)": "Intelligent Technologies S.A.",
      "Operator (ID)": 748,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 523357,
      "Operator (nazwa)": "IPTELL Sp. z o.o.",
      "Operator (ID)": 7201,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 523358,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 523359,
      "Operator (nazwa)": "BT Poland Sp. z o.o.",
      "Operator (ID)": 109,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-12-07 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 52337,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52338(0-4,6-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-23 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 523385,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 523389,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-23 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 5234,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "5235(1,3-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52350(1-5)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 523500,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 523506,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 523507,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-07 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 523508,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-10-31 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 523509,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-10-30 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52352(0,1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52352(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52359(0-3,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52359(6,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 523594,
      "Operator (nazwa)": "GECKONET SPӣKA Z OGRANICZON� ODPOWIEDZIALNO�CI�",
      "Operator (ID)": 9572,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-10-19 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 523595,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2023-03-29 12:45"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 523598,
      "Operator (nazwa)": "Dialoga Servicios Interactivos S.A.",
      "Operator (ID)": 10930,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "5236(0,1,2,5,6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "5236(7-9)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52363(0,1-4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 523635,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52364(0,1,3-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 523642,
      "Operator (nazwa)": "Telnyx Ireland Limited",
      "Operator (ID)": 13021,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-01 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 523647,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-15 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "5237(0-3,5-6,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52374(0,1-4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 523745,
      "Operator (nazwa)": "Blue Prospect Sp. z o.o.",
      "Operator (ID)": 13345,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-08-05 12:45"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52377(0,6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-12-22 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52377(1-3)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52377(4-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 523777,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-12-22 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 52378,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "5238(1,2,4-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52380(0,1)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52380(2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52383(0,1,5,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52383(2-4,6,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "5239(2-4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "5239(5-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 52390,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52391(0-3,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 523914,
      "Operator (nazwa)": "Celcom Sp. z o.o.",
      "Operator (ID)": 10610,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-20 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 523915,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-17 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 523916,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-08-15 12:45"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 52399,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "5250(1-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 52500,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52506(0,1-4,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52506(5,6)",
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "5251(0,2)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52511(0,8-9)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525111,
      "Operator (nazwa)": "Nettelekom GK Sp. z o.o.",
      "Operator (ID)": 9896,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525112,
      "Operator (nazwa)": "Marat Sp. z o.o.",
      "Operator (ID)": 8620,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-08-03 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525113,
      "Operator (nazwa)": "Connected Sp. z o.o.",
      "Operator (ID)": 8033,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-04-17 12:45"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525114,
      "Operator (nazwa)": "VIRTUAL OPERATOR Sp. z o.o. (d. ServCom Sp. z o.o.)",
      "Operator (ID)": 5770,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525115,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-04 12:45"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525116,
      "Operator (nazwa)": "Colt Technology Services GmbH",
      "Operator (ID)": 12380,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-12-02 12:45"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525117,
      "Operator (nazwa)": "Hyperion S.A.",
      "Operator (ID)": 329,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52513(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525131,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 52514,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:17"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52515(0,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52515(1-8)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52516(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52516(1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 52517,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52518(0,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52518(1-8)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52519(0,7)",
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52519(3,5)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-01-31 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525191,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525192,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-01 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525194,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525196,
      "Operator (nazwa)": "didXL B.V.",
      "Operator (ID)": 13079,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-11-03 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525198,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-23 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525199,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-02-29 10:01"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52520(1-9)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525200,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52521(1-5)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52521(6,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-04-01 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525210,
      "Operator (nazwa)": "MEDIDESK Sp. z o.o.",
      "Operator (ID)": 12669,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN (w granicach woj.. kujawsko-pomorskiego)",
      "Data modyfikacji": "2022-01-22 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525218,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525219,
      "Operator (nazwa)": "Focus Telecom Polska Sp. z o.o.",
      "Operator (ID)": 8974,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-04-01 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52522(2,5)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525220,
      "Operator (nazwa)": "Debesis Sp. z o.o.",
      "Operator (ID)": 10138,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-05-19 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525221,
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525223,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525224,
      "Operator (nazwa)": "CLUDO Sp z o.o.",
      "Operator (ID)": 10056,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-02-01 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525226,
      "Operator (nazwa)": "e-Telko Sp. z o.o.",
      "Operator (ID)": 11564,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-04-19 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525227,
      "Operator (nazwa)": "Televoice Polska Sp. z o.o.",
      "Operator (ID)": 8808,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525228,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525229,
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52523(0-5,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52523(6-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 52524,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52525(0,1-7,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525258,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52526(8,9)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-02-17 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525260,
      "Operator (nazwa)": "DIDWW Ireland Limited",
      "Operator (ID)": 12132,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-10-19 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525261,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525262,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525263,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525264,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-24 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525265,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-04 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525266,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-07-15 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525267,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-01-12 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52527(0-1,3-4)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52527(2,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-08-30 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525275,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-08-30 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52528(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52528(1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52529(0,6-9)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52529(1,2,4,5)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525293,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-04 12:46"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "5253(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-08-29 12:45"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52530(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-08-29 12:45"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525300,
      "Operator (nazwa)": "LoVo Sp. z o.o.",
      "Operator (ID)": 7184,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-08-29 12:45"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "5254(0,3,5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52541(0,1-5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52541(6-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 52542,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52544(0,3,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-24 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52544(5,6)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-24 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525441,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-08-29 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525442,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-08-29 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525444,
      "Operator (nazwa)": "MICROLINK Sp. z o.o.",
      "Operator (ID)": 12805,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-03-31 12:45"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52546(0,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52546(2,3)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-13 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525461,
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-13 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525464,
      "Operator (nazwa)": "Progress Plus Sp. z o.o.",
      "Operator (ID)": 10755,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "5255(6-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 52550,
      "Operator (nazwa)": "TeleGo sp. z o.o.",
      "Operator (ID)": 9324,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52551(1,2)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-02-28 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52551(3,9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52551(5-8)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-02-28 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525510,
      "Operator (nazwa)": "Voice Net Sp. z o.o.",
      "Operator (ID)": 12749,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2024-02-29 12:45"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525514,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52552(3,5-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525520,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525521,
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525522,
      "Operator (nazwa)": "ENTER T&T Sp. z o.o.",
      "Operator (ID)": 10688,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-12-22 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525524,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-24 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 52553,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "Bydgoszcz",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52554(0,1-4)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52554(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525545,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 52555,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52559(0,1-4)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52559(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-23 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525595,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-23 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "5256(2,6)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52560(0,1-3)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52560(4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52561(1-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525610,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-24 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52563(2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525630,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525631,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52564(0,1-3)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52564(4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52565(0,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52565(1-5)",
      "Operator (nazwa)": "Petrus Sp. z o.o.",
      "Operator (ID)": 139,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-12-31 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52565(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52567(0,1-6)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52567(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52568(0,1-4)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52568(5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52569(0,1,2,4-8)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525693,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525699,
      "Operator (nazwa)": "VOXNET Sp. z o.o. Sp. k.",
      "Operator (ID)": 728,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "5257(0-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-07-12 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52577(1-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-10-30 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525770,
      "Operator (nazwa)": "LoVo Sp. z o.o.",
      "Operator (ID)": 7184,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-10-30 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525777,
      "Operator (nazwa)": "GECKONET SPӣKA Z OGRANICZON� ODPOWIEDZIALNO�CI�",
      "Operator (ID)": 9572,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-07-12 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "5258(1-5)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 52580,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52586(0-2,5-8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52586(4,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525863,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52587(0,1-4,6-8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52587(5,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52588(0,1)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52588(2-4,7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52588(5,6)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 525888,
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-20 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52589(0,1,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52589(2-4)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 5259,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-08-29 12:45"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "528(2,4,6-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "5280(0,6-9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52801(0-1,5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52801(2,4,6,8,9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 528013,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 528017,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52802(1-4,7-9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 528020,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 528025,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 528026,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52803(1-9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 528030,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-01-06 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52804(0,1,3)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52804(2,4-9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52805(1,2)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52805(4-9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 528050,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 528053,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "5281(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-03 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52813(0,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-03 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52813(1-3)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-03 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "5283(0-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-22 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52838(0-4,6-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-23 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 528385,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-22 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 528389,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-23 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "5285(0-1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52852(0-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 528528,
      "Operator (nazwa)": "Telfon Poland Sp. z o.o.",
      "Operator (ID)": 12517,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "5288(1-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "52880(2-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-04-27 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 528800,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-10-29 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 528801,
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-04-27 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 528808,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 528809,
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-04-27 12:15"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": 52888,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "529(0,1-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 52,
      "Zakres (number)": "529(8,9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "BYDGOSZCZ",
      "Strefa (symbol)": "SN-BY",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54(5-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "541(0,1-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-08-31 12:45"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 5419,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "542(1,2,4,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "5420(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 54200,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:57"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "5423(0-1,3-4,6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54232(0-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 542328,
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-12 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54235(0,1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54235(7-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54237(0-1,3,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 542372,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 542374,
      "Operator (nazwa)": "FREELY Sp. z o.o.",
      "Operator (ID)": 13143,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 542375,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-03-29 12:46"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54238(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 542380,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 54239,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "5425(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54251(0,1,2,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54251(3,4,6,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 542515,
      "Operator (nazwa)": "Dialoga Servicios Interactivos S.A.",
      "Operator (ID)": 10930,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54252(0,1-3,6-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 542524,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 542525,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 542529,
      "Operator (nazwa)": "Voice Net Sp. z o.o.",
      "Operator (ID)": 12749,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-02-20 12:45"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54253(0,1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54253(3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54254(0,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 542541,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 542542,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-10 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54255(2-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 542550,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 542551,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 542555,
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-20 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "5426(1-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54260(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54260(1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54265(0,1,2,4-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54265(3,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "5427(1,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54270(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 542709,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54272(0,1,2,4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54272(3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54273(0,1,2,4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54273(3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54274(0,2,4,6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54274(1,3,5,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "5428(3,4,6-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54280(0,1-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 542808,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-01 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54281(2,5)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-14 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 542810,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 542811,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 542813,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2021-03-12 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 542814,
      "Operator (nazwa)": "Multimedia Polska Biznes S.A.",
      "Operator (ID)": 11221,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2021-03-12 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 542816,
      "Operator (nazwa)": "IPTELL Sp. z o.o.",
      "Operator (ID)": 7201,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 542817,
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 542818,
      "Operator (nazwa)": "ITTAM Zawilski Ryszard",
      "Operator (ID)": 1288,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 542819,
      "Operator (nazwa)": "e-Telko Sp. z o.o.",
      "Operator (ID)": 11564,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-04-19 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54282(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 542829,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54285(0,1-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 542858,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-04-04 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54289(0,6,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-19 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54289(3-5,7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 542891,
      "Operator (nazwa)": "Telnyx Ireland Limited",
      "Operator (ID)": 13021,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-01 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 542892,
      "Operator (nazwa)": "DIDWW Ireland Limited",
      "Operator (ID)": 12132,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-19 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "543(1-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-25 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "5430(1-5,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54300(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 543000,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54306(0,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-03-30 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54306(1-5)",
      "Operator (nazwa)": "TeleGo sp. z o.o.",
      "Operator (ID)": 9324,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-09-25 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 543066,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-10-02 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 543067,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 543068,
      "Operator (nazwa)": "Marat Sp. z o.o.",
      "Operator (ID)": 8620,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-03-30 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54307(1-5)",
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54307(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 543070,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54308(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 543082,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "5435(0-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-25 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54354(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-25 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 543543,
      "Operator (nazwa)": "Telfon Poland Sp. z o.o.",
      "Operator (ID)": 12517,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-25 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "544(0,3,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "5441(0,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "5441(1-4)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54415(0,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54415(1-3)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54416(0-7,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 544168,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54420(2,3)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54420(4-8)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 544200,
      "Operator (nazwa)": "ITEL Solutions Sp. z o.o.",
      "Operator (ID)": 11274,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-12-14 12:45"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 544201,
      "Operator (nazwa)": "Sat Film Sp. z o.o. i Wsp�lnicy Sp.k.",
      "Operator (ID)": 8267,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 544209,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-04 12:46"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54421(0,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54421(2-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 544211,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 544218,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54422(0-2)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54422(3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54423(1-2)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54423(3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 544230,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54424(0,1-5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54424(6-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54425(0,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54425(1-4)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 544260,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 544261,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 544262,
      "Operator (nazwa)": "Sat Film Sp. z o.o. i Wsp�lnicy Sp.k.",
      "Operator (ID)": 8267,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "SN w granicach powiatu w�oc�awskiego",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 544263,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 544264,
      "Operator (nazwa)": "ENTER T&T Sp. z o.o.",
      "Operator (ID)": 10688,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-03 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 544265,
      "Operator (nazwa)": "Aduro Network Technologies Sp. z o.o.",
      "Operator (ID)": 6318,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 544266,
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 544267,
      "Operator (nazwa)": "Intelligent Technologies S.A.",
      "Operator (ID)": 748,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 544268,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 544269,
      "Operator (nazwa)": "Sat Film Sp. z o.o. i Wsp�lnicy Sp.k.",
      "Operator (ID)": 8267,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54427(1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54427(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 544270,
      "Operator (nazwa)": "Sat Film Sp. z o.o. i Wsp�lnicy Sp.k.",
      "Operator (ID)": 8267,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 54428,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54429(0-3,7-8)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 544294,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-08-07 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 544295,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 544296,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 544299,
      "Operator (nazwa)": "VOXNET Sp. z o.o. Sp. k.",
      "Operator (ID)": 728,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "5444(0,2,3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54441(0,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54441(1-3)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54444(0,1,7)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54444(2,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54444(3-6)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 544449,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "5445(0,1-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54454(0-2,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-15 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 544543,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-15 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 544544,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 544545,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-17 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "548(1-3,6-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "5480(0,6-9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "5480(3-5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54801(0,3,4,6,8-9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54801(1,5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 548012,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 548017,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54802(0,2-6,8-9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 548021,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 548027,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "5484(0,1-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54844(0,1-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 548444,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "5485(0-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54854(0-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 548548,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-05 12:15"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "5488(0,1-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "54888(0,2-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 548881,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": 548888,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "549(0,1-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 54,
      "Zakres (number)": "549(8,9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "W�OC�AWEK",
      "Strefa (symbol)": "SN-W�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55(4,7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "551(0,1-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 5519,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "552(0,1,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "5522(0,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55221(0-1,3-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-08 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 552212,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 552216,
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-12 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55222(1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 552220,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 552222,
      "Operator (nazwa)": "MEDIDESK Sp. z o.o.",
      "Operator (ID)": 12669,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-03-10 12:45"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "5523(2-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55230(0-1,4-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 552302,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-07-09 12:16"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 552303,
      "Operator (nazwa)": "Marat Sp. z o.o.",
      "Operator (ID)": 8620,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-07-23 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55231(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 552319,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 55238,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "5524(3,4,7,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 55240,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55241(1-3)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55241(4,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55241(5,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55241(7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 552410,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-08 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55242(0,1-5,7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55242(6,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55245(0,4,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-08 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55245(1-3,6-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 552455,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-08 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55246(0,1,2,4,5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55246(3,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55249(0,1-4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 552495,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "5525(0-1,3-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55252(0,6-9)",
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55252(1-4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 552525,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 55255,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "5526(0,3-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 55261,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55262(0,5,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55262(1-4,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55267(0,1-3,6,7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55267(4,5,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "5527(2,7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55270(0,4,5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55270(1-3,6,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 552707,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 552708,
      "Operator (nazwa)": "Dialoga Servicios Interactivos S.A.",
      "Operator (ID)": 10930,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55271(0,1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55271(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55273(0,1-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 552738,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 55274,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55275(0,1-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 552758,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55276(0,1-3,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55276(4-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55278(0,1-3,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 552784,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-02-29 10:01"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "553(1-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "5530(1-6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55300(1-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-22 12:20"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 553000,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 553003,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-22 12:20"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55307(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 553070,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55308(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 553082,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "5535(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55355(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 553553,
      "Operator (nazwa)": "Telfon Poland Sp. z o.o.",
      "Operator (ID)": 12517,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "5550(1-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55500(1-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-10-01 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 555000,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:46"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 555005,
      "Operator (nazwa)": "LOGIKA Tomasz Gruca",
      "Operator (ID)": 5129,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-10-01 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55506(0,1-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 555065,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "5551(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55511(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 555111,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:46"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "5552(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55522(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 555222,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:46"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "5553(0,1,2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55533(1,2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 555330,
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-20 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 555333,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-07-13 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "5554(0,1-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55544(0,1-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 555444,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:46"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "5555(0,1-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55555(2-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-03-07 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 555550,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 555551,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-06-19 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 555555,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:46"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "5556(0,1-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55566(0,1-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 555666,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:46"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "5557(0,1-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55577(0,1-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 555777,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:46"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "5558(0,1-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55588(0,1-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 555888,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:46"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "5559(0,1-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55599(0,1-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 555999,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-07-13 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "556(0,3,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-03 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 55610,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 55611,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "Elbl�g",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55612(2,4)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "Elbl�g",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55612(3,5)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55612(7-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 556120,
      "Operator (nazwa)": "FREELY Sp. z o.o.",
      "Operator (ID)": 13143,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 556121,
      "Operator (nazwa)": "Platan Sp. z o.o. Sp. k.",
      "Operator (ID)": 12733,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-08-29 12:45"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 556126,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55613(0-3,5-9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-03-13 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 556134,
      "Operator (nazwa)": "Multimedia Kwidzyn Sp. z o.o.",
      "Operator (ID)": 13177,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-06-16 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 55614,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55615(0,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55615(1-4)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55616(0,1,3-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 556162,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-24 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55617(0,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55617(1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55618(0,8-9)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55618(1,2)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55618(3,6)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "Elbl�g",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 556184,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 556185,
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 556187,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "Kwidzyn",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55619(0,2,6,7,9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 556191,
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 556193,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 556194,
      "Operator (nazwa)": "VOXNET Sp. z o.o. Sp. k.",
      "Operator (ID)": 728,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 556195,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 556198,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "Elbl�g",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "5562(4,6,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55620(0,9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55620(4-5,8)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55620(6,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 556201,
      "Operator (nazwa)": "INTELLY Niski Stypu�kowski B�aszczuk Sp�ka Jawna",
      "Operator (ID)": 11999,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-05-17 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 556202,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 556203,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55621(2,4,6)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 556210,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 556211,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 556213,
      "Operator (nazwa)": "DIDWW Ireland Limited",
      "Operator (ID)": 12132,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-10-19 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 556215,
      "Operator (nazwa)": "ATMEL Sp. z o.o.",
      "Operator (ID)": 9777,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-17 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 556217,
      "Operator (nazwa)": "GECKONET SPӣKA Z OGRANICZON� ODPOWIEDZIALNO�CI�",
      "Operator (ID)": 9572,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-10-19 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 556218,
      "Operator (nazwa)": "Galena Sp. z o.o.",
      "Operator (ID)": 66,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-03-04 12:45"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 556219,
      "Operator (nazwa)": "Nettelekom GK Sp. z o.o.",
      "Operator (ID)": 9896,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55622(1-4)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55622(5-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 556220,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-04 12:46"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 556228,
      "Operator (nazwa)": "Telnyx Ireland Limited",
      "Operator (ID)": 13021,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-01 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 556229,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-01 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55623(0,8-9)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55623(1-5)",
      "Operator (nazwa)": "TeleGo sp. z o.o.",
      "Operator (ID)": 9324,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 556236,
      "Operator (nazwa)": "e-Telko Sp. z o.o.",
      "Operator (ID)": 11564,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-04-19 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 556237,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55625(0,2,4-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55625(1,3)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 55628,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55629(0,8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55629(4,5)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 556291,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 556292,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-12-01 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 556293,
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 556296,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 556297,
      "Operator (nazwa)": "TOYA Sp. z o.o.",
      "Operator (ID)": 127,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-18 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 556299,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "5564(1-3)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "Elbl�g",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55640(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55640(1-6)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "Sztum",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 556407,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "Sztum",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 55644,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "Braniewo",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 55645,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "Kwidzyn",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55646(0,6-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "Malbork",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55646(1-5)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "Kwidzyn",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 55647,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "Malbork",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55648(0,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55648(1-6)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "Nowy Dw�r Gda�ski",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55649(0,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55649(1,3-6)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "Pas��k",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 556492,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "Pas��k",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "5565(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-03 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55655(0-5,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-05-15 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 556556,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-03 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 556557,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-05-17 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 556558,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-05-15 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "5566(0,1-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55667(0,1-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 556677,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:46"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "558(1-4,6-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "5580(0,6-9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "5580(2,4,5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55801(0,4,5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55801(1,3,7-9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55801(2,6)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55803(0,1-4,6-9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:58"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 558035,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "5585(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55855(0-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 558558,
      "Operator (nazwa)": "Telfon Poland Sp. z o.o.",
      "Operator (ID)": 12517,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "5588(0,2-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "55881(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 558811,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": 55888,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "559(0,1-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 55,
      "Zakres (number)": "559(8,9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "ELBL�G",
      "Strefa (symbol)": "SN-EL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56(2,5,7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-01-11 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "561(0,1-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 5619,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "563(1-2,4,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "5630(1-2,4-5,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-22 12:20"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56300(5-9)",
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 563000,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 563001,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-08-07 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 563002,
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-12 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 563003,
      "Operator (nazwa)": "NETTEL Sp. z o.o.",
      "Operator (ID)": 9575,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 563004,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-12-22 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 56303,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-22 12:20"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56306(0,4-5,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-09-12 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56306(1-3)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 563066,
      "Operator (nazwa)": "E-CALL POLSKA Sp. z o.o.",
      "Operator (ID)": 9331,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-09-12 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 563067,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56307(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 563070,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56308(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 563082,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "5633(0,1,2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-16 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56333(0,1,2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-16 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 563333,
      "Operator (nazwa)": "VOXNET Sp. z o.o. Sp. k.",
      "Operator (ID)": 728,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-16 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "5635(0-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56356(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 563563,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-04-10 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "5636(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56363(0-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 563636,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "564(1-3,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "5640(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56400(0,4)",
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56400(1-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "5644(0,2,3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56441(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 564411,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56444(0,1-3,5-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 564444,
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 564449,
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-20 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "5645(2-5,7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 56450,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56451(0,1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 564518,
      "Operator (nazwa)": "Platan Sp. z o.o. Sp. k.",
      "Operator (ID)": 12733,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-05-27 12:45"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 564519,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56456(0-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 564564,
      "Operator (nazwa)": "K2M Sp. z o.o.",
      "Operator (ID)": 13135,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-30 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56458(0,1,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56458(2-4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "5646(0,7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "5646(2,5,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56461(0-3,5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 564614,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 564616,
      "Operator (nazwa)": "ATMAN Sp. z o.o.",
      "Operator (ID)": 944,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-01-13 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56463(0-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 564636,
      "Operator (nazwa)": "Celcom Sp. z o.o.",
      "Operator (ID)": 10610,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-20 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56464(0,1,4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56464(2,3,5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56466(0,1-5,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 564666,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-05-17 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 564667,
      "Operator (nazwa)": "Blue Prospect Sp. z o.o.",
      "Operator (ID)": 13345,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2023-08-05 12:46"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "5647(8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-10-31 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56470(0-5,7-9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 564706,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 56471,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56472(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 564720,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56473(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-10-31 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 564730,
      "Operator (nazwa)": "Multimedia Polska Biznes S.A.",
      "Operator (ID)": 11221,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-10-31 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 56474,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56475(0,1,3,5,8)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-14 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56475(2,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-14 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56475(6,9)",
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 564754,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56476(0-4)",
      "Operator (nazwa)": "VECTRA TORU� SPӣKA Z OGRANICZON� ODPOWIEDZIALNO�CI�",
      "Operator (ID)": 12114,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-20 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56476(5-9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-12-31 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56477(0,3,5-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 564771,
      "Operator (nazwa)": "VOXNET Sp. z o.o. Sp. k.",
      "Operator (ID)": 728,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 564772,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 564774,
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "5649(0,2,6,7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "5649(3,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56491(1-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 564910,
      "Operator (nazwa)": "TOYA Sp. z o.o.",
      "Operator (ID)": 127,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-02-13 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 564916,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56494(0-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 564946,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56495(0,1-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 564956,
      "Operator (nazwa)": "ETH Sieci Komputerowe Sp. z o.o.",
      "Operator (ID)": 2774,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-03-26 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "5660(0-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-28 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56606(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-28 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566060,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-28 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "5661(1,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "5661(3-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56610(0,2-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566101,
      "Operator (nazwa)": "DIDWW Ireland Limited",
      "Operator (ID)": 12132,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-19 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56612(0,1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56612(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "5662(2-3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "5662(4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56620(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566200,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56621(0-2,4-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566213,
      "Operator (nazwa)": "GECKONET SPӣKA Z OGRANICZON� ODPOWIEDZIALNO�CI�",
      "Operator (ID)": 9572,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-10-19 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "5663(0,1,2,4-6,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 56633,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56637(1,3)",
      "Operator (nazwa)": "M�odzie�owa Sp�dzielnia Mieszkaniowa",
      "Operator (ID)": 2148,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56637(2,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566370,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566374,
      "Operator (nazwa)": "e-Telko Sp. z o.o.",
      "Operator (ID)": 11564,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-04-19 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56639(0,1,2,4,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56639(3,5-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "5664(2-3)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "5664(5,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 56640,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "Toru�",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56641(0-4,7)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56641(5,6,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56644(0-1,6,8-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56644(2-5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566447,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56646(0,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56646(1-4)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56647(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56647(1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56649(4,5)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566490,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566491,
      "Operator (nazwa)": "Marat Sp. z o.o.",
      "Operator (ID)": 8620,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-08-03 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566492,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566493,
      "Operator (nazwa)": "Hyperion S.A.",
      "Operator (ID)": 329,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566496,
      "Operator (nazwa)": "M�odzie�owa Sp�dzielnia Mieszkaniowa",
      "Operator (ID)": 2148,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566497,
      "Operator (nazwa)": "FREELY Sp. z o.o.",
      "Operator (ID)": 13143,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566498,
      "Operator (nazwa)": "ELTRONIK Sp. z o.o.",
      "Operator (ID)": 1674,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "Bobrowo, Brodnica, Zbiczno, Biskupiec, Kurz�tnik, Nowe Miasto Lu",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566499,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-10-30 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "5665(4-5,7-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56650(0,1,3-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566502,
      "Operator (nazwa)": "Intelligent Technologies S.A.",
      "Operator (ID)": 748,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56651(0,1,3,4,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56651(2,5,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56652(0,1,2,4-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566523,
      "Operator (nazwa)": "Dialoga Servicios Interactivos S.A.",
      "Operator (ID)": 10930,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56653(0,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56653(1,4-7)",
      "Operator (nazwa)": "TeleGo sp. z o.o.",
      "Operator (ID)": 9324,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-09-25 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56653(2,3)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56656(1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56656(6,7)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56656(8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-05-19 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566560,
      "Operator (nazwa)": "Debesis Sp. z o.o.",
      "Operator (ID)": 10138,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-05-19 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56659(0-1,3-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566592,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-10-30 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "5666(0-1,3-5,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56662(0,2-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566621,
      "Operator (nazwa)": "Roman Bidu� TELBIT wsp�lnik sp�ki cywilnej",
      "Operator (ID)": 7904,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-11-03 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56666(0-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566666,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 56667,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56668(0-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566689,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "5667(0,1-3)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "5667(4-6,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56677(0-2,6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56677(5,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56677(8,9)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566773,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566774,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56679(0,2-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566791,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566796,
      "Operator (nazwa)": "Focus Telecom Polska Sp. z o.o.",
      "Operator (ID)": 8974,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-01 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "5668(6,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 56680,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56681(0,2,4)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566811,
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566813,
      "Operator (nazwa)": "Voice Net Sp. z o.o.",
      "Operator (ID)": 12749,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-02-29 12:45"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566815,
      "Operator (nazwa)": "PERFECT-NET Sp. z o.o.",
      "Operator (ID)": 11548,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-12-31 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566816,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566817,
      "Operator (nazwa)": "Colt Technology Services GmbH",
      "Operator (ID)": 12380,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-12-02 12:45"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566818,
      "Operator (nazwa)": "Nettelekom GK Sp. z o.o.",
      "Operator (ID)": 9896,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566819,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN (w granicach woj. kujawsko-pomorskiego)",
      "Data modyfikacji": "2020-05-15 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56682(0,1-3,7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56682(4-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566828,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56683(2-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566830,
      "Operator (nazwa)": "Telnyx Ireland Limited",
      "Operator (ID)": 13021,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-01 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566831,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-13 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56684(0,1-4,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56684(5-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 56685,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56687(0,1-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566878,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-04-30 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56689(0,1,3,4,6,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56689(2,5,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566898,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-12-01 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "5669(3,4,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56690(1-2,4-5)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56690(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566900,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566903,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566906,
      "Operator (nazwa)": "MEDIDESK Sp. z o.o.",
      "Operator (ID)": 12669,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-12-29 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566907,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-12-29 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56691(0-3,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56691(4-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566918,
      "Operator (nazwa)": "M�odzie�owa Sp�dzielnia Mieszkaniowa",
      "Operator (ID)": 2148,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56692(0-1,6-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56692(2-5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 56695,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56696(1-3)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566960,
      "Operator (nazwa)": "Televoice Polska Sp. z o.o.",
      "Operator (ID)": 8808,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566964,
      "Operator (nazwa)": "IPTELL Sp. z o.o.",
      "Operator (ID)": 7201,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566965,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566966,
      "Operator (nazwa)": "Firma Informatyczna NSOLVE S.C. P. Cie�lik, R. Baran",
      "Operator (ID)": 6445,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566967,
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566968,
      "Operator (nazwa)": "Sp�dzielnia Mieszkaniowa w Grudzi�dzu",
      "Operator (ID)": 2032,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566969,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56697(0-7,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566978,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56699(0,6-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-02-02 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56699(1-5)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 566999,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-02-02 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "568(1-4,6-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "5680(0,6-9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "5680(2-5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56801(0,1)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56801(4,8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56801(5-7,9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 568012,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-01-06 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 568013,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "5685(0-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "56856(0-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 568568,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-05 12:15"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "5688(0,1-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": 56888,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "569(0,1-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 56,
      "Zakres (number)": "569(8,9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "TORU�",
      "Strefa (symbol)": "SN-TO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58(2,4)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "581(0,1-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 5819,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "583(6,7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "5830(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58309(0-2,4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-08 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 583093,
      "Operator (nazwa)": "M3.NET Sp. z o.o.",
      "Operator (ID)": 10099,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-08-26 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 583095,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "5831(0,1-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58319(0,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-12-29 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 583191,
      "Operator (nazwa)": "didXL B.V.",
      "Operator (ID)": 13079,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-10-22 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 583192,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-02-29 10:01"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 583193,
      "Operator (nazwa)": "NETTEL Sp. z o.o.",
      "Operator (ID)": 9575,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "5832(0,2-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-04-01 12:45"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58321(0-3,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-04-01 12:45"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58321(4-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-04-01 12:45"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58325(2-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 583250,
      "Operator (nazwa)": "Marat Sp. z o.o.",
      "Operator (ID)": 8620,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-08-03 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 583251,
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-12 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58326(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 583269,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-13 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 58327,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58328(0,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58328(1,2,3,5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-08 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58328(6-8)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 583284,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58329(0-1,4,6-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58329(3,5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 583292,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 583299,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-18 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "5833(0,1,2,4-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58333(0,6)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 583331,
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 583332,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-06-01 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 583333,
      "Operator (nazwa)": "Volta Communications Sp. z o.o.",
      "Operator (ID)": 9327,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-08-29 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 583334,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 583335,
      "Operator (nazwa)": "NETPOL Piotr Pruba",
      "Operator (ID)": 9618,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 583337,
      "Operator (nazwa)": "JP-Telekom Sp. z o.o.",
      "Operator (ID)": 6539,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 583338,
      "Operator (nazwa)": "Colt Technology Services GmbH",
      "Operator (ID)": 12380,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-12-02 12:45"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 583339,
      "Operator (nazwa)": "Nettelekom GK Sp. z o.o.",
      "Operator (ID)": 9896,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 58337,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "5834(0-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58348(0-1,4-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58348(2,3)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "5835(6-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58350(0,1,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58350(3-8)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 583502,
      "Operator (nazwa)": "Focus Telecom Polska Sp. z o.o.",
      "Operator (ID)": 8974,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58351(0,9)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58351(1-4)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58351(5-8)",
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-20 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 58352,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58353(0-1)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-10 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58353(2-4)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-10 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58353(5-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 58354,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58355(1,2)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58355(3,7)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58355(5,6)",
      "Operator (nazwa)": "Volta Communications Sp. z o.o.",
      "Operator (ID)": 9327,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 583550,
      "Operator (nazwa)": "TB Telecom Sp. z o.o.",
      "Operator (ID)": 8831,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-01-21 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 583554,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 583558,
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-12-14 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 583559,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-24 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58358(0,9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-12-15 12:45"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58358(1-2,4-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-14 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 583583,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-10 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 583588,
      "Operator (nazwa)": "E-CALL POLSKA Sp. z o.o.",
      "Operator (ID)": 9331,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-12-31 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "5838(1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 58380,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58382(0,1,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-03-18 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58382(3-5)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 583822,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-03-18 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "5850(1-4)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "5850(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58500(5,8)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 585000,
      "Operator (nazwa)": "CALL3.PL Sp. z o.o.",
      "Operator (ID)": 7273,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 585001,
      "Operator (nazwa)": "Volta Communications Sp. z o.o.",
      "Operator (ID)": 9327,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 585002,
      "Operator (nazwa)": "CLUDO Sp z o.o.",
      "Operator (ID)": 10056,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-02-01 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 585003,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 585004,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 585006,
      "Operator (nazwa)": "Telnyx Ireland Limited",
      "Operator (ID)": 13021,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-01 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 585007,
      "Operator (nazwa)": "DANAR Wojtysiak Sp. J.",
      "Operator (ID)": 10433,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-02-16 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 585009,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-04 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58505(1-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 585050,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 585055,
      "Operator (nazwa)": "Limtel Sp. z o.o.",
      "Operator (ID)": 9118,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58506(0-3,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-04-10 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58506(5,6)",
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 585064,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-13 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 585067,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-04-10 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "5851(3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58510(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 585100,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-01-21 12:45"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 58511,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58512(0-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 585128,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "5852(0,1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "5852(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-04-21 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58523(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 585230,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58524(0,1-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 585246,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58525(0,1-4)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-17 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58525(5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-17 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58526(0,2-7,9)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 585261,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 585268,
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58527(0,2-8)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58527(1,9)",
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "5853(0,1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "5853(4,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58533(0,1-3,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58533(4,6)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 585335,
      "Operator (nazwa)": "FIBERNET �ukasz Wi�niewski",
      "Operator (ID)": 10087,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58535(0,6,7)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58535(1-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-08 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 585355,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 585358,
      "Operator (nazwa)": "GECKONET SPӣKA Z OGRANICZON� ODPOWIEDZIALNO�CI�",
      "Operator (ID)": 9572,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-10-19 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 585359,
      "Operator (nazwa)": "DIDWW Ireland Limited",
      "Operator (ID)": 12132,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-19 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58536(0,1-4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 585365,
      "Operator (nazwa)": "ZIPNET Sp. z o.o. (d. PC SERVICE S.C.)",
      "Operator (ID)": 948,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-12-16 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 5854,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "5855(0-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58558(0-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 585588,
      "Operator (nazwa)": "THULIUM Sp. z o.o.",
      "Operator (ID)": 13433,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-16 12:45"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58559(0,2,4,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58559(1,3,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-02-09 12:45"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 585595,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-02-09 12:45"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "5856(4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58560(0-2,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-08 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58560(4-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 585603,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-08 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58561(0,1-4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 585615,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 58562,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58563(0,1-4,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58563(5,6)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "5857(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "5857(3,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58571(0,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58571(1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58571(3-7)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 585719,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 58572,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58574(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58574(1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "5858(1,3,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-12-11 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 58580,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58582(0,1-5,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58582(6-8)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-17 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58584(0-3,5-9)",
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-12-03 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 585844,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-12-11 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58585(1,2)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-13 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 585850,
      "Operator (nazwa)": "Debesis Sp. z o.o.",
      "Operator (ID)": 10138,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-05-19 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 585853,
      "Operator (nazwa)": "LIMES Sp. z o.o.",
      "Operator (ID)": 1588,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-30 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 585854,
      "Operator (nazwa)": "JMDI J. Maleszko",
      "Operator (ID)": 3487,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 585855,
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-20 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 585856,
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 585857,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-10-30 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 585858,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 585859,
      "Operator (nazwa)": "IP Telecom Bulgaria LTD",
      "Operator (ID)": 11805,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-03-23 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58586(0,2,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58586(1,3-5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 12:59"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58587(0,2-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58587(1,7,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 585879,
      "Operator (nazwa)": "Dialoga Servicios Interactivos S.A.",
      "Operator (ID)": 10930,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58588(0,1-5,7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 585886,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-12-30 12:45"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 585888,
      "Operator (nazwa)": "SUDER Sp. z o.o.",
      "Operator (ID)": 10822,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-03-02 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "5859(0,1-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58595(0,1-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 585959,
      "Operator (nazwa)": "AIROUTE S.C.",
      "Operator (ID)": 10919,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "586(1,3-4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "5860(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58600(7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-08-31 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 586000,
      "Operator (nazwa)": "CALL3.PL Sp. z o.o.",
      "Operator (ID)": 7273,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 586001,
      "Operator (nazwa)": "Volta Communications Sp. z o.o.",
      "Operator (ID)": 9327,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-01-10 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 586002,
      "Operator (nazwa)": "Volta Communications Sp. z o.o.",
      "Operator (ID)": 9327,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-08 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 586003,
      "Operator (nazwa)": "Volta Communications Sp. z o.o.",
      "Operator (ID)": 9327,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 586004,
      "Operator (nazwa)": "MEDIDESK Sp. z o.o.",
      "Operator (ID)": 12669,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-08-31 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 586005,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-12-22 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 586006,
      "Operator (nazwa)": "LOGIKA Tomasz Gruca",
      "Operator (ID)": 5129,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 586008,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "5862(0,2-5,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58621(0,1,2,4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58621(3,5)",
      "Operator (nazwa)": "Zarz�d Morskiego Portu Gdynia S.A.",
      "Operator (ID)": 1155,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "Gda�sk",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58626(0-1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-08 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 586262,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-08 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58627(0,2-3,5-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-08 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58627(1,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-08 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 586274,
      "Operator (nazwa)": "Zarz�d Morskiego Portu Gdynia S.A.",
      "Operator (ID)": 1155,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "Gda�sk",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58628(0-1,4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58628(2,3,5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "5865(0-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58658(0-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 586586,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-05 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "5866(0,1,3-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58662(0,1-4,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58662(5,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58667(0,1-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 586676,
      "Operator (nazwa)": "Actus-Info Sp. z o.o.",
      "Operator (ID)": 9186,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "5867(1-4,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58670(6,7)",
      "Operator (nazwa)": "CHOPIN Telewizja Kablowa Sp. z o.o.",
      "Operator (ID)": 2677,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "Wejherowo",
      "Data modyfikacji": "2017-02-01 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 586700,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-12 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 586701,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 586702,
      "Operator (nazwa)": "UAB B2B Network (d. UAB NordConnect)",
      "Operator (ID)": 11976,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2021-03-20 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 586703,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-24 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 586704,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "Ko�cierzyna",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 586705,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "Gdynia",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 586708,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "Wejherowo",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 586709,
      "Operator (nazwa)": "FORWEB S.C. Monika Bodetko Tomasz Paw�owski",
      "Operator (ID)": 5731,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58675(0-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 586757,
      "Operator (nazwa)": "Connected Sp. z o.o.",
      "Operator (ID)": 8033,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-05-16 12:45"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58676(0,1-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 586767,
      "Operator (nazwa)": "CHOPIN Telewizja Kablowa Sp. z o.o.",
      "Operator (ID)": 2677,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "Wejherowo",
      "Data modyfikacji": "2017-02-01 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "5868(1,3-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58680(0-5,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-08 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 586806,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-08-13 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 586807,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-04-30 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58682(0-3,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 586824,
      "Operator (nazwa)": "Platan Sp. z o.o. Sp. k.",
      "Operator (ID)": 12733,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-10-08 12:20"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58688(0,1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58688(3-5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-08-05 12:45"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 586886,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-10-29 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 586887,
      "Operator (nazwa)": "LoVo Sp. z o.o.",
      "Operator (ID)": 7184,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-08-30 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 586888,
      "Operator (nazwa)": "MAXNET �ukasz Hamerski",
      "Operator (ID)": 1197,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 586889,
      "Operator (nazwa)": "Blue Prospect Sp. z o.o.",
      "Operator (ID)": 13345,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-08-05 12:45"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 58689,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "5869(1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "5869(3,5,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58690(0-2,5,8-9)",
      "Operator (nazwa)": "Cyfrowe Systemy Telekomunikacyjne Sp. z o.o.",
      "Operator (ID)": 1058,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2021-12-07 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58690(4,7)",
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 586903,
      "Operator (nazwa)": "ENTER T&T Sp. z o.o.",
      "Operator (ID)": 10688,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 586906,
      "Operator (nazwa)": "MAXNET �ukasz Hamerski",
      "Operator (ID)": 1197,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2021-12-07 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58694(0,1-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 586947,
      "Operator (nazwa)": "ATMAN Sp. z o.o.",
      "Operator (ID)": 944,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 58697,
      "Operator (nazwa)": "TeleGo sp. z o.o.",
      "Operator (ID)": 9324,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58698(0,6-9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58698(1,3-5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-04-01 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 586982,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2021-08-11 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58699(1,2)",
      "Operator (nazwa)": "MAXNET �ukasz Hamerski",
      "Operator (ID)": 1197,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2021-12-07 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58699(3,5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-11-30 12:45"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 586990,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2024-04-25 12:45"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 586994,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2024-04-24 12:45"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 586996,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2021-04-30 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 586997,
      "Operator (nazwa)": "FREELY Sp. z o.o.",
      "Operator (ID)": 13143,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 586998,
      "Operator (nazwa)": "Cyfrowe Systemy Telekomunikacyjne Sp. z o.o.",
      "Operator (ID)": 1058,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-05-01 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 586999,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-06-16 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "5870(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58700(1-6,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587000,
      "Operator (nazwa)": "CALL3.PL Sp. z o.o.",
      "Operator (ID)": 7273,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587007,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-08-07 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587009,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "5871(0,7-9)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58711(1-9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "Gdynia",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587110,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "Gdynia",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58712(8,9)",
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587120,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "Gda�sk",
      "Data modyfikacji": "2020-09-08 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587121,
      "Operator (nazwa)": "EPOKA Sp. z o.o.",
      "Operator (ID)": 5875,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2021-04-08 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587122,
      "Operator (nazwa)": "EPOKA Sp. z o.o.",
      "Operator (ID)": 5875,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-12-31 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587123,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-04-30 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587124,
      "Operator (nazwa)": "EPOKA Sp. z o.o.",
      "Operator (ID)": 5875,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2021-04-08 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587125,
      "Operator (nazwa)": "PLAST-COM S.C. Marcin Skucha, Monika Skucha",
      "Operator (ID)": 8465,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-07 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587126,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587127,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-01 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 58713,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58714(0-7,9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587148,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 58715,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-15 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58716(0,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-13 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58716(1-3)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-15 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58716(4-8)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-13 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "5872(0,3-5,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "5872(1,2)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58726(0,6)",
      "Operator (nazwa)": "EPOKA Sp. z o.o.",
      "Operator (ID)": 5875,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-12-31 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58726(1-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-12-31 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58727(0,3)",
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587271,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587272,
      "Operator (nazwa)": "Nowatel Sp. z o.o.",
      "Operator (ID)": 1633,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587274,
      "Operator (nazwa)": "e-Telko Sp. z o.o.",
      "Operator (ID)": 11564,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-04-19 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587275,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-12-14 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587276,
      "Operator (nazwa)": "Tintech Facility Sp. z o.o.",
      "Operator (ID)": 12296,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-07-12 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587277,
      "Operator (nazwa)": "BIALL-NET Sp. z o.o.",
      "Operator (ID)": 2290,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587278,
      "Operator (nazwa)": "Televoice Polska Sp. z o.o.",
      "Operator (ID)": 8808,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587279,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587280,
      "Operator (nazwa)": "AMS SYSTEM Sp. z o.o.",
      "Operator (ID)": 7006,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587281,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "Hel",
      "Data modyfikacji": "2023-01-02 12:20"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587282,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587283,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-01 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587284,
      "Operator (nazwa)": "LIMES Sp. z o.o.",
      "Operator (ID)": 1588,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587285,
      "Operator (nazwa)": "TELKAB Sp. z o.o.",
      "Operator (ID)": 6807,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587286,
      "Operator (nazwa)": "TOYA Sp. z o.o.",
      "Operator (ID)": 127,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-18 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587287,
      "Operator (nazwa)": "E-CALL POLSKA Sp. z o.o.",
      "Operator (ID)": 9331,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587288,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-10-30 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587289,
      "Operator (nazwa)": "Netpartner Polska Sp. z o.o.",
      "Operator (ID)": 7242,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "5873(0,4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 58731,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58732(0,1,3-6,8,9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58732(2,7)",
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-24 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58733(0,7)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-31 12:45"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58733(1-3)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-02-14 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58733(5,6)",
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587334,
      "Operator (nazwa)": "Intelligent Technologies S.A.",
      "Operator (ID)": 748,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-02-14 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587338,
      "Operator (nazwa)": "Intelligent Technologies S.A.",
      "Operator (ID)": 748,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587339,
      "Operator (nazwa)": "IPTELL Sp. z o.o.",
      "Operator (ID)": 7201,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 58735,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "Gda�sk",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58736(0,6-9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-01 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58736(1-4)",
      "Operator (nazwa)": "CHOPIN Telewizja Kablowa Sp. z o.o.",
      "Operator (ID)": 2677,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-04-14 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587365,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 58737,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58738(1-3,6,8,9)",
      "Operator (nazwa)": "CHOPIN Telewizja Kablowa Sp. z o.o.",
      "Operator (ID)": 2677,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-04-14 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587380,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-01 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587384,
      "Operator (nazwa)": "OK Telekom Sp. z o.o.",
      "Operator (ID)": 12913,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-04-09 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587385,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "Wejherowo",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587387,
      "Operator (nazwa)": "SMSWIZARD POLSKA Sp. z o.o.",
      "Operator (ID)": 12975,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-01-25 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58739(1,6,8-9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-14 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58739(2-4)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587390,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-12-15 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587395,
      "Operator (nazwa)": "VOXNET Sp. z o.o. Sp. k.",
      "Operator (ID)": 728,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587397,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "5874(4,5)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "5874(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58740(0-6,8)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58740(7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 58741,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58742(1-3)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587420,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587424,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587425,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587426,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587427,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587428,
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587429,
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58743(0,6)",
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58743(1,2)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58743(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-09 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587433,
      "Operator (nazwa)": "e-Telko Sp. z o.o.",
      "Operator (ID)": 11564,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587434,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587435,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58746(0,1)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58746(2-5,7-8)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587466,
      "Operator (nazwa)": "ITH Sp. Z o.o.",
      "Operator (ID)": 12635,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-03-14 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587469,
      "Operator (nazwa)": "BT Poland Sp. z o.o.",
      "Operator (ID)": 109,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-03 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "5875(0,4,6-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58751(0,1,2,4,5)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58751(3,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58752(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587522,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 58753,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58755(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-23 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587555,
      "Operator (nazwa)": "Nowatel Sp. z o.o.",
      "Operator (ID)": 1633,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-23 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58758(0-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587587,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2021-10-19 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "5876(0-4,7-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58765(0-2,4-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587653,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58766(0,1-3,5-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587664,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "5877(0-4,7-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58775(0-7,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587758,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58776(0,6,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58776(2-5)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58776(8,9)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587761,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "5878(0,4,6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-08 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "5878(1,5)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58782(0,1-3,5-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587824,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58783(0-6,8-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587837,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58787(0-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-08 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 587878,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-08 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 5879,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "588(1-4,6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "5880(0,6-9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "5880(4,5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58801(2,5-9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 588010,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 588011,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 588013,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 588014,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58802(2-4,7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58802(5,8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58802(6,9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 588020,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 588021,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-01-06 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58803(1,2,6-9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58803(4,5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 588030,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-04-01 12:45"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 588033,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "5885(0-4,6-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-03-07 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58855(1-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-03-11 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 588550,
      "Operator (nazwa)": "Multimedia Capital Two Sp. z o.o.",
      "Operator (ID)": 12983,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-03-11 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 588555,
      "Operator (nazwa)": "K2M Sp. z o.o.",
      "Operator (ID)": 13135,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-30 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58858(0,1-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 588588,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "5887(0,1-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58879(0,1,2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 588793,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "5888(3-4,6-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-02-01 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58880(1,2)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-06-11 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58880(3-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-06-11 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 588800,
      "Operator (nazwa)": "SUDER Sp. z o.o.",
      "Operator (ID)": 10822,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 588808,
      "Operator (nazwa)": "Spikon Sp. z o.o.",
      "Operator (ID)": 10784,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-09-15 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 58881,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 58882,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "58885(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-02-01 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 588855,
      "Operator (nazwa)": "GT11 Sp. z o.o.",
      "Operator (ID)": 10475,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-02-01 12:15"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": 58888,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "589(0,1-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 58,
      "Zakres (number)": "589(8,9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "GDA�SK",
      "Strefa (symbol)": "SN-GD",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59(2,4-6)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "591(0,1-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 5919,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "593(1-2,4,6-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "5930(1-5,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59300(2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2023-06-22 12:20"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 593000,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 593001,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2023-06-22 12:20"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 593003,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59306(0,4-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59306(1-3)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 593067,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59307(3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-10-31 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 593070,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 593071,
      "Operator (nazwa)": "Multimedia Polska Biznes S.A.",
      "Operator (ID)": 11221,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-01-16 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 593072,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2021-03-12 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59308(1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 593080,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-12-31 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 593082,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "5933(0,1,2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 59333,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "5935(0-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59359(1-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-09-03 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 593590,
      "Operator (nazwa)": "ASTA-NET S.A.",
      "Operator (ID)": 609,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-09-03 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 593593,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-10 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "5939(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59393(0-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 593939,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "597(0,3,4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-08-14 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "5971(0,3,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59711(0,1-5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59711(6-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59712(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 597121,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59714(0-4,8-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59714(5-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 597150,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 597151,
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 597152,
      "Operator (nazwa)": "IPTELL Sp. z o.o.",
      "Operator (ID)": 7201,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 597153,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 597154,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 597155,
      "Operator (nazwa)": "Nettelekom GK Sp. z o.o.",
      "Operator (ID)": 9896,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 597156,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-10-05 12:20"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 597157,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 597158,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 597159,
      "Operator (nazwa)": "Telnyx Ireland Limited",
      "Operator (ID)": 13021,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-01 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59720(0,8-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59720(1,4)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59720(5,6)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 597202,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-17 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 597203,
      "Operator (nazwa)": "Petrus Sp. z o.o.",
      "Operator (ID)": 139,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 597207,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59721(4,5)",
      "Operator (nazwa)": "Gawex Media Sp. z o.o.",
      "Operator (ID)": 1111,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 597210,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "L�bork",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 597211,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-01-01 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 597212,
      "Operator (nazwa)": "Petrus Sp. z o.o.",
      "Operator (ID)": 139,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 597213,
      "Operator (nazwa)": "DIDWW Ireland Limited",
      "Operator (ID)": 12132,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-19 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 597216,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-15 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 597217,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-04 12:46"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 597218,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "S�upsk",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 597219,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "Ustka",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59722(4,5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 597220,
      "Operator (nazwa)": "PPH RENE Wies�aw Kolatowicz",
      "Operator (ID)": 681,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 597221,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 597222,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 597223,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 597226,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-12-01 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 597227,
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 597228,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 597229,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59723(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59723(4,5)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 597231,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "L�bork",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 597232,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "S�upsk",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 597233,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "Ustka",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59724(1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 597240,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 597247,
      "Operator (nazwa)": "Pomerania Services Group Sp. z o.o.",
      "Operator (ID)": 11625,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-03-03 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 597248,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 597249,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59725(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59725(1-6)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 597257,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59726(0,1,8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59726(2-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59727(0,1-5)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 597276,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 597277,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 597278,
      "Operator (nazwa)": "VOXNET Sp. z o.o. Sp. k.",
      "Operator (ID)": 728,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 597279,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59728(0,6-9)",
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59728(1-4)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 597285,
      "Operator (nazwa)": "WMC NET Sp. z o.o. (d. WMC Systemy Informatyczne Sp. z o.o.)",
      "Operator (ID)": 8208,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 59729,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "5975(0-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-08-14 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59759(0-6,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-08-14 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 597597,
      "Operator (nazwa)": "SUDER Sp. z o.o.",
      "Operator (ID)": 10822,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-08-14 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "598(7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "5980(0,6-9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "5980(3-5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59801(0,3-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59801(5-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 598011,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-01-06 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 598012,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 598018,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 598019,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59802(3,9,0)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59802(4-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 598021,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-04-01 12:45"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 598022,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "5981(2,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 59810,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59811(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 598110,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-03-29 12:46"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59813(0,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59813(1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59814(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 598140,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59815(2-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59815(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 598150,
      "Operator (nazwa)": "Dialoga Servicios Interactivos S.A.",
      "Operator (ID)": 10930,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 598151,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 598156,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "5982(0,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59821(0,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59821(1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59822(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 598220,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59823(0,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59823(1-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59824(0,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59824(2,3)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-14 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 598241,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "5983(0,1,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59832(1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59832(7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 598320,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 598328,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 59833,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59834(0,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59834(1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "5984(1-3,6,8,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59840(0-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59840(6,7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 598408,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59844(0,1-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 598446,
      "Operator (nazwa)": "ENTER T&T Sp. z o.o.",
      "Operator (ID)": 10688,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59845(0,1,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59845(2-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59847(0,1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59847(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "5985(0-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 59857,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59858(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 598589,
      "Operator (nazwa)": "DARCONNECT Dariusz Wasiuta",
      "Operator (ID)": 5797,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59859(0-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 598598,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-05 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "5986(0,4,5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59861(0,6-9)",
      "Operator (nazwa)": "TeleGo sp. z o.o.",
      "Operator (ID)": 9324,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-09-25 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59861(1-3,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 598614,
      "Operator (nazwa)": "FREELY Sp. z o.o.",
      "Operator (ID)": 13143,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 59862,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59863(0,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59863(1-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59866(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59866(1-5,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 598666,
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-20 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "5988(1-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59880(0,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59880(1-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "59888(0,2-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 598881,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 598888,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": 598889,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-07-23 12:15"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "599(0,1-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 59,
      "Zakres (number)": "599(8,9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "S�UPSK",
      "Strefa (symbol)": "SN-S�",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61(5,7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "611(2-5,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-01-24 12:45"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "6110(3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-08-13 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61100(2-6)",
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61100(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 611000,
      "Operator (nazwa)": "SUDER Sp. z o.o.",
      "Operator (ID)": 10822,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 611001,
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-12 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 61101,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2021-08-07 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 61102,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2021-08-07 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "6111(2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61110(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 611100,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61111(4-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-04-30 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 611110,
      "Operator (nazwa)": "THULIUM Sp. z o.o.",
      "Operator (ID)": 13433,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2024-03-16 12:45"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 611111,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 611112,
      "Operator (nazwa)": "e-Telko Sp. z o.o.",
      "Operator (ID)": 11564,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-04-19 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 611113,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-07-02 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 611116,
      "Operator (nazwa)": "K2M Sp. z o.o.",
      "Operator (ID)": 13135,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-04-30 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "6116(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61161(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-09 12:45"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61161(3-6)",
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-04 12:45"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 611611,
      "Operator (nazwa)": "SUDER Sp. z o.o.",
      "Operator (ID)": 10822,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 611612,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 611617,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-09 12:45"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "6117(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-01-24 12:45"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61171(0-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-01-24 12:45"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 611717,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-01-24 12:45"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 6119,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "612(1,3,4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "6120(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-06-15 12:45"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61200(3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-22 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 612000,
      "Operator (nazwa)": "SUDER Sp. z o.o.",
      "Operator (ID)": 10822,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 612001,
      "Operator (nazwa)": "ST LINE Sp. z o.o. Sp. k.",
      "Operator (ID)": 12096,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-01 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 612002,
      "Operator (nazwa)": "ITEL Solutions Sp. z o.o.",
      "Operator (ID)": 11274,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-22 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "6122(0,8-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "6122(1-7)",
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-08-07 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "6125(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61250(6,7)",
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 612500,
      "Operator (nazwa)": "GCI Sp. z o.o.",
      "Operator (ID)": 9672,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 612501,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 612502,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 612503,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 612504,
      "Operator (nazwa)": "ST LINE Sp. z o.o. Sp. k.",
      "Operator (ID)": 12096,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-01 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 612505,
      "Operator (nazwa)": "WWW.PIOSIK.PL Dariusz Piosik",
      "Operator (ID)": 4972,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 612508,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 612509,
      "Operator (nazwa)": "CLUDO Sp z o.o.",
      "Operator (ID)": 10056,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-02-01 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "6126(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61261(0,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-10-30 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 612611,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-10-30 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 612612,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 612613,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-10-30 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "6127(0,2-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 61271,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61278(0,8-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61278(1-3,5)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 612784,
      "Operator (nazwa)": "ITH Sp. Z o.o.",
      "Operator (ID)": 12635,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-03-14 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 612786,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 612787,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61279(0-1,3-5,9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61279(7,8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 612792,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 612796,
      "Operator (nazwa)": "Super Net S.A.",
      "Operator (ID)": 12117,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-28 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "6128(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "6128(1-2,5-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61283(0,2-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 612831,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-01 12:45"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61284(0,1-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 612846,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-01 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "6129(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "6129(1-3,5,6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61294(2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 612940,
      "Operator (nazwa)": "Dialoga Servicios Interactivos S.A.",
      "Operator (ID)": 10930,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 612941,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61297(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 612979,
      "Operator (nazwa)": "Galena Sp. z o.o.",
      "Operator (ID)": 66,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-16 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "613(1,2,4,5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "6130(1-5,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61300(2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-09-21 12:20"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 613000,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-07-01 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 613001,
      "Operator (nazwa)": "JMDI J. Maleszko",
      "Operator (ID)": 3487,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-09-21 12:20"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 613003,
      "Operator (nazwa)": "NETTEL Sp. z o.o.",
      "Operator (ID)": 9575,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61306(0,4-6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-01-18 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 613061,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 613062,
      "Operator (nazwa)": "S-NET Sp. z o.o.",
      "Operator (ID)": 8697,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 613063,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 613067,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 613068,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-01-18 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 61307,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61308(0,1,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:00"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61308(2-3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 613084,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "6133(0-2,4-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61333(0,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61333(1,2)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61333(4,5)",
      "Operator (nazwa)": "LoVo Sp. z o.o.",
      "Operator (ID)": 7184,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 613333,
      "Operator (nazwa)": "AlterTEL Sp. z o.o.",
      "Operator (ID)": 8614,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 613336,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-11-26 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61338(1-4,6-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2024-04-17 12:45"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 613380,
      "Operator (nazwa)": "Connected Sp. z o.o.",
      "Operator (ID)": 8033,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2024-04-17 12:45"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 613385,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 613389,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-23 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "6136(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61361(0,1,2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 613613,
      "Operator (nazwa)": "SUDER Sp. z o.o.",
      "Operator (ID)": 10822,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "614(5,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "6140(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61400(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 614000,
      "Operator (nazwa)": "IPTELEKOM Micha� Ludwiczak",
      "Operator (ID)": 10051,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "6141(0,2-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61411(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 614111,
      "Operator (nazwa)": "Debesis Sp. z o.o.",
      "Operator (ID)": 10138,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61415(1,2,5)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61415(4,6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 614150,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-10-30 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 614153,
      "Operator (nazwa)": "Colt Technology Services GmbH",
      "Operator (ID)": 12380,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-12-02 12:45"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 614157,
      "Operator (nazwa)": "Nettelekom GK Sp. z o.o.",
      "Operator (ID)": 9896,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 614158,
      "Operator (nazwa)": "ITEL Solutions Sp. z o.o.",
      "Operator (ID)": 11274,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-09-30 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 614159,
      "Operator (nazwa)": "VISTEL Dariusz Ptak",
      "Operator (ID)": 10338,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "6142(0,1,2)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "6142(4-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61423(0,1,4,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 614232,
      "Operator (nazwa)": "Komster Sp. z o.o.",
      "Operator (ID)": 1265,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 614233,
      "Operator (nazwa)": "Actus-Info Sp. z o.o.",
      "Operator (ID)": 9186,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 614235,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2021-12-14 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 614236,
      "Operator (nazwa)": "DIDWW Ireland Limited",
      "Operator (ID)": 12132,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-10-19 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61428(0,3,5,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61428(1,2,4,6,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61429(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 614299,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "Brodnica, Czempi�, Mosina",
      "Data modyfikacji": "2023-12-15 12:45"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "6143(0,2-4,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "6143(7,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61431(0,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61431(1,2)",
      "Operator (nazwa)": "SpTelek Sp. z o.o.",
      "Operator (ID)": 7320,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "Nowy Tomy�l",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61435(0,1-4,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61435(5-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61436(0,1-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 614368,
      "Operator (nazwa)": "Telkonekt Sp. z o.o.",
      "Operator (ID)": 9086,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-06-14 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "6144(0,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "6144(1,2,4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "6144(7,8)",
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-08-07 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61443(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 614439,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-01 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61445(0,1-3,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61445(4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61449(0,3-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61449(1,2)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 614499,
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-20 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "6146(0,2-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61461(0,8,9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-05-13 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61461(1-3)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-10 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61461(5-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-05-13 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 614614,
      "Operator (nazwa)": "SUDER Sp. z o.o.",
      "Operator (ID)": 10822,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61468(0,1,2)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61468(3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "6147(0,1-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "6147(8,9)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61477(0,5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61477(1,2)",
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-20 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61477(3,4,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "616(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "6160(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61600(1-5,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-08-29 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616000,
      "Operator (nazwa)": "SUDER Sp. z o.o.",
      "Operator (ID)": 10822,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616006,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-12-22 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616007,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-08-29 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "6161(2,3,5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-08 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61610(0,9)",
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61610(2,3)",
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616101,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616104,
      "Operator (nazwa)": "Intelligent Technologies S.A.",
      "Operator (ID)": 748,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616105,
      "Operator (nazwa)": "EAST AND WEST Sp. z o.o.",
      "Operator (ID)": 2863,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616106,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616107,
      "Operator (nazwa)": "IPTELL Sp. z o.o.",
      "Operator (ID)": 7201,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616108,
      "Operator (nazwa)": "UAB B2B Network (d. UAB NordConnect)",
      "Operator (ID)": 11976,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-03-20 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 61611,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61614(0,1)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-04-19 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61614(2,4,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-04-19 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61614(3,5-7)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-03 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616148,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-23 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61616(0,4-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2021-10-30 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61616(2,3)",
      "Operator (nazwa)": "SUDER Sp. z o.o.",
      "Operator (ID)": 10822,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2021-10-30 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616161,
      "Operator (nazwa)": "SUDER Sp. z o.o.",
      "Operator (ID)": 10822,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-08 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616166,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2021-06-08 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "6162(7-8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 61620,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61621(1,2)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61621(3-4,6-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-12-16 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616210,
      "Operator (nazwa)": "e-Telko Sp. z o.o.",
      "Operator (ID)": 11564,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616215,
      "Operator (nazwa)": "ATMEL Sp. z o.o.",
      "Operator (ID)": 9777,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-12-16 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616219,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61622(0,1-8)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616229,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-12-01 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61623(0,3-9)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61623(1,2)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61624(0,1,4-6,8,9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61624(2,3,7)",
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-12-01 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61625(0,1-5,7-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616256,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-12-01 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 61626,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61629(0-2,7-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61629(3-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "6163(6,7)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61630(0,1,2,4-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616303,
      "Operator (nazwa)": "Pozna�ska Sp�dzielnia Mieszkaniowa WINOGRADY w Poznaniu",
      "Operator (ID)": 1805,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61631(0,4-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-02-09 12:45"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616311,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616312,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616313,
      "Operator (nazwa)": "Limtel Sp. z o.o.",
      "Operator (ID)": 9118,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616316,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-02-09 12:45"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 61632,
      "Operator (nazwa)": "TeleGo sp. z o.o.",
      "Operator (ID)": 9324,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 61633,
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61634(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61634(1-7)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61635(1,3-5)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61635(6-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616350,
      "Operator (nazwa)": "Focus Telecom Polska Sp. z o.o.",
      "Operator (ID)": 8974,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616352,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-04 12:46"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616359,
      "Operator (nazwa)": "Focus Telecom Polska Sp. z o.o.",
      "Operator (ID)": 8974,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-12-08 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 61638,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 61639,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "6164(4,5)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 61640,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61641(4,5)",
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61641(6,7,9)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616410,
      "Operator (nazwa)": "didXL B.V.",
      "Operator (ID)": 13079,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "Pozna�",
      "Data modyfikacji": "2021-10-22 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616411,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-03-01 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616412,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616413,
      "Operator (nazwa)": "TOYA Sp. z o.o.",
      "Operator (ID)": 127,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-02-13 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616418,
      "Operator (nazwa)": "ENTER T&T Sp. z o.o.",
      "Operator (ID)": 10688,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-03 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61642(0,6,8)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61642(1-5)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "Pozna�",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616427,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616429,
      "Operator (nazwa)": "VOXNET Sp. z o.o. Sp. k.",
      "Operator (ID)": 728,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61643(0-8)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616439,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61646(1-5)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61646(7-9)",
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616460,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616466,
      "Operator (nazwa)": "ENTER T&T Sp. z o.o.",
      "Operator (ID)": 10688,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61647(0,6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61647(1-5,7)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61648(0,2,5)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61648(1,3,4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616486,
      "Operator (nazwa)": "SERWUS Katarzyna Grzybowska",
      "Operator (ID)": 12372,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-11-24 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616487,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-11-24 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616488,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-06-09 12:35"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616489,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61649(0,6-9)",
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-08-07 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616491,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-04 12:45"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616492,
      "Operator (nazwa)": "4 CARRIERS GLOBAL Sp. z o.o.",
      "Operator (ID)": 12739,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-12-23 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616493,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-04 12:45"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616494,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616495,
      "Operator (nazwa)": "SATPOL Sp. z o.o. Sp. k.",
      "Operator (ID)": 12693,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-09-12 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "6165(0,1-3,5-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61654(0-1,3-8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616542,
      "Operator (nazwa)": "SMSWIZARD POLSKA Sp. z o.o.",
      "Operator (ID)": 12975,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-01-25 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616549,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "6166(1-5,7)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 61660,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "Pozna�",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61666(1-4)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616660,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616665,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616666,
      "Operator (nazwa)": "SUDER Sp. z o.o.",
      "Operator (ID)": 10822,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-10-29 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616667,
      "Operator (nazwa)": "Pozna�ska Sp�dzielnia Mieszkaniowa WINOGRADY w Poznaniu",
      "Operator (ID)": 1805,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616668,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616669,
      "Operator (nazwa)": "VIRTUAL OPERATOR Sp. z o.o. (d. ServCom Sp. z o.o.)",
      "Operator (ID)": 5770,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61668(0,1,3-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616682,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61669(1-3,5,6)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61669(7,9)",
      "Operator (nazwa)": "VIRTUAL OPERATOR Sp. z o.o. (d. ServCom Sp. z o.o.)",
      "Operator (ID)": 5770,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616690,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616694,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616698,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "6167(0,7)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 61671,
      "Operator (nazwa)": "VIRTUAL OPERATOR Sp. z o.o. (d. Echostar Studio Sp. z o.o.)",
      "Operator (ID)": 584,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61672(1-8)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616720,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-24 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616729,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-24 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61673(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61673(1-5)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61674(0,1-5,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61674(6,7)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616748,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-04-30 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61675(0,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61675(1-3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61676(0,3,6,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61676(1,2,4,5,8,9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61678(0,6-9)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616781,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-08 12:45"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616782,
      "Operator (nazwa)": "FREELY Sp. z o.o.",
      "Operator (ID)": 13143,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616783,
      "Operator (nazwa)": "e-Telko Sp. z o.o.",
      "Operator (ID)": 11564,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616784,
      "Operator (nazwa)": "ITEL Solutions Sp. z o.o.",
      "Operator (ID)": 11274,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-11-27 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 616785,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61679(0,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61679(1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "618(1-2,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "6180(0,6-9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "6180(1,2)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61803(0,5,7-9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61803(1,4,6)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61803(2-3)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61804(1,4)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61804(2,0)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-01-06 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61804(3,7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61804(5-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61804(8,9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61805(0,1,4,5,8-9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61805(2,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-04-01 12:45"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 618053,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 618056,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "6183(0,2-3,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "6183(6,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61831(0-4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 618315,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61834(1-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 618340,
      "Operator (nazwa)": "AMS SYSTEM Sp. z o.o.",
      "Operator (ID)": 7006,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-02-03 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 618347,
      "Operator (nazwa)": "Telnyx Ireland Limited",
      "Operator (ID)": 13021,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-01 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61838(1,3,6-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 618380,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 618382,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 618384,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-23 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 618385,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-22 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 618389,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-23 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61839(0-4,6-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 618395,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 618398,
      "Operator (nazwa)": "Blue Prospect Sp. z o.o.",
      "Operator (ID)": 13345,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-08-05 12:45"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "6184(2-3,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61840(0-4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 618405,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61841(0-2,4-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 618413,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61844(0,1-6,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 618447,
      "Operator (nazwa)": "ATMAN Sp. z o.o.",
      "Operator (ID)": 944,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-01-13 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 618448,
      "Operator (nazwa)": "ATMAN Sp. z o.o.",
      "Operator (ID)": 944,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-01-13 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61845(0,1-6,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61845(7,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61846(0,1-4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 618465,
      "Operator (nazwa)": "ITEL Solutions Sp. z o.o.",
      "Operator (ID)": 11274,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "6185(0-5,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61856(0,2-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 618561,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61857(0,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61857(1-2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "6186(0-2,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61863(0,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61863(1,2)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61863(4,5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 618633,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61864(0-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 618647,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "6188(1,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 61880,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61882(0,2,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61882(1,3-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 618827,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 618829,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61883(0,2-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61883(5-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-08-30 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 618831,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 618838,
      "Operator (nazwa)": "LoVo Sp. z o.o.",
      "Operator (ID)": 7184,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-08-30 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61884(0,1)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-05-31 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61884(2-6,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 618847,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-04-01 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 618848,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-23 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61885(0-4,6-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 618855,
      "Operator (nazwa)": "Best Telecom Sp. z o.o.",
      "Operator (ID)": 11304,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 618859,
      "Operator (nazwa)": "BT Poland Sp. z o.o.",
      "Operator (ID)": 109,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-03-02 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61886(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 618869,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61888(0,1-3,5-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61888(4,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 61889,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "6189(0,3,4,6,7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61891(0,2,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61891(1,3-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61892(0,1,3-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 618922,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-12-31 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61895(0-2,5-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 618953,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 618954,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-02-16 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 618957,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "61898(0,1-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": 618987,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "619(0,1-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 61,
      "Zakres (number)": "619(8,9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "POZNA�",
      "Strefa (symbol)": "SN-PO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62(2,4)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-01-11 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "621(0,1-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 6219,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "623(2,4,5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "6230(1-6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62300(0,3)",
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62300(2,4-7)",
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 623001,
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-12 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 623008,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-06 12:45"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 623009,
      "Operator (nazwa)": "ITEL Solutions Sp. z o.o.",
      "Operator (ID)": 11274,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-06 12:45"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62307(3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 623070,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 623071,
      "Operator (nazwa)": "Nettelekom GK Sp. z o.o.",
      "Operator (ID)": 9896,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 623072,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62308(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 623082,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "6231(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62310(1-3)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62310(4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 623100,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "6233(0,1,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-21 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62332(0,1,7-9)",
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-08-07 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62332(2-6)",
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-08-07 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 62333,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62334(0-4)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-21 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62334(5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-21 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "6236(0-1,3-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62362(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 623623,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-05 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62366(0,1,3-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 623662,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 623666,
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-20 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "625(1-5,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "6250(1-2)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "6250(7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62500(0-2)",
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62500(3-6)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62500(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62503(0-3)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62503(4,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62504(0-4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62504(5-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62505(0,2-8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62505(1,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62506(0-4,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-16 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 625065,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 625066,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-23 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 625067,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-16 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62508(0-3,5-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 625084,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "6256(1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62560(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 625600,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62562(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 625625,
      "Operator (nazwa)": "Telfon Poland Sp. z o.o.",
      "Operator (ID)": 12517,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62580(0,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-12-09 12:45"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62580(3,5,6)",
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 625801,
      "Operator (nazwa)": "IPTELL Sp. z o.o.",
      "Operator (ID)": 7201,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 625802,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 625804,
      "Operator (nazwa)": "Aleksander Cuprych Audio Systems",
      "Operator (ID)": 2060,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 625807,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-07-22 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 625808,
      "Operator (nazwa)": "Aleksander Cuprych Audio Systems",
      "Operator (ID)": 2060,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-12-09 12:45"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62581(0-2)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62581(3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62582(0-2)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62582(3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62583(0-1)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62583(2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62584(2,3)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62584(4,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62584(7,8)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 625840,
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 625841,
      "Operator (nazwa)": "TIMPLUS B. Dudek, R. Walentowski sp�ka jawna",
      "Operator (ID)": 3110,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 625845,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-01 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 625846,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62585(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-07-15 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62585(1-3)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 625854,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-24 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 625855,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-07-15 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62586(0-2)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62586(3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62587(0-1)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62587(2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62588(0-2)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62588(3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62589(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 625890,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "6259(1-2)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62590(8,9)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 625900,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 625901,
      "Operator (nazwa)": "FREELY Sp. z o.o.",
      "Operator (ID)": 13143,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 625902,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 625903,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 625904,
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 625905,
      "Operator (nazwa)": "TOYA Sp. z o.o.",
      "Operator (ID)": 127,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-01-13 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 625906,
      "Operator (nazwa)": "Intelligent Technologies S.A.",
      "Operator (ID)": 748,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 625907,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62593(2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 625930,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 625931,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62594(0,5-7)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62594(1-3)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 625944,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 625948,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 625949,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62595(0-3,5-7,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62595(4,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 62596,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62597(1,3,8,9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62597(2,5)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 625970,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 625974,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 625976,
      "Operator (nazwa)": "VOXNET Sp. z o.o. Sp. k.",
      "Operator (ID)": 728,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 625977,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62598(0-5,8-9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 625986,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 625987,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62599(0-1,3)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62599(7-9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 625992,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 625994,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 625995,
      "Operator (nazwa)": "AMS SYSTEM Sp. z o.o.",
      "Operator (ID)": 7006,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-10-25 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 625996,
      "Operator (nazwa)": "Marat Sp. z o.o.",
      "Operator (ID)": 8620,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-02-02 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "626(1,4-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-25 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "6260(1-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-15 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62600(1-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-26 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 626000,
      "Operator (nazwa)": "SUDER Sp. z o.o.",
      "Operator (ID)": 10822,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 626006,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-26 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62606(0-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-15 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 626066,
      "Operator (nazwa)": "eDial Internet Sp. z o.o. (d. AWB-NET Agnieszka Barzdo)",
      "Operator (ID)": 3258,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-15 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "6262(1-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-20 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62620(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-20 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 626200,
      "Operator (nazwa)": "POLTEL Sp. z o.o.",
      "Operator (ID)": 13246,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-02-02 12:45"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62626(0,1,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 626262,
      "Operator (nazwa)": "ComNet Multimedia Sp. z o.o.",
      "Operator (ID)": 9769,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 626263,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-11-03 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "6263(0,1-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62636(0,1-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 626364,
      "Operator (nazwa)": "ComNet Multimedia Sp. z o.o.",
      "Operator (ID)": 9769,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "6266(0-1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-25 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62662(0-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-25 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 626626,
      "Operator (nazwa)": "Telfon Poland Sp. z o.o.",
      "Operator (ID)": 12517,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-25 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "627(1,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "6270(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62700(2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-10-01 12:20"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 627000,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 627001,
      "Operator (nazwa)": "Spikon Sp. z o.o.",
      "Operator (ID)": 10784,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-10-01 12:20"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "6272(1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62720(0,9)",
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62720(3-7)",
      "Operator (nazwa)": "Przedsi�biorstwo PROMAX Sp. J.",
      "Operator (ID)": 316,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "Nowe Skalmierzyce, Jarocin, Kotlin, Ko�min Wlkp., Odolan�w, Ostr",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 627201,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 627202,
      "Operator (nazwa)": "Przedsi�biorstwo PROMAX Sp. J.",
      "Operator (ID)": 316,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "Dobrzyca, Doruch�w, Go�uch�w, Jaraczewo, Jarocin, Kalisz, Kotlin",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 627208,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62723(0,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62723(1-4)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62724(0,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62724(1-5)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 627246,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-09-30 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62725(0,1-5,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62725(6,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62726(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62726(1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62727(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 627272,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62728(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 627282,
      "Operator (nazwa)": "BORKOW.ORG PPHU Bart�omiej Borkowski",
      "Operator (ID)": 2363,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 62729,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "6273(0,5,6,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62731(0,1,2,4-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 627313,
      "Operator (nazwa)": "Dialoga Servicios Interactivos S.A.",
      "Operator (ID)": 10930,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-01-13 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 627318,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2023-03-29 12:45"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62732(0,1-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 627326,
      "Operator (nazwa)": "Telnyx Ireland Limited",
      "Operator (ID)": 13021,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-01 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62733(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 627330,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-01-02 12:20"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62734(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 627340,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-04 12:46"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62737(1-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 627370,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-22 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 627377,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62739(0,1-3,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 627394,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "6274(0,2,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "6274(3-6,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62741(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 627410,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-12 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62749(1-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-08 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 627490,
      "Operator (nazwa)": "DIDWW Ireland Limited",
      "Operator (ID)": 12132,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-19 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 627497,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-01 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "6275(0,4-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "6275(3,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62751(0,1-4,7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62751(5,6,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62752(0,1,5,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62752(2-4,6,7)",
      "Operator (nazwa)": "TeleGo sp. z o.o.",
      "Operator (ID)": 9324,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-09-25 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "6276(1,4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62760(0-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 627606,
      "Operator (nazwa)": "CLUDO Sp z o.o.",
      "Operator (ID)": 10056,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-02-01 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62762(0,1-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 627628,
      "Operator (nazwa)": "ENTER T&T Sp. z o.o.",
      "Operator (ID)": 10688,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62763(0,1-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 627636,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62765(0-4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 627655,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "6278(0,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "6278(1,2,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62783(0,4,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62783(1-3,5,6,8,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62784(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 627849,
      "Operator (nazwa)": "OneFone S.A.",
      "Operator (ID)": 11295,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62786(0,3-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62786(1,2,8,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "6279(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62791(0,1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62791(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "628(1-3,5,7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "6280(0,6-9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "6280(2-5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62801(3-5,8,9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62801(6,7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 628010,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 628011,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 628012,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "6284(0,1-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62844(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 628442,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "6286(0-1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62862(0-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 628628,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-10 12:15"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "6288(0,1-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62888(0,3-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "62888(1,2)",
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": 628888,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "629(0,1-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:01"
    },
    {
      "Strefa (AB)": 62,
      "Zakres (number)": "629(8,9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "KALISZ",
      "Strefa (symbol)": "SN-KL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63(4-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-08-31 12:45"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "631(0,1-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 6319,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "6320(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63200(1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-26 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 632000,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 632002,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-26 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "6321(0,5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63211(1-6)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "Konin",
      "Data modyfikacji": "2018-06-01 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63211(7-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 632110,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-02-29 10:01"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63212(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63212(1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63213(0,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63213(1-3,5)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "S�upca",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63213(4,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "S�upca",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63214(0,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63214(1-5)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "Turek",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 632146,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "Turek",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63216(0,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63216(1-4)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63217(1-5)",
      "Operator (nazwa)": "TeleGo sp. z o.o.",
      "Operator (ID)": 9324,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63217(7-9)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 632170,
      "Operator (nazwa)": "DIDWW Ireland Limited",
      "Operator (ID)": 12132,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-19 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 632176,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63218(0,6,9)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 632181,
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 632182,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-01 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 632183,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 632184,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 632185,
      "Operator (nazwa)": "FREELY Sp. z o.o.",
      "Operator (ID)": 13143,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 632187,
      "Operator (nazwa)": "Voice Net Sp. z o.o.",
      "Operator (ID)": 12749,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-02-20 12:45"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 632188,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63219(0,2,3,6-9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 632191,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 632194,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 632195,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63220(0,2,9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-13 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63220(4,6)",
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63220(5,7,8)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 632201,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-04-01 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 632203,
      "Operator (nazwa)": "VOXNET Sp. z o.o. Sp. k.",
      "Operator (ID)": 728,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 63221,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63222(1-3)",
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-08-07 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 632220,
      "Operator (nazwa)": "PRZEDSI�BIORSTWO AJC Adam Ozga Przedsi�biorstwo AJC2",
      "Operator (ID)": 390,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 632224,
      "Operator (nazwa)": "IPTELL Sp. z o.o.",
      "Operator (ID)": 7201,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 632225,
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 632226,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 632227,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 632228,
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 632229,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-04 12:46"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63223(0,9)",
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-08-07 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63223(2-8)",
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-08-07 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 632231,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63224(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 632241,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 63225,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63226(0,1-6)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63226(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63227(0,1-4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63227(5-9)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63228(0,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63228(1,2)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63229(0,1-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63229(7-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "6323(0,1,2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 63233,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "6324(0-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63247(0-1,3-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 632472,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "6325(0,1-4,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63255(4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 632550,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 632551,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-13 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 632552,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 632553,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63256(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 632560,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-08-07 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "6326(0,4-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "6326(1,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63262(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 632620,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63263(1,4,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 632630,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-15 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 632632,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-17 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 632633,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 632635,
      "Operator (nazwa)": "ATMAN Sp. z o.o.",
      "Operator (ID)": 944,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-01-13 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 632636,
      "Operator (nazwa)": "KOL-NET S.C.",
      "Operator (ID)": 10611,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-08-13 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "6327(0,1-3,5,6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63274(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 632749,
      "Operator (nazwa)": "Telnyx Ireland Limited",
      "Operator (ID)": 13021,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-01 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63277(1-2,6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63277(3,4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63277(5,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 632770,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-07 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 632777,
      "Operator (nazwa)": "POLTEL Sp. z o.o.",
      "Operator (ID)": 13246,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-02-02 12:45"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "6328(1-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "6328(8,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63280(0,1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 632807,
      "Operator (nazwa)": "Dialoga Servicios Interactivos S.A.",
      "Operator (ID)": 10930,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 632808,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-07-22 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 632809,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-05-19 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 6329,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "633(1-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "6330(1-5,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63300(4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-25 12:45"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 633000,
      "Operator (nazwa)": "SUDER Sp. z o.o.",
      "Operator (ID)": 10822,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 633001,
      "Operator (nazwa)": "Nettelekom GK Sp. z o.o.",
      "Operator (ID)": 9896,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 633002,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-25 12:45"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 633003,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63306(0,1-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 633067,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63307(1-5)",
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63307(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 633070,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63308(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 633082,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "6336(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63363(0-2,4-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-08-30 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 633633,
      "Operator (nazwa)": "Telfon Poland Sp. z o.o.",
      "Operator (ID)": 12517,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 633636,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-08-30 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "638(1-5,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "6380(0,6-9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "6380(1,3-5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-10-29 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63802(0,3,8-9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63802(1,5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63802(2,4,6,7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "6386(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63863(0-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 638638,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-10 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "6388(0,1-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63888(0,2-4,6,7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 638881,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 638885,
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-20 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": 638888,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "6389(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63890(0-3,6-9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "63890(4,5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "639(0,1-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 63,
      "Zakres (number)": "639(8,9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "KONIN",
      "Strefa (symbol)": "SN-KN",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65(2,7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-24 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "651(0,1-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 6519,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "653(3-4,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "6530(1-6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65300(1-9)",
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-08-07 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 653000,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65307(4-8)",
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 653070,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 653071,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 653072,
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-12 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 653073,
      "Operator (nazwa)": "DIDWW Ireland Limited",
      "Operator (ID)": 12132,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-19 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 653079,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-07-22 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65308(1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-22 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 653080,
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-22 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 653082,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "6531(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-23 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65315(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-23 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 653152,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-23 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "6532(0,3,4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-23 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 65321,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65322(2-6)",
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-08-07 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65322(7-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 653220,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 653221,
      "Operator (nazwa)": "OneFone S.A.",
      "Operator (ID)": 11295,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65325(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-23 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 653252,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-23 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "6535(0,1-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65356(0,2-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-12-29 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 653561,
      "Operator (nazwa)": "e-Telko Sp. z o.o.",
      "Operator (ID)": 11564,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-04-19 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 653568,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "6536(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65365(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 653653,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-04-10 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "654(0-2,4-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "6543(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-24 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65432(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-24 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 654321,
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-24 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "6546(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65465(0-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 654654,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-05-05 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "655(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "6550(1-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65500(1-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-24 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 655000,
      "Operator (nazwa)": "SUDER Sp. z o.o.",
      "Operator (ID)": 10822,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 655005,
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-24 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65506(0,6-9)",
      "Operator (nazwa)": "TeleGo sp. z o.o.",
      "Operator (ID)": 9324,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-09-25 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65506(1-4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-09-25 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 655065,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "6551(0,4-6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65511(0,4-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65511(1-3)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 65512,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65513(0,1,2,4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65513(3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65517(0,4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65517(1-3)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 655175,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65518(0,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65518(1-4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "6552(0,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "6552(2,4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65521(0-2,4-5)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65521(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 655213,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65523(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 655231,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65525(0-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 655257,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-03 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65526(0,1-3,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 655264,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65527(0,1)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65527(2-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 655278,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65528(0,3,4,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65528(1,2,5-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "6553(0,1,2,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65533(0,1,3,6-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65533(4-5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-01-13 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 655332,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2023-03-29 12:45"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 655339,
      "Operator (nazwa)": "Dialoga Servicios Interactivos S.A.",
      "Operator (ID)": 10930,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65534(0,1,2,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65534(3-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-13 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 655348,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65535(0,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65535(2-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 655351,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65536(0,1,2,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65536(3-5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65537(0,1,2,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65537(5-7)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 655373,
      "Operator (nazwa)": "KAJA KOMPUTER Krzysztof Jasek",
      "Operator (ID)": 4411,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-15 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 655374,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-01 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 655378,
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65538(0,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65538(1-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "6554(0,3,6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65541(1-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-06-07 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 655410,
      "Operator (nazwa)": "KAJA KOMPUTER Krzysztof Jasek",
      "Operator (ID)": 4411,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-06-07 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 655419,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65542(0,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-01-12 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65542(1-5)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 655426,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-24 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 655427,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-07-15 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 655428,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-01-12 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65544(0,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-17 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65544(1-4,6-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 655445,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-17 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65545(0,1-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 655458,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65547(0,1-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 655476,
      "Operator (nazwa)": "KAJA KOMPUTER Krzysztof Jasek",
      "Operator (ID)": 4411,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65548(0,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65548(1-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65549(0,2-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 655491,
      "Operator (nazwa)": "Telnyx Ireland Limited",
      "Operator (ID)": 13021,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-01 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "6555(0,1-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 65555,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "6556(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65565(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 655655,
      "Operator (nazwa)": "Telfon Poland Sp. z o.o.",
      "Operator (ID)": 12517,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "6557(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "6557(1-3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65574(2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 655740,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 655741,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65575(0,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65575(1-3,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65575(5-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 655754,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "656(0,3-4,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-11-24 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65610(1,2)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65610(3-5,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65610(6-8)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 656100,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65611(0,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65611(1,2)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-01 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65612(0,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65612(1-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65613(0,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 656131,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 656132,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65614(0,7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-03 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65614(1-6)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 656148,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-03 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 656150,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-16 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 656151,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 656152,
      "Operator (nazwa)": "FREELY Sp. z o.o.",
      "Operator (ID)": 13143,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 656153,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 656154,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 656155,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-01 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 656156,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 656157,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 656158,
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 656159,
      "Operator (nazwa)": "IPTELL Sp. z o.o.",
      "Operator (ID)": 7201,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65616(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-28 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 656161,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 656162,
      "Operator (nazwa)": "Nettelekom GK Sp. z o.o.",
      "Operator (ID)": 9896,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 656163,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 656164,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 656165,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-02-29 10:01"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65617(0,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65617(1,2)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-01 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 656173,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65618(0,1-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65618(7-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65619(0,9)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65619(3,4)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65619(7,8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 656191,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 656192,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 656195,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 656196,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-12-01 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "6562(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65621(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:02"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 656211,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "6565(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-11-24 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65650(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-11-24 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 656500,
      "Operator (nazwa)": "POLTEL Sp. z o.o.",
      "Operator (ID)": 13246,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-02-02 12:45"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "6566(0-4,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-25 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65665(0-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-25 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 656656,
      "Operator (nazwa)": "Telfon Poland Sp. z o.o.",
      "Operator (ID)": 12517,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-25 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65666(1-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-26 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 656660,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-26 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 656666,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-03-15 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "658(1-5,7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "6580(0,6-9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "6580(2-5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65801(0,4,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-04-01 12:45"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65801(1,3)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65801(2,6-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 658015,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-04-01 12:45"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "6586(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65865(0-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 658658,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-05 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "6588(0,1-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "65888(2-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-16 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 658880,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 658881,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 658888,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": 658889,
      "Operator (nazwa)": "ITEL Solutions Sp. z o.o.",
      "Operator (ID)": 11274,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-16 12:15"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "659(0,1-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 65,
      "Zakres (number)": "659(8,9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "LESZNO",
      "Strefa (symbol)": "SN-LE",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67(4-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "671(0,1-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 6719,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "672(7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-23 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "6720(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67200(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 672000,
      "Operator (nazwa)": "SUDER Sp. z o.o.",
      "Operator (ID)": 10822,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "6721(1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "6721(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67210(0-1,4-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67210(2,3)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67217(2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 672170,
      "Operator (nazwa)": "Dialoga Servicios Interactivos S.A.",
      "Operator (ID)": 10930,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 672171,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "6722(0,1,3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-13 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67222(0,1)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67222(2-6)",
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-08-07 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67222(7-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67224(0,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-01-16 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 672241,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2021-03-12 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 672242,
      "Operator (nazwa)": "Multimedia Polska Biznes S.A.",
      "Operator (ID)": 11221,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-01-16 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "6723(1-4,6-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-23 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67230(0,1-3,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67230(5-8)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 672304,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67235(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-23 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 672351,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-23 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67239(0,3-5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-03 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67239(6-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 672391,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 672392,
      "Operator (nazwa)": "e-Telko Sp. z o.o.",
      "Operator (ID)": 11564,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-03 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "6724(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-23 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67245(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-23 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 672451,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-23 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "6725(1,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "6725(3-6,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67250(0-1,4,6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67250(2,3,5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 672507,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67252(1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67252(7,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 672520,
      "Operator (nazwa)": "DIDWW Ireland Limited",
      "Operator (ID)": 12132,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-19 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 672529,
      "Operator (nazwa)": "Telnyx Ireland Limited",
      "Operator (ID)": 13021,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-01 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67259(0,1-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 672598,
      "Operator (nazwa)": "AMS SYSTEM Sp. z o.o.",
      "Operator (ID)": 7006,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-05-31 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "6726(1-3,5,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67260(0,7)",
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67260(1-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 672608,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-11-25 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 672609,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-12-18 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67264(0,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67264(2,3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 672641,
      "Operator (nazwa)": "ASTA-NET S.A.",
      "Operator (ID)": 609,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-03-17 12:41"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 672644,
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-20 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67266(0,1-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 672668,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67267(0,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-17 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 672671,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 672672,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 672673,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-17 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 67269,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "6728(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "6728(2-4,6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67281(0-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67281(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 672816,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67285(0,1-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 672855,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67287(0,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67287(1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "673(1,2,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-03 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "6730(1-5,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67300(2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-25 12:45"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 673000,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 673001,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-25 12:45"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 673003,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-26 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67306(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67306(2-6)",
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 673061,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-11-29 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 673067,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67307(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 673070,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67308(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 673082,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "6733(0,1,2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 67333,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "6734(0,6,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67341(0,2-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 673411,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 673419,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-03-02 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 673420,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 673421,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-04 12:45"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 673422,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 673423,
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 673424,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-04 12:45"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 673425,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-01 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 673426,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 673427,
      "Operator (nazwa)": "ENTER T&T Sp. z o.o.",
      "Operator (ID)": 10688,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 673428,
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 673429,
      "Operator (nazwa)": "IPTELL Sp. z o.o.",
      "Operator (ID)": 7201,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67343(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67343(1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67344(0,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67344(1-4)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67345(0,3-4,6,8-9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67345(1,2)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 673455,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2023-03-29 12:46"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 673457,
      "Operator (nazwa)": "FREELY Sp. z o.o.",
      "Operator (ID)": 13143,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67348(0,3)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67348(2,5)",
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67348(4,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-05-18 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 673481,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-04-01 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 673486,
      "Operator (nazwa)": "ASTA-NET S.A.",
      "Operator (ID)": 609,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-03-17 12:41"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 673487,
      "Operator (nazwa)": "ASTA-NET S.A.",
      "Operator (ID)": 609,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-03-17 12:41"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 673488,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67349(0,1-4)",
      "Operator (nazwa)": "ASTA-NET S.A.",
      "Operator (ID)": 609,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-03-17 12:41"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 673495,
      "Operator (nazwa)": "ASTA-NET S.A.",
      "Operator (ID)": 609,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-18 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 673496,
      "Operator (nazwa)": "VOXNET Sp. z o.o. Sp. k.",
      "Operator (ID)": 728,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 673497,
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 673498,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 673499,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "6735(1-2)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "6735(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67350(2,3)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67350(4-6)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 673500,
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 673501,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 673507,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 673508,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "Pi�a",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 673509,
      "Operator (nazwa)": "ASTA-NET S.A.",
      "Operator (ID)": 609,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-03-17 12:41"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67353(0,6-9)",
      "Operator (nazwa)": "TeleGo sp. z o.o.",
      "Operator (ID)": 9324,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67353(2,3)",
      "Operator (nazwa)": "ASTA-NET S.A.",
      "Operator (ID)": 609,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-03-17 12:41"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 673531,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 673534,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "Pi�a",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 673535,
      "Operator (nazwa)": "Galena Sp. z o.o.",
      "Operator (ID)": 66,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-16 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67354(0-1)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67354(2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67355(0,1)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67355(2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67356(0-8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:17"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 673569,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:16"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67357(3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 673570,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 673571,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:17"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 673572,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:17"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "6736(0-3,5-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67364(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-03 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 673641,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-03 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67367(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 673673,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-04-10 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "6738(0,2-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 67381,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:17"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67387(0-6,8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:17"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67387(7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:17"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "6739(0,1-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67397(0,1-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 673975,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "678(1-5,7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-25 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "6780(0,6-9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "6780(2-5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67801(1,5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67801(2,6)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67801(3,7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67801(4,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-04-01 12:45"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 678010,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "6786(0-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-25 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67867(0-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-25 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 678678,
      "Operator (nazwa)": "Telfon Poland Sp. z o.o.",
      "Operator (ID)": 12517,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-25 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "6788(0,1-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "67888(0,2-5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-10-11 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 678881,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 678886,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-10-11 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 678887,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 678888,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": 678889,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-07-23 12:15"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "679(0,1-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 67,
      "Zakres (number)": "679(8,9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "PI�A",
      "Strefa (symbol)": "SN-PI",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68(2,6,7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "681(0,1-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 6819,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:03"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "6830(1,2,4-6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68300(0,3)",
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68300(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-22 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 683001,
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-12 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 683002,
      "Operator (nazwa)": "TOYA Sp. z o.o.",
      "Operator (ID)": 127,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-18 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 683004,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-10-30 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 683005,
      "Operator (nazwa)": "ITEL Solutions Sp. z o.o.",
      "Operator (ID)": 11274,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-22 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68303(1-5)",
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68303(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 683030,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68307(1-3)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68307(4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 683070,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68308(1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-11-16 12:50"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 683080,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-11-16 12:50"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 683082,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 6831,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "6832(0,2-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68321(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 683210,
      "Operator (nazwa)": "Marat Sp. z o.o.",
      "Operator (ID)": 8620,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-08-03 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "6833(0,1,4-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-12-03 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68332(0,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-03-08 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68332(2-5)",
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-08-07 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 683321,
      "Operator (nazwa)": "Nowogrod.Net Sp. Z o.o.",
      "Operator (ID)": 5680,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-02-02 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 683326,
      "Operator (nazwa)": "AMS SYSTEM Sp. z o.o.",
      "Operator (ID)": 7006,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-03-08 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68333(1-4,7-9)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68333(5-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-01-22 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 683330,
      "Operator (nazwa)": "MEDIDESK Sp. z o.o.",
      "Operator (ID)": 12669,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-01-22 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68338(0-4,6-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-23 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 683385,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-12-03 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 683389,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-23 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "6834(0,2,4,5,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-03 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "6834(6,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68341(0,1-4,6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 683415,
      "Operator (nazwa)": "Dialoga Servicios Interactivos S.A.",
      "Operator (ID)": 10930,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 683417,
      "Operator (nazwa)": "e-Telko Sp. z o.o.",
      "Operator (ID)": 11564,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-04-19 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68343(0-2,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-10-31 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68343(5-7)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-03 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 683433,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2021-03-12 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 683434,
      "Operator (nazwa)": "Multimedia Polska Biznes S.A.",
      "Operator (ID)": 11221,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2021-03-12 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "6835(4,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-02-21 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "6835(5,6,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68350(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-02-21 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 683500,
      "Operator (nazwa)": "KAJA KOMPUTER Krzysztof Jasek",
      "Operator (ID)": 4411,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-02-21 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68351(0,6-9)",
      "Operator (nazwa)": "TeleGo sp. z o.o.",
      "Operator (ID)": 9324,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-09-25 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68351(1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68352(0,1-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 683527,
      "Operator (nazwa)": "Telnyx Ireland Limited",
      "Operator (ID)": 13021,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-01 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68353(0,1,3-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 683532,
      "Operator (nazwa)": "ENTER T&T Sp. z o.o.",
      "Operator (ID)": 10688,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 683539,
      "Operator (nazwa)": "Telkonekt Sp. z o.o.",
      "Operator (ID)": 9086,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-06-14 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68358(0,2-3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68358(4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 683581,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "6836(0,3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "6836(1,4-6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68362(0,1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68362(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68367(0,1-3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68367(4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68368(1,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68368(4-5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-02-01 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 683680,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 683682,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 683683,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-10 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 683686,
      "Operator (nazwa)": "didXL B.V.",
      "Operator (ID)": 13079,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2021-10-22 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 683687,
      "Operator (nazwa)": "e-Telko Sp. z o.o.",
      "Operator (ID)": 11564,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-10-20 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 683689,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-02-29 10:01"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "6837(0,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-13 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "6837(4-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68371(0,1-4,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68371(5-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68372(0,1-5,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68372(6-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68373(0-4,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-13 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68373(5-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-13 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68377(0,1-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 683778,
      "Operator (nazwa)": "DIDWW Ireland Limited",
      "Operator (ID)": 12132,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-19 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68378(0,1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68378(3-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-08-05 12:20"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 683788,
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-20 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 683789,
      "Operator (nazwa)": "Blue Prospect Sp. z o.o.",
      "Operator (ID)": 13345,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-08-05 12:20"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "6838(2-5,7,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68380(0,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 683801,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 683802,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68381(0,1-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 683817,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68386(0,5-9)",
      "Operator (nazwa)": "SpTelek Sp. z o.o.",
      "Operator (ID)": 7320,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68386(1-4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68389(0,1-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68389(5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "6839(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68391(0,1-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 683916,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "684(8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "6840(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 68400,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68410(0,1,2)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68410(5-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 684103,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 684104,
      "Operator (nazwa)": "VOXNET Sp. z o.o. Sp. k.",
      "Operator (ID)": 728,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68411(0,1-3,5-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 684114,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-12-01 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68412(1-9)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 684120,
      "Operator (nazwa)": "CITYLAN Sp. z o.o.",
      "Operator (ID)": 4877,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-08-15 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 684130,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 684131,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 684132,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 684133,
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 684134,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-12 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 684135,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 684136,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "Gubin",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 684137,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "�wiebodzin",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 684138,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "�ary",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 684139,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68414(0,2,4,5,7-9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 684141,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 684143,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 684146,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68415(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68415(1-7)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68416(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68416(1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68417(1-8)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 684170,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-04 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 684179,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-24 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68418(0,6-9)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68418(1-5)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68419(0,9)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68419(1-3,5)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68419(6-8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 684194,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "6842(1,3-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 68420,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68422(7,8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 684220,
      "Operator (nazwa)": "Lubuskie Sieci �wiat�owodowe Sp. z o.o.",
      "Operator (ID)": 1687,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 684221,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 684222,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-08-07 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 684223,
      "Operator (nazwa)": "Wektor Sp. z o.o.",
      "Operator (ID)": 2108,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 684224,
      "Operator (nazwa)": "Galena Sp. z o.o.",
      "Operator (ID)": 66,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-16 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 684225,
      "Operator (nazwa)": "Firstcom Europe Sp. z o.o.",
      "Operator (ID)": 9658,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-04-12 12:45"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 684226,
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 684229,
      "Operator (nazwa)": "Internet Cafe - Us�ugi Informatyczne M. Backiel",
      "Operator (ID)": 321,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 68429,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "6843(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68432(1,3)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68432(6,9)",
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 684320,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 684322,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 684324,
      "Operator (nazwa)": "GT11 Sp. z o.o.",
      "Operator (ID)": 10475,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 684325,
      "Operator (nazwa)": "Intelligent Technologies S.A.",
      "Operator (ID)": 748,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-10-12 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 684327,
      "Operator (nazwa)": "Nettelekom GK Sp. z o.o.",
      "Operator (ID)": 9896,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 684328,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-10-05 12:20"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "6844(0,2-3,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-02-03 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68441(0,1-5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68441(6-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68444(1,2)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68444(3,7,8)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68444(4-5,9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 684440,
      "Operator (nazwa)": "Zak�ad Us�ugowy PAWLUK Krzysztof Pawluk",
      "Operator (ID)": 434,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-02-02 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 684446,
      "Operator (nazwa)": "Wektor Sp. z o.o.",
      "Operator (ID)": 2108,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68445(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-02-03 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 684455,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-02-03 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "6845(1-3,5,7-8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:17"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 68450,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68454(0-4,6-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:17"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 684545,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:17"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68456(0,2-7,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:17"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 684561,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-26 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 684568,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-26 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68459(0-7,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:17"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 684598,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:17"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "6846(0-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68468(0-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 684684,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-05 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "6847(0,5,7-8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:17"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "6847(1-4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68476(0,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:17"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68476(1-7,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:17"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68479(0-4,6,8-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:17"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68479(5,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:17"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "685(1-4,7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "6850(1-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68500(1-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 685000,
      "Operator (nazwa)": "SUDER Sp. z o.o.",
      "Operator (ID)": 10822,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 685005,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68506(2-4,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-12-09 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68506(5,6)",
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 685060,
      "Operator (nazwa)": "Internet Cafe - Us�ugi Informatyczne M. Backiel",
      "Operator (ID)": 321,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 685061,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-01 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "6855(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-11-11 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68555(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-11-11 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 685555,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-11-11 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "6856(0-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68568(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 685685,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-05 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "6858(0,1-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68584(9,0)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-03-26 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 685841,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-15 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 685842,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 685843,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-05-20 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 685844,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-12 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 685845,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-11-29 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 685846,
      "Operator (nazwa)": "FREELY Sp. z o.o.",
      "Operator (ID)": 13143,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 685847,
      "Operator (nazwa)": "G-NET Tomasz Serwatka, Wojciech Rakoniewski Sp. J.",
      "Operator (ID)": 5635,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 685848,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-23 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "688(2,4-5,7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "6880(0,6-9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "6880(2-5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68801(1,5,6,8,0)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 688012,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 688013,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 688014,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 688017,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 688019,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-01-06 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "6881(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68812(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 688121,
      "Operator (nazwa)": "BESTGO.PL Sp. z o.o.",
      "Operator (ID)": 7555,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "6883(0-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-01-15 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68838(0-2,4,6-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-04-09 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 688383,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-04-09 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 688385,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-01-15 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 688388,
      "Operator (nazwa)": "LoVo Sp. z o.o.",
      "Operator (ID)": 7184,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2021-10-30 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 688389,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-23 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "6886(0-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68868(0-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 688688,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2021-10-19 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "6888(0,1-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "68888(2-5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-01-13 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 688880,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 688881,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 688886,
      "Operator (nazwa)": "POLTEL Sp. z o.o.",
      "Operator (ID)": 13246,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-02-02 12:45"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 688887,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-03-29 12:46"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 688888,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": 688889,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-08-30 12:15"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "689(0,1-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 68,
      "Zakres (number)": "689(8,9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "ZIELONA G�RA",
      "Strefa (symbol)": "SN-ZG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71(2,4,5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "711(0,1-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 7119,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "713(2,4,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "7130(1,3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71300(5,6)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-08-19 12:45"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71300(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-08-19 12:45"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 713000,
      "Operator (nazwa)": "AlterTEL Sp. z o.o.",
      "Operator (ID)": 8614,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 713001,
      "Operator (nazwa)": "ST LINE Sp. z o.o. Sp. k.",
      "Operator (ID)": 12096,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-01 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 713002,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-01-02 12:20"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 713003,
      "Operator (nazwa)": "NETTEL Sp. z o.o.",
      "Operator (ID)": 9575,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 713004,
      "Operator (nazwa)": "JMDI J. Maleszko",
      "Operator (ID)": 3487,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-09-21 12:20"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71302(0-2,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71302(3,4)",
      "Operator (nazwa)": "ASSET Lubczy�ski, Olik, Sokolik Sp. J.",
      "Operator (ID)": 6782,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-11-15 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71304(0,6,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71304(1-5,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-13 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71305(0,1)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71305(3,4)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-12-15 12:45"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71305(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-14 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 713052,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-27 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 713055,
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-20 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71306(0,9)",
      "Operator (nazwa)": "FineMEDIA Sp. J.Wojciech Wrona, Grzegorz Ka�u�a",
      "Operator (ID)": 3959,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71306(3,6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 713061,
      "Operator (nazwa)": "Connected Sp. z o.o.",
      "Operator (ID)": 8033,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-04-17 12:45"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 713062,
      "Operator (nazwa)": "Krapkowickie Sieci Internetowe Sp. z o.o.",
      "Operator (ID)": 11425,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-12-11 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 713064,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 713065,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 713067,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 713068,
      "Operator (nazwa)": "Marat Sp. z o.o.",
      "Operator (ID)": 8620,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 71307,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71308(2,3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 713080,
      "Operator (nazwa)": "ENTER T&T Sp. z o.o.",
      "Operator (ID)": 10688,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 713081,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-01 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 713084,
      "Operator (nazwa)": "ASSET Lubczy�ski, Olik, Sokolik Sp. J.",
      "Operator (ID)": 6782,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 713085,
      "Operator (nazwa)": "ASSET Lubczy�ski, Olik, Sokolik Sp. J.",
      "Operator (ID)": 6782,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-25 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 713086,
      "Operator (nazwa)": "VISTEL Dariusz Ptak",
      "Operator (ID)": 10338,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-20 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 713087,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 713088,
      "Operator (nazwa)": "LoVo Sp. z o.o.",
      "Operator (ID)": 7184,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 713089,
      "Operator (nazwa)": "Osiedlowa.pl Sp. z o.o.",
      "Operator (ID)": 1899,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 71309,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-13 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "7131(0,1-3,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71314(0,1-4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 713145,
      "Operator (nazwa)": "ITH Sp. Z o.o.",
      "Operator (ID)": 12635,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-03-14 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "7133(0,2-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71331(0,2-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 713311,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 713317,
      "Operator (nazwa)": "Limtel Sp. z o.o.",
      "Operator (ID)": 9118,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71339(0-4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 713395,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "7136(0-4,7-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71365(0-4,6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71365(5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71366(0-1,6-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71366(2,3)",
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-01-28 12:45"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71366(4,5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 713669,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-01-28 12:45"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71369(0-3,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71369(4,5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "7137(0-5,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71376(0-6,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71376(7,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 71378,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 71379,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "7138(0,1,4,5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 71382,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71383(0-5,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71383(6,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 713838,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-13 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71386(0,6-9)",
      "Operator (nazwa)": "TeleGo sp. z o.o.",
      "Operator (ID)": 9324,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-09-25 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71386(1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "7139(0-3,6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71394(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 713940,
      "Operator (nazwa)": "ASSET Lubczy�ski, Olik, Sokolik Sp. J.",
      "Operator (ID)": 6782,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-08-03 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71395(0,1-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 713956,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71397(0,1-4,6-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 713975,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2021-12-14 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 713979,
      "Operator (nazwa)": "Dialoga Servicios Interactivos S.A.",
      "Operator (ID)": 10930,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "716(1,3,5,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-12-23 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "7160(1-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71600(1-5,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-22 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71600(6,7)",
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 716000,
      "Operator (nazwa)": "IPTELEKOM Micha� Ludwiczak",
      "Operator (ID)": 10051,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 716009,
      "Operator (nazwa)": "KORBANK-MEDIA CYFROWE Sp. z o.o.",
      "Operator (ID)": 6729,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-22 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71606(1-5,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-10-30 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71606(8,9)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-10-30 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 716060,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 716066,
      "Operator (nazwa)": "eDial Internet Sp. z o.o. (d. AWB-NET Agnieszka Barzdo)",
      "Operator (ID)": 3258,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-15 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "7162(0-1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-12-23 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71622(0-1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-12-23 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 716222,
      "Operator (nazwa)": "Galena Sp. z o.o.",
      "Operator (ID)": 66,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-16 12:16"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "7164(0-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-20 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71644(0-1,3,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-12-06 12:45"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 716442,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-20 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 716444,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-09-13 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 716445,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-12-06 12:45"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "7166(0,2-3,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-05-10 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "7166(5,6)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 71661,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71664(0,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-12-06 12:45"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 716641,
      "Operator (nazwa)": "SERWUS Katarzyna Grzybowska",
      "Operator (ID)": 12372,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-05-10 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 716642,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-12-06 12:45"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "7167(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71671(0-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 716716,
      "Operator (nazwa)": "K2M Sp. z o.o.",
      "Operator (ID)": 13135,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-30 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "7169(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71691(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 716911,
      "Operator (nazwa)": "ITEL Solutions Sp. z o.o.",
      "Operator (ID)": 11274,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "7170(3-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-04-04 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71700(1-5)",
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-20 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717000,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717006,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-06-19 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717007,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717008,
      "Operator (nazwa)": "PRZEDSI�BIORSTWO TELEKOMUNIKACYJNE \"TELGAM\" SPӣKA AKCYJNA",
      "Operator (ID)": 37,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-12-30 12:45"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717009,
      "Operator (nazwa)": "Platan Sp. z o.o. Sp. k.",
      "Operator (ID)": 12733,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-10-13 12:45"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71701(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-18 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717011,
      "Operator (nazwa)": "Debesis Sp. z o.o.",
      "Operator (ID)": 10138,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-18 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71702(0,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71702(1-5)",
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717026,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-04-04 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71707(4-6)",
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717070,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717071,
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717072,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717073,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717077,
      "Operator (nazwa)": "e-Telko Sp. z o.o.",
      "Operator (ID)": 11564,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717078,
      "Operator (nazwa)": "didXL B.V.",
      "Operator (ID)": 13079,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-10-22 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717079,
      "Operator (nazwa)": "DIDWW Ireland Limited",
      "Operator (ID)": 12132,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-19 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 71710,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "Wroc�aw",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71711(0,8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "Wroc�aw",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71711(6,7)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717111,
      "Operator (nazwa)": "TVK El�bieta Zjawiona",
      "Operator (ID)": 407,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "Wroc�aw, Jelcz-Laskowice",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717112,
      "Operator (nazwa)": "FreePhone Sp. z o.o.",
      "Operator (ID)": 10788,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-10-04 12:20"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717113,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717114,
      "Operator (nazwa)": "Messageroute LTD",
      "Operator (ID)": 12026,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-13 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717115,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "Wroc�aw",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717119,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-12-22 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71712(1-5)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "Wroc�aw",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71712(8,9)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717120,
      "Operator (nazwa)": "BESTGO.PL Sp. z o.o.",
      "Operator (ID)": 7555,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717126,
      "Operator (nazwa)": "SMSWIZARD POLSKA Sp. z o.o.",
      "Operator (ID)": 12975,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-01-25 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717127,
      "Operator (nazwa)": "KORBANK S.A.",
      "Operator (ID)": 9294,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 71713,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 71714,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71715(0,9)",
      "Operator (nazwa)": "FineMEDIA Sp. J.Wojciech Wrona, Grzegorz Ka�u�a",
      "Operator (ID)": 3959,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717151,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717152,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717153,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717154,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-10-30 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717155,
      "Operator (nazwa)": "Bardzo Szybki Internet Sp. z o.o.",
      "Operator (ID)": 11727,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-12 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717156,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717157,
      "Operator (nazwa)": "Televoice Polska Sp. z o.o.",
      "Operator (ID)": 8808,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717158,
      "Operator (nazwa)": "ASSET Lubczy�ski, Olik, Sokolik Sp. J.",
      "Operator (ID)": 6782,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-10-20 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71716(0,7)",
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71716(4,5)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717161,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717162,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717163,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717166,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-08 12:45"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717168,
      "Operator (nazwa)": "FREELY Sp. z o.o.",
      "Operator (ID)": 13143,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717169,
      "Operator (nazwa)": "didXL B.V.",
      "Operator (ID)": 13079,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-11-03 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71717(0,1-5,7-9)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717176,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-04 12:46"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71718(0,4)",
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-04 12:45"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71718(1-3)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71718(7,8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717185,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-04 12:45"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717186,
      "Operator (nazwa)": "VISTEL Dariusz Ptak",
      "Operator (ID)": 10338,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-08-07 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717189,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "Wroc�aw",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71719(0,1,4,5)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71719(2,3,6-8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717199,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71720(1-4,6)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71720(7-9)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717200,
      "Operator (nazwa)": "TELEPORT Sp z o.o.",
      "Operator (ID)": 11164,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-01-14 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717205,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717210,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717211,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-06-10 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717212,
      "Operator (nazwa)": "BT Poland Sp. z o.o.",
      "Operator (ID)": 109,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-03 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717213,
      "Operator (nazwa)": "Actus-Info Sp. z o.o.",
      "Operator (ID)": 9186,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717214,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717215,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717216,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717217,
      "Operator (nazwa)": "I-TEL Sp. z o.o.",
      "Operator (ID)": 6768,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717218,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-25 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717219,
      "Operator (nazwa)": "Nettelekom GK Sp. z o.o.",
      "Operator (ID)": 9896,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71722(1-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717220,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-12-01 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71723(0,1,5-9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717232,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717233,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717234,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71724(0,3,7,9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717241,
      "Operator (nazwa)": "Colt Technology Services GmbH",
      "Operator (ID)": 12380,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-12-02 12:20"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717242,
      "Operator (nazwa)": "VOXNET Sp. z o.o. Sp. k.",
      "Operator (ID)": 728,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717244,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717245,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717246,
      "Operator (nazwa)": "Blue Prospect Sp. z o.o.",
      "Operator (ID)": 13345,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2023-08-05 12:46"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717248,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71725(1-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717250,
      "Operator (nazwa)": "Actus-Info Sp. z o.o.",
      "Operator (ID)": 9186,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71726(1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717260,
      "Operator (nazwa)": "FreePhone Sp. z o.o.",
      "Operator (ID)": 10788,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717269,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71727(0,1,7-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71727(2,3)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717274,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717275,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717276,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71728(0,5-9)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71728(1,2)",
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717283,
      "Operator (nazwa)": "Intelligent Technologies S.A.",
      "Operator (ID)": 748,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717284,
      "Operator (nazwa)": "CLUDO Sp z o.o.",
      "Operator (ID)": 10056,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-02-01 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 71729,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "7173(0,9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "7173(1,2)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71733(0,1-5,7-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717336,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-12-01 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71734(0,1-4,6-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717345,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-12-01 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71735(0,2-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717351,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-12-01 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 71736,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71737(3,4)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-07-15 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71737(7,8)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71737(9,0)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-03-26 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717371,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717372,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-24 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717375,
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-07-12 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717376,
      "Operator (nazwa)": "ICT Future Sp. z o.o.",
      "Operator (ID)": 11756,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-09-20 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71738(5,8,9)",
      "Operator (nazwa)": "Actus-Info Sp. z o.o.",
      "Operator (ID)": 9186,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717380,
      "Operator (nazwa)": "TOYA Sp. z o.o.",
      "Operator (ID)": 127,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-02-13 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717381,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717382,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717383,
      "Operator (nazwa)": "ATMAN Sp. z o.o.",
      "Operator (ID)": 944,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717384,
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-12-14 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717386,
      "Operator (nazwa)": "S-NET Sp. z o.o.",
      "Operator (ID)": 8697,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717387,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "7174(0,1)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71742(0,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71742(1-3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 71743,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71744(0,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-10-10 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71744(1-3)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-10-10 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717444,
      "Operator (nazwa)": "FineMEDIA Sp. J.Wojciech Wrona, Grzegorz Ka�u�a",
      "Operator (ID)": 3959,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-24 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 71745,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-24 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 71746,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-09-16 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71747(0,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-11-03 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71747(1-8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 71748,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 71749,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "7175(1-4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71750(4,5,7,8)",
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717500,
      "Operator (nazwa)": "VISTEL Dariusz Ptak",
      "Operator (ID)": 10338,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717501,
      "Operator (nazwa)": "IPTELL Sp. z o.o.",
      "Operator (ID)": 7201,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717502,
      "Operator (nazwa)": "POXBOX.PL Sp. z o.o.",
      "Operator (ID)": 12813,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-01-31 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717503,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717506,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717509,
      "Operator (nazwa)": "NETCENTRICA Sp. z o.o.",
      "Operator (ID)": 7087,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71755(1-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-24 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717550,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717555,
      "Operator (nazwa)": "FineMEDIA Sp. J.Wojciech Wrona, Grzegorz Ka�u�a",
      "Operator (ID)": 3959,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-24 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 71756,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71757(1-4,6)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717570,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717575,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717577,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-02-15 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717578,
      "Operator (nazwa)": "Multimedia Polska Biznes S.A.",
      "Operator (ID)": 11221,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-10-31 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717579,
      "Operator (nazwa)": "E-CALL POLSKA Sp. z o.o.",
      "Operator (ID)": 9331,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-02-02 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71758(1-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717580,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 71759,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "7176(0,5,6)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "7176(1-4,7,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71769(1-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717690,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-12-30 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "7177(0,6)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "7177(5,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71771(0,1,2,7-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71771(3-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71772(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71772(1-7)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71773(0,1,2,5-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717733,
      "Operator (nazwa)": "THULIUM Sp. z o.o.",
      "Operator (ID)": 13433,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-16 12:45"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717734,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-01-14 12:45"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71774(0,2,4-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71774(1,3)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71777(0,1-5,7-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717776,
      "Operator (nazwa)": "POLTEL Sp. z o.o.",
      "Operator (ID)": 13246,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-02-02 12:45"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71778(0,1-4)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71778(6,7)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-15 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717785,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-12-15 12:45"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717788,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717789,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-17 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 7178,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "7179(1-4,7-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71790(0,1-8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717909,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-04-30 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71795(0,1,2,4-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717953,
      "Operator (nazwa)": "OneFone S.A.",
      "Operator (ID)": 11295,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71796(0,1-7,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 717968,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-08-08 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "7180(0,6-9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71801(1,3-5,7,9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71801(6,8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-01-06 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 718010,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 718012,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71802(1-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 718020,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 718029,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71803(0,9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71803(1-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71804(0,9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71804(1,3,5-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 718042,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 718044,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-01-06 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71805(1,2,4,6,8,9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71805(3,7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 718050,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 718055,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "7181(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71811(0,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 718111,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-04 12:46"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 718112,
      "Operator (nazwa)": "Telnyx Ireland Limited",
      "Operator (ID)": 13021,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-01 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "7182(0,6-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "7182(1,3)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71822(0-1,3-4,6-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-09-29 12:45"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 718222,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-04 12:46"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 718225,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-17 12:46"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 718228,
      "Operator (nazwa)": "LoVo Sp. z o.o.",
      "Operator (ID)": 7184,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-08-30 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71824(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 718242,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-04 12:46"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71825(0,1-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 718259,
      "Operator (nazwa)": "Actus-Info Sp. z o.o.",
      "Operator (ID)": 9186,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "7183(0,1,2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71833(0-1,4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-09-29 12:45"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71833(2,5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-17 12:46"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 718333,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-04 12:46"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "7184(0,1-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71844(0,1-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 718444,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-04 12:46"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "7185(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-24 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71855(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-24 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 718555,
      "Operator (nazwa)": "FineMEDIA Sp. J.Wojciech Wrona, Grzegorz Ka�u�a",
      "Operator (ID)": 3959,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-24 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "7186(0,1,4,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "7186(5,6)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71862(0,1-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 718626,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-01 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71863(0,1-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 718636,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71867(0,1-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 718676,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "7187(0,2-6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71871(0,6-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71871(1-3)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71871(4,5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 718718,
      "Operator (nazwa)": "Telfon Poland Sp. z o.o.",
      "Operator (ID)": 12517,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71877(0,1-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 718777,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71878(0,1-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 718787,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "7188(2-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-11-30 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71880(1-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-22 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 718800,
      "Operator (nazwa)": "SUDER Sp. z o.o.",
      "Operator (ID)": 10822,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 718808,
      "Operator (nazwa)": "IP Telecom Bulgaria LTD",
      "Operator (ID)": 11805,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-23 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 718809,
      "Operator (nazwa)": "MEDIDESK Sp. z o.o.",
      "Operator (ID)": 12669,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-22 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 71881,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71887(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-11-30 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 718875,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-11-30 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 71888,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "71889(0,1-5)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 718896,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 718897,
      "Operator (nazwa)": "Focus Telecom Polska Sp. z o.o.",
      "Operator (ID)": 8974,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-12-08 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 718898,
      "Operator (nazwa)": "Focus Telecom Polska Sp. z o.o.",
      "Operator (ID)": 8974,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 718899,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-08-07 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": 7189,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-24 12:15"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "719(0,1-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 71,
      "Zakres (number)": "719(8,9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "WROC�AW",
      "Strefa (symbol)": "SN-WR",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74(2,4,5,7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "741(0,1-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 7419,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:04"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "743(1-2,4-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "7430(1,2,4,5,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74300(0,3)",
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74300(2,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-22 12:20"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 743001,
      "Operator (nazwa)": "MEDIDESK Sp. z o.o.",
      "Operator (ID)": 12669,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-30 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 743004,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-22 12:20"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74303(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 743030,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74306(0,6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-18 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74306(1-3)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 743064,
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-12 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 743065,
      "Operator (nazwa)": "TOYA Sp. z o.o.",
      "Operator (ID)": 127,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-18 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 743067,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74307(1-5)",
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74307(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 743070,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74308(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 743082,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "7433(1-2,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-18 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74330(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 743300,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74333(1,2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 743330,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 743333,
      "Operator (nazwa)": "SUDER Sp. z o.o.",
      "Operator (ID)": 10822,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74334(0,2-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-12-06 12:45"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 743341,
      "Operator (nazwa)": "Debesis Sp. z o.o.",
      "Operator (ID)": 10138,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-18 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 743344,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-12-06 12:45"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "7437(0-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74374(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 743743,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-10 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "746(1,5,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "7460(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 74600,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "7462(0,1-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74626(0,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 746261,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-07-31 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 746262,
      "Operator (nazwa)": "W-EXPLORER Sp. z o.o.",
      "Operator (ID)": 9756,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "7463(4,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74630(0,1-4,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 746305,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 746306,
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-20 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74631(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74631(1-7)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 74632,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 74633,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74635(0,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74635(1,2)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74637(2-5)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 746370,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 746371,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 746376,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-12-10 12:45"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 746377,
      "Operator (nazwa)": "DSS OPERATOR S.A.",
      "Operator (ID)": 10708,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-22 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 746378,
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 746379,
      "Operator (nazwa)": "Dolnet Group Sp. z o.o.",
      "Operator (ID)": 9521,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74638(0,1-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74638(8,9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74639(0,2-3,6,8-9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74639(4,5,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 746391,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "7464(0,1,3,5,7)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74642(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 746421,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74644(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74644(1-6)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 746447,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74646(0,9)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74646(1-5)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 746466,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "Wa�brzych",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 746467,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "K�odzko",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 746468,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74648(0,1-3,5,6,8-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 746484,
      "Operator (nazwa)": "Marat Sp. z o.o.",
      "Operator (ID)": 8620,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-12-14 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 746487,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74649(0,1-5,7,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74649(6,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "7466(4-6)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74660(1,2,5,6,8)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 746600,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 746603,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 746604,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 746607,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 746609,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74661(0,5-7,9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-14 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 746611,
      "Operator (nazwa)": "e-Telko Sp. z o.o.",
      "Operator (ID)": 11564,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-04-19 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 746612,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 746613,
      "Operator (nazwa)": "VOXNET Sp. z o.o. Sp. k.",
      "Operator (ID)": 728,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 746614,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 746618,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74662(3-7)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 746620,
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 746621,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "Wa�brzych",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 746622,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 746628,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 746629,
      "Operator (nazwa)": "Nettelekom GK Sp. z o.o.",
      "Operator (ID)": 9896,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74663(0,9)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74663(1,2,4,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74663(6,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 746633,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 746638,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74667(0,4)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74667(1-3,5)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 746676,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-07-15 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 746677,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 746678,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 746679,
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 74668,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74669(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74669(1-5)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "7467(0-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74674(0-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 746746,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-10 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "7480(0,6-9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "7480(2-5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74801(0,9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74801(1-3,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-03 12:45"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74801(6-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 748014,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-03 12:45"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "7481(1,6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74810(0,1-3,5,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74810(4,6,7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74812(0,1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74812(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74813(0,2,5,6,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74813(1,3,4,7,8)",
      "Operator (nazwa)": "TeleGo sp. z o.o.",
      "Operator (ID)": 9324,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-09-25 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74814(0,1-3,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74814(4,5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74815(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 748150,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-03-29 12:46"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74817(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 748179,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74818(0-2,4-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 748183,
      "Operator (nazwa)": "Galena Sp. z o.o.",
      "Operator (ID)": 66,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-16 12:16"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 748188,
      "Operator (nazwa)": "Stimo.Net Sp. z o.o.",
      "Operator (ID)": 11891,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2024-05-15 12:45"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 748189,
      "Operator (nazwa)": "Sieci �wiat�owodowe Sp. z o.o.",
      "Operator (ID)": 12882,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2021-01-21 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74819(0,1-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 748198,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 7482,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "7483(1-3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "7483(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74830(0,1-3,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74830(4-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74834(0-2,4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74834(5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 748343,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74835(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 748350,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74836(0,1-4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74836(5-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 748368,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74837(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 748379,
      "Operator (nazwa)": "DIDWW Ireland Limited",
      "Operator (ID)": 12132,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-19 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "7484(0-5,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74846(0-1,3-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 748462,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-17 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74848(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 748489,
      "Operator (nazwa)": "ENTER T&T Sp. z o.o.",
      "Operator (ID)": 10688,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74849(0-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 748498,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "7485(0,2-6,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74851(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 748510,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74857(0,1-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 748576,
      "Operator (nazwa)": "Telnyx Ireland Limited",
      "Operator (ID)": 13021,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-01 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 74859,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "7486(0,1,3,4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "7486(6-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74862(0,5,6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74862(1,4,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 748622,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 748623,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74865(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 748650,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-04 12:46"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74869(0,1-4,6-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 748695,
      "Operator (nazwa)": "Dialoga Servicios Interactivos S.A.",
      "Operator (ID)": 10930,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 748699,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-24 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "7487(0,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74871(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 748710,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-07-22 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 74872,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74873(0,3,5,6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74873(1-2,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-09-29 12:45"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 748734,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-17 12:46"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74874(0,1,3)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74874(2,4,8-9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74874(5-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "7488(1,3)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74880(0,1,3-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 748802,
      "Operator (nazwa)": "BESTGO.PL Sp. z o.o.",
      "Operator (ID)": 7555,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-09-16 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74882(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 748820,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74884(0,2,7)",
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 748841,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 748843,
      "Operator (nazwa)": "IPTELL Sp. z o.o.",
      "Operator (ID)": 7201,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 748844,
      "Operator (nazwa)": "STRONG-PC Tomasz Piekarski",
      "Operator (ID)": 1924,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 748845,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-04-01 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 748846,
      "Operator (nazwa)": "FREELY Sp. z o.o.",
      "Operator (ID)": 13143,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 748848,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 748849,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-15 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74885(0,2-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 748851,
      "Operator (nazwa)": "didXL B.V.",
      "Operator (ID)": 13079,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-10-22 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74886(0,1-4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74886(5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74887(0,1,2,7,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74887(3-6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74888(0,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74888(2-6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 748881,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 748887,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74889(0-1,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-11-30 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74889(2-4,6,8,9)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 748895,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-11-30 12:15"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "7489(0,1,2,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74893(0,1-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74893(7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "74894(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": 748940,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "749(0,1-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 74,
      "Zakres (number)": "749(8,9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "WA�BRZYCH",
      "Strefa (symbol)": "SN-WB",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75(2,4,5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "751(0,1-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 7519,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "753(1-6,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "7530(1-2,5,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-30 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75300(0,3)",
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75300(2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-08-13 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 753001,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-08-07 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75303(2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-07-09 12:16"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 753030,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 753031,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-07-09 12:16"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75304(2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-12-06 12:45"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 753040,
      "Operator (nazwa)": "MEDIDESK Sp. z o.o.",
      "Operator (ID)": 12669,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-30 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 753041,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-12-06 12:45"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75306(0,5-6,8-9)",
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75306(1-3)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 753064,
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-12 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 753067,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75307(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 753070,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75308(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 753082,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "7537(0,1-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75375(0-2,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-22 12:20"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 753753,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 753754,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-17 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 753755,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-22 12:20"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "756(3,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-03-17 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "7560(0-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-03-17 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75609(0-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-03-17 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 756099,
      "Operator (nazwa)": "INFO SERWIS Jacek Jarosz",
      "Operator (ID)": 8082,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-03-17 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75610(1,2,6,8)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-07-31 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75610(3-5)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 756100,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-02-17 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 756107,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-07-31 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 756109,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-02-17 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75611(0,1,3,4,6,7,9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75611(2,8)",
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 756115,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75612(0,5,9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75612(7,8)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-13 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 756121,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 756122,
      "Operator (nazwa)": "e-Telko Sp. z o.o.",
      "Operator (ID)": 11564,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-04-19 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 756123,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 756124,
      "Operator (nazwa)": "VOXNET Sp. z o.o. Sp. k.",
      "Operator (ID)": 728,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 756126,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75613(3,7)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "Jelenia G�ra",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75613(8,9)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 756130,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 756131,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 756132,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-02-17 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 756134,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-06 12:45"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 756135,
      "Operator (nazwa)": "Dolnet Group Sp. z o.o.",
      "Operator (ID)": 9521,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 756136,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75614(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75614(1-5)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75615(1-4)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75615(5,6)",
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75615(7-9)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 756150,
      "Operator (nazwa)": "e-Telko Sp. z o.o.",
      "Operator (ID)": 11564,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75616(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75616(1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75617(0,3-7)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75617(1,2)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75617(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 75618,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-04-21 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75619(2,3,5,7)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 756190,
      "Operator (nazwa)": "C3 NET Sp. z o.o. Sp.k.",
      "Operator (ID)": 1651,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 756191,
      "Operator (nazwa)": "FREELY Sp. z o.o.",
      "Operator (ID)": 13143,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 756194,
      "Operator (nazwa)": "Nettelekom GK Sp. z o.o.",
      "Operator (ID)": 9896,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 756196,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 756198,
      "Operator (nazwa)": "E-CALL POLSKA Sp. z o.o.",
      "Operator (ID)": 9331,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 756199,
      "Operator (nazwa)": "INFO SERWIS Jacek Jarosz",
      "Operator (ID)": 8082,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "powiat jeleniog�rski",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "7562(0,3-6,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-05 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75621(1-3)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75621(4,5)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-24 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75621(8,9)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-07-04 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 756210,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-07-04 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 756216,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-04 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 756217,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-11-29 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75622(0,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-19 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 756221,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-17 12:46"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 756222,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75627(0,8-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75627(4-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-10-31 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 756271,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-03-12 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 756272,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 756273,
      "Operator (nazwa)": "Multimedia Polska Biznes S.A.",
      "Operator (ID)": 11221,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-03-12 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75629(0-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-05 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 756299,
      "Operator (nazwa)": "INFO SERWIS Jacek Jarosz",
      "Operator (ID)": 8082,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-05 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "7564(1-3)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "Jelenia G�ra",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "7564(8,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "Zgorzelec",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75640(1,2)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 756400,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-01 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 756403,
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 756404,
      "Operator (nazwa)": "VISTEL Dariusz Ptak",
      "Operator (ID)": 10338,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 756405,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 756406,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 756407,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 756408,
      "Operator (nazwa)": "Tomk�w Sp. z o.o.",
      "Operator (ID)": 1889,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 756409,
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 75644,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "Boles�awiec",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 75645,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "Kamienna G�ra",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 75646,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "Luba�",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 75647,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "Lw�wek �l�ski",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "7565(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75650(0,1-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 756505,
      "Operator (nazwa)": "Zicom Next Sp. z o.o.",
      "Operator (ID)": 7661,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "7566(0-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-16 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75666(0-5,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-05 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 756666,
      "Operator (nazwa)": "POLTEL Sp. z o.o.",
      "Operator (ID)": 13246,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-02-02 12:45"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 756667,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-05 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "7567(0,2-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75671(0,1-7)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75671(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 75675,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "7570(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 75700,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "7571(0,1,5,6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75712(0,1,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75712(2-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 75713,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75714(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 757141,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75717(0,1,6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75717(2-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 757177,
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-20 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75718(0,1,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75718(2-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "7572(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "7572(1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75723(1,2,6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75723(3-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 757230,
      "Operator (nazwa)": "Dialoga Servicios Interactivos S.A.",
      "Operator (ID)": 10930,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75724(0,1-5,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75724(6,7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75725(0,1,2,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75725(3-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 757255,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "7573(3,7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "7573(4,6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75730(1-3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-08 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75730(5-9)",
      "Operator (nazwa)": "TeleGo sp. z o.o.",
      "Operator (ID)": 9324,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-09-25 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 757300,
      "Operator (nazwa)": "Intelligent Technologies S.A.",
      "Operator (ID)": 748,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-24 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 757304,
      "Operator (nazwa)": "ITH Sp. Z o.o.",
      "Operator (ID)": 12635,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-10 12:45"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75731(0,1-3,6,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75731(4,5,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 757319,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75732(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 757329,
      "Operator (nazwa)": "DIDWW Ireland Limited",
      "Operator (ID)": 12132,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-19 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75735(0-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75735(5,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75735(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75738(0,1-3,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75738(4-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "7574(0,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75741(0,1-5,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75741(6-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75742(0,1,7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75742(2-6,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75743(0,1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75743(3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75744(0-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 757448,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75745(0,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75745(1-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75746(0,1,2,4-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75746(3,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "7575(3-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "7575(6,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75750(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 757500,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75751(1-2,4-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 757510,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 757513,
      "Operator (nazwa)": "TOYA Sp. z o.o.",
      "Operator (ID)": 127,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-18 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 757517,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75752(1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 757520,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 757529,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-10-30 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75757(0-4,6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-26 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 757575,
      "Operator (nazwa)": "P.K. TELE Sp.J. Jacek Paprota, Dariusz Kulikowski",
      "Operator (ID)": 1602,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 757577,
      "Operator (nazwa)": "ENREACH PROSTA SPӣKA AKCYJNA",
      "Operator (ID)": 13141,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-26 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "7576(0,3,6,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "7576(1,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75762(0,1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75762(3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75764(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 757640,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75765(0,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75765(1-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75769(0,1-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 757696,
      "Operator (nazwa)": "SUPERMEDIA Sp. z o.o.",
      "Operator (ID)": 1369,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-09-03 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "7577(3,5,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 75770,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75771(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 757710,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75772(0,6,7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75772(1-5,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75774(0,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75774(1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75776(0-1)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-08 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75776(2-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 757769,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-08 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75777(0,1,2,4-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 757773,
      "Operator (nazwa)": "INTERA Sp. z o.o.",
      "Operator (ID)": 11036,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-23 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75779(0,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75779(1,2,4-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 757793,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "7578(0,5-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75781(0,5,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75781(1-4,6-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75782(0,1-5,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75782(6-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75783(0,3-5,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75783(1,2,6-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75784(0,1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75784(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 75789,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 7579,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "758(1-6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "7580(0,6-9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "7580(3-5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75801(0,5,7,9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75801(1,8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 758012,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 758013,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 758014,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-01-06 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 758016,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75802(0,8-9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:05"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75802(2-3)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75802(5-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 758021,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 758024,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "7587(0,1-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75875(0,6-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75875(1-3)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75875(4,5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 758758,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-05 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "7588(0,1-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75888(2-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 758880,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-04 12:46"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 758881,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 758888,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 758889,
      "Operator (nazwa)": "Telnyx Ireland Limited",
      "Operator (ID)": 13021,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-01 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75889(0,1-4)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "75889(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-11-30 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 758895,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-11-30 12:15"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": 758896,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "759(0,1-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 75,
      "Zakres (number)": "759(8,9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "JELENIA G�RA",
      "Strefa (symbol)": "SN-JG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76(2,4,6)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "761(0,1-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 7619,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "763(1-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "7630(1-6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76300(3-5)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76300(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-25 12:45"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 763000,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 763001,
      "Operator (nazwa)": "Nettelekom GK Sp. z o.o.",
      "Operator (ID)": 9896,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 763002,
      "Operator (nazwa)": "OneFone S.A.",
      "Operator (ID)": 11295,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-28 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 763006,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-25 12:45"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76307(1-5)",
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76307(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 763070,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76308(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 763082,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "7637(0-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76376(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 763763,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-05 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "765(1,3-6,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "7650(1-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76500(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 765000,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76506(0,1-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 765065,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "7652(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76522(0-4,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-10-31 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 765225,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-03-12 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 765226,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "7657(0-4,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76575(0,1,3,4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 765752,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 765755,
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-20 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76576(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 765765,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-05 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "767(0,3,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "7671(0,2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76711(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76711(1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76713(0-3,6-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-09-29 12:45"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76713(4,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 767139,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-17 12:46"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "7672(1-4,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76720(0,1,3-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76720(2,8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76725(0,1,8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76725(2-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76726(0-5,7-8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:17"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 767266,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:17"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 767269,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76727(1-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 767270,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76728(0,1,3-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76728(2,6)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "7674(6-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "Lubin",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76740(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 767400,
      "Operator (nazwa)": "KAMNET Kamil Turo�",
      "Operator (ID)": 6927,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76741(0,6-9)",
      "Operator (nazwa)": "TeleGo sp. z o.o.",
      "Operator (ID)": 9324,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-09-25 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76741(2-3,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 767411,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 767414,
      "Operator (nazwa)": "DIDWW Ireland Limited",
      "Operator (ID)": 12132,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-19 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76742(0,8-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76742(2,3)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76742(5,6)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 767421,
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 767424,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-08-07 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 767427,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76743(0,2,5)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-13 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76743(1,3)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-03-18 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76743(4,7)",
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 767436,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 767438,
      "Operator (nazwa)": "VOXNET Sp. z o.o. Sp. k.",
      "Operator (ID)": 728,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 767439,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76744(0,6-8)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76744(1,3,4,9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 767442,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 767445,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76745(0,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76745(4-5)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76745(7,8)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 767451,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 767452,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-01 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 767453,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 767456,
      "Operator (nazwa)": "FREELY Sp. z o.o.",
      "Operator (ID)": 13143,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "7675(7,8)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76750(1,2)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76750(4-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-24 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 767500,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 767503,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-24 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 767509,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76751(0,1,2)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76751(3,7,8)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "Legnica",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 767514,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-04 12:45"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 767515,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 767516,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-04 12:45"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 767519,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "Jawor",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76752(0,5-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-12-06 12:45"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76752(1-4)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 767529,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-12-06 12:45"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76753(5-7)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 767530,
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 767531,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "Polkowice",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 767532,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-24 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 767533,
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 767534,
      "Operator (nazwa)": "VISTEL Dariusz Ptak",
      "Operator (ID)": 10338,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 767538,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 767539,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 76754,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 76755,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76756(1-3)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76756(4-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 767560,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76759(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76759(1-5)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "7676(1-5,7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-06-22 12:45"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76760(1-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-22 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 767600,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 767606,
      "Operator (nazwa)": "Debesis Sp. z o.o.",
      "Operator (ID)": 10138,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-22 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 76766,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76768(0-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-06-22 12:45"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 767686,
      "Operator (nazwa)": "MEDIDESK Sp. z o.o.",
      "Operator (ID)": 12669,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-06-22 12:45"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "7677(0-5,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-02-25 12:17"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76776(0-1,4-5,8-9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76776(3,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-07-25 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 767762,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-13 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 767767,
      "Operator (nazwa)": "Telfon Poland Sp. z o.o.",
      "Operator (ID)": 12517,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-07-25 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76777(0-2,4-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-16 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 767773,
      "Operator (nazwa)": "INTERA Sp. z o.o.",
      "Operator (ID)": 11036,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-23 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 767777,
      "Operator (nazwa)": "SUDER Sp. z o.o.",
      "Operator (ID)": 10822,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-16 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "768(2,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "7680(0,6-9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "7680(3-5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76801(0,1,4,5,7-9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76801(2,3,6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76802(0,2-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76802(1,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "7681(0,1-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76817(1,3-5,7-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 768170,
      "Operator (nazwa)": "Telnyx Ireland Limited",
      "Operator (ID)": 13021,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-01 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 768172,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-03-29 12:45"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 768176,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 768179,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-12 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76818(0,1,3-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 768182,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 768189,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76819(0,3,4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76819(1,2,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "7683(0,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "7683(1,4,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76832(0,1-3,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76832(4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76833(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 768330,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-10-30 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76836(0,1,2)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76836(3,5-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 768364,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 768369,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76837(1-3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76837(4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 768370,
      "Operator (nazwa)": "Dialoga Servicios Interactivos S.A.",
      "Operator (ID)": 10930,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76838(0,5-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76838(1-4,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "7684(4,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "7684(6,7)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "Lubin",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76840(0-1,3-6,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76840(7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 768402,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76841(0,1,2,5,6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76841(3,4,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76842(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 768429,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76843(0,2-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76843(1,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76848(0,2,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76848(1,3-6,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 768487,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76849(0,2,4,6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76849(1,3,5,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "7685(0,4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76851(0,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76851(1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76852(0,1,6-9)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-04-19 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76852(2-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76853(0,1-4)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-04-19 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76853(5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76855(0,1-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76855(5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76856(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 768569,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76857(0,2-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76857(1,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76858(0,2,4-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76858(1,3,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 76859,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "7686(0-1,3-5,7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 76862,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76866(0-5,7-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76866(6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76868(0-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 768686,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "7687(5,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 76870,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76871(0,1,3,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76871(2,4-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76872(0,1-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 768728,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76873(0,1-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 768738,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76874(1-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-01-20 12:45"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 768740,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 768749,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-01-20 12:45"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76876(0,2,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76876(1,5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76876(3-4,7-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 768766,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76877(0,2-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76877(1,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76878(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 768789,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "7688(0,1-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76887(0,2,3,5-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76887(1,4,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76888(0,2-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 768881,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 768888,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76889(0-4,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-11-30 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "76889(8,9)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 768895,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-11-30 12:15"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": 768896,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "769(0,1-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 76,
      "Zakres (number)": "769(8,9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "LEGNICA",
      "Strefa (symbol)": "SN-LG",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "771(0,1-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 7719,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 772,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "773(1-2,4-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "7730(1-6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77300(0,3)",
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77300(1,2,4-6)",
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77300(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 773070,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 773071,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-01-02 12:20"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 773072,
      "Operator (nazwa)": "Syrion Sp. z o.o.",
      "Operator (ID)": 7883,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 773073,
      "Operator (nazwa)": "ELZAPPERO.NET Marcin Zapotoczny",
      "Operator (ID)": 7076,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "SN w granicach powiatu g�ubczyckiego",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 773074,
      "Operator (nazwa)": "M3.NET Sp. z o.o.",
      "Operator (ID)": 10099,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 773075,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 773076,
      "Operator (nazwa)": "e-Telko Sp. z o.o.",
      "Operator (ID)": 11564,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-04-19 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 773077,
      "Operator (nazwa)": "TOYA Sp. z o.o.",
      "Operator (ID)": 127,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2021-04-10 10:55"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 773078,
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-07-12 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 773079,
      "Operator (nazwa)": "Pavel Zizka ORELSOFT.PL",
      "Operator (ID)": 11433,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2023-01-26 12:45"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77308(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-04-01 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77308(3-5)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 773081,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-04-01 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 773082,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "7733(0,1,2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 77333,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "7737(0-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77377(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 773773,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-08-07 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "7740(0,2,4,6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77401(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 774010,
      "Operator (nazwa)": "G-NET Tomasz Serwatka, Wojciech Rakoniewski Sp. J.",
      "Operator (ID)": 5635,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:06"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77403(0,1-4,6,7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 774035,
      "Operator (nazwa)": "Dialoga Servicios Interactivos S.A.",
      "Operator (ID)": 10930,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 774038,
      "Operator (nazwa)": "Intelligent Technologies S.A.",
      "Operator (ID)": 748,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-10-12 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77405(0,1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77405(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77407(0,2-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 774071,
      "Operator (nazwa)": "@ALFANET M. Ma�olepszy",
      "Operator (ID)": 163,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77408(0,3,5-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77408(1,2,4,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77409(0,1-5,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77409(6,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "7741(0,2-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77411(1-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 774110,
      "Operator (nazwa)": "OneFone S.A.",
      "Operator (ID)": 11295,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 774117,
      "Operator (nazwa)": "GISSNET Bogusz Kr�ciproch-Radoma�ski",
      "Operator (ID)": 10565,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-12-15 12:45"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77419(0,1-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 774198,
      "Operator (nazwa)": "Colt Technology Services GmbH",
      "Operator (ID)": 12380,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-12-02 12:45"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "7742(1,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "7742(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77420(0,1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77420(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77422(0,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 774221,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 774222,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 774223,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77423(0-2,4-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77423(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 774233,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77424(0,1-3,5,6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77424(7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-01-20 12:45"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 774244,
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-20 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 774248,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-01-20 12:45"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77425(0,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77425(1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77426(0,6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77426(1,5,7-9)",
      "Operator (nazwa)": "TeleGo sp. z o.o.",
      "Operator (ID)": 9324,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-09-25 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77426(2-4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "7743(0,2)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "7743(1,3,5,6,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77434(0,1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77434(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77437(0,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77437(1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77438(0,1,7,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77438(2-6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "7744(0-4,6-8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:17"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77445(0-5,7-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:17"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 774456,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:17"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77449(0,3-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77449(1,2)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "7745(1,4-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 77450,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77452(0-2,4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77452(3,5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77453(0-2,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77453(3,4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77458(0,1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77458(3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77459(0,4-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77459(1-3,8,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "7746(1,3,4,6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77460(0,1,2,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77460(3-5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77462(0,1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77462(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77465(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77465(1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77467(0,4,6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77467(1-3,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 774675,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 77468,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77469(0,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77469(1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "7747(3,6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77470(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 774700,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77471(0,1)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77471(2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77472(0,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77472(1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77474(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 774749,
      "Operator (nazwa)": "Krapkowickie Sieci Internetowe Sp. z o.o.",
      "Operator (ID)": 11425,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-12-11 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77475(0,1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77475(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77477(0-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 774774,
      "Operator (nazwa)": "PI TELECOM Wojciech Pipa�a",
      "Operator (ID)": 12740,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-06-13 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "7748(1-3,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77480(0,1-3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77480(4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77484(0-2,4-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 774843,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-10-30 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77486(0,1,2,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77486(3-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77487(0,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77487(1-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77488(0,1-5,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77488(6,7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 77489,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "7749(0,1-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77494(0,1,2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 774943,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "775(1-3,6,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "7750(1-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77500(1-4,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-20 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77500(6,7)",
      "Operator (nazwa)": "MULTIPLAY Sp. z o.o. Sp. k.",
      "Operator (ID)": 10068,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-20 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 775000,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 775005,
      "Operator (nazwa)": "MULTIPLAY Sp. z o.o. Sp. k.",
      "Operator (ID)": 10068,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-10-07 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77506(0,4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-08-05 12:20"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 775061,
      "Operator (nazwa)": "Telnyx Ireland Limited",
      "Operator (ID)": 13021,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-01 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 775062,
      "Operator (nazwa)": "XBEST.NET.PL Sp. z o.o.",
      "Operator (ID)": 5309,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 775063,
      "Operator (nazwa)": "Blue Prospect Sp. z o.o.",
      "Operator (ID)": 13345,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-08-05 12:20"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 775065,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77540(0-5,7-9)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 775406,
      "Operator (nazwa)": "Syrion Sp. z o.o.",
      "Operator (ID)": 7883,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 77541,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77542(0,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77542(1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77543(0,1-4,6-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 775435,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-12-01 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77544(0,2)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77544(3,5)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77544(7,8)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 775441,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 775444,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 775446,
      "Operator (nazwa)": "VOXNET Sp. z o.o. Sp. k.",
      "Operator (ID)": 728,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 775449,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77545(0,7-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77545(1-3)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77545(5,6)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 775454,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77546(0,3-5)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-13 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77546(1,7)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77546(2,6)",
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 775468,
      "Operator (nazwa)": "BT Poland Sp. z o.o.",
      "Operator (ID)": 109,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-12-21 12:20"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 775469,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-12-21 12:20"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77547(0,9)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77547(1,2)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77547(3,4,6-8)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-13 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 775475,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2023-03-29 12:46"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 77548,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77549(0,1-5)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77549(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "7755(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77550(6,7)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 775500,
      "Operator (nazwa)": "City-Sat Gordzielik Sp. z o.o.",
      "Operator (ID)": 734,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "Strzelce Opolskie",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 775501,
      "Operator (nazwa)": "Hyperion S.A.",
      "Operator (ID)": 329,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 775502,
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 775503,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 775504,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-01 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 775505,
      "Operator (nazwa)": "IPTELL Sp. z o.o.",
      "Operator (ID)": 7201,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 775508,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 775509,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 77551,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 775520,
      "Operator (nazwa)": "INTERMATIK Mateusz Skucik",
      "Operator (ID)": 5742,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 775521,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 775522,
      "Operator (nazwa)": "CITYMEDIA NET Sp. z o.o.",
      "Operator (ID)": 10570,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 775523,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 775524,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-06-30 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 775525,
      "Operator (nazwa)": "CLUDO Sp z o.o.",
      "Operator (ID)": 10056,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-02-01 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 775526,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 775527,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 775528,
      "Operator (nazwa)": "I-TEL Sp. z o.o.",
      "Operator (ID)": 6768,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 775529,
      "Operator (nazwa)": "AMS SYSTEM Sp. z o.o.",
      "Operator (ID)": 7006,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-10-18 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77553(0,7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2023-09-29 12:45"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77553(1,2,4,5)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 775533,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 775536,
      "Operator (nazwa)": "e-Telko Sp. z o.o.",
      "Operator (ID)": 11564,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-04-19 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 775538,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2023-11-17 12:46"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77554(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77554(1-5)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77555(5-7)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 775550,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 775551,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-02-17 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 775552,
      "Operator (nazwa)": "Galena Sp. z o.o.",
      "Operator (ID)": 66,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-16 12:16"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 775553,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 775554,
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 775558,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 775559,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 77556,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77557(0,9)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-03-21 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77557(1-3)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77557(7,8)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-01-12 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 775574,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-24 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 775575,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-07-15 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 775576,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-11-29 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "7757(0,1-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77577(1,4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77577(2-3,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 775770,
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 775775,
      "Operator (nazwa)": "Telfon Poland Sp. z o.o.",
      "Operator (ID)": 12517,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "776(0-4,6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "7765(0,1-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77656(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 776562,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:46"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "7767(0-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77677(0-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 776776,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-05 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "7768(0-3,5-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-06-28 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77684(0,1,2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 776843,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:46"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77688(0-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-06-28 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 776888,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-06-28 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "7770(1-6,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77700(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 777000,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:46"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77707(1-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-03-07 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 777070,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 777077,
      "Operator (nazwa)": "MEDIDESK Sp. z o.o.",
      "Operator (ID)": 12669,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-30 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "7771(2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77710(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 777100,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77711(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 777111,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:46"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "7772(1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77720(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 777200,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77722(0-1,3-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-12-06 12:45"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 777222,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:46"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 777228,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-12-06 12:45"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "7773(0,1,2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77733(0,1,2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 777333,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:46"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "7774(0,1-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77744(0,1-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 777444,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:46"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "7775(0,1-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77755(0,1-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 777555,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:46"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "7776(0,1-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77766(0,1-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 777666,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:46"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "7777(0,1-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77777(1-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77777(8,9)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 777770,
      "Operator (nazwa)": "INTEGRAL Katarzyna Fitas-Antczak",
      "Operator (ID)": 10429,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-12-08 12:45"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 777777,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:46"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "7778(0,1-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77788(0,1-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 777888,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:46"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "7779(0,1-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77799(0,1-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 777999,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:46"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "778(1-6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "7780(0,6-9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "7780(4,5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77801(1,2,6,9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77801(4,7,8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 778010,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-04-01 12:45"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 778013,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 778015,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77802(1,3,5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77802(2,7,8,0)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77802(6,9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 778024,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77803(1,0)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77803(2,3,7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77803(5-6)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77803(8,9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 778034,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-01-06 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "7787(0-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77877(0-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 778778,
      "Operator (nazwa)": "Telfon Poland Sp. z o.o.",
      "Operator (ID)": 12517,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "7788(0,1-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 778870,
      "Operator (nazwa)": "INTERNET SERWIS Wojciech Bandurowski",
      "Operator (ID)": 2015,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 778871,
      "Operator (nazwa)": "TIMPLUS B. Dudek, R. Walentowski sp�ka jawna",
      "Operator (ID)": 3110,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 778872,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 778873,
      "Operator (nazwa)": "E-CALL POLSKA Sp. z o.o.",
      "Operator (ID)": 9331,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 778874,
      "Operator (nazwa)": "NETON S.A.",
      "Operator (ID)": 10710,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-05 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 778875,
      "Operator (nazwa)": "PROMEDIA Nowicki Weso�owski Sp.J.",
      "Operator (ID)": 7534,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 778876,
      "Operator (nazwa)": "FREELY Sp. z o.o.",
      "Operator (ID)": 13143,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 778877,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 778878,
      "Operator (nazwa)": "DIDWW Ireland Limited",
      "Operator (ID)": 12132,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-19 12:15"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 778879,
      "Operator (nazwa)": "Raczek.NET Sp. z o.o.",
      "Operator (ID)": 6382,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77888(0,2-5,7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "77888(6,8)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 778881,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": 77889,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "779(0,1-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 77,
      "Zakres (number)": "779(8,9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "OPOLE",
      "Strefa (symbol)": "SN-OP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "811(0,1-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 8119,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "812(0,1,4-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-04-01 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "8122(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-04-01 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81222(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81222(3-7)",
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 812221,
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-07-12 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 812222,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-04-01 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "8123(0-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-09-08 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81234(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-09-08 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 812345,
      "Operator (nazwa)": "AP-MEDIA Sp. z o.o. Sp. k.",
      "Operator (ID)": 7476,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-09-08 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "8128(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81281(0,1,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81281(3-5)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 812812,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "813(2,4-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-02-16 12:45"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "8130(1-6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81300(0,2)",
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-03-04 12:45"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81300(4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-03-04 12:45"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 813001,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-08-07 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 813003,
      "Operator (nazwa)": "NETTEL Sp. z o.o.",
      "Operator (ID)": 9575,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81307(0,6-9)",
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81307(1-5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81308(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 813082,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "8131(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 81311,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "8133(0-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-02-16 12:45"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81337(0-3,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-02-16 12:45"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81337(4,5)",
      "Operator (nazwa)": "SUDER Sp. z o.o.",
      "Operator (ID)": 10822,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-02-16 12:45"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "8138(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81381(0,9)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-04-17 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81381(1-3)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81381(4,5)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-04 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81381(7,8)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-01-12 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 813816,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-11-29 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "814(0,2,3,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "8141(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81411(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814111,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-10-19 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "8144(1-6,8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81440(3-5)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814400,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814401,
      "Operator (nazwa)": "VIRTUAL TELECOM Sp. z o.o.",
      "Operator (ID)": 8894,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-02-01 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814402,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814406,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814407,
      "Operator (nazwa)": "Intelligent Technologies S.A.",
      "Operator (ID)": 748,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814408,
      "Operator (nazwa)": "LUBMAN UMCS Sp. z o.o.",
      "Operator (ID)": 871,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814409,
      "Operator (nazwa)": "TOYA Sp. z o.o.",
      "Operator (ID)": 127,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-02-13 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 81447,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 81449,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "Lublin",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "8145(3,6,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81450(0,6-9)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814501,
      "Operator (nazwa)": "TELEKOM SYSTEM Sp. z o.o.",
      "Operator (ID)": 2793,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814502,
      "Operator (nazwa)": "MPWiK Wodoci�gi Pu�awskie Sp. z o.o.",
      "Operator (ID)": 9740,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814503,
      "Operator (nazwa)": "DIDWW Ireland Limited",
      "Operator (ID)": 12132,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-19 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814504,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-01 12:45"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814505,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81451(1,4-5,7-8)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81451(2,3,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-13 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814510,
      "Operator (nazwa)": "Ambit Systemy Informatyczne Sp. z o.o.",
      "Operator (ID)": 165,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814519,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 81452,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:17"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81454(0-1,5-6)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:17"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81454(2-4,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:17"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81455(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81455(1-5)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81458(1,2)",
      "Operator (nazwa)": "VIRTUAL TELECOM Sp. z o.o.",
      "Operator (ID)": 8894,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814580,
      "Operator (nazwa)": "�widman S.C. M. Bzowski, D. Drelich",
      "Operator (ID)": 3591,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814583,
      "Operator (nazwa)": "Futuro Exito Sp. z o.o.",
      "Operator (ID)": 7986,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814584,
      "Operator (nazwa)": "TELEKOM SYSTEM Sp. z o.o.",
      "Operator (ID)": 2793,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814585,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-10-30 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814586,
      "Operator (nazwa)": "MPWiK Wodoci�gi Pu�awskie Sp. z o.o.",
      "Operator (ID)": 9740,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814587,
      "Operator (nazwa)": "INSTALNET Szabat, Rydzewski Sp. J.",
      "Operator (ID)": 706,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814588,
      "Operator (nazwa)": "CLUDO Sp z o.o.",
      "Operator (ID)": 10056,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-02-01 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814589,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81459(0-8)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814599,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "8146(1-2,8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:17"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81460(0,4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:17"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81460(1-3,5-7,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:17"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814608,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81463(0,1-3,5-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814634,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-12-01 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81464(0,1,2,4-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814643,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-12-01 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81465(0,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81465(1-3,6-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814654,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-01-02 12:20"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814655,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:17"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81466(1-5)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814660,
      "Operator (nazwa)": "LUBMAN UMCS Sp. z o.o.",
      "Operator (ID)": 871,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814666,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814667,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814668,
      "Operator (nazwa)": "Telewizja Kablowa �widnik Sp. z o.o.",
      "Operator (ID)": 1918,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814669,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81467(1-9)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814670,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81469(0-3,6-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:17"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81469(4,5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:17"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81470(0-1,4,6,8-9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81470(2,3)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814705,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814707,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81471(0-2,5)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81471(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814713,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814714,
      "Operator (nazwa)": "VOXNET Sp. z o.o. Sp. k.",
      "Operator (ID)": 728,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81472(1-5,7,8)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814720,
      "Operator (nazwa)": "Motomat Group Sp. z o.o.",
      "Operator (ID)": 12553,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-03-07 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814726,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814729,
      "Operator (nazwa)": "Multimedia Polska Biznes S.A.",
      "Operator (ID)": 11221,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-10-31 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81473(1,3-4,8-9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81473(2,5-7)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814730,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 81474,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81475(0,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814751,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814752,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814753,
      "Operator (nazwa)": "INTERDUO Bujek K�opotek Sowa S.J.",
      "Operator (ID)": 6900,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814754,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814755,
      "Operator (nazwa)": "ETERNA Piotr �ukasik",
      "Operator (ID)": 3344,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814756,
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814757,
      "Operator (nazwa)": "Focus Telecom Polska Sp. z o.o.",
      "Operator (ID)": 8974,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814758,
      "Operator (nazwa)": "KONVER Sp. z o.o.",
      "Operator (ID)": 11385,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-02-06 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81476(0,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81476(1-6)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81477(0,6-8)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81477(1-3)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814774,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814775,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814779,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81478(1,4-6,9)",
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81478(7,8)",
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-20 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814780,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-12-14 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814782,
      "Operator (nazwa)": "Futuro Exito Sp. z o.o.",
      "Operator (ID)": 7986,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-21 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814783,
      "Operator (nazwa)": "Galena Sp. z o.o.",
      "Operator (ID)": 66,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-16 12:16"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 81479,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "8148(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81481(0,2,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-01-13 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814811,
      "Operator (nazwa)": "ATMAN Sp. z o.o.",
      "Operator (ID)": 944,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-01-13 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814813,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 814814,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "8150(0,5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81501(0,1)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81501(2,4-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 815013,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81502(0,1-3,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81502(4-8)",
      "Operator (nazwa)": "TeleGo sp. z o.o.",
      "Operator (ID)": 9324,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-09-25 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81503(0,1,2,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81503(3-6,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81504(0,1-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 815049,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81506(0,1-4,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81506(5,6)",
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "8151(2-5,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81510(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 815100,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-10-19 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81511(0,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-15 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81511(1,3,6,8,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 815112,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-17 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 815114,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-15 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 815115,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-05-31 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81516(0,5)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81516(1-3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81516(7-9)",
      "Operator (nazwa)": "Netia S.A. (d. ENTERPOL Sp. z o.o.)",
      "Operator (ID)": 9838,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-02-16 12:45"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 815164,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-01-03 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 815166,
      "Operator (nazwa)": "Orientelco Sp. z o.o.",
      "Operator (ID)": 12993,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-08-05 12:46"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81517(0,1-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 815176,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "8152(3,9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "8152(5-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81520(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 815200,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-12-22 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81521(0,1,2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 815213,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81522(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 815222,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-10-19 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81524(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 815249,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81528(0,2,4-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81528(1,3)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "8153(2-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81530(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 815300,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-12-22 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81531(0,2,3,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 815311,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-12-15 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 815314,
      "Operator (nazwa)": "Dialoga Servicios Interactivos S.A.",
      "Operator (ID)": 10930,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81537(1-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 815370,
      "Operator (nazwa)": "FREELY Sp. z o.o.",
      "Operator (ID)": 13143,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 815378,
      "Operator (nazwa)": "KORBANK S.A.",
      "Operator (ID)": 9294,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-11-29 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81538(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81538(1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 815387,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 81539,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "8154(1-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81540(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 815400,
      "Operator (nazwa)": "IPTELEKOM Micha� Ludwiczak",
      "Operator (ID)": 10051,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81544(0,1-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 815444,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-10-19 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "8155(2-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81550(1-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-23 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 815500,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-12-22 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 815505,
      "Operator (nazwa)": "AUTO-MIK �urek Sp. J.",
      "Operator (ID)": 10103,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-23 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81551(0,1)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81551(2,3,5-9)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 815514,
      "Operator (nazwa)": "Telewizja Kablowa �widnik Sp. z o.o.",
      "Operator (ID)": 1918,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81555(0,2-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 815551,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 815555,
      "Operator (nazwa)": "AlterTEL Sp. z o.o.",
      "Operator (ID)": 8614,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "8156(4,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81560(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 815600,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-10-19 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81561(0,1,3-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81561(7,8)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 815612,
      "Operator (nazwa)": "Voice Net Sp. z o.o.",
      "Operator (ID)": 12749,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2024-02-20 12:45"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 815616,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-24 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 815619,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-01 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81562(0,2,6,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81562(1,3-5,8,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81563(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81563(1-5)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81565(2,3)",
      "Operator (nazwa)": "Grupa Azoty Zak�ady Azotowe \"Pu�awy\" S.A.",
      "Operator (ID)": 2256,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "Pu�awy",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 815650,
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 815651,
      "Operator (nazwa)": "Pu�awska Sp�dzielnia Mieszkaniowa",
      "Operator (ID)": 1287,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "Pu�awy",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 815654,
      "Operator (nazwa)": "IPTELL Sp. z o.o.",
      "Operator (ID)": 7201,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 815655,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 815656,
      "Operator (nazwa)": "Blue Prospect Sp. z o.o.",
      "Operator (ID)": 13345,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-08-05 12:45"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 815657,
      "Operator (nazwa)": "World Discount Telecommunication Polska Sp. z o.o.",
      "Operator (ID)": 68,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 815658,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 815659,
      "Operator (nazwa)": "FreePhone Sp. z o.o.",
      "Operator (ID)": 10788,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-10-04 12:20"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81566(0,1-6,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81566(7,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "8157(1-6,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81570(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 815700,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-10-19 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81577(0,1-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 815777,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-07-09 12:16"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "8158(3,6,7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81580(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 815800,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-12-22 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81581(1-4,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81581(6-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 815810,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 815815,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81582(0,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81582(1-3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81584(0,1-3,6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81584(4,5,7,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81585(0,2-4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 815851,
      "Operator (nazwa)": "ENTER T&T Sp. z o.o.",
      "Operator (ID)": 10688,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 815855,
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-20 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81588(0,1-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 815888,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-12-22 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "8159(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81590(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 815900,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-10-19 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 816,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "817(0,3,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-09-15 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "8171(1-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81710(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 817109,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-12-30 12:45"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81718(1-2,4-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 817180,
      "Operator (nazwa)": "VIRTUAL TELECOM Sp. z o.o.",
      "Operator (ID)": 8894,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-01-23 12:45"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 817183,
      "Operator (nazwa)": "didXL B.V.",
      "Operator (ID)": 13079,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-10-22 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "8172(0,5-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81721(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81721(1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81722(0,4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81722(1-3,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 81723,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81724(0,1-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 817244,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81728(0,1-3,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81728(4-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 8174,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "8175(3,4,5,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81750(0-4,6,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81750(7,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 817505,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81751(0,2-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 817511,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-12-29 12:45"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81752(0,1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81752(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81756(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 817560,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 81757,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81759(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 817590,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "8176(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81760(0,1-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 817604,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "8177(0-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-09-15 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81777(0-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-09-15 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 817777,
      "Operator (nazwa)": "IPTELEKOM Micha� Ludwiczak",
      "Operator (ID)": 10051,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-09-15 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "8178(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81781(1-3)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-12 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81781(7,8)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81781(9,0)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-15 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 817814,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-12 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 817815,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-05-20 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 817816,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-04-11 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "818(3-4,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-01-09 12:45"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "8180(0,6-9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81801(0,2,3,5,6,9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 818011,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 818014,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 818017,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 818018,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81802(0,2,6-9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81802(1,4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81802(3,5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81803(0,5-9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81803(2,3)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 818031,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 818034,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81804(0,2,4-9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 818041,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 818043,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 81805,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "8181(0-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-01-09 12:45"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81818(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-01-09 12:45"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 818181,
      "Operator (nazwa)": "SUDER Sp. z o.o.",
      "Operator (ID)": 10822,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-01-09 12:45"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "8182(5,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81820(1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81820(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 818200,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81821(0,1-3,5-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81821(4,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81822(0,2-4,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81822(1,5-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 818228,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81823(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 818230,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 81824,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81826(0,1,2,4,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81826(3,5,6,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81827(0,1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81827(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81829(0,2,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81829(1,3,4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "8185(0,3,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81851(0,2-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81851(1,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81852(0,1-3,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 818524,
      "Operator (nazwa)": "Telnyx Ireland Limited",
      "Operator (ID)": 13021,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-01 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81854(0,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81854(1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81855(0,1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81855(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81856(0,1,5,7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81856(2-4,6,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81857(0,1-3,6,7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81857(4,5,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81859(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81859(1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "8186(0,1-4,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81865(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 818659,
      "Operator (nazwa)": "Systemy Informatyczne CINC Robert Hali�ski",
      "Operator (ID)": 9585,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81866(1,3,5,7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81866(2,4,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-03-26 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 818660,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-03-26 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 818666,
      "Operator (nazwa)": "Debesis Sp. z o.o.",
      "Operator (ID)": 10138,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-18 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "8188(1,7,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81880(0,1,3-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81880(2,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81882(0,1,3-6,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81882(2,7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81883(0,1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81883(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81884(0,3-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81884(2,7)",
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 818841,
      "Operator (nazwa)": "Syrion Sp. z o.o.",
      "Operator (ID)": 7883,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-06-24 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 818848,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 818849,
      "Operator (nazwa)": "BT Poland Sp. z o.o.",
      "Operator (ID)": 109,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-01-03 12:45"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 81885,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81886(0,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81886(1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81889(0,1-3,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "81889(4,6-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-11-18 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 818899,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-11-18 12:15"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": 8189,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "819(0,1-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:07"
    },
    {
      "Strefa (AB)": 81,
      "Zakres (number)": "819(8,9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "LUBLIN",
      "Strefa (symbol)": "SN-LU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "82(2,4,7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "821(0,1-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 8219,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "823(1-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "8230(1-6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "82300(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 823000,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "82307(1-3)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "82307(4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 823070,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "82308(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 823082,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "8238(0-1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "82382(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 823823,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-05 12:15"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "825(1,3,5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "8250(0,1-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "82506(0,1-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825065,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "8252(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "82525(0,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-19 12:15"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825251,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-07-22 12:15"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825252,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "82540(4,5)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "82540(8,9)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825400,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825401,
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825402,
      "Operator (nazwa)": "IPTELL Sp. z o.o.",
      "Operator (ID)": 7201,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825403,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-01-02 12:20"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825406,
      "Operator (nazwa)": "AKASHA.NET Sp. z o.o.",
      "Operator (ID)": 5819,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825407,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-08-07 12:15"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "82541(0,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-12-29 12:15"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "82541(1,2)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825413,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-02-29 10:01"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "82542(0,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "82542(1-6)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "82543(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825431,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "82544(1-3,5,6)",
      "Operator (nazwa)": "TeleGo sp. z o.o.",
      "Operator (ID)": 9324,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-09-25 12:15"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "82544(7-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825440,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825444,
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-20 12:15"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "82545(0,3,5,8)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825451,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825452,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825454,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825456,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-15 12:15"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825457,
      "Operator (nazwa)": "VOXNET Sp. z o.o. Sp. k.",
      "Operator (ID)": 728,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825459,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "82546(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825461,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "82547(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825470,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "82548(0,2-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-30 12:45"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825481,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-30 12:45"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "82549(1-9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825490,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "8256(0,2-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "82561(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "82561(1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825617,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-10-01 12:15"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "82568(0,2-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825681,
      "Operator (nazwa)": "DIDWW Ireland Limited",
      "Operator (ID)": 12132,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-19 12:15"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "82569(0,1-3,6-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825694,
      "Operator (nazwa)": "Telnyx Ireland Limited",
      "Operator (ID)": 13021,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-01 12:15"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825695,
      "Operator (nazwa)": "EFEKT-SERWIS Wojciech Szlechta",
      "Operator (ID)": 95,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825699,
      "Operator (nazwa)": "Inter Plus Sp. z o.o.",
      "Operator (ID)": 11092,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "8257(3,4,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "82570(1,6)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "82570(5,8)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825700,
      "Operator (nazwa)": "Hyperion S.A.",
      "Operator (ID)": 329,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825702,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825703,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825704,
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825707,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825709,
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "82571(0,2-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825711,
      "Operator (nazwa)": "Intelligent Technologies S.A.",
      "Operator (ID)": 748,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-09-16 12:15"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825716,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-24 12:15"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "82572(1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825720,
      "Operator (nazwa)": "Dialoga Servicios Interactivos S.A.",
      "Operator (ID)": 10930,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825728,
      "Operator (nazwa)": "ATMAN Sp. z o.o.",
      "Operator (ID)": 944,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-01-13 12:15"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825729,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-07-15 12:15"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "82575(1-4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "82575(6,7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825750,
      "Operator (nazwa)": "Futuro Exito Sp. z o.o.",
      "Operator (ID)": 7986,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-11-09 12:15"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825755,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-17 12:15"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825758,
      "Operator (nazwa)": "Galena Sp. z o.o.",
      "Operator (ID)": 66,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-16 12:16"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "82576(0,1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825768,
      "Operator (nazwa)": "Telewizja Kablowa Krasnystaw Sp. z o.o.",
      "Operator (ID)": 1420,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825769,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "82577(0,1-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825777,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "8258(0,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "82581(0,1-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825819,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "82582(0,6-9)",
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "82582(1-4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825825,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-10 12:15"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "8259(3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "82590(0,8-9)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "82590(1,3-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825902,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "82591(0,1,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "82591(2-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "82592(1,8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "82592(4-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825920,
      "Operator (nazwa)": "FreePhone Sp. z o.o.",
      "Operator (ID)": 10788,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-10-04 12:20"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825922,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825923,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 825929,
      "Operator (nazwa)": "FREELY Sp. z o.o.",
      "Operator (ID)": 13143,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "826(0,1-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "8268(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "82682(0,1-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 826826,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "828(1,3-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "8280(0,6-9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "8280(2-5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "82801(0,5-9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "82801(1,3-4)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 828012,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "8282(1-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-06-13 12:45"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 82820,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "82828(0-1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-06-13 12:45"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 828282,
      "Operator (nazwa)": "SUDER Sp. z o.o.",
      "Operator (ID)": 10822,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-06-13 12:45"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "8288(0,1,3-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "82882(0,1-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 828828,
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "82888(0,2-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 828881,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": 828888,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "829(0,1-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 82,
      "Zakres (number)": "829(8,9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "CHE�M",
      "Strefa (symbol)": "SN-CH",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83(2,5-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "831(0,1-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 8319,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "833(2,6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "8330(1-5,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83300(4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-22 12:20"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 833000,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 833001,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-08-07 12:15"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 833002,
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-22 12:15"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 833003,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-22 12:20"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83306(0,4-6,9)",
      "Operator (nazwa)": "TeleGo sp. z o.o.",
      "Operator (ID)": 9324,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-09-25 12:15"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83306(1-3)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 833067,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 833068,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83307(2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-12-29 12:15"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 833070,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 833071,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-02-29 10:01"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83308(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 833082,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "8331(0,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83311(0,1-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 833117,
      "Operator (nazwa)": "Lukman Multimedia Sp. z o.o.",
      "Operator (ID)": 9030,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83312(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83312(1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83313(0,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83313(1-6)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 83314,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "8333(1-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83330(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 833300,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83333(0,2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-06-13 12:15"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 833331,
      "Operator (nazwa)": "Zak�ad Budownictwa Liniowego TELBIAL Sp. z o.o.",
      "Operator (ID)": 10473,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-06-13 12:15"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 833333,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-01 12:15"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "8334(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "8334(1,2,4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83343(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 833430,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83345(0,1-4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 833455,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 83350,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83351(1-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 833510,
      "Operator (nazwa)": "Liquid Systems Sp. z o.o.",
      "Operator (ID)": 3365,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 833516,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-17 12:15"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83352(0,1,2,4-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 833523,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-10-30 12:15"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83353(0,1-5,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83353(6-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83354(0,1-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 833546,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83355(0-2,4-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 833553,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83356(0,1,2,4,5,7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 833563,
      "Operator (nazwa)": "Dialoga Servicios Interactivos S.A.",
      "Operator (ID)": 10930,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 833566,
      "Operator (nazwa)": "DIDWW Ireland Limited",
      "Operator (ID)": 12132,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-10-19 12:15"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 833568,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2023-03-29 12:45"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83357(0,1-3,5,6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 833574,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 833577,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83358(0,2,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83358(1,3-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83359(0,1,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83359(2-6)",
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83370(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 833701,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83371(0,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83371(1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83372(0,1-3,6,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83372(4,5,7,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83373(0,1-3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83373(4-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 833739,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83374(0,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83374(1-3)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 83375,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83376(1-4,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83376(5,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 833760,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83377(0,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83377(1-5,7,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 833776,
      "Operator (nazwa)": "Telnyx Ireland Limited",
      "Operator (ID)": 13021,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-01 12:15"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83378(0,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83378(1-4,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 833785,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 833786,
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 833787,
      "Operator (nazwa)": "Galena Sp. z o.o.",
      "Operator (ID)": 66,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-16 12:16"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83379(0,4,7)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83379(1-3,5,6,8,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "8338(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83383(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 833833,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-12-31 12:15"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "834(0,2-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "8341(5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83410(0,1-3)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83410(8,9)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 834104,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 834105,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "Bia�a Podlaska",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 834106,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 834107,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83411(0,8)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83411(1,2)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83411(7,9)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 834113,
      "Operator (nazwa)": "Voice Net Sp. z o.o.",
      "Operator (ID)": 12749,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-02-20 12:45"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 834114,
      "Operator (nazwa)": "Intelligent Technologies S.A.",
      "Operator (ID)": 748,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-09-16 12:15"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 834115,
      "Operator (nazwa)": "TAU Sp. z o.o.",
      "Operator (ID)": 12291,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-02-01 12:15"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 834116,
      "Operator (nazwa)": "TELEKOM SYSTEM Sp. z o.o.",
      "Operator (ID)": 2793,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83412(0,2-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-30 12:45"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 834121,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-30 12:45"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83413(1-4)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "Radzy� Podlaski",
      "Data modyfikacji": "2018-06-01 12:15"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 834130,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 834135,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-11-25 12:15"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 834136,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-09-12 12:15"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 834137,
      "Operator (nazwa)": "IPTELL Sp. z o.o.",
      "Operator (ID)": 7201,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 834138,
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 834139,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83414(2,4,5)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 834140,
      "Operator (nazwa)": "FREELY Sp. z o.o.",
      "Operator (ID)": 13143,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 834141,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 834143,
      "Operator (nazwa)": "VOXNET Sp. z o.o. Sp. k.",
      "Operator (ID)": 728,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 834146,
      "Operator (nazwa)": "TELEKOM SYSTEM Sp. z o.o.",
      "Operator (ID)": 2793,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 834147,
      "Operator (nazwa)": "TAU Sp. z o.o.",
      "Operator (ID)": 12291,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-02-01 12:15"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 834148,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:08"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 834149,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "8348(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83483(0-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 834834,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-05 12:15"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "838(1,2,4-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "8380(0,6-9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "8380(2-5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83801(2-9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 838010,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 838011,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "8383(0,1-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83838(0,1,2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 838383,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-01 12:15"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "8388(0-2,4-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-25 12:15"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83883(0-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-25 12:15"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 838838,
      "Operator (nazwa)": "Telfon Poland Sp. z o.o.",
      "Operator (ID)": 12517,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-25 12:15"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "83888(2-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 838880,
      "Operator (nazwa)": "AMS SYSTEM Sp. z o.o.",
      "Operator (ID)": 7006,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 838881,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 838888,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": 838889,
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-20 12:15"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "839(0,1-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 83,
      "Zakres (number)": "839(8,9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "BIA�A PODLASKA",
      "Strefa (symbol)": "SN-BP",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84(2,4,7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "841(0,1-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 8419,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "843(1-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "8430(1-6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84300(1-5)",
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84300(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 843000,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 843070,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 843071,
      "Operator (nazwa)": "DERKOM Sp�ka Jawna Dariusz Klimczuk",
      "Operator (ID)": 1272,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-10-26 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 843072,
      "Operator (nazwa)": "Nettelekom GK Sp. z o.o.",
      "Operator (ID)": 9896,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 843073,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-01-02 12:20"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 843074,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 843075,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 843076,
      "Operator (nazwa)": "Galena Sp. z o.o.",
      "Operator (ID)": 66,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-16 12:16"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 843077,
      "Operator (nazwa)": "Telnyx Ireland Limited",
      "Operator (ID)": 13021,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-01 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 843078,
      "Operator (nazwa)": "TOYA Sp. z o.o.",
      "Operator (ID)": 127,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-09-13 12:45"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 843079,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-09-13 12:45"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84308(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-12-29 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84308(3-5)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 843081,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-07-23 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 843082,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "8438(0-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84384(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 843843,
      "Operator (nazwa)": "MEDIDESK Sp. z o.o.",
      "Operator (ID)": 12669,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-30 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "845(1-2,5-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "8450(0,1-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84506(0,1-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 845065,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84530(0-6,8-9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 845307,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84531(0,2-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-30 12:45"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 845311,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-30 12:45"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84532(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-10-31 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84532(1-3)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84532(4,5)",
      "Operator (nazwa)": "Multimedia Polska Biznes S.A.",
      "Operator (ID)": 11221,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-10-31 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84533(0,1,2,4,5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84533(6-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 845333,
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-20 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84534(1,2)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84534(3-6)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 845340,
      "Operator (nazwa)": "Voice Net Sp. z o.o.",
      "Operator (ID)": 12749,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2024-02-20 12:45"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 845347,
      "Operator (nazwa)": "FREELY Sp. z o.o.",
      "Operator (ID)": 13143,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 845348,
      "Operator (nazwa)": "CYFROTEL Sp�ka Jawna Mariusz Ulidowski, Marek B�dziuch, Pawe� Olekszyk",
      "Operator (ID)": 10529,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-09-29 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 845349,
      "Operator (nazwa)": "Intelligent Technologies S.A.",
      "Operator (ID)": 748,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-09-16 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84535(2,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 845350,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-12-01 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 845351,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 845353,
      "Operator (nazwa)": "HRUBIESZOWSKA TELEWIZJA KABLOWA W. Migas, B. Sanek, M. Szerment, C. Bajurka Sp�ka Jawna",
      "Operator (ID)": 4343,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 845354,
      "Operator (nazwa)": "IPTELL Sp. z o.o.",
      "Operator (ID)": 7201,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 845355,
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 845357,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 845358,
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 845359,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84536(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84536(1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84537(1-5)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84537(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 845370,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84538(3,7)",
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 845380,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-04 12:46"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 845381,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 845382,
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 845384,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 845385,
      "Operator (nazwa)": "VOXNET Sp. z o.o. Sp. k.",
      "Operator (ID)": 728,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 845386,
      "Operator (nazwa)": "Platan Sp. z o.o. Sp. k.",
      "Operator (ID)": 12733,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2023-08-29 12:45"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 845388,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-05-17 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 845389,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84539(0,2-3,5-6)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84539(8,9)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 845391,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-08-07 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 845394,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 845397,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "8454(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84541(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84541(1-4)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 845415,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84542(0-2)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:17"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84542(4-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 845423,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:17"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 845429,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-10-21 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84543(0,8-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84543(3-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-13 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 845431,
      "Operator (nazwa)": "DERKOM Sp�ka Jawna Dariusz Klimczuk",
      "Operator (ID)": 1272,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-13 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 845432,
      "Operator (nazwa)": "P.P.H.U. Koper Jan",
      "Operator (ID)": 1753,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84544(0,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84544(2,3)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:17"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 845441,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:17"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84545(0-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 845454,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "8458(0-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84584(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 845845,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-05 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "8460(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 84600,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "8461(0,2-4,7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84611(0,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84611(1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84615(0,1,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84615(2-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84616(0,2-4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 846161,
      "Operator (nazwa)": "3PLAY Sp. z o.o.",
      "Operator (ID)": 10116,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-05-12 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 846165,
      "Operator (nazwa)": "Dialoga Servicios Interactivos S.A.",
      "Operator (ID)": 10930,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84618(0,3,6-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84618(2,4,5,8,9)",
      "Operator (nazwa)": "TeleGo sp. z o.o.",
      "Operator (ID)": 9324,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-09-25 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 846181,
      "Operator (nazwa)": "DIDWW Ireland Limited",
      "Operator (ID)": 12132,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-19 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "8462(0,2-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84621(0,1,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84621(2-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84627(0,1-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 846277,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "8463(0,2-4,6,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "8463(8,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84631(0,1,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84631(2-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84635(0,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 846351,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-26 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 846352,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:17"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "8464(0,2,4,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84641(0,1,2,4-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 846413,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 84643,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84645(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 846451,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84646(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 846461,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84647(0,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84647(1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84649(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84649(1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "8465(0,2,4-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84651(0,2,4-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84651(1,3)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84653(0,1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84653(3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84657(0,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84657(1-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "8466(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84660(0,1-6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84660(7,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84661(0,1-4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 846615,
      "Operator (nazwa)": "VIKOM Sp. z o.o.",
      "Operator (ID)": 10424,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-08-28 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84662(0,1-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 846624,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84663(0,1-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84663(5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84664(0,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84664(1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 846648,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 84665,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84666(0,2-5,7,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84666(1,6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84667(0,1-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 846674,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "8467(0,2-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84671(0,1,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84671(2-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84677(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84677(1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "8468(5-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84680(0,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84680(1-5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84681(0,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84681(1-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84682(0,4,5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84682(1-3,6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84683(0,1-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 846837,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84684(0,2-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 846841,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 846846,
      "Operator (nazwa)": "Roman Bidu� TELBIT wsp�lnik sp�ki cywilnej",
      "Operator (ID)": 7904,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-10-07 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84688(0,1,2,4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84688(3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84689(0,1-4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 846895,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "8469(0,2,3)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84691(0,2-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84691(1,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84694(0,1)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84694(2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84695(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 846951,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84696(0,1,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84696(2-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84697(0,1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84697(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84698(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 846981,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84699(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 846991,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "848(1-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "8480(0,6-9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "8480(3-5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84801(1-5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84801(6-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 848010,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 848019,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84802(0,1)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84802(4-9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 848022,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 848023,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "8488(0-3,5-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-25 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84884(0-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-25 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 848848,
      "Operator (nazwa)": "Telfon Poland Sp. z o.o.",
      "Operator (ID)": 12517,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-25 12:15"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84888(2,3,8)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "84888(4-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 848880,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": 848881,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "849(0,1-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 84,
      "Zakres (number)": "849(8,9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "ZAMO��",
      "Strefa (symbol)": "SN-ZA",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85(2,4)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "851(0,1-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 8519,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "853(1,4-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "8530(1-5,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85300(2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-22 12:20"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 853000,
      "Operator (nazwa)": "AlterTEL Sp. z o.o.",
      "Operator (ID)": 8614,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 853001,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-22 12:20"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 853003,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-09 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85306(0,5-6,8-9)",
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85306(1-3)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 853064,
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-12 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 853067,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85307(0,6-9)",
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85307(1-5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85308(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 853082,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:09"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "8532(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-08-25 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85325(0,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-12 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 853251,
      "Operator (nazwa)": "TOYA Sp. z o.o.",
      "Operator (ID)": 127,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-12 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 853252,
      "Operator (nazwa)": "FALCONN Falkowski Tomasz",
      "Operator (ID)": 8255,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-08-25 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "8533(0,1,2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85333(0,2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-12-29 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 853331,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-02-29 10:01"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 853333,
      "Operator (nazwa)": "KOBA Sp. z o.o.",
      "Operator (ID)": 1102,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "8538(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85385(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 853853,
      "Operator (nazwa)": "LoVo Sp. z o.o.",
      "Operator (ID)": 7184,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-10-30 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "855(1-4,6-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-02-02 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "8550(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-02-02 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85500(2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2021-12-29 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 855000,
      "Operator (nazwa)": "HomeNet Technologies Sp. z o.o.",
      "Operator (ID)": 2382,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-02-02 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 855001,
      "Operator (nazwa)": "MEDIDESK Sp. z o.o.",
      "Operator (ID)": 12669,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2021-12-29 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "8555(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-17 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85555(0-4,7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-08-21 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 855555,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-17 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 855556,
      "Operator (nazwa)": "JMDI J. Maleszko",
      "Operator (ID)": 3487,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-05-31 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 855558,
      "Operator (nazwa)": "SERVIRE Adam Koz�owski",
      "Operator (ID)": 9103,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-08-21 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "8558(0,1-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85585(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-04-21 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 855855,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "856(1-4,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "8560(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85600(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 856000,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "8565(1,3-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "8565(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85650(0,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85650(1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85652(0,1-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 856528,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-17 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85656(0,1,4-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85656(2,3)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 856567,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-07-22 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85657(0,2,3,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85657(1,4,5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "8566(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "8566(1-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85665(2,4-6,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-08 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85665(3,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 856650,
      "Operator (nazwa)": "Dialoga Servicios Interactivos S.A.",
      "Operator (ID)": 10930,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 856651,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-08 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 856659,
      "Operator (nazwa)": "FREELY Sp. z o.o.",
      "Operator (ID)": 13143,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85666(1-4,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-08-25 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85666(5,6)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 856660,
      "Operator (nazwa)": "HomeNet Technologies Sp. z o.o.",
      "Operator (ID)": 2382,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2024-02-08 12:45"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 85667,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85670(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85670(2-4)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-01 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 856701,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 856705,
      "Operator (nazwa)": "VIRTUAL TELECOM Sp. z o.o.",
      "Operator (ID)": 8894,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85671(0,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85671(1-4)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85672(0,2,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 856721,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-02-01 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 856723,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 856724,
      "Operator (nazwa)": "Intelligent Technologies S.A.",
      "Operator (ID)": 748,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 856725,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-12-01 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85673(0,6-9)",
      "Operator (nazwa)": "TeleGo sp. z o.o.",
      "Operator (ID)": 9324,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-09-25 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85673(1-3,5)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 856734,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-04 12:46"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85674(1,2)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-13 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85674(4,9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-13 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85674(5-8)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 856740,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 856743,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85675(0,1-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 856758,
      "Operator (nazwa)": "ENTER T&T Sp. z o.o.",
      "Operator (ID)": 10688,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85676(0,1-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 856766,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-11-13 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85677(0,1,5,6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85677(3,4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-08 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 856772,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-08 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 856777,
      "Operator (nazwa)": "BIALNET Sp. z o.o.",
      "Operator (ID)": 5057,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85678(0-1)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-10-29 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85678(2-6,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85678(7,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-10-29 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85679(2-5)",
      "Operator (nazwa)": "Telekomunikacja Podlasie Sp. z o.o.",
      "Operator (ID)": 4858,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 856790,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2021-12-14 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 856791,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 856796,
      "Operator (nazwa)": "Telewizja Kablowa Hajn�wka Ki�dy�, Kiryluk Sp. J.",
      "Operator (ID)": 2033,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 856797,
      "Operator (nazwa)": "PPHU AK Chamerli�scy Sp. J.",
      "Operator (ID)": 8011,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 856798,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 856799,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "8568(0,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85681(0,1,4,7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85681(2,3,5,6,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85682(0,2-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 856821,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85683(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85683(2-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 856831,
      "Operator (nazwa)": "Telnyx Ireland Limited",
      "Operator (ID)": 13021,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-01 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85684(0,1,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85684(2-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85685(0-4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 856855,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-15 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85686(0,1,2,6,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85686(3-5,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 856869,
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-20 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85687(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85687(1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85688(8,9)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 856880,
      "Operator (nazwa)": "Nettelekom GK Sp. z o.o.",
      "Operator (ID)": 9896,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 856881,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 856882,
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 856883,
      "Operator (nazwa)": "EURONET Norbert Saniewski Sp.J.",
      "Operator (ID)": 9544,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 856884,
      "Operator (nazwa)": "SulikaNET S.C. Tomasz Lewko i Pawe� Moj�uk",
      "Operator (ID)": 8573,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 856885,
      "Operator (nazwa)": "IPTELL Sp. z o.o.",
      "Operator (ID)": 7201,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 856886,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 856887,
      "Operator (nazwa)": "CLUDO Sp z o.o.",
      "Operator (ID)": 10056,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-02-01 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "857(0,5-6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "8571(1,2,6,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85710(0,5-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85710(1-4,8,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85713(0,1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85713(3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85714(0,1-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 857146,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85715(1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-08 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 857150,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 857159,
      "Operator (nazwa)": "Galena Sp. z o.o.",
      "Operator (ID)": 66,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-16 12:16"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85717(0,1,2,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85717(3,4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85719(0,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85719(1-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "8572(0,3-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85721(0,2-4,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85721(1,5,6,8,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85722(0,1,4-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 857222,
      "Operator (nazwa)": "KOBA Sp. z o.o.",
      "Operator (ID)": 1102,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 857223,
      "Operator (nazwa)": "Connected Sp. z o.o.",
      "Operator (ID)": 8033,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-04-17 12:45"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85727(0,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85727(1-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "8573(0,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85731(0-4,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 857315,
      "Operator (nazwa)": "DIDWW Ireland Limited",
      "Operator (ID)": 12132,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-10-19 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 857316,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85733(6,8,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 857330,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 857331,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 857332,
      "Operator (nazwa)": "HomeNet Technologies Sp. z o.o.",
      "Operator (ID)": 2382,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 857333,
      "Operator (nazwa)": "KOBA Sp. z o.o.",
      "Operator (ID)": 1102,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 857334,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-01 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 857335,
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 857337,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85734(0,2-4,6)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85734(7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 857341,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 857345,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 857348,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85735(0,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85735(1-3)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 85736,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85737(0,2,4,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85737(1,3,5-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85738(0,1-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 857388,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-11-25 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85739(0,3,6-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85739(1,2,4,5,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "8574(0-2,4-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-01-08 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85743(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 857439,
      "Operator (nazwa)": "Hyperion S.A.",
      "Operator (ID)": 329,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-08-08 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85749(0-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-01-08 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 857498,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2023-03-29 12:45"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "8577(0,1-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85777(1-6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-15 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 857770,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-15 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 857777,
      "Operator (nazwa)": "KOBA Sp. z o.o.",
      "Operator (ID)": 1102,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 857778,
      "Operator (nazwa)": "GT11 Sp. z o.o.",
      "Operator (ID)": 10475,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-24 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "8578(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85785(0-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 857857,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-05 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "858(2,4,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "8580(0,6-9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "8580(4,5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85801(1,3)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85801(2,4,5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85801(7-9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 858010,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 858016,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85802(2-9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 858020,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 858021,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-01-06 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85803(1-3,5,6,8,9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 858030,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 858034,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 858037,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "8581(0,2,3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85811(0,1-3,8-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85811(4-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85814(0,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85814(1,4-6)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "�apy",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85814(2,3)",
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "�apy",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "8583(0,2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85831(1-4)",
      "Operator (nazwa)": "Przedsi�biorstwo Elektroniczno-Mechaniczne \"ELPOS\" Sp. z o.o.",
      "Operator (ID)": 2240,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "Bia�ystok",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85831(5,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85831(6-8)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-01 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 858310,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85833(0,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85833(1,3,6)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "Bielsk Podlaski",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85833(2,4,5)",
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "Bielsk Podlaski",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "8585(1-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-01-09 12:45"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 85850,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85858(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-01-09 12:45"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 858585,
      "Operator (nazwa)": "SUDER Sp. z o.o.",
      "Operator (ID)": 10822,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-01-09 12:45"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "8586(0,1-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85868(1-3,5,9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "Bia�ystok",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85868(4,6)",
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "Bia�ystok",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85868(7,8,0)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "Bia�ystok",
      "Data modyfikacji": "2022-07-13 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85869(1,2)",
      "Operator (nazwa)": "Hyperion S.A.",
      "Operator (ID)": 329,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85869(3-9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "Bia�ystok",
      "Data modyfikacji": "2022-07-13 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 858690,
      "Operator (nazwa)": "ITH Sp. Z o.o.",
      "Operator (ID)": 12635,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "Bia�ystok",
      "Data modyfikacji": "2023-06-10 12:45"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "8587(0,2)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 85871,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "Bia�ystok",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85873(0,9)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85873(1-2,4-6)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "Hajn�wka",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85873(7,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 858733,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "Hajn�wka",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85874(0,4)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85874(2,5)",
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85874(7-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 858741,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-10-30 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 858743,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 858746,
      "Operator (nazwa)": "VOXNET Sp. z o.o. Sp. k.",
      "Operator (ID)": 728,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85875(4-8)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "Bia�ystok",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 858750,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 858751,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "Bia�ystok",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 858752,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 858753,
      "Operator (nazwa)": "Telekomunikacja Podlasie Sp. z o.o.",
      "Operator (ID)": 4858,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 858759,
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85876(2,3)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85876(6,9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 858760,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 858761,
      "Operator (nazwa)": "Blue Prospect Sp. z o.o.",
      "Operator (ID)": 13345,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-08-05 12:45"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 858764,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 858765,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-03-10 12:45"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 858767,
      "Operator (nazwa)": "Telewizja Kablowa Hajn�wka Ki�dy�, Kiryluk Sp. J.",
      "Operator (ID)": 2033,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 858768,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85877(0,1-4)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85877(5,6,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 858777,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85878(0-3,5-8)",
      "Operator (nazwa)": "Hyperion S.A.",
      "Operator (ID)": 329,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-19 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 858784,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-19 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 858789,
      "Operator (nazwa)": "Telekomunikacja Podlasie Sp. z o.o.",
      "Operator (ID)": 4858,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85879(1-9)",
      "Operator (nazwa)": "Hyperion S.A.",
      "Operator (ID)": 329,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-05-26 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 858790,
      "Operator (nazwa)": "Telekomunikacja Podlasie Sp. z o.o.",
      "Operator (ID)": 4858,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-05-26 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "8588(0-4,6-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-25 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "85885(0-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-25 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 858858,
      "Operator (nazwa)": "Telfon Poland Sp. z o.o.",
      "Operator (ID)": 12517,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-25 12:15"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": 85888,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "859(0,1-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 85,
      "Zakres (number)": "859(8,9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "BIA�YSTOK",
      "Strefa (symbol)": "SN-BK",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86(5-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "861(0,1-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 8619,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "862(0,3-5,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "8621(5-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86210(0-1,3-4,7-9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86210(5,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 862102,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86211(1-6)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 862110,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-07-22 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 862117,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-02-29 10:01"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 862118,
      "Operator (nazwa)": "DIDWW Ireland Limited",
      "Operator (ID)": 12132,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-10-19 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 862119,
      "Operator (nazwa)": "Podlaskie Sieci �wiat�owodowe Sp. z o.o.",
      "Operator (ID)": 11262,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-10-20 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86212(0,5-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-01-08 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86212(3-4,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-09-21 12:20"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 862121,
      "Operator (nazwa)": "JMDI J. Maleszko",
      "Operator (ID)": 3487,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-09-21 12:20"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 862122,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-01-08 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86213(0,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86213(1-5)",
      "Operator (nazwa)": "TeleGo sp. z o.o.",
      "Operator (ID)": 9324,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86213(6-8)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86214(0,1)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86214(2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86219(0,1-4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 862195,
      "Operator (nazwa)": "Intelligent Technologies S.A.",
      "Operator (ID)": 748,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-09-16 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "8622(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86221(0,1-5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86221(6-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86222(1,3)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86222(4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-22 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 862220,
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-22 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 862222,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86223(0,9)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86223(1,2)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 862233,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 862234,
      "Operator (nazwa)": "Galena Sp. z o.o.",
      "Operator (ID)": 66,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-16 12:16"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 862235,
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 862236,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 862237,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 862238,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86224(0,2,3,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86224(6,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 862241,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 862244,
      "Operator (nazwa)": "TEPSANET NOWACCY Sp. J.",
      "Operator (ID)": 11755,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-08-13 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 862245,
      "Operator (nazwa)": "INTERTOR.NET Bo�ena Wojtczak",
      "Operator (ID)": 5760,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86225(0,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86225(1,2)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "8626(0,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86261(0-3,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 862614,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 862615,
      "Operator (nazwa)": "ATMAN Sp. z o.o.",
      "Operator (ID)": 944,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-01-13 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 862616,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 862620,
      "Operator (nazwa)": "Telnyx Ireland Limited",
      "Operator (ID)": 13021,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-01 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 862621,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 862622,
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 862623,
      "Operator (nazwa)": "NET-KOMP Sp. z o.o.",
      "Operator (ID)": 11905,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-09-30 12:45"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 862624,
      "Operator (nazwa)": "Telewizja Kablowa Hajn�wka Ki�dy�, Kiryluk Sp. J.",
      "Operator (ID)": 2033,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 862625,
      "Operator (nazwa)": "IPTELL Sp. z o.o.",
      "Operator (ID)": 7201,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 862626,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-09-12 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 862627,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-11-25 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 862628,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-10-21 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 862629,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-09-06 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "8627(0-3,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86274(0,2,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86274(1,3-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86276(0-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86276(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 862766,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-23 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86277(1-6,8,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 862770,
      "Operator (nazwa)": "Voice Net Sp. z o.o.",
      "Operator (ID)": 12749,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2024-02-20 12:45"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 862777,
      "Operator (nazwa)": "Dialoga Servicios Interactivos S.A.",
      "Operator (ID)": 10930,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86278(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 862780,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-17 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86279(0-1)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86279(2,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86279(3-6,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "8628(0-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86286(0-1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 862862,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-10 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "863(1-2,4-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "8630(1-5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86300(2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-22 12:20"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 863000,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 863001,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-22 12:20"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 863003,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-08-11 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86306(0,1-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 863067,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86307(1-5)",
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86307(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 863070,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86308(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 863082,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86309(0,2-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-30 12:45"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 863091,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-30 12:45"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "8633(0,1,2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 86333,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "8638(0-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86386(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 863863,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-10 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "864(0-3,5-6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "8644(0,1-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86444(3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-22 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 864440,
      "Operator (nazwa)": "Montmay Sp. z o.o.",
      "Operator (ID)": 11750,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-22 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 864441,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 864442,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 864444,
      "Operator (nazwa)": "MARKOMP Marek Matejkowski",
      "Operator (ID)": 1360,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86470(0,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86470(1,2)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86471(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86471(1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86472(0,1-3)",
      "Operator (nazwa)": "Hyperion S.A.",
      "Operator (ID)": 329,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86472(4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86473(0-2,4-9)",
      "Operator (nazwa)": "Hyperion S.A.",
      "Operator (ID)": 329,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-08 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 864733,
      "Operator (nazwa)": "INTERTOR.NET Bo�ena Wojtczak",
      "Operator (ID)": 5760,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-08 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86474(0,2-5)",
      "Operator (nazwa)": "Hyperion S.A.",
      "Operator (ID)": 329,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-08-03 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86474(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 864741,
      "Operator (nazwa)": "FREELY Sp. z o.o.",
      "Operator (ID)": 13143,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86475(0-3,5)",
      "Operator (nazwa)": "Hyperion S.A.",
      "Operator (ID)": 329,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-05-16 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86475(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 864754,
      "Operator (nazwa)": "Telekomunikacja Podlasie Sp. z o.o.",
      "Operator (ID)": 4858,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-05-16 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86476(0-7)",
      "Operator (nazwa)": "Hyperion S.A.",
      "Operator (ID)": 329,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-06 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86476(8,9)",
      "Operator (nazwa)": "TEPSANET NOWACCY Sp. J.",
      "Operator (ID)": 11755,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-06 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86477(1-3,5-9)",
      "Operator (nazwa)": "Hyperion S.A.",
      "Operator (ID)": 329,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-27 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 864770,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-27 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 864774,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86478(0,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86478(1-3)",
      "Operator (nazwa)": "Hyperion S.A.",
      "Operator (ID)": 329,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86479(0,1,9)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86479(3,7)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "�om�a",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86479(4,5)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 864792,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 864796,
      "Operator (nazwa)": "Telewizja Kablowa DIPOL Sp. z o.o.",
      "Operator (ID)": 1311,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 864798,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "�om�a",
      "Data modyfikacji": "2022-02-05 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "8648(0-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86486(0-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 864864,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-05 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "868(1-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "8680(0,6-9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "8680(3-5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86801(0,6-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86801(1-4)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 868015,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-04-01 12:45"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 868019,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86802(0,4,5,8-9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86802(2,3)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 868021,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 868026,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 868027,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "8688(1-5,7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-25 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86880(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 868800,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86886(0-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-25 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 868868,
      "Operator (nazwa)": "Telfon Poland Sp. z o.o.",
      "Operator (ID)": 12517,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-25 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "86888(2-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-24 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 868880,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 868881,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 868888,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": 868889,
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-24 12:15"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "869(0,1-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 86,
      "Zakres (number)": "869(8,9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "�OM�A",
      "Strefa (symbol)": "SN-�O",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "871(0,1-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:10"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 8719,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 872,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "873(1-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "8730(1-6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87300(0,3)",
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87300(2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-22 12:20"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 873001,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-22 12:20"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87307(1-3)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87307(4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 873070,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87308(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 873082,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "8738(0-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87387(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 873873,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-10 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "874(0-1,5-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "8742(1,3,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "8742(2,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87420(0,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87420(1-5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87420(6,7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87424(0,1)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87424(2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87425(0,1-4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 874255,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-12-15 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "8743(0,1-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87437(0,3-6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87437(1,2)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87437(7-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "8744(0,2,3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87441(0,2-6,8-9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87441(1,7)",
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87444(1-3)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 874440,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 874444,
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 874445,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 874446,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-17 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 874447,
      "Operator (nazwa)": "FREELY Sp. z o.o.",
      "Operator (ID)": 13143,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 874448,
      "Operator (nazwa)": "IPTELL Sp. z o.o.",
      "Operator (ID)": 7201,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 874449,
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "8748(0-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87487(0-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 874874,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-10 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "875(3-4,7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "8750(0,1-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87506(0,1-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 875065,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "8751(0,1-5,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87516(0,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87516(1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87517(0,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87517(1-3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "8752(2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87520(0,1-4,6,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87520(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 875205,
      "Operator (nazwa)": "Marinex Ampol 2 Telewizja Kablowa Sp. z o.o.",
      "Operator (ID)": 2339,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "Suwa�ki",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87521(0,1,2,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87521(3-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87523(0,4,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 875231,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-01-02 12:20"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 875232,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 875233,
      "Operator (nazwa)": "MILICHSOFT Rafa� Maliszewski",
      "Operator (ID)": 6415,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 875235,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-01 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 875236,
      "Operator (nazwa)": "e-Telko Sp. z o.o.",
      "Operator (ID)": 11564,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-04-19 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "8755(0,1-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87555(0,1-4,6-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 875555,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 875559,
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-20 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "8756(0,1)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "8756(2,5,7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87563(0,1-3,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 875634,
      "Operator (nazwa)": "Telnyx Ireland Limited",
      "Operator (ID)": 13021,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-01 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87564(0-2,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87564(3,4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87564(5,6,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87566(0,1-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 875668,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-03-29 12:46"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87568(1-3,5-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 875680,
      "Operator (nazwa)": "Dialoga Servicios Interactivos S.A.",
      "Operator (ID)": 10930,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 875684,
      "Operator (nazwa)": "Telewizja Kablowa DIPOL Sp. z o.o.",
      "Operator (ID)": 1311,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2024-02-14 12:45"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 875689,
      "Operator (nazwa)": "DIDWW Ireland Limited",
      "Operator (ID)": 12132,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-10-19 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "8758(0-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87587(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 875875,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-05 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "876(0,3,7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "8761(0,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "8761(2-4,6,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87611(0,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87611(1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87618(0,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-04-21 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87618(1,2)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87619(0,1,2,5,8)",
      "Operator (nazwa)": "TeleGo sp. z o.o.",
      "Operator (ID)": 9324,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-09-25 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87619(3,4,6,7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "8762(0,1)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "8762(2-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87629(0-1,4,7-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87629(3,5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87629(6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 876292,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "8764(0,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87641(0,2-4,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87641(1,5,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87642(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87642(1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 87643,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87644(0,1,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87644(2-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "8765(0,1-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87654(0,1,2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 876543,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-08-01 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "8766(0,1-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87666(0,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87666(1-5)",
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 876666,
      "Operator (nazwa)": "SEJNET Rafa� S�awi�ski",
      "Operator (ID)": 1454,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "8768(0-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87687(0-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 876876,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-10-19 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "877(0-2,4-6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87730(0,1)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87730(4,5)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 877302,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-12-01 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 877303,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 877306,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-01 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 877307,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-04 12:46"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 877308,
      "Operator (nazwa)": "Intelligent Technologies S.A.",
      "Operator (ID)": 748,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 877309,
      "Operator (nazwa)": "TOYA Sp. z o.o.",
      "Operator (ID)": 127,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87731(0,9)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "Suwa�ki",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87731(1,2,8)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 877313,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 877314,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 877315,
      "Operator (nazwa)": "Televoice Polska Sp. z o.o.",
      "Operator (ID)": 8808,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 877316,
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 877317,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87732(0-5,7-9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 877326,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87733(0,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87733(1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87734(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87734(1-7)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87735(0,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87735(1,4,6)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 877352,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 877353,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 877355,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87736(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 877361,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87737(0,2,4)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87737(7,8)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 877371,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 877373,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 877375,
      "Operator (nazwa)": "MACROSAT Sp. z o.o.",
      "Operator (ID)": 1706,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 877376,
      "Operator (nazwa)": "VOXNET Sp. z o.o. Sp. k.",
      "Operator (ID)": 728,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 877379,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87738(0,2-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-30 12:45"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 877381,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-30 12:45"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87739(0-7)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:17"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87739(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:17"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "8777(0,1-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 87777,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "8778(0-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87787(0-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 877877,
      "Operator (nazwa)": "Telfon Poland Sp. z o.o.",
      "Operator (ID)": 12517,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "878(1-6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "8780(0,6-9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "8780(3-5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87801(1,5,8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87801(2,9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87801(6,7,0)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 878013,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 878014,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87802(1,5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87802(2,7-9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87802(3-4,6)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 878020,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "8787(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87870(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 878700,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "8788(0-6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-25 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87887(0-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-25 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 878878,
      "Operator (nazwa)": "Telfon Poland Sp. z o.o.",
      "Operator (ID)": 12517,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-25 12:15"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "87888(0,2-6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 878881,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 878887,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": 878888,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "879(0,1-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 87,
      "Zakres (number)": "879(8,9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "SUWA�KI",
      "Strefa (symbol)": "SN-SU",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89(2,4)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "891(0,1-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 8919,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "893(1-2,4-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "8930(1-6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89300(0,3)",
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89300(2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 893001,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89307(1-3)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89307(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-10-19 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 893070,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 893074,
      "Operator (nazwa)": "Firma TONETIC Krzysztof Adamczyk",
      "Operator (ID)": 709,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-08-31 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 893075,
      "Operator (nazwa)": "TOYA Sp. z o.o.",
      "Operator (ID)": 127,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-09-18 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 893076,
      "Operator (nazwa)": "DIDWW Ireland Limited",
      "Operator (ID)": 12132,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-10-19 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89308(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 893082,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "8933(0-1,4-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-08-29 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89332(0-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-08-29 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 893328,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-08-29 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 89333,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89339(0-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-08-29 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 893398,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-08-29 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "8938(0-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89389(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 893893,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-10 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "895(6-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "8950(1-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89500(4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-04-17 12:45"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 895000,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 895001,
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-12 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 895002,
      "Operator (nazwa)": "IPTELEKOM Micha� Ludwiczak",
      "Operator (ID)": 10051,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-02 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 895003,
      "Operator (nazwa)": "Platan Sp. z o.o. Sp. k.",
      "Operator (ID)": 12733,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-04-17 12:45"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 895005,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-22 12:20"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89506(1-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-23 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 895060,
      "Operator (nazwa)": "TBM Telekom Sp. z o.o. Sp.K.",
      "Operator (ID)": 11804,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-23 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 895065,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "8951(0,5-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89511(0,1-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89511(8,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89512(0,1-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 895127,
      "Operator (nazwa)": "Telnyx Ireland Limited",
      "Operator (ID)": 13021,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-01 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89513(0,1-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 895137,
      "Operator (nazwa)": "ITH Sp. Z o.o.",
      "Operator (ID)": 12635,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-10 12:46"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89514(0,1,3-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 895142,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89519(0,1-5,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89519(6-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "8952(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "8952(1-4,6,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89525(1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 895250,
      "Operator (nazwa)": "Dialoga Servicios Interactivos S.A.",
      "Operator (ID)": 10930,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 895258,
      "Operator (nazwa)": "ATMAN Sp. z o.o.",
      "Operator (ID)": 944,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-01-13 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 895259,
      "Operator (nazwa)": "AMS SYSTEM Sp. z o.o.",
      "Operator (ID)": 7006,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-08-26 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "8953(2-5,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89530(0,1-3,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89530(4-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89531(0,1,2,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89531(3-5)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 89536,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89537(0-3,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 895374,
      "Operator (nazwa)": "NETKOM Us�ugi Informatyczne Maciej �ukowski",
      "Operator (ID)": 8419,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-04-21 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89538(0-1,3,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89538(4,5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 895382,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "8954(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "8954(1,3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89542(0,4-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89542(1-3)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89544(0,2,5-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89544(1,3,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 895444,
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-20 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89545(0,2)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89545(1,3-5,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89545(6-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "8955(1-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89550(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 895500,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89555(0,1,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89555(2-5)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "8958(0-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89589(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 895895,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-05 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "896(0,3,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-05-09 12:45"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "8961(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89611(2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 896110,
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 896111,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89612(0,2-9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 896121,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89613(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89613(1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89614(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-03-18 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 896141,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "Bartoszyce",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 896142,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "Mor�g",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 896143,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "Nidzica",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 896144,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 896145,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-03-18 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89615(0-1,3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89615(4-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 896152,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 896158,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "Olsztyn",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 896159,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "Ostr�da",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 89616,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89617(0,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89617(1-5)",
      "Operator (nazwa)": "TeleGo sp. z o.o.",
      "Operator (ID)": 9324,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "8962(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "8962(4,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89621(0,1-3,5-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 896214,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 896219,
      "Operator (nazwa)": "Focus Telecom Polska Sp. z o.o.",
      "Operator (ID)": 8974,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89622(0-1,5-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89622(4,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 896222,
      "Operator (nazwa)": "Firma TONETIC Krzysztof Adamczyk",
      "Operator (ID)": 709,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 896223,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89623(0,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89623(1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89626(0,3,6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89626(1,2,4,5,7,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89627(0,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89627(1,2)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-13 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 896273,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-13 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "8964(2,5-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89640(0,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89640(1-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89641(0,1-3,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89641(4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:11"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 89643,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89644(0,1,5,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89644(2,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-11-11 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 896443,
      "Operator (nazwa)": "IVENDO Bartosz Ku�min",
      "Operator (ID)": 1750,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 896444,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 896446,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-11-11 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89648(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 896480,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-17 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89649(0,1-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 896498,
      "Operator (nazwa)": "e-Telko Sp. z o.o.",
      "Operator (ID)": 11564,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-04-19 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "8965(3-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89650(0,6)",
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89650(2,3)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89650(8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-09-30 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 896501,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 896504,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 896505,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 896507,
      "Operator (nazwa)": "Multimedia Capital One Sp. z o.o.",
      "Operator (ID)": 12982,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-03-13 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89651(0,1-4,8-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89651(5-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89652(0,4,5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-01-28 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89652(1-3)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 896526,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89658(0,1-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89658(7-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 89659,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "8966(0-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-05-09 12:45"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89666(0-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-05-09 12:45"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 896666,
      "Operator (nazwa)": "Oskar Rybczy�ski",
      "Operator (ID)": 13148,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-05-09 12:45"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89670(3-5)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89670(6,7)",
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 896700,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "Olsztyn",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 896701,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 896702,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 896708,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 896709,
      "Operator (nazwa)": "P.H.U. IWKD Adam Gw�d�",
      "Operator (ID)": 5246,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89671(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-10-31 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89671(1-5)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 896716,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-10 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 896717,
      "Operator (nazwa)": "Multimedia Polska Biznes S.A.",
      "Operator (ID)": 11221,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-10 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89672(1-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 896720,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89673(0,1,3-9)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 896732,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-23 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89674(3,5,6)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-05-21 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 896740,
      "Operator (nazwa)": "MACROSAT Sp. z o.o.",
      "Operator (ID)": 1706,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 896741,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 896742,
      "Operator (nazwa)": "INTELLY Niski Stypu�kowski B�aszczuk Sp�ka Jawna",
      "Operator (ID)": 11999,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-05-17 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 896744,
      "Operator (nazwa)": "P.H.U. IWKD Adam Gw�d�",
      "Operator (ID)": 5246,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-03-01 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 896747,
      "Operator (nazwa)": "Telewizja Kablowa BART-SAT Stowarzyszenie",
      "Operator (ID)": 2181,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 896748,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 896749,
      "Operator (nazwa)": "VOXNET Sp. z o.o. Sp. k.",
      "Operator (ID)": 728,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89675(1,3,6,8)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89675(2,5)",
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 896750,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 896754,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-05-15 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 896757,
      "Operator (nazwa)": "CFC Sp. z o.o. Sp. k.",
      "Operator (ID)": 10286,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-04-16 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 896759,
      "Operator (nazwa)": "Galena Sp. z o.o.",
      "Operator (ID)": 66,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-16 12:16"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89676(1-9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 896760,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89677(0,6-9)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "Olsztyn",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89677(1-5)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89678(0,2)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89678(3,4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89678(5-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 896781,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89679(0,7)",
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-12-01 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89679(1-6,8,9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "8968(0-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89689(0-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 896896,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-05 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "897(3,7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "8970(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-03-28 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89700(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-03-28 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 897000,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-08-07 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "8971(0,1-4,7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89715(0,1-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 897158,
      "Operator (nazwa)": "NETSERVICE Daniel Wojda",
      "Operator (ID)": 9325,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-05-14 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89716(0,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89716(1-3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 897164,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89718(0,4,5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89718(1-3,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "8972(0,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 897210,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 897211,
      "Operator (nazwa)": "INTELLY J. NISKI Sp. J.",
      "Operator (ID)": 9370,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 897212,
      "Operator (nazwa)": "BARTNET Naruszewicz i Krawczun Sp. J.",
      "Operator (ID)": 4800,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 897213,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 897214,
      "Operator (nazwa)": "ELTRONIK Sp. z o.o.",
      "Operator (ID)": 1674,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "Lubawa",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 897215,
      "Operator (nazwa)": "Multimedia Ostr�da Sp. Z o.o.",
      "Operator (ID)": 13199,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-11-16 12:50"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 897216,
      "Operator (nazwa)": "Opertel Sp. z o.o.",
      "Operator (ID)": 12297,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-06-12 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 897217,
      "Operator (nazwa)": "Telewizja Kablowa BART-SAT Stowarzyszenie",
      "Operator (ID)": 2181,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 897218,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-10-30 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 897219,
      "Operator (nazwa)": "CLUDO Sp z o.o.",
      "Operator (ID)": 10056,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-02-01 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89722(3-4)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:17"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89722(8,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 897220,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 897221,
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 897222,
      "Operator (nazwa)": "Intelligent Technologies S.A.",
      "Operator (ID)": 748,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 897225,
      "Operator (nazwa)": "FREELY Sp. z o.o.",
      "Operator (ID)": 13143,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 897226,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 897227,
      "Operator (nazwa)": "IPTELL Sp. z o.o.",
      "Operator (ID)": 7201,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89723(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 897232,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:17"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "8974(0,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "8974(1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89743(0,1,2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 897433,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "8975(6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "8975(7,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89750(0,1,2,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89750(3-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89751(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 897519,
      "Operator (nazwa)": "Core-Tel Polska Sp. z o.o.",
      "Operator (ID)": 9961,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-12-30 12:45"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89752(0,1-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 897527,
      "Operator (nazwa)": "HARDSOFT s.c. Krzysztof Szechi�ski Adam Sza�anda",
      "Operator (ID)": 6632,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-02-03 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89753(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89753(1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89754(0-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89754(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 897545,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89755(0,1-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89755(5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "8976(0,5,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 89761,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89762(0,1-3,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 897624,
      "Operator (nazwa)": "Temedia Sp. z o.o.",
      "Operator (ID)": 11030,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-05-20 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89763(0,2,3,5,6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89763(1,4,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89764(0,1-3,5,6,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89764(4,7,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89766(0,1-3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89766(4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89767(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 897679,
      "Operator (nazwa)": "Blue Prospect Sp. z o.o.",
      "Operator (ID)": 13345,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-08-05 12:45"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "8978(0-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89789(0-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 897897,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-05 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "898(1-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "8980(0,6-9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "8980(2,4,5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89801(1-3)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89801(4,5,8,9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89801(6,0)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 898017,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89803(2,5,0)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89803(4,9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89803(6,7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 898031,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 898033,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-04-01 12:45"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 898038,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "8988(0-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89888(2-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-09 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 898880,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-01 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 898881,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 898888,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 898889,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-09 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89889(0-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 898898,
      "Operator (nazwa)": "Roman Bidu� TELBIT wsp�lnik sp�ki cywilnej",
      "Operator (ID)": 7904,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-11-23 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "8989(0,1-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "89898(0-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-26 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 898988,
      "Operator (nazwa)": "ENREACH PROSTA SPӣKA AKCYJNA",
      "Operator (ID)": 13141,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-26 12:15"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": 898989,
      "Operator (nazwa)": "SUDER Sp. z o.o.",
      "Operator (ID)": 10822,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "899(0,1-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 89,
      "Zakres (number)": "899(8,9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "OLSZTYN",
      "Strefa (symbol)": "SN-OL",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91(2,6,7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "911(0,1-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 9119,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "913(4,6,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "9130(1,2)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "9130(4-6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91300(4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-19 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 913000,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 913001,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-08-07 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 913002,
      "Operator (nazwa)": "ITEL Solutions Sp. z o.o.",
      "Operator (ID)": 11274,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-16 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 913003,
      "Operator (nazwa)": "NETTEL Sp. z o.o.",
      "Operator (ID)": 9575,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91303(0,3)",
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91303(1,2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91307(0,6-9)",
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91307(1-3)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 913074,
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-12 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 913075,
      "Operator (nazwa)": "OPTO-TECH Grzegorz Wr�blewski",
      "Operator (ID)": 11318,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-06 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91308(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 913082,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "9131(0,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-11-14 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "9131(2,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91311(0,2-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 913111,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91313(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-11-14 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 913131,
      "Operator (nazwa)": "POLTEL Sp. z o.o.",
      "Operator (ID)": 13246,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-02-02 12:45"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91314(0,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91314(1-3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91315(0,1-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 913154,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91316(0,1-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 913165,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91318(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 913180,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "9132(1-2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "9132(3,5,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-05-14 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 91320,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-05-14 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91324(0,1,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91324(2,3,6,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91324(4,5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91326(0,1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 913268,
      "Operator (nazwa)": "Dialoga Servicios Interactivos S.A.",
      "Operator (ID)": 10930,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 913269,
      "Operator (nazwa)": "e-Telko Sp. z o.o.",
      "Operator (ID)": 11564,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-04-19 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91327(0-1,3-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 913272,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 913276,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91328(0,1,2,4,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91328(3,6-9)",
      "Operator (nazwa)": "TeleGo sp. z o.o.",
      "Operator (ID)": 9324,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-09-25 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "9133(1,4-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-12-03 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91330(0,3)",
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91330(1,2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91332(0,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-13 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91332(1-3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-13 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 91333,
      "Operator (nazwa)": "ESPOL MEDIA Sp. z o.o.",
      "Operator (ID)": 7998,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-01 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91338(0-4,6-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-23 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 913385,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-12-03 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 913389,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-23 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "9135(2-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91350(1,6)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91350(7,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 913500,
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 913502,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "Police",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 913503,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "Nowogard",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 913504,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "Stargard Szczeci�ski",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 913505,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "�winouj�cie",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 913508,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91351(0,9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91351(4,5)",
      "Operator (nazwa)": "SAT-MONT-SERVICE Jacek Mruk, Krzysztof Mruk Sp. z o.o.",
      "Operator (ID)": 315,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "Szczecin",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 913511,
      "Operator (nazwa)": "ENTER T&T Sp. z o.o.",
      "Operator (ID)": 10688,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 913512,
      "Operator (nazwa)": "Shentel Sp. z o.o.",
      "Operator (ID)": 9685,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-30 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 913513,
      "Operator (nazwa)": "Sp�dzielnia Mieszkaniowa Lokatorsko-W�asno�ciowa \"S�OWIANIN",
      "Operator (ID)": 2018,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "SN w granicach powiatu �winouj�cie",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 913516,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 913517,
      "Operator (nazwa)": "FREELY Sp. z o.o.",
      "Operator (ID)": 13143,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 913518,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91358(0,1-3,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91358(4,5)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91359(1-3,6-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91359(4,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 913590,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 913599,
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-20 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "9138(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "9138(2,4,6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91381(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91381(1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91383(3-5)",
      "Operator (nazwa)": "ESPOL MEDIA Sp. z o.o.",
      "Operator (ID)": 7998,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-01 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 913830,
      "Operator (nazwa)": "UAB B2B Network (d. UAB NordConnect)",
      "Operator (ID)": 11976,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-03-20 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 913831,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 913832,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 913836,
      "Operator (nazwa)": "Intelligent Technologies S.A.",
      "Operator (ID)": 748,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 913837,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-06-10 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 913838,
      "Operator (nazwa)": "IPTELL Sp. z o.o.",
      "Operator (ID)": 7201,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 913839,
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91385(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91385(1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91387(0,2-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 913871,
      "Operator (nazwa)": "TOYA Sp. z o.o.",
      "Operator (ID)": 127,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-18 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 913879,
      "Operator (nazwa)": "E-CHO Sp. z o.o.",
      "Operator (ID)": 5933,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "9139(0,3,4,6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91391(0,1,3,4,6-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91391(2,5,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91392(0,1-3,5-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91392(4,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91395(0,1-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91395(5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 91397,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "9140(1,3,5,6,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 91400,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91402(0,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91402(1-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91404(1,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91404(2-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 914040,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91407(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91407(1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "9141(0,3,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-05-13 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "9141(4,6,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91411(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-05-13 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 914111,
      "Operator (nazwa)": "Spikon Sp. z o.o.",
      "Operator (ID)": 10784,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-05-13 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91412(0,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-03 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91412(1-3)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-03 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91415(0,1-3,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91415(4-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91419(0-1,3-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91419(6-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-18 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 914192,
      "Operator (nazwa)": "Blue Prospect Sp. z o.o.",
      "Operator (ID)": 13345,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-08-05 12:46"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 914199,
      "Operator (nazwa)": "Debesis Sp. z o.o.",
      "Operator (ID)": 10138,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-18 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "9142(1-3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "9142(7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91420(0,1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91420(3-9)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91424(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 914249,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-01 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91425(0-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 914258,
      "Operator (nazwa)": "DIDWW Ireland Limited",
      "Operator (ID)": 12132,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-19 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 914259,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91426(0,3-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91426(1,2,8,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91428(0,1-3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91428(4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "9143(0,1-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "9143(6-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91435(0-1,5,7-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91435(2-4,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 914356,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91439(0,1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91439(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "9144(1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91440(0,1,3,5,6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91440(2,4,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 914430,
      "Operator (nazwa)": "Platan Sp. z o.o. Sp. k.",
      "Operator (ID)": 12733,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-06 12:45"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 914431,
      "Operator (nazwa)": "SpeedMedia Sp. z o.o.",
      "Operator (ID)": 10796,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 914432,
      "Operator (nazwa)": "PRZEDSI�BIORSTWO AJC Adam Ozga Przedsi�biorstwo AJC2",
      "Operator (ID)": 390,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 914433,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-10-30 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 914434,
      "Operator (nazwa)": "Televoice Polska Sp. z o.o.",
      "Operator (ID)": 8808,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 914435,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 914436,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 914437,
      "Operator (nazwa)": "CLUDO Sp z o.o.",
      "Operator (ID)": 10056,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-02-01 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 914438,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 914439,
      "Operator (nazwa)": "Focus Telecom Polska Sp. z o.o.",
      "Operator (ID)": 8974,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91444(1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 914440,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 914446,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 914447,
      "Operator (nazwa)": "Galena Sp. z o.o.",
      "Operator (ID)": 66,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-04-16 12:16"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 914448,
      "Operator (nazwa)": "Actus-Info Sp. z o.o.",
      "Operator (ID)": 9186,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-05-14 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 914449,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2021-12-14 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91445(0,1,3,4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 914452,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 914455,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91446(0,1-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 914467,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 91447,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91448(0,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91448(1-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91449(0,1-3,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91449(4-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "9145(6-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91450(0,1,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91450(2-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91451(1,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91451(2-5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 914510,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91452(0,1,2,7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91452(3-6,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91453(0,1,2,4-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 914533,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-12-30 12:45"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91454(0,1,2)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91454(3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91455(0,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-03-18 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91455(1,6)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91455(3,4,7,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 914552,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-03-18 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 914555,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-06-10 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91459(0,3-5,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91459(1,2,6,7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "9146(2,4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "9146(5,7,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91460(0,1-3,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 914604,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91461(0,2-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91461(1,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91463(0,1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91463(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91466(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 914660,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-17 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91469(0,1-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 914696,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "9147(0,3-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 91471,
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91472(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91472(1-3,5)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 914724,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91479(0,1-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 914794,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "9148(0,1-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91486(0,1-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 914867,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-13 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "9149(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91491(0-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 914914,
      "Operator (nazwa)": "Telfon Poland Sp. z o.o.",
      "Operator (ID)": 12517,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "915(1-4,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "9150(1-5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91500(2-3,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-12-29 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 915000,
      "Operator (nazwa)": "SUDER Sp. z o.o.",
      "Operator (ID)": 10822,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 915001,
      "Operator (nazwa)": "MEDIDESK Sp. z o.o.",
      "Operator (ID)": 12669,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-12-29 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 915004,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-06-29 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 915005,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-12-22 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91506(0-4,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-16 12:45"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 915065,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 915066,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-12-16 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 915067,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-01-18 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 915068,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-16 12:45"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "9155(0,1-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-09-18 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91555(0,1-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 915555,
      "Operator (nazwa)": "AlterTEL Sp. z o.o.",
      "Operator (ID)": 8614,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "9156(0,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91561(0,1-3,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 915614,
      "Operator (nazwa)": "Telnyx Ireland Limited",
      "Operator (ID)": 13021,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-01 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91562(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 915629,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91563(0,3,4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91563(1,2,5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91564(0-1,3-5,7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91564(2,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 915646,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "9157(1,2,4,5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "9157(3,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91570(0,1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91570(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91576(0-4,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91576(5,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91576(6,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91577(0,1,2,4-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 915773,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-15 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91579(0,1-3,6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91579(4,5,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "9159(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91591(0,1-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 915915,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "918(4,6-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "9180(0,6-9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91801(0-1)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91801(3-9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 918012,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91802(0,1,3,4,9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91802(5,7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 918022,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 918026,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-01-06 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 918028,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-04-01 12:45"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91803(0,3,5,6)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91803(1-2,8-9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91803(4,7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91804(0,7-9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91804(1,2)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91804(3,4)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-01-06 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 918045,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 918046,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91805(0,2,3)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91805(5-9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 918051,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-07-05 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 918054,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "9181(2,8)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91810(0-2,5,6)",
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91810(3-4,7,9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 918108,
      "Operator (nazwa)": "Multimedia Stargard Sp. z o.o.",
      "Operator (ID)": 13179,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-06-16 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 91811,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91813(0,2)",
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91813(3-9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 918131,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-07-13 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91814(0,3,5)",
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91814(1-2,4,6-9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91815(0,1)",
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91815(2-5)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91815(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91816(1-5)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-07-13 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91816(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 918160,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-07-13 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91817(0,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91817(1-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91819(0,2,4)",
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91819(1,3)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91819(6-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-03-26 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 918195,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-07-13 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 918199,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-03-26 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "9182(0,9)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "9182(5-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 91821,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 91822,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91823(0,4-9)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 918231,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 918232,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-24 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 918233,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-24 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91824(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91824(1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91828(0,3-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-12 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91828(1,2)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-12 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 918288,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "9183(0,2,3,7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-01-15 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91831(1-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 918310,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91834(0,1,5,9)",
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91834(2-4,6-8)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91835(0,1-3,5,9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91835(4,6-8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91836(0,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-07-13 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91836(1,3)",
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91836(4,5,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91836(8-9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-07-13 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 918362,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91838(0-4,6-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-04-09 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 918385,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-01-15 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 918388,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2022-04-09 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 918389,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-23 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "9185(0,4-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91851(0,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91851(1-4)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91852(0,2,6-9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91852(1,3-5)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91853(0,1-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91853(8,9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 91858,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91880(0,1,4-6)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91880(2,3)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91880(7,9)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 918808,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91881(0,1,3-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 918812,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-12-01 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91882(1,3,6-7)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91882(2,4,5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 918820,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-09-10 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 918828,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 918829,
      "Operator (nazwa)": "VOXNET Sp. z o.o. Sp. k.",
      "Operator (ID)": 728,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91883(1,4)",
      "Operator (nazwa)": "didXL B.V.",
      "Operator (ID)": 13079,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-11-03 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91883(7-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 918830,
      "Operator (nazwa)": "ITH Sp. Z o.o.",
      "Operator (ID)": 12635,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-03-14 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 918832,
      "Operator (nazwa)": "Colt Technology Services GmbH",
      "Operator (ID)": 12380,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-12-02 12:45"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 918833,
      "Operator (nazwa)": "K2M Sp. z o.o.",
      "Operator (ID)": 13135,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-30 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 918835,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 918836,
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 91884,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "Szczecin",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91885(1,3,4)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91885(6,8)",
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-04 12:45"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 918850,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "Szczecin",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 918852,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-06-10 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 918855,
      "Operator (nazwa)": "BT Poland Sp. z o.o.",
      "Operator (ID)": 109,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "Stargard Szczeci�ski",
      "Data modyfikacji": "2021-03-27 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 918857,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-04 12:45"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 918859,
      "Operator (nazwa)": "didXL B.V.",
      "Operator (ID)": 13079,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-10-22 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91886(0,4,6,7,9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91886(1-3)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "Szczecin",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 918865,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-12-01 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 918868,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91887(0,5-9)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 918871,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 918872,
      "Operator (nazwa)": "Nettelekom GK Sp. z o.o.",
      "Operator (ID)": 9896,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 918873,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 918874,
      "Operator (nazwa)": "Przedsi�biorstwo Przemys�owo-Handlowe AURA Sp. z o.o.",
      "Operator (ID)": 2656,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "Lipiany, Pyrzyce, Stare Czarnowo",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91888(0,1-4)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91888(5-9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2018-06-01 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 91889,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "9189(0,2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "91891(0-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": 918918,
      "Operator (nazwa)": "Telfon Poland Sp. z o.o.",
      "Operator (ID)": 12517,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "919(0,1-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 91,
      "Zakres (number)": "919(8,9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "SZCZECIN",
      "Strefa (symbol)": "SN-SZ",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94(2,4-6)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "941(0,1-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 9419,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:12"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "9430(2,4-5,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-25 12:45"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 94300,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 94301,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-25 12:45"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94303(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 943030,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94306(0,1-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 943067,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94307(1-5)",
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94307(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 943070,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94308(0,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-05-06 12:45"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 943081,
      "Operator (nazwa)": "ITEL Solutions Sp. z o.o.",
      "Operator (ID)": 11274,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-05-06 12:45"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 943082,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "9431(1-2,6,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "9431(3,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94310(0,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94310(1-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94314(0-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 943147,
      "Operator (nazwa)": "DIDWW Ireland Limited",
      "Operator (ID)": 12132,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-19 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94315(3-4,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-03-22 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94315(5-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 943150,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 943151,
      "Operator (nazwa)": "e-Telko Sp. z o.o.",
      "Operator (ID)": 11564,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-04-19 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 943152,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-07-23 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94317(0,1,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94317(2-4)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94317(5,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 9432,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "9433(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-01-14 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94333(0,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-02-22 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94333(1,2)",
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-04-11 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 943333,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-01-14 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 943334,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. UPC Polska Sp. z o.o.)",
      "Operator (ID)": 111,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-02-22 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "9434(1,6-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94340(0,2-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 943401,
      "Operator (nazwa)": "THULIUM Sp. z o.o.",
      "Operator (ID)": 13433,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-16 12:45"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94342(0-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 943429,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94343(0-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 943437,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-10-30 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94344(0,4-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94344(1-3)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94345(0-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 943456,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-05-15 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 94349,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "9435(1,2,4,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "9435(6,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-09-03 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94350(0-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-09-03 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 943509,
      "Operator (nazwa)": "ASTA-NET S.A.",
      "Operator (ID)": 609,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-09-03 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94353(0,1-6,8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 943537,
      "Operator (nazwa)": "Intelligent Technologies S.A.",
      "Operator (ID)": 748,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-09-16 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 943539,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94355(0,1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94355(7-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-01-13 12:45"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 943556,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 943559,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-01-13 12:45"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94357(2-4,7,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 943570,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2023-03-29 12:45"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 943571,
      "Operator (nazwa)": "E-CHO Sp. z o.o.",
      "Operator (ID)": 5933,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-02-16 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 943575,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 943576,
      "Operator (nazwa)": "Dialoga Servicios Interactivos S.A.",
      "Operator (ID)": 10930,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 943579,
      "Operator (nazwa)": "Pomerania Services Group Sp. z o.o.",
      "Operator (ID)": 11625,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-03-03 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "9436(0,2,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94361(0,3,4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94361(1,2,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94363(0-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94363(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 943637,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94364(0,1,4,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94364(2,3,5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94365(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 943659,
      "Operator (nazwa)": "Telnyx Ireland Limited",
      "Operator (ID)": 13021,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-01 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94366(0,7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94366(1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94367(0-3,6-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94367(4,5,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-09 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "9437(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "9437(2,4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94371(0,1-3,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94371(4,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94373(0,1-4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 943735,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-17 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94375(1-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 943750,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "9438(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94382(0,1-3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94382(4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "9439(0-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94394(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 943943,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-04-10 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "947(5-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-08-14 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "9470(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94700(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 947000,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94710(6,7)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 947100,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "Koszalin",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 947101,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 947102,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 947103,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 947104,
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 947105,
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 947108,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 947109,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 94711,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "Koszalin",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94712(0-1,3-4,9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94712(7,8)",
      "Operator (nazwa)": "Gawex Media Sp. z o.o.",
      "Operator (ID)": 1111,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 947122,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-07-22 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 947125,
      "Operator (nazwa)": "TOYA Sp. z o.o.",
      "Operator (ID)": 127,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-18 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 947126,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94713(1-3)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94713(5-7)",
      "Operator (nazwa)": "Gawex Media Sp. z o.o.",
      "Operator (ID)": 1111,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 947130,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "Szczecinek",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 947134,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "�widwin",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 947138,
      "Operator (nazwa)": "TK KO�OBRZEG Agencja Us�ugowo-Reklamowa Sp. z o.o.",
      "Operator (ID)": 1156,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 947139,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94714(2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 947140,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 947141,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94715(0,6-9)",
      "Operator (nazwa)": "TeleGo sp. z o.o.",
      "Operator (ID)": 9324,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-09-25 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94715(1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94716(1,6,7)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-13 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94716(2,4,5)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94716(3,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-13 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 947160,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94717(0,1)",
      "Operator (nazwa)": "Gawex Media Sp. z o.o.",
      "Operator (ID)": 1111,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94717(4,6,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 947172,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 947173,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 947175,
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 947177,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 947179,
      "Operator (nazwa)": "VOXNET Sp. z o.o. Sp. k.",
      "Operator (ID)": 728,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94718(0,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94718(1-3)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94719(0,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94719(1-4)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "9472(3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 94720,
      "Operator (nazwa)": "Telewizja Kablowa Koszalin Sp. z o.o.",
      "Operator (ID)": 2655,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94721(3,4)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 947210,
      "Operator (nazwa)": "Nettelekom GK Sp. z o.o.",
      "Operator (ID)": 9896,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 947211,
      "Operator (nazwa)": "FREELY Sp. z o.o.",
      "Operator (ID)": 13143,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 947212,
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 947215,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-11-04 12:46"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 947216,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 947217,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-01 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 947218,
      "Operator (nazwa)": "IPTELL Sp. z o.o.",
      "Operator (ID)": 7201,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 947219,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-07-01 12:16"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 94722,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "9473(0,3,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "9473(4,5)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94731(0-5)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:17"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94731(6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:17"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94732(0,1)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:17"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94732(2-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-06-10 12:17"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "9474(0,1-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94747(0,1-3)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94747(4-6,8,9)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 947477,
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-20 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "9479(0-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-08-14 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94794(0-6,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-08-14 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 947947,
      "Operator (nazwa)": "SUDER Sp. z o.o.",
      "Operator (ID)": 10822,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-08-14 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "948(1-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "9480(0,6-9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "9480(4,5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94801(0,7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94801(1-6,8,9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94802(0,7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94802(1-6,8,9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94803(1-6,8,9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 948030,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 948037,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "9488(0,1-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94888(4-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-10-31 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 948880,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 948881,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 948882,
      "Operator (nazwa)": "Multimedia Polska Biznes S.A.",
      "Operator (ID)": 11221,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-01-16 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 948883,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2021-03-12 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 948888,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 948889,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-07-23 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "9489(0-3,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "94894(0-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-11-03 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 948948,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-10 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": 948949,
      "Operator (nazwa)": "LoVo Sp. z o.o.",
      "Operator (ID)": 7184,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-11-03 12:15"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "949(0,1-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 94,
      "Zakres (number)": "949(8,9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "KOSZALIN",
      "Strefa (symbol)": "SN-KO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95(4-6)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "951(0,1-8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 9519,
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "952(1,3-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "9520(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95200(4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-22 12:20"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 952000,
      "Operator (nazwa)": "SUDER Sp. z o.o.",
      "Operator (ID)": 10822,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 952001,
      "Operator (nazwa)": "MEDIDESK Sp. z o.o.",
      "Operator (ID)": 12669,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-12-29 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 952002,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 952003,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-06-22 12:20"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "9522(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95222(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-12 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95222(3-5)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 952221,
      "Operator (nazwa)": "TOYA Sp. z o.o.",
      "Operator (ID)": 127,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-12 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 952222,
      "Operator (nazwa)": "INEA Sp. z o o.",
      "Operator (ID)": 13009,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-08-07 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "9529(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95295(0-1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 952952,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-10 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "953(1,4-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "9530(2,4,5,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95300(0,3)",
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95300(1,2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 95301,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95303(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 953030,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95306(0,1-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 953067,
      "Operator (nazwa)": "SLICAN Sp. z o.o.",
      "Operator (ID)": 10012,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95307(1-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 953070,
      "Operator (nazwa)": "P4 Sp. z o.o.",
      "Operator (ID)": 92,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95308(0-1,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95308(3-7)",
      "Operator (nazwa)": "Belgacom International Carrier Services SA/NV",
      "Operator (ID)": 12051,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-01-11 12:16"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 953082,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 953088,
      "Operator (nazwa)": "Telestrada S.A.",
      "Operator (ID)": 4078,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-04-20 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "9532(0,1,3-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-03 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95322(0-4,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-03 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95322(5-7)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-03 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "9533(0,1,4-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-12-03 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95332(0-4,8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-13 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95332(5-7)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-13 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 95333,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95338(0-4,6-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-23 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 953385,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-12-03 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 953389,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-23 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "9539(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95395(0-2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 953953,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2020-04-10 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 9570,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "9571(0,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95711(2-4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95711(6,9)",
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95711(7,8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957110,
      "Operator (nazwa)": "Hyperion S.A.",
      "Operator (ID)": 329,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957111,
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-30 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957115,
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95712(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95712(1-4)",
      "Operator (nazwa)": "TK Telekom Sp. z o.o.",
      "Operator (ID)": 7,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957125,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95713(0,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95713(1-3)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95714(0-5,7,9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957146,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957148,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-09-08 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95715(1,7,8)",
      "Operator (nazwa)": "PROVECTOR Sp. z o.o. (d. KSU PROVECTOR Mariusz Dziakowicz)",
      "Operator (ID)": 1795,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957150,
      "Operator (nazwa)": "e-Telko Sp. z o.o.",
      "Operator (ID)": 11564,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-04-19 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957152,
      "Operator (nazwa)": "Naukowa i Akademicka Sie� Komputerowa - Pa�stwowy Instytut Badawczy",
      "Operator (ID)": 59,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957153,
      "Operator (nazwa)": "Lubuskie Sieci �wiat�owodowe Sp. z o.o.",
      "Operator (ID)": 1687,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957154,
      "Operator (nazwa)": "IPTELL Sp. z o.o.",
      "Operator (ID)": 7201,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957155,
      "Operator (nazwa)": "FREELY Sp. z o.o.",
      "Operator (ID)": 13143,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-11 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957156,
      "Operator (nazwa)": "DIDWW Ireland Limited",
      "Operator (ID)": 12132,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-10-19 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957159,
      "Operator (nazwa)": "E-CHO Sp. z o.o.",
      "Operator (ID)": 5933,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "Choszczno",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95717(0-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95717(8,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957177,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95718(0,1-3,5,6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95718(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957184,
      "Operator (nazwa)": "Dialoga Servicios Interactivos S.A.",
      "Operator (ID)": 10930,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-10-30 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95719(0,1-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95719(7-9)",
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "9572(0,2,5,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95721(0-1,3-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957212,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95723(0,1,3,4,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95723(2,5,6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95724(0,1)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95724(2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95726(0,1,3)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95726(2,4-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95727(0,2-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957271,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "9573(6,8)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95730(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95730(1-5)",
      "Operator (nazwa)": "TeleGo sp. z o.o.",
      "Operator (ID)": 9324,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95731(0,1,2,4-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957313,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95732(0,1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95732(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95733(0-1,4-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95733(2,3)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95734(0,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95734(1-5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95735(0-4,6-9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957355,
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95737(0,1,9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95737(2-4)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-04-11 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95737(6,7)",
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957375,
      "Operator (nazwa)": "VARS Sp. z o.o.",
      "Operator (ID)": 3274,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957378,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95739(0-1,6-9)",
      "Operator (nazwa)": "VECTRA S.A. (d. Multimedia Polska Sp. z o.o.)",
      "Operator (ID)": 180,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95739(2-5)",
      "Operator (nazwa)": "Multimedia Polska Sp. z o. o.",
      "Operator (ID)": 12078,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-03-28 12:45"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "9574(4,5)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95740(0,1,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95740(2-4)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95741(0-3,5-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957414,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95742(0-4,6-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-08 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957425,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-08 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95743(1-6)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95743(7-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957430,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95746(0,1-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957469,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 95747,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-13 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95748(1-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957480,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957487,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-08 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95749(0-4)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2016-07-08 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957495,
      "Operator (nazwa)": "Blue Prospect Sp. z o.o.",
      "Operator (ID)": 13345,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-08-05 12:45"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957496,
      "Operator (nazwa)": "TELEPOLSKA Sp. z o.o.",
      "Operator (ID)": 11575,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-07 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957497,
      "Operator (nazwa)": "Flexcom Sp. z o.o.",
      "Operator (ID)": 11973,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-02-19 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957498,
      "Operator (nazwa)": "Softblue S.A.",
      "Operator (ID)": 12915,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2021-01-15 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957499,
      "Operator (nazwa)": "AlterTEL Sp. z o.o.",
      "Operator (ID)": 8614,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "9575(1,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "9575(3,4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:14"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95750(0,1,2,4,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:13"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957503,
      "Operator (nazwa)": "Colt Technology Services GmbH",
      "Operator (ID)": 12380,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-12-02 12:45"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957505,
      "Operator (nazwa)": "Aiton Caldwell S.A.",
      "Operator (ID)": 8085,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:14"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957506,
      "Operator (nazwa)": "Galena Sp. z o.o.",
      "Operator (ID)": 66,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-04-16 12:16"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95752(0,1-6,8-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:14"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957527,
      "Operator (nazwa)": "Telnyx Ireland Limited",
      "Operator (ID)": 13021,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-03-01 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95755(0-5,7-9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957556,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-03-16 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95756(0,1)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:14"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95756(2-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:14"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95757(0-7,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957578,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2024-05-14 12:45"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "9576(0,3,5)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:14"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "9576(4,7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:14"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95761(0,4,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:14"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95761(1-3,5-8)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:14"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95762(0,1-6,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:14"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95762(7,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:14"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95766(0,1)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:14"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95766(2-4)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:14"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957665,
      "Operator (nazwa)": "Peoplefone Polska S.A.",
      "Operator (ID)": 7317,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:14"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957666,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-07-04 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957667,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "Choszczno",
      "Data modyfikacji": "2015-07-20 01:14"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957668,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-12-01 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957669,
      "Operator (nazwa)": "Vectra S.A.",
      "Operator (ID)": 5568,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:14"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95768(0,1-6,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:14"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95768(7,8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:14"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95769(0,1,3,9)",
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:14"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95769(2,4-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:14"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "9577(0-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-15 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95777(1-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-04-06 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957770,
      "Operator (nazwa)": "POLTEL Sp. z o.o.",
      "Operator (ID)": 13246,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2023-02-02 12:45"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957777,
      "Operator (nazwa)": "GT11 Sp. z o.o.",
      "Operator (ID)": 10475,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-15 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "9578(6,7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:14"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95780(1,2)",
      "Operator (nazwa)": "SpTelek Sp. z o.o.",
      "Operator (ID)": 7320,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "Trzciel",
      "Data modyfikacji": "2015-07-20 01:14"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95780(3-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-06-28 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95780(7-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:14"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957800,
      "Operator (nazwa)": "LoVo Sp. z o.o. Sp�ka komandytowa",
      "Operator (ID)": 10664,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2017-06-28 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95781(0,4)",
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:14"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95781(1-3,5-8)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:14"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957819,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:14"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95782(0,2,3)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:14"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95782(4-8)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:14"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957821,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:14"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957829,
      "Operator (nazwa)": "SYNTELL S.A.",
      "Operator (ID)": 10628,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:14"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95783(1,4,5,7,8)",
      "Operator (nazwa)": "EXATEL S.A.",
      "Operator (ID)": 18,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2015-11-13 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957830,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:14"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957832,
      "Operator (nazwa)": "TARTEL Sp. z o.o.",
      "Operator (ID)": 7269,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:14"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957833,
      "Operator (nazwa)": "VOXNET Sp. z o.o. Sp. k.",
      "Operator (ID)": 728,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:14"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957836,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:14"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957839,
      "Operator (nazwa)": "e-Telko Sp. z o.o.",
      "Operator (ID)": 11564,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-04-19 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95784(0,5-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:14"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957841,
      "Operator (nazwa)": "E-CHO Sp. z o.o.",
      "Operator (ID)": 5933,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-02-16 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957842,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-06-29 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957843,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2017-09-30 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957844,
      "Operator (nazwa)": "OXYLION S.A.",
      "Operator (ID)": 9891,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:14"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 95785,
      "Operator (nazwa)": "T-MOBILE POLSKA S.A.",
      "Operator (ID)": 4,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:14"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95788(0,1,7-9)",
      "Operator (nazwa)": "Netia S.A.",
      "Operator (ID)": 6,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:14"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95788(2-6)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:14"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "9579(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95795(0-6,8-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-08-17 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 957957,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2020-04-10 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "958(1-2,4,6-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "9580(0,6-9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:14"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "9580(1,2,4,5)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:14"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95803(1-2,4-5,8)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2019-07-03 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95803(6,7,9)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-01-16 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 958030,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:14"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 958033,
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-09-06 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "9583(0-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-01-15 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95838(0-4,6-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-23 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 958385,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-01-15 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 958389,
      "Operator (nazwa)": "Orange Polska S.A.",
      "Operator (ID)": 1,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2016-06-23 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "9585(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95855(0-4,6-9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 958555,
      "Operator (nazwa)": "Globitel Sp. z o.o.",
      "Operator (ID)": 7243,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "",
      "Data modyfikacji": "2019-06-06 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "9588(1-7,9)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:14"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95880(1-8)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-17 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 958800,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:14"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 958809,
      "Operator (nazwa)": "P4 Sp. z o.o. (d. 3S S.A.)",
      "Operator (ID)": 4408,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2018-05-17 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "95888(0,2-7)",
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:14"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 958881,
      "Operator (nazwa)": "VOXBONE SA",
      "Operator (ID)": 7575,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:14"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 958888,
      "Operator (nazwa)": "Polkomtel Sp. z o.o.",
      "Operator (ID)": 3,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:14"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": 958889,
      "Operator (nazwa)": "Rezerwa Prezesa UKE",
      "Operator (ID)": 99990,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2022-07-23 12:15"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "959(0,1-7)",
      "Operator (nazwa)": "Rezerwa regulacyjna",
      "Operator (ID)": 99991,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:14"
    },
    {
      "Strefa (AB)": 95,
      "Zakres (number)": "959(8,9)",
      "Operator (nazwa)": "Rezerwa techniczna",
      "Operator (ID)": 99992,
      "Strefa (nazwa)": "GORZ�W WIELKOPOLSKI",
      "Strefa (symbol)": "SN-GO",
      "Obszar numeracyjny": "CA�A SN",
      "Data modyfikacji": "2015-07-20 01:14"
    }
  ]

  export  { stationaryData };