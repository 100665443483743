import React from "react";
import css from "./edit.module.css";

class EditSolid extends React.Component {
    handleClick(e) {
        let top = e.target.getBoundingClientRect().top;
        this.props.clickedShow(this.props.type, top + window.pageYOffset - 200);
    }
    render() {
        return (
            <button
                margin={this.props.margin}
                className={[css.btn, css[this.props.className]].join(" ")}
                onClick={this.handleClick.bind(this)}
            >
                {this.props.name}
            </button>
        );
    }
}

export default EditSolid;
