import React from "react";
import { connect } from "react-redux";
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch
} from "react-router-dom";
import "./App.css";
import Homepage from "./pages/Homepage/Homepage";
import Edit from "./pages/Edit/Edit";
import Preview from "./pages/Preview/Preview";
import Statistics from "./pages/Statistics/Statistics";
import Error from "./pages/Error/Error";
import Add from "./pages/Add/Add";
import LoaderComponent from "./components/LoaderComponent/LoaderComponent";
import { getUserData } from "./services/service";
import * as cons from "./redux/const";
import "./zona-font.css";
import Advanced from "./components/Advanced/Advanced";
import Landing from "./pages/Landing/Landing";
import Default from "./pages/Default/Default";
import { setPageInfo } from "./redux/page/actions";

class App extends React.Component {
    state = {
        render: false
    };

    componentDidMount() {
        this.props.setPageInfo(
            window.location.pathname + "" + window.location.search
        );
        getUserData(window.location.search).then(data => {
            this.props.dispatchUserData(data);
            this.setState({ render: true });
        });
    }

    render() {
        return this.state.render ? (
            <Router>
                <div id={"sp"}>
                    <Switch>
                        <Route exact path="/me/home" component={Homepage} />
                        <Route exact path="/me/error" component={Error} />
                        <Route exact path="/me/edit" component={Edit} />
                        <Route exact path="/me/preview" component={Preview} />
                        <Route exact path="/me/advanced" component={Advanced} />
                        <Route
                            exact
                            path="/me/statistics"
                            component={Statistics}
                        />
                        <Route exact path="/me/add" component={Add} />
                        <Route exact path={"/default"} component={Default} />
                        <Route path={"/me/"} component={Home} />
                        <Route path={"/"} component={Landing} />
                    </Switch>
                </div>
            </Router>
        ) : (
            <LoaderComponent />
        );
    }
}

const Home = () => {
    return <Redirect to={"/me/home"} />;
};

const mapDispatchToProps = dispatch => {
    return {
        dispatchUserData: data => {
            dispatch({
                type: cons.SET_USER_STATE,
                payload: data
            });
        },
        setPageInfo: data => {
            dispatch(setPageInfo(data));
        }
    };
};

export default connect(null, mapDispatchToProps)(App);
