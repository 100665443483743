import React from "react";
import ButtonOutline from "../Buttons/Outline";
import css from "./deleteConfirmation.module.css";

class DeleteConfirmation extends React.Component {
    render() {
        return (
            <div className={css.box}>
                <div className={css.content}>
                    <h2>{this.props.content}</h2>
                </div>
                <div className={css.buttons}>
                    <ButtonOutline
                        active={true}
                        name={this.props.yes}
                        clicked={this.props.clickedYes}
                    />
                    <ButtonOutline
                        active={true}
                        name={this.props.no}
                        clicked={this.props.clickedNo}
                    />
                </div>
            </div>
        );
    }
}

export default DeleteConfirmation;
