import React from "react";
import { setImages, setVideo } from "../../services/service";
import { connect } from "react-redux";
import Form from "../../components/Form/Form";
import { editInitialState } from "../../states/edit";
import Template5 from "../../components/Templates/Template5/Template5";
import Template6 from "../../components/Templates/Template6/Template6";
import Template7 from "../../components/Templates/Template7/Template7";
import Template8 from "../../components/Templates/Template8/Template8";
import Template9 from "../../components/Templates/Template9/Template9";
import Template10 from "../../components/Templates/Template10/Template10";
import Template11 from "../../components/Templates/Template11/Template11";
import css from "./preview.module.css";
import Template4 from "../../components/TemplatesEdit/Template4/Template4";
import GoBackButton from "../../components/Buttons/GoBack";

class Preview extends React.Component {
  state = editInitialState;

  componentDidMount() {
    window.scrollTo(0, 0);
    const template = this.props.store.template;

    if (!template.activePageId && !template.activeTmpId)
      return this.props.history.push("/me/home");

    if (template.activeTmpId < 5) {
      this.errorHandler();
    }
    const leader = template.leader;
    let inputs = { ...this.state.inputs };
    let newState = { ...this.state };

    inputs.phone.value = leader.phone !== undefined ? leader.phone : "";
    inputs.email.value = leader.email !== undefined ? leader.email : "";

    if (leader.link !== undefined) {
      inputs.pageLink.value = leader.link;
    }
    if (leader.pageName !== undefined) {
      inputs.pageName.value = leader.pageName;
    }

    if (template.images.confirmed !== undefined) {
      newState.images = { ...template.images };
    }

    newState.images = { ...setImages(newState.images, template) };
    newState.inputs = inputs;
    newState.templateId = template.activeTmpId;
    newState.loader = false;
    newState.template = template.template;
    newState.leader = leader;
    newState.activePageId = template.activePageId;
    newState.video.videoId = setVideo(this.state.video.videoId, template);

    this.setState({ ...newState });
  }

  errorHandler = () => {
    this.props.history.push("/me/error");
  };
  template = () => {
    switch (this.props.store.template.activeTmpId) {
      case 4:
        return (
          <Template4
            view={true}
            temp={this.state.template}
            img={this.state.images.confirmed}
            video={this.state.video}
          />
        );
      case 5:
        return (
          <Template5
            view={true}
            temp={this.state.template}
            img={this.state.images.confirmed}
            video={this.state.video}
          />
        );
      case 6:
        return (
          <Template6
            view={true}
            temp={this.state.template}
            img={this.state.images.confirmed}
            video={this.state.video}
          />
        );
      case 7:
        return (
          <Template7
            view={true}
            temp={this.state.template}
            img={this.state.images.confirmed}
            video={this.state.video}
          />
        );

      case 8:
        return (
          <Template8
            view={true}
            temp={this.state.template}
            img={this.state.images.confirmed}
            video={this.state.video}
          />
        );

      case 9:
        return (
          <Template9
            view={true}
            temp={this.state.template}
            img={this.state.images.confirmed}
            video={this.state.video}
          />
        );

      case 10:
        return (
          <Template10
            view={true}
            temp={this.state.template}
            img={this.state.images.confirmed}
            video={this.state.video}
          />
        );
      case 11:
        return (
          <Template11
            view={true}
            temp={this.state.template}
            img={this.state.images.confirmed}
            video={this.state.video}
            uplnEmail={this.state.leader.email}
            phone={this.state.leader.phone}
            test={true}
            tempId={this.props.store.template.activeTmpId}
          />
        );
      default:
        this.errorHandler();
    }
  };
  goHomeHandler = () => {
    this.props.history.push("/me/home");
  };

  goBackHandler = () => {
    this.props.history.goBack();
  };
  render() {
    return (
      <div className={css.preview + " temp11Landing"}>
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            zIndex: 2,
          }}
        >
          <GoBackButton clicked={this.goBackHandler} name={"Powrót"} />
          <GoBackButton clicked={this.goHomeHandler} />
        </div>
        {this.template()}
        {this.props.store.template.activeTmpId !== 11 && (
          <Form
            uplnEmail={this.state.leader.email}
            phone={this.state.leader.phone}
            test={true}
            tempId={this.props.store.template.activeTmpId}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    store: state,
  };
};

export default connect(mapStateToProps)(Preview);
