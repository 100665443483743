import React from "react";
import css from "../pageSectionTop.module.css";
import HelpComponent from "../../HelpElement/HelpComponent";

export default class SectionInput extends React.Component {
    render() {
        const setStyles = () => {
            let styles = css.input_box;
            if (this.props.temp.error) styles += ` ${css.error}`;

            return styles;
        };

        const setMessage = () => {
            let message = this.props.message;

            if (this.props.temp.error) message = this.props.temp.errMessage;

            return message;
        };
        return (
            <div className={setStyles()}>
                <input
                    type={"text"}
                    value={this.props.temp.header}
                    onChange={event =>
                        this.props.changed(
                            event,
                            this.props.id,
                            this.props.validation.name
                        )
                    }
                />
                <HelpComponent
                    error={this.props.temp.error}
                    message={setMessage()}
                />
            </div>
        );
    }
}
