import React from "react";
import css from "./selectimg.module.css";
import ImgBox from "./ImgBox/ImgBox";
import ButtonOutline from "../Buttons/Outline";

class SelectImg extends React.Component {
    render() {
        const imgBoxes = this.props.images.map(img => {
            return (
                <ImgBox
                    key={img.name}
                    selected={this.props.selected}
                    name={img.name}
                    id={this.props.id}
                    active={this.props.active}
                />
            );
        });
        return (
            <div className={css.select_img}>
                <div className={css.top}>
                    <h2>Wybierz zdjęcie</h2>
                </div>
                <div className={css.content}>
                    <p>
                        Tutaj możesz wybrać zdjęcie nagród z programu Twój Pewny
                        Start. Sama decyduj, którą nagrodę pokazać, testuj różne
                        opcje i sprawdzaj ich efektywność. Pamiętaj o
                        aktualizacji strony kiedy nagrody się zmienią.
                    </p>
                </div>
                <div className={css.img_section}>{imgBoxes}</div>
                <div className={css.button_section}>
                    <ButtonOutline
                        active={true}
                        name={"Ok"}
                        clicked={this.props.confirmed}
                    />
                </div>
            </div>
        );
    }
}

export default SelectImg;
