export const ACTIVE_PAGES_LIMIT = 5;
export const TOP_HEADING_MAX_LENGTH = 35;
export const TOP_HEADING_MIN_LENGTH = 3;
export const TOP_HEADING_TEXT = `Wprowadź treść nagłówka strony. Maksymalnie ${TOP_HEADING_MAX_LENGTH} znaków.`;
export const LIIV_TOP_HEADING_MAX_LENGTH = 45;
export const LIIV_TOP_HEADING_MIN_LENGTH = 3;
export const LIIV_TOP_HEADING_TEXT = `Wprowadź treść nagłówka strony. Maksymalnie ${LIIV_TOP_HEADING_MAX_LENGTH} znaków.`;
export const CALLBACK_MIN_LENGTH = 3;
export const CALLBACK_MAX_LENGTH = 50;
export const CALLBACK_TEXT =
    "Dobrą praktyką w pozyskiwaniu nowych Ambasadorek jest szybkie działanie!\n" +
    "Im szybciej oddzwonisz do nowej Ambasadorki i się nią zaopiekujesz - tym lepiej.\n" +
    " Napisz na swojej stronie jak szybko jesteś w stanie oddzwonić do osoby, która wypełniła formularz. 10 minut? Pół godziny? Tego samego dnia bądź jutro? Poinformuj o tym.\n";
export const REASON_MIN_LENGTH = 3;
export const REASON_MAX_LENGTH = 50;
export const REASON_TEXT = "Dlaczego warto";
export const BUSINESS_MIN_LENGTH = 3;
export const BUSINESS_MAX_LENGTH = 510;
export const LIIV_MAX_LENGTH = 200;
export const BUSINESS_TEXT = `Napisz w kilka zdań od siebie. Maksymalnie ${BUSINESS_MAX_LENGTH} znaków`;
export const LIIV_TEXT = `Napisz w kilka zdań od siebie. Maksymalnie ${LIIV_MAX_LENGTH} znaków`;
export const IMAGE_MIN_LENGTH = 3;
export const IMAGE_MAX_LENGTH = 60;
export const IMAGE_TEXT = `Wybierz zdjęcie i wpisz zachęcający tytuł. Maksymalnie ${IMAGE_MAX_LENGTH} znaków`;
export const VIDEO_MIN_LENGTH = 3;
export const VIDEO_MAX_LENGTH = 79;
export const VIDEO_TEXT = `Wklej link do swojego filmu z youtube wpisz zachęcający tytuł. Maksymalnie ${VIDEO_MAX_LENGTH} znaków`;
export const URL_MIN_LENGTH = 3;
export const URL_MAX_LENGTH = 50;
