import React from "react";
import css from "./advanced.module.css";
import InputBox from "./InputBox/InputBox";
import ButtonOutline from "../Buttons/Outline";

class Advanced extends React.Component {
  state = {
    inputs: [
      {
        description:
          "Google Analytics to internetowe narzędzie do analizy statystyk stron internetowych. Dzięki niemu \n" +
          "dowiesz się, kto kiedy i skąd przychodzi na Twoją stronę.",
        href: "https://support.google.com/analytics/answer/1008080?hl=pl",
        name: "Twój identyfikator śledzenia Google Analytics",
        id: "ga",
        value: "",
      },
      {
        description:
          "Google Tag Manager to darmowe narzędzie, które pomaga samodzielnie kontrolować fragmenty kodów \n" +
          "i tagi. Polecamy szczególnie, kiedy wykupujesz reklamę w AdWords.",
        href: "https://www.google.pl/intl/pl/tagmanager/",
        name: "Twój kod Google Tag Manager",
        id: "gtm",
        value: "",
      },
      {
        description:
          "Remarketing ułatwia docieranie do użytkowników, którzy odwiedzili Twoją witrynę lub skorzystali z Twojej" +
          " aplikacji. Mogą im się wyświetlać Twoje reklamy, gdy przeglądają inne strony lub szukają w Google" +
          " terminów związanych z Twoimi produktami lub usługami.",
        href: "https://support.google.com/adwords/answer/2453998?hl=pl",
        name: "Twój kod Google Remarketing",
        id: "gr",
        value: "",
      },
      {
        description:
          "Piksel Facebook to narzędzie analityczne, które pomaga mierzyć skuteczność reklam. Możesz skorzystać \n" +
          "z piksela Facebooka, aby dowiedzieć się, jakie działania podejmują odbiorcy w Twojej witrynie, oraz dotrzeć do użytkowników, na których Ci zależy.",
        href: "https://www.facebook.com/business/help/651294705016616",
        name: "Twój kod Facebook Pixel",
        id: "fp",
        value: "",
      },
    ],
  };

  componentDidMount() {
    let newInputs = this.state.inputs;
    // eslint-disable-next-line array-callback-return
    newInputs.map((input) => {
      input.value = this.props.inputs[input.id];
    });

    this.setState({ inputs: newInputs });
  }

  inputChangeHandler = (event, id) => {
    let newInputs = this.state.inputs;
    // eslint-disable-next-line array-callback-return
    newInputs.map((input) => {
      if (input.id === id) input.value = event.target.value;
    });
    this.setState({ inputs: newInputs });
  };

  prepareToSaveHandler = () => {
    let save = { ga: "", gtm: "", gr: "", fp: "" };
    // eslint-disable-next-line array-callback-return
    this.state.inputs.map((input) => {
      save[input.id] = input.value;
    });
    return save;
  };

  render() {
    let inputs = this.state.inputs.map((input, id) => {
      return (
        <InputBox key={id} input={input} changed={this.inputChangeHandler} />
      );
    });

    return (
      <div className={css.container}>
        <div className={css.top_box}>
          <h3>Ustawienia zaawansowane</h3>
          <p>
            W tym miejscu możesz wkleić kody potrzebne do narzędzi
            analitycznych.
          </p>
        </div>
        {inputs}
        <div className={css.button}>
          <ButtonOutline
            active={true}
            name={"OK"}
            clicked={() => this.props.confirmed(this.prepareToSaveHandler())}
          />
        </div>
      </div>
    );
  }
}

export default Advanced;
