import * as cons from "../const";
import CryptoJS from "crypto-js";

const preparePageInfo = data => {
    return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(`${data}_|_${Date.now()}`));
};

export const setPageInfo = data => ({
    type: cons.SET_PAGE_INFO,
    payload: preparePageInfo(data)
});
