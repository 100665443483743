import * as cons from "../const";
import store from "../store";

const INITIAL_STATE = {
    userNumber: null,
    userToken: ""
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case cons.SET_USER_STATE:
            return {
                ...state,
                userNumber: action.payload.userNumber,
                userToken: action.payload.userToken,
                src: action.payload.src,
                uplnemail: action.payload.uplnemail,
                landing: action.payload.landing
            };
        default:
            return state;
    }
};

export default reducer;
