import {
    BUSINESS_MAX_LENGTH,
    LIIV_MAX_LENGTH,
    BUSINESS_MIN_LENGTH,
    CALLBACK_MAX_LENGTH,
    CALLBACK_MIN_LENGTH,
    IMAGE_MAX_LENGTH,
    IMAGE_MIN_LENGTH,
    REASON_MAX_LENGTH,
    REASON_MIN_LENGTH,
    TOP_HEADING_MAX_LENGTH,
    TOP_HEADING_MIN_LENGTH,
    URL_MAX_LENGTH,
    URL_MIN_LENGTH,
    VIDEO_MAX_LENGTH,
    VIDEO_MIN_LENGTH, LIIV_TOP_HEADING_MIN_LENGTH, LIIV_TOP_HEADING_MAX_LENGTH
} from "./const";

export const validatePhone = phone => {
    const reg = /^[0-9]{9}$/;

    if (!phone) return false;

    return phone.match(reg);
};

export const validateURL = url => {
    const props = textValidationDict.default.url;
    if (url.trim().length < props.min_length)
        return {
            error: true,
            message: `Nazwa powinna mieć co najmniej ${props.min_length} znaków.`
        };
    if (url.trim().length > props.max_length)
        return {
            error: true,
            message: `Nazwa powinna mieć nie więcej niż ${props.max_length} znaków.`
        };

    if (url.includes("@"))
        return {
            error: true,
            message: `Niedozwolony znak`
        };
    const reg = /[<>%$@£“&!#';":*+\/^${}()|[\]\\]/g;

    if (url.trim().match(reg))
        return {
            error: true,
            message: `Niepoprawna nazwa`
        };

    return {
        error: false,
        message: ""
    };
};

export const validateEmail = email => {
    const reg = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;

    return email.match(reg);
};

export const validateText = (text, name, tempId) => {
    let props;

    switch (tempId) {
        case 8:
            const tempIdName = "tempId" + tempId;
            props = textValidationDict[tempIdName][name];
            break;
        default:
            props = textValidationDict.default[name];
            break;
    }

    text = text.trim();
    const escapedText = text.replace(/&nbsp;|<b>|<\/b>|<i>|<\/i>|<u>|<\/u>/gi, "");

    if (props.max_length && escapedText.length > props.max_length) {
        return {
            error: true,
            message: `Tekst nie może być dłuższy niż ${props.max_length} znaków! Aktualna długość wynosi: ${escapedText.length}`
        };
    }

    if (props.min_length && escapedText.length < props.min_length) {
        return {
            error: true,
            message: `Tekst nie może być krótszy niż ${props.min_length} znaki!`
        };
    }

    return {error: false, message: ""};
};

export const validateYoutubeLink = url => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;
    const valid = url.match(regExp);
    if (!valid) return {error: true, message: "Niepoprawny youtube link"};

    return {error: false, message: "", link: valid};
};

export const validateMetedata = value => {
    return value.replace(/@|#|\$|%|\^|\*|/gi, "");
};

const textValidationDict = {
    default: {
        topheading: {
            min_length: TOP_HEADING_MIN_LENGTH,
            max_length: TOP_HEADING_MAX_LENGTH
        },
        callback: {
            min_length: CALLBACK_MIN_LENGTH,
            max_length: CALLBACK_MAX_LENGTH
        },
        reason: {
            min_length: REASON_MIN_LENGTH,
            max_length: REASON_MAX_LENGTH
        },
        business: {
            min_length: BUSINESS_MIN_LENGTH,
            max_length: BUSINESS_MAX_LENGTH
        },
        image: {min_length: IMAGE_MIN_LENGTH, max_length: IMAGE_MAX_LENGTH},
        video: {min_length: VIDEO_MIN_LENGTH, max_length: VIDEO_MAX_LENGTH},
        url: {min_length: URL_MIN_LENGTH, max_length: URL_MAX_LENGTH}
    },
    tempId8: {
        topheading: {
            min_length: LIIV_TOP_HEADING_MIN_LENGTH,
            max_length: LIIV_TOP_HEADING_MAX_LENGTH
        },
        callback: {
            min_length: CALLBACK_MIN_LENGTH,
            max_length: CALLBACK_MAX_LENGTH
        },
        reason: {
            min_length: REASON_MIN_LENGTH,
            max_length: REASON_MAX_LENGTH
        },
        business: {
            min_length: BUSINESS_MIN_LENGTH,
            max_length: BUSINESS_MAX_LENGTH
        },
        image: {min_length: IMAGE_MIN_LENGTH, max_length: IMAGE_MAX_LENGTH},
        video: {min_length: VIDEO_MIN_LENGTH, max_length: VIDEO_MAX_LENGTH},
        url: {min_length: URL_MIN_LENGTH, max_length: URL_MAX_LENGTH}
    }
};
