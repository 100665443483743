import React from "react";
import css from "./shareLink.module.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import buttonCss from "../../Buttons/solid.module.css";
const assetUrl = "../../../assets";

class ShareLink extends React.Component {
    state = {
        copied: false
    };

    render() {
        return (
            <div className={css.box}>
                <h3>{this.props.title}</h3>
                <div className={css.input}>
                    <CopyToClipboard
                        text={this.props.value}
                        onCopy={() => this.setState({ copied: true })}
                    >
                        <button className={buttonCss.btn}>Kopiuj</button>
                    </CopyToClipboard>
                    {this.props.value && this.props.show ? (
                        <input
                            readOnly
                            className={css.input_box}
                            value={this.props.value}
                        />
                    ) : null}
                </div>

                <h5>
                    {this.props.description}
                    {this.props.descriptionLink ? (
                        <a
                            href={require(`.${assetUrl}/docs/${this.props.linkHref}.pdf`)}
                            target="_blank"
                        >
                            {this.props.linkName}
                        </a>
                    ) : null}
                </h5>
            </div>
        );
    }
}

export default ShareLink;
