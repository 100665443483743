import React, { Component } from "react";
import css from "./databox.module.css";

export default class DataBox extends Component {
    render() {
        return (
            <div className={css.data_box}>
                <p className={css.number}>{this.props.value}</p>
                <p>{this.props.name}</p>
            </div>
        );
    }
}
