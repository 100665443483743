import React from "react";
import css from "./pageSectionTop.module.css";
import logo from "../../assets/img/avon_white.png";
import logoliiv from "../../assets/img/liiv-logo.png";
import logomain from "../../assets/img/logo.svg";
import universal_home from "../../assets/img/universal/home.svg";
import mobilegift from "../../assets/img/mobilegift.svg";
import IconSection from "../IconSection/IconSection";
import Dark from "../../hoc/Dark/Dark";
import SectionInput from "./SectionInput/SectionInput";
import SectionTextArea from "./SectionTextArea/SectionTextArea";
import EditBox from "./EditBox/EditBox";
import {
    BUSINESS_TEXT,
    LIIV_TEXT,
    CALLBACK_TEXT,
    REASON_TEXT,
    TOP_HEADING_TEXT,
    LIIV_TOP_HEADING_TEXT
} from "../../utils/const";

class PageSectionTop extends React.Component {
    state = {};
    handleChange = function(event, number) {
        this.props.changed(
            event,
            number,
            this.props.template[number].content.name
        );
    };

    updateDimensions = () => {
        this.setState({
            mobile: window.innerWidth < 900 ? true : false
        });
    };

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    render() {
        let top = css.top;

        if (this.props.css !== undefined) {
            top += " " + css[this.props.css];
        }

        if (this.props.view) top += ` ${css.landing}`;
        return this.props.css != "liiv" && this.props.css != "xmas" && this.props.css != "universal" ? (
            <div className={top}>
                <Dark>
                    {this.props.showImages ? <div></div> : null}
                    <div className={css.section1}>
                        {this.props.view ? (
                            <div className={css.box}>
                                <h1>{this.props.template[0].content.header}</h1>
                            </div>
                        ) : (
                            <SectionInput
                                temp={this.props.template[0].content}
                                changed={this.props.changed}
                                message={TOP_HEADING_TEXT}
                                id={0}
                                validation={{
                                    name: this.props.template[0].content.name
                                }}
                            />
                        )}
                        <div className={css.logo}>
                            <img src={logo} alt="logo" />
                        </div>
                    </div>
                    <div className={css.section2}>
                        <div className={css.text}>
                            <h4>{this.props.template[1].content.header2}</h4>
                        </div>
                        <IconSection
                            icons={this.props.template[1].content.icons}
                            iconsPath={this.props.iconsPath}
                            custom={"iconsmiddle"}
                        />
                    </div>
                    <div className={css.section3}>
                        <section3>
                            <div className={css.work_icon}>
                                <div>
                                    <img src="/img/icons/house.svg" />
                                </div>
                                <div>
                                    Możesz<br></br>pracować z domu!
                                </div>
                            </div>
                            {this.props.view ? (
                                <div className={css.section_box}>
                                    <div className={css.text}>
                                        <h5
                                            dangerouslySetInnerHTML={{
                                                __html: this.props.template[2]
                                                    .content.header
                                            }}
                                        ></h5>
                                        <h4
                                            dangerouslySetInnerHTML={{
                                                __html: this.props.template[3]
                                                    .content.header
                                            }}
                                        ></h4>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: this.props.template[4]
                                                    .content.header
                                            }}
                                        ></p>
                                    </div>
                                </div>
                            ) : (
                                <div className={css.section_box}>
                                    <SectionTextArea
                                        temp={this.props.template[2].content}
                                        changed={this.props.changed}
                                        message={CALLBACK_TEXT}
                                        id={2}
                                        validation={{
                                            name: this.props.template[2].content
                                                .name
                                        }}
                                    />

                                    <SectionTextArea
                                        temp={this.props.template[3].content}
                                        changed={this.props.changed}
                                        message={REASON_TEXT}
                                        id={3}
                                        validation={{
                                            name: this.props.template[3].content
                                                .name
                                        }}
                                    />

                                    <EditBox
                                        temp={this.props.template[4].content}
                                        id="edibox4"
                                        onChange={event =>
                                            this.handleChange(event, 4)
                                        }
                                        message={BUSINESS_TEXT}
                                    />

                                    <SectionTextArea
                                        temp={this.props.template[4].content}
                                        changed={this.props.changed}
                                        message={BUSINESS_TEXT}
                                        hidden={true}
                                        id={4}
                                        validation={{
                                            name: this.props.template[4].content
                                                .name
                                        }}
                                    />
                                </div>
                            )}
                        </section3>
                    </div>
                </Dark>
            </div>
        ) : this.props.css == "liiv" ? (
            <div className={top}>
                <Dark>
                    <div className={css.section1}>
                        {this.props.view ? (
                            <div className={css.box}>
                                <h1>{this.props.template[0].content.header}</h1>
                            </div>
                        ) : (
                            <SectionInput
                                temp={this.props.template[0].content}
                                changed={this.props.changed}
                                message={LIIV_TOP_HEADING_TEXT}
                                id={0}
                                validation={{
                                    name: this.props.template[0].content.name
                                }}
                            />
                        )}
                    </div>

                    {this.props.css == "liiv" ? (
                        <img src={logoliiv} alt="logo" />
                    ) : this.props.css == "xmas" ? (
                        <img src={logomain} alt="logo" />
                    ) : null}

                    <div className={css.section3}>
                        <section3>
                            {this.props.view ? (
                                <div
                                    className={`${css.section_box} ${css.liiv}`}
                                >
                                    <div className={css.text}>
                                        <h4
                                            dangerouslySetInnerHTML={{
                                                __html: this.props.template[3]
                                                    .content.header
                                            }}
                                        ></h4>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: this.props.template[4]
                                                    .content.header
                                            }}
                                        ></p>
                                    </div>
                                </div>
                            ) : (
                                <div
                                    className={`${css.section_box} ${css.liiv}`}
                                >
                                    <SectionTextArea
                                        temp={this.props.template[3].content}
                                        changed={this.props.changed}
                                        message={REASON_TEXT}
                                        id={3}
                                        validation={{
                                            name: this.props.template[3].content
                                                .name
                                        }}
                                    />

                                    <EditBox
                                        temp={this.props.template[4].content}
                                        id="edibox4"
                                        onChange={event =>
                                            this.handleChange(event, 4)
                                        }
                                        message={LIIV_TEXT}
                                    />

                                    <SectionTextArea
                                        temp={this.props.template[4].content}
                                        changed={this.props.changed}
                                        message={LIIV_TEXT}
                                        hidden={true}
                                        id={4}
                                        validation={{
                                            name: this.props.template[4].content
                                                .name
                                        }}
                                    />
                                </div>
                            )}
                        </section3>
                    </div>
                </Dark>
            </div>
        ) : this.props.css == "xmas" ? (
            <div className={top}>
                <Dark>
                    <div className={css.section1}>
                        {this.props.view ? (
                            <div className={css.box}>
                                <h1>{this.props.template[0].content.header}</h1>
                            </div>
                        ) : (
                            <SectionInput
                                temp={this.props.template[0].content}
                                changed={this.props.changed}
                                message={LIIV_TOP_HEADING_TEXT}
                                id={0}
                                validation={{
                                    name: this.props.template[0].content.name
                                }}
                            />
                        )}
                        <img src={logomain} alt="logo" />
                    </div>

                    <div className={css.section3}>
                        <section3>
                            {this.props.view ? (
                                <div
                                    className={`${css.section_box} ${css.xmas}`}
                                >
                                    <div className={css.text}>
                                        <h4
                                            dangerouslySetInnerHTML={{
                                                __html: this.props.template[3]
                                                    .content.header
                                            }}
                                        ></h4>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: this.props.template[4]
                                                    .content.header
                                            }}
                                        ></p>
                                    </div>
                                </div>
                            ) : (
                                <div
                                    className={`${css.section_box} ${css.xmas}`}
                                >
                                    <SectionTextArea
                                        temp={this.props.template[3].content}
                                        changed={this.props.changed}
                                        message={REASON_TEXT}
                                        id={3}
                                        validation={{
                                            name: this.props.template[3].content
                                                .name
                                        }}
                                    />

                                    <EditBox
                                        temp={this.props.template[4].content}
                                        id="edibox4"
                                        onChange={event =>
                                            this.handleChange(event, 4)
                                        }
                                        message={LIIV_TEXT}
                                    />

                                    <SectionTextArea
                                        temp={this.props.template[4].content}
                                        changed={this.props.changed}
                                        message={LIIV_TEXT}
                                        hidden={true}
                                        id={4}
                                        validation={{
                                            name: this.props.template[4].content
                                                .name
                                        }}
                                    />
                                </div>
                            )}

                            {this.state.mobile ? <img style={{marginTop: 30 + 'px'}} src={mobilegift} /> : ""}
                        </section3>
                    </div>
                </Dark>
            </div>
       ) : this.props.css == "universal" ? (
        <div className={top}>
            <Dark>
                <div className={css.section1}>
                    {this.props.view ? (
                        <div className={css.box}>
                            <h1>{this.props.template[0].content.header}</h1>
                        </div>
                    ) : (
                        <SectionInput
                            temp={this.props.template[0].content}
                            changed={this.props.changed}
                            message={LIIV_TOP_HEADING_TEXT}
                            id={0}
                            validation={{
                                name: this.props.template[0].content.name
                            }}
                        />
                    )}
                    <img src={logomain} alt="logo" />
                </div>

                <div className={css.section3}>
                    <section3>
                        {this.props.view ? (
                            <div
                                className={`${css.section_box} ${css.universal}`}
                            >
                                <div className={css.text}>
                                    <h4
                                        dangerouslySetInnerHTML={{
                                            __html: this.props.template[3]
                                                .content.header
                                        }}
                                    ></h4>
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: this.props.template[4]
                                                .content.header
                                        }}
                                    ></p>
                                </div>
                            </div>
                        ) : (
                            <div
                                className={`${css.section_box} ${css.universal}`}
                            >
                                <SectionTextArea
                                    temp={this.props.template[3].content}
                                    changed={this.props.changed}
                                    message={REASON_TEXT}
                                    id={3}
                                    validation={{
                                        name: this.props.template[3].content
                                            .name
                                    }}
                                />

                                <EditBox
                                    temp={this.props.template[4].content}
                                    id="edibox4"
                                    onChange={event =>
                                        this.handleChange(event, 4)
                                    }
                                    message={LIIV_TEXT}
                                />

                                <SectionTextArea
                                    temp={this.props.template[4].content}
                                    changed={this.props.changed}
                                    message={LIIV_TEXT}
                                    hidden={true}
                                    id={4}
                                    validation={{
                                        name: this.props.template[4].content
                                            .name
                                    }}
                                />
                            </div>
                        )}

                       


                        <div className={css.universal_home}>
                        <img src={universal_home} alt="logo" />
                        <h2>Możesz pracować<br></br>z domu</h2>

                        </div>


                       
                    </section3>
                </div>
            </Dark>
        </div>
    ) : null;
    }
}

export default PageSectionTop;
