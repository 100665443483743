import { mobileData } from './mobile';
import { stationaryData } from './stationary';

export const validateNameValue = name => {
    const reg = /^[A-Za-zĄĘĆŁŃÓŚŻŹąęćłńóśżź\s]{2,}[-]?[A-Za-zĄĘĆŁŃÓŚŻŹąęćłńóśżź\s]*[-.]?[A-Za-zĄĘĆŁŃÓŚŻŹąęćłńóśżź\s]*$/;

    return name.match(reg);
};

export const validateEmail = email => {
    const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return email.match(reg);
};

export const validatePhone =  (phone) => {
    if (!phone || phone.length < 2) return false; // Ensure phone has at least two digits
    
    // Extract the first two digits of the phone number
    const phonePrefix = phone.slice(0, 2);
  
    // Check if the phone prefix matches the first two digits of any "Zakres (number)" in mobile or stationary data
    const isValidMobile = mobileData.some(entry => entry["Zakres (number)"].toString().slice(0, 2) === phonePrefix);
    const isValidStationary = stationaryData.some(entry => entry["Zakres (number)"].toString().slice(0, 2) === phonePrefix);
  
    // Validate against the regular expression for the complete phone number format
    const reg = /^[0-9]{9}$/;
    return reg.test(phone) && (isValidMobile || isValidStationary);
  };

export const validateStreetName = street => {
    return street.match(
        /^[0-9A-Za-zĄĘĆŁŃÓŚŻŹąęćłńóśżź\s]{3,100}[.]?[\s]?$|^[A-Za-zĄĘĆŁŃÓŚŻŹąęćłńóśżź]+[A-Za-zĄĘĆŁŃÓŚŻŹąęćłńóśżź0-9\s\-.]+[A-Za-zĄĘĆŁŃÓŚŻŹąęćłńóśżź0-9]{1,100}[\s]?$/
    );
};

export const validateCityName = city => {
    return city.match(
        /^[A-Za-zĄĘĆŁŃÓŚŻŹąęćłńóśżź]{3,100}$|^[A-Za-zĄĘĆŁŃÓŚŻŹąęćłńóśżź]+[A-Za-zĄĘĆŁŃÓŚŻŹąęćłńóśżź0-9\s-]+[A-Za-zĄĘĆŁŃÓŚŻŹąęćłńóśżź0-9]{1,100}[\s]?$/
    );
};

export const validatePostCode = postCode => {
    return postCode.match(/^[0-9]{2}-[0-9]{3}$/);
};

export const validateStreetNo = number => {
    return number.match(/^[0-9]{1,9}[A-Za-z]{0,9}[\/]?[\s]?[A-Za-z0-9\s]{0,5}$/);
};

export const validateAptNo = number => {
    return number.match(/^[0-9A-Za-z][\/0-9A-Za-z]{0,9}$/);
};
