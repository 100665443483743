import React from "react";
import css from "../temp.module.css";
import PageSectionTop from "../../PageSection/PageSectionTop";
import PageSectionMiddle from "../../PageSection/PageSectionMiddle";
import PageSectionBottom from "../../PageSection/PageSectionBottom";

class Template4 extends React.Component {
    state = {
        iconsTop: [
            {
                icon: "test.png"
            },
            {
                icon: "union.png"
            },
            {
                icon: "piggy-bank.png"
            }
        ],
        iconsBottom: [
            {
                icon: "gift.png"
            },
            {
                icon: "bag.png"
            },
            {
                icon: "box.png"
            }
        ]
    };

    render() {
        return (
            <section className={css.page_section}>
                <h5>Edytor zawartości:</h5>
                <PageSectionTop
                    template={this.props.temp}
                    changed={this.props.changed}
                    showImages={true}
                    iconsPath={this.state.iconsTop}
                />
                <PageSectionMiddle
                    template={this.props.temp}
                    changed={this.props.changed}
                    clickedShow={this.props.clickedShow}
                    clickedHide={this.props.clickedHide}
                    margin={this.props.margin}
                    img={this.props.img}
                    video={this.props.video}
                />
                <PageSectionBottom
                    template={this.props.temp}
                    changed={this.props.changed}
                    iconsPath={this.state.iconsBottom}
                />
            </section>
        );
    }
}

export default Template4;
