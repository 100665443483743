import React from "react";
import ButtonSolid from "../Buttons/Solid";
import styles from "./content.module.css";
import stylesBtn from "../Buttons/solid.module.css";

class Content extends React.Component {
    render() {
        return (
            <div className={styles.content}>
                <div className={styles.title}>
                    <p>Moje strony rekrutacyjne</p>
                </div>
                <div className={styles.text1}>
                    Rekrutacja w internecie to stała aktywność poprzedzona
                    analizą wyników i korektą treści. Przygotowane przez nas
                    narzędzia pozwolą Ci osiągnąć powyższe w ciągu kilku minut.
                </div>
                {this.props.createPage ? (
                    <ButtonSolid
                        name={"Utwórz nową stronę"}
                        clicked={this.props.clicked}
                        linkTo={"/me/add"}
                    />
                ) : (
                    <ButtonSolid inactive={1} name={"Utwórz nową stronę"} />
                )}
                {/*<div className={styles.text2}>*/}
                {/*    Przeczytaj nasz poradnik i dowiedz się jak skutecznie*/}
                {/*    tworzyć reklamy w Internecie. Dowiesz się także kilku*/}
                {/*    praktycznych informacji na temat marketingu internetowego*/}
                {/*    oraz jak efektywnie rekrutować przez internet.*/}
                {/*</div>*/}
                {/*<ButtonSolid name={"Pobierz nasz poradnik"} />*/}
                <div className={styles.text2}>
                    Przygotowaliśmy też zestaw reklam online do Twojej strony,
                    które możesz wykorzystać w płatnych kampaniach.
                </div>
                <a className={stylesBtn.btn} href='/img/banners/reklama.zip'>
                    Pobierz reklamy
                </a>
                <div className={styles.tooltipMobile}>
                    <strong>Podgląd nie działa?</strong>
                    <div>Zezwól na wyskakujące okienka w ustawieniach przeglądarki.</div>
                </div>
            </div>
        );
    }
}

export default Content;
