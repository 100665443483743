import React from "react";
import css from "./share.module.css";
import ShareLink from "./ShareLink/ShareLink";

class Share extends React.Component {
    state = {
        path: window.location.origin
    }
    render() {
        return (
            <div className={css.container}>
                <div className={css.top}>
                    <h1>Udostępnij</h1>
                    <h3>Udostępnij swoją stronę w mediach społecznościowych</h3>
                </div>
                <div className={css.icons}>
                    <a
                        target={"_blank"}
                        href={`https://www.facebook.com/sharer/sharer.php?u=https://start.avon.pl/${this.props.data.link}?utm_share=facebook`}
                    >
                        <img
                            src="/img/icons/facebook.png"
                            alt={"facebook icon"}
                        />
                    </a>

                    <a
                        target={"_blank"}
                        href={`https://twitter.com/intent/tweet?url=https://start.avon.pl/${this.props.data.link}`}
                    >
                        <img
                            src="/img/icons/twitter.png"
                            alt={"twitter icon"}
                        />
                    </a>
                    <a
                        target={"_blank"}
                        href={`https://www.linkedin.com/shareArticle?mini=true&url=https://start.avon.pl/${this.props.data.link}&title=${this.props.data.link}&summary=start.avon.pl&source=${this.props.data.link}`}
                    >
                        <img
                            src="/img/icons/linkedin.png"
                            alt={"linkedIn icon"}
                        />
                    </a>
                </div>

                <ShareLink
                    show={true}
                    title={"Udostępnij na forum Wizaz.pl"}
                    value={`[url=${this.state.path}/${this.props.data.link}?utm_share=wizaz][img]${this.props.data.imgPath}[/img][/url]`}
                    description={
                        "Aby udostępnić na wizaz.pl wklej kod do posta."
                    }
                    descriptionLink={false}
                    linkHref={"Katalog"}
                    linkName={"naszego poradnika."}
                />
                <ShareLink
                    show={true}
                    title={"Wklej banner na swoją stronę"}
                    value={`<a href="${this.state.path}/${this.props.data.link}"><img src="${this.props.data.imgPath}"></a>`}
                    description={
                        "Jeśli prowadzisz bloga możesz umieścić na nim banner."
                    }
                    descriptionLink={false}
                    linkHref={"Magazyn"}
                    linkName={"naszego poradnika."}
                />
                <ShareLink
                    show={true}
                    title={"Link do strony"}
                    description={"Skopiuj link do strony."}
                    descriptionLink={false}
                    value={`${this.state.path}/${this.props.data.link}`}
                />
            </div>
        );
    }
}

export default Share;
