import React from "react";
import css from "./iconSection.module.css";
import IconBox from "./IconBox/IconBox";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class IconSection extends React.Component {
    state = {
        icons: [
            {
                icon: "test.png"
            },

            {
                icon: "union.png"
            },
            {
                icon: "piggy-bank.png"
            }
        ]
    };

    componentDidMount() {
        let newIcons = { ...this.state.icons };
        if (this.props.iconsPath !== undefined) {
            newIcons = this.props.iconsPath;
        }

        this.setState({ icons: newIcons });
    }

    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        const mobile = window.innerWidth <= 480;
        const boxes = this.state.icons.map((box, i) => {
            const iconIndex = "icon" + (i + 1);
            return (
                <IconBox
                    key={iconIndex}
                    icon={box.icon}
                    text={
                        this.props.icons[iconIndex] &&
                        this.props.icons[iconIndex].text
                            ? this.props.icons[iconIndex].text
                            : ""
                    }
                    textSize={this.props.textSize}
                    customclass={this.props.custom}
                />
            );
        });
        return (
            <div
                className={
                    this.props.custom === "iconsmiddle"
                        ? css.section
                        : `${css.iconsbottom} ${css.section}`
                }
            >
                {boxes}
            </div>
        );
    }
}

export default IconSection;
