import React from "react";
import TableRowFlex from "./TableRow/TableRowFlex";
import style from "./table.module.css";

class Table extends React.Component {
    render() {
        const dataRows = this.props.pages;

        let pagesflex = dataRows.map(row => {
            return (
                <TableRowFlex
                    key={row.id}
                    pageDetails={row}
                    active={row.active}
                    clicked={this.props.clicked}
                    share={this.props.share}
                    onOff={this.props.onOff}
                    edit={this.props.edit}
                    preview={this.props.preview}
                    stats={this.props.stats}
                    margin={this.props.margin}
                />
            );
        });

        return (
            <div className={style.tableflex}>
                <div>
                    <div></div>
                    <div>
                        <div>Tytuł strony</div>
                        <div>Utworzona</div>
                        <div>Wizyty</div>
                        <div>Zgłoszenia</div>
                    </div>
                </div>
                {pagesflex}
            </div>
        );
    }
}

export default Table;
