import React from "react";
import ButtonOutline from "../../Buttons/Outline";
import { createdAt } from "../../../services/service";
import style from "./table-row.module.css";
import ButtonSolidOnOff from "../../Buttons/SolidOnOff";

class TableRowFlex extends React.Component {
    state = {
        pageDetails: this.props.pageDetails,
        userData: this.props.pageDetails.userdata,
        active: this.props.pageDetails.active
    };

    render() {
        return (
            <div className={style.flexrow}>
                <div>
                    <ButtonOutline
                        name={"Udostępnij"}
                        id={this.state.pageDetails.id}
                        active={this.props.pageDetails.active}
                        clicked={this.props.share}
                        linkTo={this.state.pageDetails.id}
                    />
                    <ButtonOutline
                        name={"Statystyki"}
                        id={this.state.pageDetails.id}
                        linkTo={"/me/statistics"}
                        clicked={() =>
                            this.props.stats(this.state.pageDetails.id)
                        }
                    />
                    <ButtonOutline
                        linkTo={this.state.pageDetails.id}
                        name={"Edytuj"}
                        id={this.state.pageDetails.id}
                        clicked={this.props.edit}
                    />
                    <ButtonOutline
                        linkTo={"/me/preview"}
                        name={"Podgląd"}
                        active={this.props.active}
                        id={this.state.pageDetails.id}
                        clicked={() =>
                            this.props.preview(
                                this.state.pageDetails.id,
                                this.state.pageDetails.link
                            )
                        }
                    />
                    <ButtonSolidOnOff
                        name={"Wyłącz"}
                        id={this.state.pageDetails.id}
                        clicked={this.props.onOff}
                        linkTo={this.state.pageDetails.id}
                        active={this.props.pageDetails.active}
                    />
                </div>
                <div>
                    <div>
                        <div className={style.mobilelabel}>Tytuł strony</div>
                        {this.state.pageDetails.name}
                    </div>
                    <div>
                        <div className={style.mobilelabel}>Utworzono</div>
                        {createdAt(this.state.pageDetails.created_at)}
                    </div>
                    <div>
                        <div className={style.mobilelabel}>Wizyty</div>
                        {this.state.pageDetails.cacheLogVisits}
                    </div>
                    <div>
                        <div className={style.mobilelabel}>Zgłoszenia</div>
                        {this.state.pageDetails.cacheLogForms}
                    </div>
                </div>
            </div>
        );
    }
}

export default TableRowFlex;
