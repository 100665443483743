import React from "react";
import css from "./agreement.module.css";

const Agreement1 = data => {
    return (
        <div className={"agreement " + data.agreementClass}>
            <label className={css.container}>
                <input
                    type="checkbox"
                    id={data.input.id}
                    name={data.input.name}
                    style={{ visibility: "hidden" }}
                />
                <span
                    className={css.checkmark + " " + data.checkmarkClass}
                    id={`${data.input.id}checkmark`}
                    name={data.input.name}
                    onClick={data.changed}
                ></span>
                <span className="" onClick={data.changed}>
                    Akceptuję{" "}
                    <a
                        href="https://www.avon.pl/polityka-prywatnosci/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Politykę Prywatności
                    </a>{" "}
                    oraz{" "}
                    <a
                        href="https://www.avon.pl/warunki-korzystania-z-serwisu"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Warunki Korzystania z serwisu
                    </a>
                </span>
                {data.input.error ? (
                    <div className={css.error}>{data.input.errorMessage}</div>
                ) : null}
            </label>
        </div>
    );
};

export default Agreement1;
