import React from "react";
import GoBackButton from "../../components/Buttons/GoBack";
import Container from "../../hoc/Container";
import css from "./statistics.module.css";
import {
    LineChart,
    XAxis,
    Tooltip,
    CartesianGrid,
    Line,
    YAxis,
    Legend
} from "recharts";
import { connect } from "react-redux";
import DataBox from "../../components/Statistics/DataBox";
import { statisticsGraph } from "../../services/service";
import { stats } from "../../states/stats";

class Statistics extends React.Component {
    state = stats;
    updateDimensions = () => {
        this.setState({
            width: window.innerWidth > 800 ? window.innerWidth * 0.6 : 800
        });
    };

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);
        const statistics = this.props.store.statistics.stats;

        if (!this.props.store.template.activePageId)
            return this.props.history.push("/me/home");

        this.setState({ leader: this.props.store.template.leader });

        const stats = {
            facebook: statistics.facebook ? statistics.facebook : 0,
            google: statistics.google ? statistics.google : 0,
            wizaz: statistics.wizaz ? statistics.wizaz : 0,
            instagram: statistics.instagram ? statistics.instagram : 0,
            other: statistics.other ? statistics.other : 0,
            messenger: statistics.messenger ? statistics.messenger : 0,
            phone: statistics.phone ? statistics.phone : 0,
            avonpolska: statistics.avonpolska ? statistics.avonpolska : 0,
            email: statistics.email ? statistics.email : 0,
            skype: statistics.skype ? statistics.skype : 0,
            whatsapp: statistics.whatsapp ? statistics.whatsapp : 0,
            direct: statistics.direct ? statistics.direct : 0,
            poczta: statistics.poczta ? statistics.poczta : 0
        };

        statisticsGraph(
            this.props.store.user,
            this.props.store.template.activePageId
        )
            .then(response => {
                this.setState({ data: response.data.data });
            })
            .catch(err => console.log(err));

        this.setState({ statistics: stats });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    goBackHandler = () => {
        this.props.history.goBack();
    };

    render() {
        return (
            <Container clName={"container_left"}>
                <GoBackButton
                    name={"Powrót do moich stron"}
                    clicked={this.goBackHandler}
                />
                <section className={css.section}>
                    <div className={css.top}>
                        <h3>Statystyki</h3>
                        <p>
                            Na tej stronie możesz zmierzyć efektywność swojej
                            strony. Sprawdź skąd użytkownicy trafili na Twoją
                            stronę, ile osób ją odwiedziło i zdecydowało się
                            wypełnić Twój formularz.
                            <br /> Dzięki temu narzędziu zmierzysz także
                            efektywność swoich reklam.
                        </p>
                        <h5>
                            Tytuł strony:
                            <br /> {this.state.leader.pageName}
                        </h5>
                    </div>
                    <div className={css.chart}>
                        <LineChart
                            width={this.state.width}
                            height={300}
                            data={this.state.data}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line
                                type="monotone"
                                dataKey="umowy"
                                activeDot={{ r: 8 }}
                                stroke="red"
                            />
                            <Line
                                type="monotone"
                                dataKey="wizyty"
                                stroke="#7F28C4"
                            />
                        </LineChart>
                    </div>
                    <div className={css.bottom}>
                        <div className={css.text_box}>
                            <h5>Wizyty na Twojej stronie:</h5>
                            <p>
                                Udostępniasz link do strony rekrutacyjnej na
                                Instagramie? A może masz wykupioną reklamę na
                                Facebooku? Sprawdź ich efektywność! Oto skąd
                                użytkownicy trafiają na Twoją stronę:
                            </p>
                        </div>
                        <div className={css.data_section}>
                            <DataBox
                                name={"Facebook"}
                                value={this.state.statistics.facebook}
                            />
                            <DataBox
                                name={"Google"}
                                value={this.state.statistics.google}
                            />
                            <DataBox
                                name={"Wizaz.pl"}
                                value={this.state.statistics.wizaz}
                            />
                            <DataBox
                                name={"Pozostałe"}
                                value={this.state.statistics.other}
                            />
                        </div>
                    </div>
                </section>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        store: state
    };
};

export default connect(mapStateToProps)(Statistics);
