import React, { createRef, useState, useEffect } from "react";

const Video = props => {
    const [showVideo, setShowVideo] = useState(false);

    const container = createRef();

    const videoObserver = new IntersectionObserver(onVideoIntersection, {
        rootMargin: "0px 0px",
        threshold: 0.25
    });

    useEffect(() => {
        if (window && "IntersectionObserver" in window) {
            if (container && container.current) {
                videoObserver.observe(container.current);
            }
        } else {
            setShowVideo(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [container]);

    function onVideoIntersection(entries) {
        if (!entries || entries.length <= 0) {
            return;
        }

        if (entries[0].isIntersecting) {
            setShowVideo(true);
            videoObserver.disconnect();
        }
    }

    return (
        <div ref={container}>
            {showVideo ? (
                // eslint-disable-next-line jsx-a11y/iframe-has-title
                <iframe
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                    width={props.opts.width}
                    height={props.opts.height}
                    src={`https://www.youtube.com/embed/${props.videoId}`}
                    frameBorder="0"
                    allow="accelerometer;
                           autoplay;
                           encrypted-media;
                           gyroscope;
                           picture-in-picture"
                    allowFullScreen
                />
            ) : undefined}
        </div>
    );
};

export default Video;
