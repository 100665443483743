export const setCookie = (cname, cvalue, exmins) => {
    const d = new Date();
    d.setTime(d.getTime() + exmins * 60 * 1000);
    const expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    checkCookie(cname);
};

export const getCookie = cname => {
    const name = cname + "=";
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
};

export const checkCookie = cookieName => {
    const cookieValue = getCookie(cookieName);
    return !!cookieValue;
};

export const deleteCookie = cookieName => {
    document.cookie =
        cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
};
