export const state = {
    iconsTop: [
        {
            icon: "list.svg"
        },
        {
            icon: "envelope.svg"
        },
        {
            icon: "dollar.svg"
        }
    ],
    iconsBottom: [
        {
            icon: "gift.svg"
        },
        {
            icon: "bag.svg"
        },
        {
            icon: "box.svg"
        },
        {
            icon: "house.svg"
        }
    ],
    css: {
        top: "green",
        middle: "middle_green",
        bottom: "bottom_green"
    }
};
