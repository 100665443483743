export const state = {
    iconsTop: [
        {
            icon: "gift_u.svg"
        },
        {
            icon: "bag_u.svg"
        },
        {
            icon: "delivery_u.svg"
        }
    ],
    iconsBottom: [
        {
            icon: "gift.svg"
        },
        {
            icon: "bag.svg"
        },
        {
            icon: "box.svg"
        },
        {
            icon: "house.svg"
        }
    ],
    css: {
        top: "universal",
        middle: "universal",
        bottom: "universal"
    }
};
