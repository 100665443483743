import React from "react";
import css from "./solid.module.css";

class ButtonSolidOnOff extends React.Component {
    state = {
        className: css.btn,
        buttonText: "",
        active: ""
    };

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (nextProps.active !== nextState.active) {
            let newClasses = css.btn;
            let buttonText = "Wyłącz";

            if (nextProps.active === 0) {
                buttonText = "Włącz";
                newClasses += " " + css.btn_inactive;
            }

            this.setState({
                active: nextProps.active,
                className: newClasses,
                buttonText: buttonText
            });
            return false;
        }
        return true;
    }

    componentDidMount() {
        let localState = this.state.className;
        let buttonText = "Wyłącz";

        if (this.props.active === 0) {
            buttonText = "Włącz";
        }

        if (this.props.inactive === 1 || this.props.active === 0) {
            localState += " " + css.btn_inactive;
        }

        if (this.props.narrow === 1) {
            localState += " " + css.narrow;
        }

        this.setState({
            className: localState,
            buttonText: buttonText,
            active: this.props.active
        });
    }

    render() {
        return (
            <button
                className={this.state.className}
                onClick={() =>
                    this.props.clicked(this.props.linkTo, this.props.id)
                }
            >
                {this.state.buttonText}
            </button>
        );
    }
}

export default ButtonSolidOnOff;
