import React, { Component } from "react";
import heartIcon from "../../assets/img/new_template/heart-success.svg";
import css from "./thankYou2.module.css";

class ThankYou2 extends Component {
    componentDidMount() {}

    clickHandler = link => {
        window.open("https://www2.pl.avon.com/pl-home");
    };

    render() {
        return (
            <div className={css.main + " px-4"}>
                <div className="container-content">
                    <div>
                        <img
                            src={heartIcon}
                            alt={"success icon"}
                        />
                        <h1 className={css.typHeader + " my-5"}>DZIĘKUJEMY <br></br> ZA REJESTRACJĘ</h1>
                        <h5>
                            <div className="mb-5"><b>Wkrótce skontaktujemy się z Tobą.</b></div>
                            <div className="mb-5">Sprawdź swoją skrzynkę email oraz SMSa i zaloguj się do strony <br></br> Klubu Avon.</div>
                        </h5>
                    </div>
                    <div className={css.bottomTextBox}>
                            <button className={css.button} type="submit" onClick={this.clickHandler}>
                               
                            </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default ThankYou2;
