import React from "react";
import Loader from "react-loader-spinner";
import css from "./loaderComponent.module.css";
const LoaderComponent = () => {
    return (
        <div className={css.loader_component}>
            <Loader type="Puff" color="#7F28C4" height={180} width={180} />
        </div>
    );
};

export default LoaderComponent;
