import React from "react";
import css from "./popup.module.css";

export default class PopUp extends React.Component {
    render() {
        return (
            <div className={css.modal}>
                <div
                    className={css.modal_content}
                    style={{ marginTop: this.props.margin + "px" }}
                >
                    <span
                        className={css.close}
                        title="Close Modal"
                        onClick={this.props.close}
                    >
                        &times;
                    </span>
                    {this.props.children}
                </div>
            </div>
        );
    }
}
