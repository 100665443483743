import React from "react";
import css from "../selectimg.module.css";
const assetUrl = "../../../assets/img";

export default class ImgBox extends React.Component {
    render() {
        return (
            <div
                className={
                    this.props.active === this.props.name
                        ? `${css.active} ${css.img_box}`
                        : css.img_box
                }
            >
                <img
                    id={this.props.name}
                    src={`/img/small/${this.props.name}.jpg`}
                    alt={"prizes"}
                    onClick={() =>
                        this.props.selected(this.props.name, this.props.id)
                    }
                />
            </div>
        );
    }
}
