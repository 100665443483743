export const stats = {
    width: 0,
    height: 0,
    statistics: {
        facebook: 0,
        google: 0,
        wizaz: 0,
        instagram: 0,
        other: 0,
        messenger: 0,
        phone: 0,
        avonpolska: 0,
        email: 0,
        skype: 0,
        whatsapp: 0,
        direct: 0,
        poczta: 0
    },
    data: [{}],
    leader: { pageName: "" }
};
