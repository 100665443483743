import React from "react";
import Container from "../../hoc/Container";
import GoBackButton from "../../components/Buttons/GoBack";
import InputEdit from "../../components/FormsElements/Inputs/InputEdit";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { store } from "react-notifications-component";
import css from "./edit.module.css";
import { connect } from "react-redux";
import * as validators from "../../utils/validators";
import LoaderComponent from "../../components/LoaderComponent/LoaderComponent";
import { editInitialState } from "../../states/edit";
import { setTemplate } from "../../redux/template/actions";
import ButtonOutline from "../../components/Buttons/Outline";
import PopUp from "../../hoc/PopUp/PopUp";
import SelectImg from "../../components/SelectImg/SelectImg";
import PasteVideo from "../../components/PasteVideo/PasteVideo";
import {
    deletePage,
    saveTemplate,
    setVideo,
    setImages,
    checkName,
    pageOnOff
} from "../../services/service";
import SampleConfirmation from "../../components/SampleConfirmation/SampleConfirmation";
import DeleteConfirmation from "../../components/DeleteConfirmation/DeleteConfirmation";
import Template5 from "../../components/TemplatesEdit/Template5/Template5";
import Template6 from "../../components/TemplatesEdit/Template6/Template6";
import Template7 from "../../components/TemplatesEdit/Template7/Template7";
import Template8 from "../../components/TemplatesEdit/Template8/Template8";
import Template9 from "../../components/TemplatesEdit/Template9/Template9";
import Template10 from "../../components/TemplatesEdit/Template10/Template10";
import Template11 from "../../components/TemplatesEdit/Template11/Template11";
import Advanced from "../../components/Advanced/Advanced";
import { validateText } from "../../utils/validators";
import ReactDOM from "react-dom";

class Edit extends React.Component {
    state = editInitialState;

    componentDidMount() {
        const template = { ...this.props.store.template };

        if (!template.activePageId && !template.activeTmpId)
            return this.continueHandler("/me/home");

        if (template.activeTmpId < 5) {
            this.errorHandler();
        }
        const leader = { ...template.leader };
        let title = "";
        let description = "";
        if (template.metadata) {

            title = template.metadata.title || "";
            description = template.metadata.description || "";
        }

        let inputs = { ...this.state.inputs };
        let newState = { ...this.state };

        inputs.metaTitle.value = title || "";
        inputs.metaDescription.value = description || "";
        inputs.phone.value = leader.phone !== undefined ? leader.phone : "";
        inputs.email.value = leader.email !== undefined ? leader.email : "";
        inputs.pageName.error = false;
        inputs.phone.error = false;

        if (leader.link !== undefined) {
            inputs.pageLink.value = leader.link;
        }
        if (leader.pageName !== undefined) {
            inputs.pageName.value = leader.pageName;
        }

        if (template.images.confirmed !== undefined) {
            newState.images = { ...template.images };
        }

        newState.tagInputs = template.tags;
        newState.images = { ...setImages(newState.images, template) };
        newState.inputs = inputs;
        newState.templateId = template.activeTmpId;
        newState.loader = false;
        newState.template = template.template;
        newState.leader = leader;
        newState.activePageId = template.activePageId;
        newState.temp = template;
        newState.video.videoId = setVideo(this.state.video.videoId, template);

        this.setState({ ...newState });
    }

    goHomeHandler = () => {
        this.props.history.push("/me/home");
    };

    inputHandler = (input, id) => {
        let temp = { ...this.state.temp };
        let leader = { ...this.state.leader };
        let inputs = { ...this.state.inputs };
        let inputLocal = { ...inputs[id] };

        const updateState = () => {
            inputs[id] = inputLocal;
            this.setState({ inputs: inputs, leader: leader, temp: temp });
        };

        switch (id) {
            case "pageLink":
                break;
            case "pageName":
                inputLocal.value = input.target.value;
                temp.leader.pageName = input.target.value;
                const pageLink = "pageLink";
                leader.pageName = input.target.value;
                let inputLink = { ...inputs[pageLink] };

                updateState();

                const urlLocal = inputLink.defaultValue + input.target.value;
                temp.leader.link = inputLink.defaultValue + input.target.value;
                inputLink.value = urlLocal;
                inputs[pageLink] = inputLink;
                this.validate(input.target.value, "URL").then(response => {
                    inputLink.error = response.error;
                    inputLocal.error = response.error;
                    inputLocal.errMessage = response.message;
                    updateState();
                });
                break;
            case "email":
                inputLocal.value = input.target.value;
                leader.email = input.target.value;
                temp.leader.email = input.target.value;

                updateState();

                this.validate(inputLocal.value, "Email").then(response => {
                    inputLocal.error = !response;
                    inputLocal.errMessage = !response
                        ? "niepoprawny adres"
                        : "";
                    updateState();
                });
                break;
            case "phone":
                inputLocal.value = input.target.value;
                leader.phone = input.target.value;
                temp.leader.phone = input.target.value;

                updateState();

                this.validate(inputLocal.value, "Phone").then(response => {
                    inputLocal.error = !response;
                    inputLocal.errMessage = !response
                        ? "niepoprawny numer"
                        : "";
                    updateState();
                });
                break;
            case "metaDescription":
                inputLocal.value = input.target.value;

                this.validate(inputLocal.value, "Metedata").then(response => {
                    inputLocal.value = response;
                    temp.metaDescription = response;
                    updateState();
                });
                break;
            case "metaTitle":
                inputLocal.value = input.target.value;

                this.validate(inputLocal.value, "Metedata").then(response => {
                    inputLocal.value = response;
                    temp.metaTitle = response;
                    updateState();
                });
                break;
            default:
                updateState();
                break;
        }
    };

    validate = (data, type, props = null, tempid) => {
        return new Promise(resolve => {
            let validate = "validate" + type;
            resolve(validators[validate](data, props, tempid));
        });
    };

    templateInputHandler = (input, id, name) => {
        let templateInputs = [...this.state.template];
        let inputLocal = { ...templateInputs[id] };
        const value = input.target.value;
        inputLocal.content.header = value;
        this.setState({ template: templateInputs });

        const updateState = () => {
            templateInputs[id] = inputLocal;
            this.setState({ template: templateInputs });
        };

        switch (name) {
            case "topheading":
            case "callback":
            case "reason":
            case "business":
            case "image":
            case "video":
                this.validate(
                    value,
                    "Text",
                    name,
                    this.props.store.template.activeTmpId
                ).then(resp => {
                    inputLocal.content.error = resp.error;
                    inputLocal.content.errMessage = resp.message;

                    updateState();
                });
                break;
            default:
                updateState();
                break;
        }
    };

    updateData = () => {
        this.props.setTemplate({
            activePageId: this.state.activePageId,
            activeTmpId: this.state.activeTmpId,
            edit: this.state.edit,
            template: this.state.template,
            leader: this.state.leader
        });
    };

    closeImgPopUpHandler = () => {
        const newState = { ...this.state };
        newState.images.selected = { ...newState.images.confirmed };
        newState.popup = { active: false, type: "" };

        this.setState(newState);
    };

    closePopUpHandler = () => {
        this.setState({ popup: { active: false, type: "" } });
    };

    showPopUpHandler = (type, margin) => {
        this.setState({ popup: { active: true, type: type, margin: margin } });
    };

    continueHandler = goTo => {
        this.props.history.push(goTo);
    };

    imgSelectedHandler = (img, id) => {
        const newImages = { ...this.state.images };
        newImages.selected = { name: img, tempId: id };
        this.setState({ images: newImages });
        this.setState({ selectedImage: img });
    };

    imgConfirmHandler = () => {
        let newState = { ...this.state };
        newState.images.confirmed = { ...newState.images.selected };
        newState.popup = { active: false, type: "" };

        newState = this.saveToTemplateComponent(
            newState,
            newState.images.confirmed.tempId,
            newState.images.confirmed.name
        );
        this.setState(newState);
    };

    settingsConfirmHandler = data => {
        const newTagInputs = this.state.tagInputs;
        newTagInputs.ga = data.ga;
        newTagInputs.gtm = data.gtm;
        newTagInputs.gr = data.gr;
        newTagInputs.fp = data.fp;

        this.setState({ tagInputs: newTagInputs });

        this.closePopUpHandler();
        store.addNotification({
            title: "Uwaga!",
            message:
                "Wprowadzone przez Ciebie ustawienia będą miały efekt dopiero po zapisaniu zmian. Dokończ edycję i naciśnij przycisk 'Zapisz zmiany' na dole strony.",
            type: "success",
            insert: "top",
            container: "top-center",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 0,
                onScreen: true,
                showIcon: true
            }
        });
    };

    videoHandler = (video, name) => {
        let newState = { ...this.state };

        newState = this.saveToTemplateComponent(newState, name, video);
        newState.popup.active = false;
        newState.popup.type = "";
        newState.video.videoId = video;

        this.setState(newState);
        this.closeImgPopUpHandler();
    };

    saveToTemplateComponent(newState, name, value) {
        // eslint-disable-next-line array-callback-return
        newState.template.map((component, id) => {
            if (component.content.name === name) {
                component.content.value = value;
                newState.template[id] = { ...component };
            }
        });

        return newState;
    }

    submitValidation = (validateAll = false) => {
        return new Promise((resolve, reject) => {
            let inputs = this.state.inputs;
            const validate = this.validate;
            const templateInputsValidation = this.templateInputsValidation;
            let isValid = true;
            const users = this.props.store.user;
            const storePageName = this.props.store.template.leader.pageName;

            async function validation() {
                try {
                    if (
                        storePageName !== inputs.pageName.value ||
                        !storePageName ||
                        !inputs.pageName.value ||
                        validateAll
                    ) {
                        let pageNameValidation = await validate(
                            inputs.pageName.value,
                            "URL"
                        );
                        if (pageNameValidation.error) {
                            inputs.pageName.errMessage =
                                pageNameValidation.message;
                            inputs.pageName.error = pageNameValidation.error;
                            isValid = false;
                        } else {
                            let name = await checkName(
                                users,
                                inputs.pageName.value
                            );
                            if (name.data.status !== "success") {
                                inputs.pageName.errMessage =
                                    "niepoprawna nazwa";
                                inputs.pageName.error = true;
                                isValid = false;
                            }

                            if (!name.data.data.availability) {
                                inputs.pageName.errMessage =
                                    "nazwa niedostępna";
                                inputs.pageName.error = true;
                                isValid = false;
                            }
                        }
                    }

                    let emailValidation = await validate(
                        inputs.email.value,
                        "Email"
                    );

                    if (!emailValidation) {
                        inputs.email.errMessage = "niepoprawna adres";
                        inputs.email.error = true;
                        isValid = false;
                    }

                    let phoneValidation = await validate(
                        inputs.phone.value,
                        "Phone"
                    );

                    if (!phoneValidation) {
                        inputs.phone.errMessage = "niepoprawny numer";
                        inputs.phone.error = true;
                        isValid = false;
                    }

                    let inputsValidated = await templateInputsValidation();

                    if (!inputsValidated) {
                        isValid = false;
                    }
                    return { isValid: isValid, inputs: inputs };
                } catch (e) {
                    return { isValid: false, inputs: inputs };
                }
            }

            validation().then(ret => {
                this.setState({ inputs: ret.inputs });
                this.scrolltoError();
                return resolve(ret.isValid);
            });
        });
    };

    scrolltoError = () => {
        const node = ReactDOM.findDOMNode(this);
        const bodyRect = document.body.getBoundingClientRect().top + 100;
        let elems = node.querySelectorAll("div[class*='error']");
        if (elems.length) {
            let domRect = elems[0].getBoundingClientRect();
            window.scrollTo({
                top: domRect.top - bodyRect,
                behavior: "smooth"
            });
        }
    };

    templateInputsValidation = () => {
        let inputs = this.state.template;
        return new Promise((resolve, reject) => {
            let isValid = true;
            inputs = inputs.map(input => {
                let resp = { error: false };
                if (!["benefits", "join"].includes(input.content.name)) {
                    resp = validateText(
                        input.content.header,
                        input.content.name
                    );
                }

                if (resp.error === true) {
                    input.content.error = resp.error;
                    input.content.errMessage = resp.message;
                    isValid = false;
                }
                return input;
            });

            this.setState({ template: inputs });
            return resolve(isValid);
        });
    };

    saveDataHandler = (publish = false) => {

        this.submitValidation()
            .then(resp => {
                if (resp) {
                    this.loaderOn();

                    return saveTemplate(
                        {
                            template: this.state.template,
                            inputs: this.state.inputs,
                            leader: this.state.leader,
                            templateId: this.state.templateId,
                            activePageId: this.state.activePageId,
                            tagInputs: this.state.tagInputs,
                            metadata: {
                                title: this.state.temp.metaTitle ?
                                    this.state.temp.metaTitle : this.state.temp?.metadata?.title ? this.state.temp.metadata.title :
                                        `${this.props.store.template.leader.pageName}  ${this.props.store.template.template[0].content.header}`,
                                description: this.state?.temp?.metaDescription ?
                                    this.state.temp.metaDescription : this.state.temp?.metadata?.description ? this.state.temp.metadata.description :
                                        `Pracuj tak jak lubisz. Dowiedz się więcej na ${this.props.store.template.leader.pageName}`
                            }
                        },
                        this.props.store.user,
                        this.props.store.template.edit
                    );
                } else {
                    return false;
                }
            })
            .then(r => {
                if (r.data.status === "success" && publish) {
                    let activePageId = this.state.activePageId;
                    let isPageActive = 0;

                    if (!activePageId) {
                        activePageId = r.data.data.landingPage.id;
                    } else {
                        isPageActive = this.props.store.landing.landingPages.rows.find(
                            page => page.id === activePageId
                        ).active;
                    }

                    if (!isPageActive) {
                        pageOnOff(this.props.store.user, activePageId).then(
                            r => {
                                this.loaderOff();
                                this.changesSavedAndPublished();
                            }
                        );
                    } else {
                        this.loaderOff();
                        this.changesSavedAndPublished();
                    }
                } else {
                    this.loaderOff();
                    this.changesSaved();
                }
            })
            .catch(err => {
                if (
                    err.validation !== "failed" ||
                    err.validation === undefined
                ) {
                    this.loaderOff();
                    this.submitValidation(true);
                    this.showPopUpHandler("saveError", 350);
                } else {
                    this.errorHandler();
                }
            });
    };

    onBlurHandler = (event, id) => {
        if (id === "pageName") {
            const inputs = { ...this.state.inputs };
            if (event.target.value.length > 1) {
                checkName(this.props.store.user, event.target.value)
                    .then(resp => {
                        if (resp.data.status === "success") {
                            inputs[id].value = resp.data.data.unformatedLink;
                            inputs["pageLink"].value =
                                inputs["pageLink"].defaultValue +
                                resp.data.data.link;
                        }

                        if (!resp.data.data.availability) {
                            inputs[id].error = true;
                            inputs[id].errMessage = "nazwa niedostępna";
                        }

                        this.setState({ inputs: inputs });
                    })
                    .catch(err => {
                        inputs[id].error = true;
                        inputs[id].errMessage = "nazwa niepoprawna";
                        this.setState({ inputs: inputs });
                    });
            }
        }
    };

    errorHandler = () => {
        this.props.history.push("/me/error");
    };

    changesSaved = () => {
        this.setState({ popup: { active: true, type: "saved" } });
    };

    changesSavedAndPublished = () => {
        this.setState({ popup: { active: true, type: "savedAndPublished" } });
    };

    openAdvancedSettings = () => {
        this.setState({ popup: { active: true, type: "settings" } });
    };

    loaderOn = () => {
        this.setState({ loader: true });
    };

    loaderOff = () => {
        this.setState({ loader: false });
    };

    previewHandler = name => {
        //this.updateData();
        this.continueHandler("/me/preview");
    };

    deleteHandler = () => {
        this.setState({ popup: { active: true, type: "delete" } });
    };

    advancedHandler = () => {
        this.openAdvancedSettings();
    };

    deleteConfirmationHandler = () => {
        this.closePopUpHandler();
        this.setState({ popup: { active: true, type: "deleteConfirm" } });
        deletePage(this.props.store.user, this.state.activePageId)
            .then(resp => {
                // this.unsetData();
            })
            .catch(err => {
                this.errorHandler();
            });
    };

    popup = () => {
        switch (this.state.popup.type) {
            case "img":
                return (
                    <SelectImg
                        selected={this.imgSelectedHandler}
                        confirmed={this.imgConfirmHandler}
                        images={this.state.images.images}
                        id={"image"}
                        active={this.state.selectedImage}
                    />
                );
            case "vid":
                return (
                    <PasteVideo
                        video={this.state.video}
                        clicked={this.videoHandler}
                    />
                );
            case "savedAndPublished":
                return (
                    <SampleConfirmation
                        content={[
                            "Zmiany zostały zapisane!",
                            "Strona została opublikowana!"
                        ]}
                        btnName={"Podgląd strony"}
                        clicked={this.continueHandler}
                        link={"/me/preview"}
                    />
                );
            case "saved":
                return (
                    <SampleConfirmation
                        content={[
                            "Zmiany zostały zapisane!",
                            "Pamiętaj aby opublikować stronę przed jej udostępnieniem!"
                        ]}
                        btnName={"Podgląd strony"}
                        clicked={this.continueHandler}
                        link={"/me/preview"}
                    />
                );
            case "delete":
                return (
                    <DeleteConfirmation
                        content={"Czy na pewno chcesz usunąć stronę?"}
                        yes={"Tak"}
                        no={"Nie"}
                        clickedYes={this.deleteConfirmationHandler}
                        clickedNo={this.closePopUpHandler}
                        link={"/me/preview"}
                    />
                );
            case "deleteConfirm":
                return (
                    <SampleConfirmation
                        content={"Strona została usunięta!"}
                        btnName={"Powrót do moich stron"}
                        clicked={this.continueHandler}
                        link={"/me/home"}
                    />
                );
            case "settings":
                return (
                    <Advanced
                        confirmed={this.settingsConfirmHandler}
                        inputs={this.state.tagInputs}
                    />
                );
            case "saveError":
                return (
                    <SampleConfirmation
                        content={`Wystąpił błąd podczas zapisu.  
                             Sprawdź dane i spróbuj ponownie.`}
                        btnName={null}
                        clicked={this.closePopUpHandler}
                        link={null}
                    />
                );
            default:
                return null;
        }
    };

    template = () => {
        switch (this.props.store.template.activeTmpId) {
            case 5:
                return (
                    <Template5
                        temp={this.state.template}
                        changed={this.templateInputHandler}
                        clickedShow={this.showPopUpHandler}
                        clickedHide={this.closeImgPopUpHandler}
                        margin={this.state.popup.margin}
                        img={this.state.images.confirmed}
                        video={this.state.video}
                    />
                );
            case 6:
                return (
                    <Template6
                        temp={this.state.template}
                        changed={this.templateInputHandler}
                        clickedShow={this.showPopUpHandler}
                        clickedHide={this.closeImgPopUpHandler}
                        margin={this.state.popup.margin}
                        img={this.state.images.confirmed}
                        video={this.state.video}
                    />
                );
            case 7:
                return (
                    <Template7
                        temp={this.state.template}
                        changed={this.templateInputHandler}
                        clickedShow={this.showPopUpHandler}
                        clickedHide={this.closeImgPopUpHandler}
                        margin={this.state.popup.margin}
                        img={this.state.images.confirmed}
                        video={this.state.video}
                    />
                );
            case 8:
                return (
                    <Template8
                        temp={this.state.template}
                        changed={this.templateInputHandler}
                        clickedShow={this.showPopUpHandler}
                        clickedHide={this.closeImgPopUpHandler}
                        margin={this.state.popup.margin}
                        img={this.state.images.confirmed}
                        video={this.state.video}
                    />
                );
            case 9:
                return (
                    <Template9
                        temp={this.state.template}
                        changed={this.templateInputHandler}
                        clickedShow={this.showPopUpHandler}
                        clickedHide={this.closeImgPopUpHandler}
                        margin={this.state.popup.margin}
                        img={this.state.images.confirmed}
                        video={this.state.video}
                    />
                );
            case 10:
                return (
                    <Template10
                        temp={this.state.template}
                        changed={this.templateInputHandler}
                        clickedShow={this.showPopUpHandler}
                        clickedHide={this.closeImgPopUpHandler}
                        margin={this.state.popup.margin}
                        img={this.state.images.confirmed}
                        video={this.state.video}
                    />
                );
            case 11:
                return (
                    <Template11
                    temp={this.state.template}
                    changed={this.templateInputHandler}
                    clickedShow={this.showPopUpHandler}
                    clickedHide={this.closeImgPopUpHandler}
                    margin={this.state.popup.margin}
                    img={this.state.images.confirmed}
                    video={this.state.video}
                    uplnEmail={this.state.leader.email}
                    phone={this.state.leader.phone}
                    pageId={this.state.pageId}
                    name={this.state.leader.name} 
                />
                );
            default:
                this.errorHandler();
        }
    };

    render() {
        const temp = this.template();
        const popup = this.popup();

        return this.state.loader ? (
            <LoaderComponent />
        ) : (
            <div>
                <ReactNotification />
                <Container clName={"container_left"}>
                    <div id={"edit"}>
                        {this.state.popup.active ? (
                            <PopUp
                                margin={this.state.popup.margin}
                                close={this.closeImgPopUpHandler}
                            >
                                {popup}
                            </PopUp>
                        ) : null}
                        <GoBackButton clicked={this.goHomeHandler} />
                        <section className={css.top_section}>
                            <h3>Edytor strony rekrutacyjnej</h3>
                            <div className={css.text}>
                                Poniżej wpisz swoje dane kontaktowe, które będą
                                wyświetlane na Twojej stronie rekrutacyjnej.
                                Wybierz także tytuł, jaki wyświetli się w
                                zakładce przeglądarki osobie, która kliknie w
                                link na np. “Dołącz do AVON!”. Możesz także
                                wybrać własną nazwę linka. Edytuj pola oznaczone
                                ołówkiem wpisując swój tekst (możesz także
                                zostawić teksty przygotowane przez nas).
                                Wklejając link do YouTube na Twojej stronie
                                pojawi się film. Zadbaj aby strona rekrutacyjna
                                była atrakcyjna dla nowej osoby w AVON - to
                                Twoja wizytówka i reklama! Zjedź na sam dół
                                strony i kliknij “Podgląd mojej strony” aby
                                podejrzeć zmiany” i “Zapisz” aby je zapisać. Na
                                tej stronie możesz także trwale usunąć stronę
                                rekrutacyjną.
                            </div>
                            <div className={css.form_box}>
                                <form>
                                    <div className={css.form_section}>
                                        <h5>Ustawienia strony</h5>
                                        <InputEdit
                                            data={this.state.inputs.pageName}
                                            changed={input =>
                                                this.inputHandler(
                                                    input,
                                                    this.state.inputs.pageName
                                                        .inputName
                                                )
                                            }
                                            blur={this.onBlurHandler}
                                        />
                                        <InputEdit
                                            data={this.state.inputs.pageLink}
                                            changed={input =>
                                                this.inputHandler(
                                                    input,
                                                    this.state.inputs.pageLink
                                                        .inputName
                                                )
                                            }
                                            blur={this.onBlurHandler}
                                        />
                                    </div>
                                    <div className={css.form_section}>
                                        <h5>Dane kontaktowe</h5>
                                        <InputEdit
                                            data={this.state.inputs.email}
                                            blur={this.onBlurHandler}
                                            changed={input =>
                                                this.inputHandler(
                                                    input,
                                                    this.state.inputs.email
                                                        .inputName
                                                )
                                            }
                                        />
                                        <InputEdit
                                            data={this.state.inputs.phone}
                                            changed={input =>
                                                this.inputHandler(
                                                    input,
                                                    this.state.inputs.phone
                                                        .inputName
                                                )
                                            }
                                            blur={this.onBlurHandler}
                                        />
                                    </div>
                                    <div className={css.form_section}>
                                        <h5>Meta tagi</h5>
                                        <InputEdit
                                            data={this.state.inputs.metaTitle || ""}
                                            changed={input =>
                                                this.inputHandler(
                                                    input,
                                                    this.state.inputs.metaTitle
                                                        .inputName
                                                )
                                            }
                                            blur={this.onBlurHandler}
                                        />
                                        <InputEdit
                                            data={this.state.inputs.metaDescription || ""}
                                            changed={input =>
                                                this.inputHandler(
                                                    input,
                                                    this.state.inputs.metaDescription
                                                        .inputName
                                                )
                                            }
                                            blur={this.onBlurHandler}
                                        />
                                    </div>
                                </form>
                            </div>
                            <div className={css.settings}>
                                <ButtonOutline
                                    active={true}
                                    name={"Ustawienia zaawansowane"}
                                    clicked={this.advancedHandler}
                                />
                                <div className={css.text}>
                                    <p>
                                        w ustawieniach zaawansowanych znajdziesz
                                        między innymi kod Google Analytics i Tag
                                        Manager.
                                    </p>
                                </div>
                            </div>
                        </section>
                        {temp}
                        <section className={css.bottom_section}>
                            <ButtonOutline
                                active={true}
                                name={"Zapisz zmiany"}
                                clicked={() => this.saveDataHandler(false)}
                            />
                            <ButtonOutline
                                active={true}
                                name={"Zapisz i opublikuj"}
                                clicked={() => this.saveDataHandler(true)}
                            />
                            <ButtonOutline
                                active={true}
                                name={"Podgląd mojej strony"}
                                clicked={this.previewHandler}
                            />
                            {this.props.store.template.edit ? (
                                <ButtonOutline
                                    active={true}
                                    name={"Usuń stronę"}
                                    clicked={this.deleteHandler}
                                />
                            ) : (
                                <ButtonOutline
                                    active={true}
                                    name={"Anuluj"}
                                    clicked={this.deleteHandler}
                                />
                            )}
                        </section>
                    </div>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        store: state
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setTemplate: data => {
            dispatch(setTemplate(data));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
