import axios from "axios";
import * as queryString from "querystring";
import * as cookies from "./cookies";

export const callApi = (data) => {
    return new Promise((resolve, reject) => {
        const url = process.env.REACT_APP_API_URL + data.leader_id;

        console.log('callApi.url', url);
        
        axios
            .get(url, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    authorization: `Basic ${data.hash} ${data.leader_id}`,
                    leaderDetails: data.uplnemail,
                },
            })
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const deletePage = (user, pageId) => {
    return new Promise((resolve, reject) => {
        const url = `${process.env.REACT_APP_API_URL}${user.userNumber}/${pageId}`;

        console.log('deletePage.url', url);

        axios
            .delete(
                url,
                authHeaders(user)
            )
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
};

export const pageOnOff = (user, id) => {
    return new Promise((resolve, reject) => {
        const url = `${process.env.REACT_APP_API_URL}${user.userNumber}/${id}/active`;

        console.log('pageOnOff.url', url);
        axios
            .patch(
                url,
                {
                    leaderId: parseInt(user.userNumber),
                    landingId: parseInt(id),
                },
                authHeaders(user)
            )
            .then((response) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const setVideo = (available, tempElements) => {
    let vidId = available;

    // eslint-disable-next-line array-callback-return
    tempElements.template.map((element) => {
        if (element.content.name === "video") {
            vidId =
                element.content.value !== undefined ? element.content.value : vidId;
            vidId =
                element.content.value !== undefined ? element.content.value : vidId;
        }
    });

    return vidId;
};

export const metaData = (data) => {
    let title = data.name;
    let description = "";

    // eslint-disable-next-line array-callback-return
    data.PageElements.map((element) => {
        if (element.content.name === "topheading") {
            title += ` - ${element.content.header}`;
        }

        if (element.content.name === "business") {
            description = element.content.header;
        }
    });

    return { title: title, description: description };
};

export const setImages = (available, tempElements) => {
    const availableImages = available.images.map((img) => img.name);
    let images =
        !available.confirmed || available === {}
            ? {
                confirmed: { name: "", tempId: null },
                selected: { name: "", tempId: null },
            }
            : { ...available };

    // eslint-disable-next-line array-callback-return
    tempElements.template.map((element) => {
        if (element.content.name === "image") {
            const val =
                element.content.value !== undefined &&
                    availableImages.includes(element.content.value)
                    ? element.content.value
                    : images.confirmed.name
                        ? images.confirmed.name
                        : "nagrody";
            images.confirmed.name = val;
            images.selected.name = val;
        }
    });
    return images;
};

export const saveTemplate = (data, user, isEdit) => {
    return new Promise((resolve, reject) => {
        const url = `${process.env.REACT_APP_API_URL}${user.userNumber}/${data.activePageId}`;

        console.log('saveTemplate.url', url);

        prepareSaveTempData(data)
            .then((toSave) => {
                if (isEdit) {
                    return axios.patch(
                        url,
                        toSave,
                        {
                            headers: {
                                authorization: `Basic ${user.userToken} ${user.userNumber}`,
                                "Access-Control-Allow-Origin": "*",
                                "Content-Type": "application/json",
                            },
                        }
                    );
                } else {
                    return axios.post(
                        `${process.env.REACT_APP_API_URL}${user.userNumber}/create`,
                        toSave,
                        {
                            headers: {
                                authorization: `Basic ${user.userToken} ${user.userNumber}`,
                                "Access-Control-Allow-Origin": "*",
                                "Content-Type": "application/json",
                            },
                        }
                    );
                }
            })
            .then((response) => {
                return resolve(response);
            })
            .catch((err) => {
                return reject(err);
            });
    });
};

export const prepareSaveTempData = (data) => {
    return new Promise((resolve) => {
        let toSave = {
            name: data.inputs.pageName.value,
            email: data.leader.email,
            phone: data.leader.phone,
            templateId: data.templateId,
            data: cleanTempData(data.template),
            statFb: data.tagInputs.fp,
            statGa: data.tagInputs.ga,
            statGtm: data.tagInputs.gtm,
            statRm: data.tagInputs.gr,
            metadata: data.metadata
        };
        resolve(toSave);
    });
};

const cleanTempData = (temp) => {
    let localObj = temp;
    return Object.keys(localObj).map((key) => {
        delete localObj[key].content.error;
        delete localObj[key].content.errMessage;
        return localObj[key];
    });
};

export const prepareData = (data) => {
    return new Promise((resolve) => {
        resolve({
            hash: data.userToken,
            leader_id: data.userNumber,
            uplnemail: data.uplnemail,
        });
    });
};

export const getUserData = (props) => {
    let data = queryString.parse(props.replace("?", ""));
    let landing = false;
    if (window.location.pathname.match(/\/me/g)) {
        // iframePostMessage();
    } else {
        landing = true;
    }

    if (!data.leaderId || !data.token) {
        if (cookies.checkCookie("authData")) {
            data = JSON.parse(cookies.getCookie("authData"));
        }
    } else {
        cookies.setCookie("authData", JSON.stringify(data), 180);
    }
    return new Promise((resolve) => {
        resolve({
            userNumber: data.leaderId,
            userToken: data.token,
            src: data.src ? data.src : null,
            uplnemail: data.uplnemail,
            landing: landing,
        });
        // resolve({ data: data, landing: landing });
    });
};

export const createdAt = (date) => {
    return date
        .split("T")[0]
        .split("-")
        .reverse()
        .join(".");
};

export const getStatistics = (user, pId) => {
    return new Promise((resolve, reject) => {
        const url = `${process.env.REACT_APP_API_URL}${user.userNumber}/${pId}/statistics`;

        console.log('getStatistics.url', url);
        axios
            .get(
                url,
                authHeaders(user)
            )
            .then((response) => {
                resolve(response);
            })
            .catch((err) => reject(err));
    });
};

export const getTempData = (user, pId) => {
    return new Promise((resolve, reject) => {
        const url = `${process.env.REACT_APP_API_URL}${user.userNumber}/${pId}`;

        console.log('getTempData.url', url);

        axios
            .get(
                url,
                authHeaders(user)
            )
            .then((response) => {
                const landingPage = response.data.data.landingPage;
                const pageTemp = {
                    activePageId: landingPage.id,
                    activeTmpId: landingPage.templateId,
                    edit: true,
                    template: landingPage.PageElements,
                    leader: {
                        phone: landingPage.userdata.phone,
                        email: landingPage.userdata.email
                            ? landingPage.userdata.email
                            : response.data.leaderEmail,
                        pageName: landingPage.name,
                        link: `${process.env.REACT_APP_PAGE_START_AVON}${landingPage.link}`,
                    },
                    metadata: landingPage.SEO?.metadata || { title: "", description: "" },
                    tags: {
                        ga: landingPage.statGa ? landingPage.statGa : "",
                        gtm: landingPage.statGtm ? landingPage.statGtm : "",
                        gr: landingPage.statRm ? landingPage.statRm : "",
                        fp: landingPage.statFb ? landingPage.statFb : "",
                    },
                };

                return resolve(pageTemp);
            })
            .catch((err) => reject(err));
    });
};

export const shareLinks = (user, pId) => {
    return new Promise((resolve, reject) => {
        const url = `${process.env.REACT_APP_API_URL}${user.userNumber}/${pId}/share?elements=false`;

        console.log('shareLinks.url', url);

        axios
            .get(
                url,
                authHeaders(user)
            )
            .then((response) => {
                resolve(response);
            })
            .catch((err) => reject(err));
    });
};

export const checkName = (user, name) => {
    return new Promise((resolve, reject) => {
        const url = `${process.env.REACT_APP_API_URL}${user.userNumber}/create/link`;

        console.log('checkName.url', url);

        axios
            .post(
                url,
                {
                    name: name,
                },
                authHeaders(user)
            )
            .then((response) => {
                return resolve(response);
            })
            .catch((err) => {
                return reject(err);
            });
    });
};

const authHeaders = (user) => {
    return {
        headers: {
            "Access-Control-Allow-Origin": "*",
            authorization: `Basic ${user.userToken} ${user.userNumber}`,
        },
    };
};

export const iframePostMessage = () => {
    setInterval(function () {
        let bodyheight = document.body.scrollHeight;
        let popup = document.querySelectorAll("[class*=popup_modal__]");
        let popupHeight = 0;
        if (popup.length)
            popupHeight = document.querySelectorAll("[class*=popup_modal__]")[0]
                .offsetHeight;

        let message = bodyheight;
        if (bodyheight < popupHeight) message = popupHeight;
        // eslint-disable-next-line no-restricted-globals
        parent.postMessage(message, "https://www2.pl.avon.com");
    }, 1000);
};

export const statisticsGraph = (user, pId) => {
    return new Promise((resolve, reject) => {
        const url = `${process.env.REACT_APP_API_URL}${user.userNumber}/${pId}/graph-stats?elements=false`;

        console.log('checkName.url', url);

        axios
            .get(
                url,
                authHeaders(user)
            )
            .then((response) => {
                return resolve(response);
            })
            .catch((err) => {
                return reject(err);
            });
    });
};
